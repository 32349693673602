import { useState, useEffect } from "react";

interface PolylineProps {
  markers: google.maps.LatLng[];
  map: google.maps.Map;
  maps: any;
}

export function Polyline(props: PolylineProps) {
  const { markers, maps, map } = props;
  const [polyline, setPolyline] = useState(
    new maps.Polyline({
      path: markers,
      geodesic: true,
      strokeColor: "#000000",
      strokeWeight: 2,
      strokeOpacity: 0.3,
    })
  );

  useEffect(() => {
    polyline.setMap(map);
    setPolyline(polyline);

    return () => {
      polyline.setMap(null);
    };
  }, [polyline, map]);

  return null;
}
