import * as Yup from "yup";

const RetrofitLightDeviceSchema = Yup.object().shape({
  retrofitLightDeviceId: Yup.string().required(
    "Retrofit Light device id is required"
  ),
  groupId: Yup.number().required(),
  serialNumber: Yup.string().required("SerialNumber is required"),
});

export default RetrofitLightDeviceSchema;
