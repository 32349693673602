import i18next from "i18next";
import { isString } from "lodash";

import fiLocale from "date-fns/locale/fi";
import ruLocale from "date-fns/locale/ru";
import plLocale from "date-fns/locale/pl";
import enLocale from "date-fns/locale/en-US";

const getTranslation = (key: any, value?: any) => {
  const translations = {
    "formError.string.notType": i18next.t(
      "formError.string.notType",
      "Value must be string"
    ),
    "formError.number.notType": i18next.t(
      "formError.number.notType",
      "Value must be number"
    ),
    "formError.number.min": i18next.t(
      "formError.number.min",
      "Value must be bigger than {{value}}",
      { value }
    ),
    "formError.number.max": i18next.t(
      "formError.number.max",
      "Value must be less than {{value}}",
      { value }
    ),
    "formError.required": i18next.t("formError.required", "Value is required"),
    "formError.password.match": i18next.t(
      "formError.password.match",
      "Passwords must match"
    ),
    "formError.password.length": i18next.t(
      "formError.password.length",
      "The password must contain at least {{value}} characters.",
      { value }
    ),
    "formError.boolean.required": i18next.t(
      "formError.boolean.required",
      "Field must be checked"
    ),
  };

  return translations[key as keyof typeof translations];
};
export const getValidationErrorText = (error: any) => {
  if (!error) {
    return "";
  }
  if (isString(error)) {
    return getTranslation(error);
  }
  return getTranslation(error.key, error.value);
};

export const getDateFnsLocale = (language: string | undefined) => {
  switch (language) {
    case "fi":
      return fiLocale;
    case "ru":
      return ruLocale;
    case "pl":
      return plLocale;
    default:
      return enLocale;
  }
};
