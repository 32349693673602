import { PressureDataErrorType } from "@shared/types";

export function pressureDataErrorCodeToText(error: PressureDataErrorType) {
  switch (error) {
    case PressureDataErrorType.PISTON_PERCENT_TOO_LOW:
      return "Could not find pressure at 95% of press.";
    case PressureDataErrorType.NO_FULL_DETECT_PRESSURE:
      return "No full detect pressure value in pressure data.";
    case PressureDataErrorType.UNKWNOWN:
      return "An unknown error occurred during fill level calculation.";
    case PressureDataErrorType.NEGATIVE_FILL_LEVEL:
      return "Calculation returned a negative fill level, using zero instead.";
    default:
      return "Invalid error type";
  }
}
