import Axios, { AxiosPromise } from "axios";

/**
 * Returns anomalies
 *
 * @export
 * @param {*} action
 * @returns {AxiosPromise}
 */
export function getAnomalies(action: any): AxiosPromise {
  let request =
    process.env.REACT_APP_LATEST_API + "/admin/devices/pressure-data-anomalies";
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    // timeout 30 s
    timeout: 30000,
  };
  return Axios.get(request, config);
}

export function updateAnomaly(action: any): AxiosPromise {
  let request =
    process.env.REACT_APP_LATEST_API + "/admin/devices/pressure-data-anomalies";

  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    // timeout 20 s
    timeout: 20000,
  };
  const body = action.payload.data;

  return Axios.post(request, body, config);
}
