import { useEffect } from "react";
import { connect } from "react-redux";
import * as TYPES from "../constants/actionTypes";
import { useScript } from "../utils/hooks";

// It might make more sense to refactor to use the useDispatch and useSelector hooks everywhere
// instead of using mapXToProps.
const mapDispatchToProps = (dispatch: any) => {
  return {
    surveyAnswered: (token: string, surveyKey: string) => {
      dispatch({
        type: TYPES.USER_SURVEY_ANSWERED,
        payload: { data: { surveyKey }, token },
      });
    },
  };
};

const mapStateToProps = (state: any) => {
  return {
    surveyKey: state.users.activeSurvey,
    token: state.token.key,
  };
};

interface SurveyProps {
  surveyKey: string;
  surveyAnswered(surveyKey: string, token: string): void;
  token: string;
}

export function SurveyPopupComponent(props: SurveyProps) {
  const { surveyKey, surveyAnswered, token } = props;
  const script: any = useScript(
    `https://zef.fi/s/zeffi.js?key=${surveyKey}`,
    "zeffi", // Script namespace
    { async: true, defer: true }
  );

  useEffect(() => {
    if (script && script.zeffi) {
      script.zeffi.openPopup();
    }
  }, [script]);

  useEffect(() => {
    const handler = (event: any) => {
      if (event.origin === "https://survey.zef.fi") {
        if (event.type === "complete") {
          console.log("TODO: Survey completed!", event);
          surveyAnswered(token, surveyKey);
        }
      }
    };

    window.addEventListener("message", handler);
    return () => window.removeEventListener("message", handler);
  }, [surveyAnswered, surveyKey, token]);

  /*
   * iframe or js popup?
  const content: JSX.Element = (
    <SurveyFrame
      title="Survey"
      src="https://survey.zef.fi/gp3f2s5r/"
      width="100%"
      height="95%"
      scrolling="no"
      style={{ minWidth: 320, width: "100%" }}
    />
  );
  */

  return null;
  // <button onClick={() => surveyAnswered(token, surveyKey)}>Debug: Mark survey done</button>
  /* <Modal
      isOpen={show}
      handleClose={() => setShow(false)}
      content={content}
      contentScroll={false}
      height={"60%"}
      width={"80%"}
      title={t("header.userMenu.aboutModal.header", "Europress SMART User Survey")}
      /> */
}

export const SurveyPopup = connect(
  mapStateToProps,
  mapDispatchToProps
)(SurveyPopupComponent);
