import styled from "styled-components";

const SelectItem = styled.div`
  width: 8rem;
  padding: 0.4em 0.4em;
  cursor: pointer;
  text-align: center;

  &.selected {
    background-color: var(--twilight-blue);
    color: white;
  }
`;
const StyledSelector = styled.div`
  display: inline-flex;
  width: min-content;
  border: 1px solid var(--twilight-blue);
  border-radius: 4px;
  color: var(--col-214269);
  font-weight: 500;
  font-family: "Roboto";
  font-size: 0.875rem;

  > ${SelectItem} ~ ${SelectItem} {
    border-left: 1px solid var(--twilight-blue);
  }
`;

export interface SelectorProps<T> {
  onSelect: (id: T) => any;
  selected: T;
  values: { id: T; title: string }[];
}
export function Selector<T extends string>({
  onSelect,
  values,
  selected,
}: SelectorProps<T>) {
  return (
    <StyledSelector className="selector">
      {values.map(({ id, title }) => (
        <SelectItem
          key={id}
          className={selected === id ? "selected" : ""}
          onClick={() => onSelect(id)}
        >
          {title}
        </SelectItem>
      ))}
    </StyledSelector>
  );
}
