export interface DeviceType {
  serialNumber: string;
  homeLatitude?: number;
  homeLongitude?: number;
  fillLevel?: number;
  fillLevelLiquid?: number;
  fillLevelLiquidPct?: number;
  fillLevelPct?: number;
  lastOnline?: Date;
  latitude?: number;
  longitude?: number;
  machineType?: number;
  productType?: string;
  onlineNow?: boolean;
  status?: number;
  statusBitmask?: number;
  site?: string;
  alias?: string;
  customerID?: string;
  ownerGroup?: string;
  operatorGroup?: string;
  wasteFraction?: string;
  wasteFractionAlternative?: string;
  lastEmptyingDate?: Date;
  updatedAt?: Date;
  EStopOn?: boolean;
  parentGroups?: Array<any>;
  equipmentCode?: string;
  serviceIsDue?: boolean;
  signalQualityRSSI?: number;
  controllerTypeId?: number;
  controllerPcbHwRevision?: string;
  firmwareId?: number;
  phoneNumber?: string;
  ownerGroupGuid?: string;
  ownerGroupId?: number;
  operatorGroupGuid?: string;
  guid?: string;
  ownerGroupUpdatedAt?: string | null;
  operatorGroupUpdatedAt?: string | null;
  scaleTotalWeightAfterLastEmptied?: number | null;
  scaleTotalWeightEmptyingLimit?: number | null;
  fillLevelMode?: number;
  // For balers with bale counter
  baleCounter?: {
    pickupLimit: number;
    balesReady: number | null;
    emailRecipients: string[];
  };
  // For skips
  rentalStartDate?: Date;
  rentalEndDate?: Date;
  rentalAddress?: string;
  customerName?: string;
  address?: string | null;
  temperature?: number | null;
  batteryLevel?: number | null;
  // For scale
  scale?: any;
  // For predictions
  validatedFullDate: string | null;
  validatedNotFullUntil: string | null;
  validatedFillPercent: number | null;
  // For fill precent calculated based on pressure curve
  pressureCurveFillPercent: number | null;
}

export interface RoutePoint {
  latitude: number;
  longitude: number;
  date: Date;
}
export interface DeviceRoute {
  device: DeviceType;
  route: Array<RoutePoint>;
}

export enum MachineType {
  Compactor = 0,
  Baler = 1,
  Liquid = 2,
  GateCompactor = 4,
  HorizontalCompactor = 7,
  PendulumCompactor = 8,
  TippingDevice = 9,
  ScrewCompactor = 10,
  TwoChamberCompactor = 11,
  PullingBaler = 13,
  Skip = 100,
}

export enum MarkerType {
  Point = 0,
  Cluster = 1,
  RoutePoint = 2,
  PointGroup = 3,
}

export enum DeviceStates {
  STATUS_UNKNOWN = 0x0000,
  PARAMS_UNSAFE = 0x0001,
  OIL_LEVEL_LOW = 0x0002,
  OIL_TEMP_HIGH = 0x0004,
  OIL_LEAKAGE_DETECTED = 0x0008,
  MOTOR_TEMP_HIGH = 0x0010,
  CONTAINER_NOT_PRESENT = 0x0020,
  DOOR_OPEN_SW_ERROR = 0x0040,
  ESTOP_PRESSED = 0x0080,
  RESET_REQUIRED_AFTER_ESTOP = 0x0100,
  NON_CRITICAL_SAFETY_SWITCH = 0x0200,
  MACHINE_IS_OVERFULL = 0x0400,
  FRONT_BLOCK_ERROR = 0x0800,
  MACHINE_IS_UNSAFE = 0x1000,
  SERVICE_IS_DUE = 0x2000,
  MACHINE_OPERATIONS_BLOCKED = 0x4000,
  WIRE_HAS_RUN_OUT = 0x8000,
  NO_ERRORS_DETECTED = 0xffff,
  MACHINE_FAULT = 0x00010000,
  MOTOR_PROTECT = 0x00020000,
  OIL_LEVEL_AND_TEMP = 0x00040000,
  DOOR_OPEN = 0x00080000,
  BACK_BLOCK_DETECT_WARNING = 0x00100000,
  BACK_BLOCK_DETECT_ERROR = 0x00200000,
  INPUT_TIMING_ERROR = 0x00400000,
  TRAVEL_TIMES_ERROR = 0x00800000,
}

export enum DeviceStatus {
  OK = 0,
  Warning = 1,
  Error = 2,
  InTransit = 3,
  PowerOff = 4,
}

export enum FillState {
  Unknown = 110,
  Empty = 0,
  Level_1 = 1,
  Level_2 = 2,
  AlmostFull = 99,
  Full = 100,
  OverFull = 101,
}

export enum FilterPopupType {
  STATUS = 1,
  FILL_LEVEL = 2,
  EDIT_COLUMNS = 3,
  EDIT_SKIP_COLUMNS = 4,
  MACHINE_TYPE = 5,
}

/*
 * NOTE: Only add new values to the end of this enum
 *
 * These enum integer values are saved to the db for
 * each user (selected columns). As a result, the existing
 * values should not be changed.
 *
 * The display order of these is controlled by
 * selectableColumns/*_DISPLAY_ORDER.
 */
export enum SelectableColumns {
  STATUS = 0,
  SERIAL_NUMBER = 1,
  ASSET_NAME = 2,
  GROUP = 3,
  FILL_LEVEL = 4,
  WASTE_FRACTION = 5,
  LAST_EMPTIED = 6,
  SITE = 7,
  ONLINE = 8,
  OPERATOR_GROUP = 9,
  EQUIPMENT_CODE = 10,
  LAST_ONLINE = 11,
  SIGNAL_QUALITY_RSSI = 12,
  PHONE_NO = 13,
  SERVICE_IS_DUE = 14,
  CONTROLLER = 15,
  HW_REV = 16,
  F_W = 17,
  LOCATION = 18,
  STATUS_MESSAGE = 19,
  PIC = 20,
  CUSTOMER = 21,
  RENTAL_ADDRESS = 22,
  RENTAL_PERIOD = 23,
  PREDICTED_FILL_UP = 24,
  ADDRESS = 25,
  BALE_COUNTER = 26,
  BATTERY_LEVEL = 27,
  OWNER_GROUP_UPDATED_AT = 28,
  OPERATOR_GROUP_UPDATED_AT = 29,
}

export enum UserRole {
  NO_ROLE = 0,
  MOBILE_ROLE = 1,
  FACTORY_ROLE = 2,
}

export enum GroupType {
  REGULAR = 0,
  OPERATOR = 1,
}

export interface Customer {
  customerName: string;
  rentalAddress: string;
  rentalStartDate: Date | undefined;
  rentalEndDate: Date | undefined;
}
export enum SCALE_TAG_STATE {
  UNASSIGNED = 0,
  ASSIGNED = 1,
  REVOKED = 2,
}
export enum Language {
  ENGLISH = 0,
  FINNISH = 1,
  RUSSIAN = 2,
  POLISH = 3,
}
export enum ScaleReportSendingSchedule {
  FIRST_DAY_OF_MONTH = 0,
}

// TODO: PressureAnomalyTypes are not yet implemented, but will be determinated "later" by Europress
// TODO: Anomaly types should be stored in database and have its own backend and interfaces etc.
export interface PressureAnomalyType {
  id: number;
  name: string;
}
export interface PressureAnomalyMetaData {
  PressureCurvesInterpolated: number[];
  ReconstructedPressureCurves: number[][]; // For some reason, these are stored as a 2D array in the database
}
export interface PressureAnomaly {
  id: number;
  model: string;
  modelVersion: string;
  pressureDataId: string;
  anomalyScore: number;
  isAnomaly: boolean | null;
  anomalyType: number; // TODO: Use the PressureAnomalyType interface once implemented
  createdAt: Date;
  updatedAt: Date;
  metaData: PressureAnomalyMetaData;
}
