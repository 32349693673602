import { useState } from "react";
import styled from "styled-components";
import { ItemListHeader } from "../../../components/Common/ItemListHeader";
import { ScaleManageButton } from "./ScaleManageButton";
import { useTranslation } from "react-i18next";
import { KeyList } from "./KeyList";

const RevokedKeyListWrapper = styled.div`
  border-top: 1px solid #bfcfde;
  padding: 10px;
  flex: 1;
  min-height: 250px;
  display: flex;
  flex-direction: column;
`;
const ButtonWrapper = styled.div`
  margin-top: auto;
`;

interface RevokedKeyListProps {
  keys: any[];
  handleUnrevoke: any;
}

export function RevokedKeyList(props: RevokedKeyListProps) {
  const { t } = useTranslation();
  const [selectedId, setSelectedId] = useState(undefined);
  const { keys } = props;
  const handleUnrevoke = (id: any) => () => {
    props.handleUnrevoke(id);
  };

  const handleOnClick = (keyId: any) => {
    setSelectedId(keyId);
    // Allow deleselecting
    if (selectedId === keyId) {
      setSelectedId(undefined);
    }
  };

  return (
    <RevokedKeyListWrapper>
      <ItemListHeader text={t("scale.manage.unrevoke.keys", "Revoked keys")} />
      <KeyList
        keys={keys}
        selectedId={selectedId}
        editMode
        onClick={handleOnClick}
        noDataText={t(
          "scale.manage.unrevoke.noKeys",
          "The group has no revoked keys"
        )}
      />
      {selectedId && (
        <ButtonWrapper>
          <ScaleManageButton
            buttonText={t("scale.manage.unrevoke.button", "Unrevoke")}
            helpText={t(
              "scale.manage.unrevoke.description",
              "Reactivates a key so that it can be used again."
            )}
            onClick={handleUnrevoke(selectedId)}
          />
        </ButtonWrapper>
      )}
    </RevokedKeyListWrapper>
  );
}
