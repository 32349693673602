import React from "react";
import styled from "styled-components";

const ButtonWrapper = styled.div`
  padding: 5px 10px;
  background-color: #fafbfc;
`;

const Button = styled.button`
  width: 100%;
  padding: 5px;
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  font-stretch: condensed;
  letter-spacing: 0.25px;
  color: #214269;
  text-transform: uppercase;
  margin-top: 10px;
  cursor: pointer;
`;

const HelpText = styled.p`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 5px;
  margin-bottom: 0;
`;

interface ScaleManageButtonProps {
  buttonText: string;
  helpText: string;
  onClick: any;
}

export function ScaleManageButton(props: ScaleManageButtonProps) {
  const { buttonText, helpText, onClick } = props;

  return (
    <ButtonWrapper>
      <Button onClick={onClick}>{buttonText}</Button>
      <HelpText>{helpText}</HelpText>
    </ButtonWrapper>
  );
}
