import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { ScalePage } from "../../Scale/ScalePage";
import * as TYPES from "../../../constants/actionTypes";
import { FormInput, Input } from "../../../components/Common/FormInput";
import { Formik } from "formik";
import { MinimumWeightSchema } from "./schemas";
import { getValidationErrorText } from "../../../utils/i18nUtils";
import { FormSubmitButton } from "../../../components/Common/FormSubmitButton";
import { effectiveGroupsSelector } from "../../../selectors/groups";

const Button = styled(FormSubmitButton)`
  margin-left: 12.5%;
`;
const Title = styled.label`
  font-family: Poppins;
  font-size: 1.125rem;
  font-weight: 500;
  color: var(--col-1-c-2020);
  width: 75%;
  margin: 0 auto 1rem;
`;
const Select = styled(Input).attrs({
  as: "select",
})`
  flex: 0;
  width: 75%;
`;
const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0.5rem;
  padding: 1rem;
  flex: 1;
  max-width: 50%;
  background-color: var(--ice-blue);
  border-radius: 4px;
`;
const Page = styled.div`
  padding: 0.5rem;
  display: flex;
`;

function MinimumWeightsForm({
  scale,
}: {
  scale: {
    use_min_weight: boolean;
    min_weight_button_threshold: number;
    min_weight: number;
    id: number;
  };
}) {
  const token = useSelector((state: any) => state.token.key);
  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={{
        active: scale.use_min_weight,
        weight: scale.min_weight,
        buttonThreshold: scale.min_weight_button_threshold,
      }}
      onSubmit={(values, actions) => {
        dispatch({
          type: TYPES.ADMIN_UPDATE_SCALE,
          payload: {
            data: {
              use_min_weight: values.active,
              min_weight_button_threshold: values.buttonThreshold,
              min_weight: values.weight,
            },
            scaleId: scale.id,
            token,
          },
        });
        actions.setSubmitting(false);
      }}
      validationSchema={MinimumWeightSchema}
    >
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        values,
        errors,
        touched,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <FormInput
            id="active"
            label="Active"
            labelColor="var(--col-1-c-2020)"
            type="checkbox"
            error=""
            value={values.active}
            onChange={handleChange}
            onBlur={handleBlur}
            required
          />
          <FormInput
            id="weight"
            type="number"
            label="Weight (kg)"
            labelColor="var(--col-1-c-2020)"
            error={
              touched.weight ? getValidationErrorText(errors.weight) : undefined
            }
            value={values.weight}
            onChange={handleChange}
            onBlur={handleBlur}
            required
          />
          <FormInput
            id="buttonThreshold"
            type="number"
            label="Button press threshold (s)"
            labelColor="var(--col-1-c-2020)"
            error={
              touched.buttonThreshold
                ? getValidationErrorText(errors.buttonThreshold)
                : undefined
            }
            value={values.buttonThreshold}
            onChange={handleChange}
            onBlur={handleBlur}
            required
          />
          <Button type="submit" disabled={isSubmitting}>
            Save
          </Button>
        </form>
      )}
    </Formik>
  );
}

export function ScaleMinimumWeights() {
  const token = useSelector((state: any) => state.token.key);
  const scales = useSelector((state: any) => state.admin.scales as any[]);
  const effectiveGroups = useSelector(effectiveGroupsSelector);
  const dispatch = useDispatch();
  const [scaleId, setScaleId] = useState<number>();

  const groupGuids =
    effectiveGroups.type === "root"
      ? effectiveGroups.hierarchies.map((group) => group.guid)
      : [effectiveGroups.selectedGroup.guid];

  const scaleOptions = !scales
    ? []
    : scales
        .filter(
          (scale) =>
            groupGuids.includes(scale.device?.ownerGroupGuid) ||
            groupGuids.includes(scale.device?.operatorGroupGuid)
        )
        .map((scale) => ({
          deviceSerialNumber: scale.device.serialNumber,
          serialNumber: scale.scale_serial_number,
          id: scale.id,
        }));

  useEffect(() => {
    dispatch({
      type: TYPES.FETCH_SCALES,
      payload: {
        token,
      },
    });
  }, [dispatch, token]);

  useEffect(() => {
    if (!scaleOptions.find((scale) => scale.id === scaleId)) {
      setScaleId(0 < scaleOptions.length ? scaleOptions[0].id : undefined);
    }
    // eslint-disable-next-line
  }, [effectiveGroups, scales]);

  return (
    <ScalePage>
      <Page>
        <Box>
          {scaleOptions.length === 0 ? (
            <div>This group has no scales.</div>
          ) : (
            <>
              <Title htmlFor="scaleId">Select scale</Title>
              <Select
                id="scaleId"
                value={scaleId}
                onChange={(event: any) =>
                  setScaleId(parseInt(event.target.value))
                }
              >
                {scaleOptions.map(
                  ({ id, serialNumber, deviceSerialNumber }) => (
                    <option key={id} value={id}>
                      {`${deviceSerialNumber} / ${serialNumber}`}
                    </option>
                  )
                )}
              </Select>
            </>
          )}
        </Box>
        {scaleId !== undefined && (
          <Box>
            <Title>Set minimum weight settings</Title>
            <MinimumWeightsForm
              scale={scales.find((scale) => scale.id === scaleId)}
            />
          </Box>
        )}
      </Page>
    </ScalePage>
  );
}
