import Supercluster from "supercluster";
import { DeviceStatus, FillState } from "../interfaces/types";

interface Location {
  homeLatitude?: number;
  homeLongitude?: number;
  latitude?: number;
  longitude?: number;
  status?: number;
  fillLevel?: number;
}
/**
 * Cluster class works as wrapper for SuperCluster and can be used for as a helper class which
 * for clustering any information on in map
 *
 * @export
 * @class Cluster
 */
export class Cluster<T extends Location> {
  private _index: any = undefined;
  private _latestClusters: any = undefined;
  constructor(radius: number, minZoom: number, maxZoom: number) {
    this._index = new Supercluster({
      radius: radius,
      maxZoom: maxZoom,
      minZoom: minZoom,
      map: (props: any) => {
        let warning = false;
        if (
          (props.status && props.status === DeviceStatus.Error) ||
          props.status === DeviceStatus.Warning ||
          (props.fillLevel && props.fillLevel === FillState.OverFull)
        ) {
          warning = true;
        }
        return { warning: warning };
      },
      reduce: (notification: any, props: any) => {
        if (props.warning) {
          notification.warning = true;
        }
        return notification;
      },
    });
  }
  /**
   * Transforms data on GeoJSON exactly as GeoJSON point. Note! if location is
   * not defined that data point is discared. After transform loads data into index
   *
   * @param {Array<T>} data
   * @returns {boolean} true if some of data was loaded into index false if there was not any data
   * @memberof Cluster
   */
  public load(data: Array<T>): boolean {
    // Transform data to GeoJSON
    const points: Array<any> = [];
    for (const object of data) {
      if (object.latitude && object.longitude) {
        points.push({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [object.longitude, object.latitude],
          },
          properties: {
            object: object,
            status: object.status,
            fillLevel: object.fillLevel,
          },
        });
      } else if (object.homeLatitude && object.homeLongitude) {
        points.push({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [object.homeLongitude, object.homeLatitude],
          },
          properties: {
            object: object,
            status: object.status,
            fillLevel: object.fillLevel,
          },
        });
      }
    }
    this._index.load(points);
    return points.length > 0;
  }
  /**
   * For the given bbox array ([westLng, southLat, eastLng, northLat]) and integer zoom,
   * returns an array of clusters as points as GeoJSON Feature objects.
   *
   * @param {Array<number>} bbox
   * @param {number} zoom
   * @returns {*}
   * @memberof Cluster
   */
  public getClusters(bounds: any, zoom: number): Array<any> {
    const ne = bounds.ne;
    const sw = bounds.sw;
    const bbox = [sw.lng, sw.lat, ne.lng, ne.lat];
    this._latestClusters = this._index.getClusters(bbox, zoom);
    return this._latestClusters;
  }
  public getCluster(clusterId: number): any | undefined {
    for (const cluster of this._latestClusters) {
      if (
        cluster &&
        cluster.properties &&
        cluster.properties.cluster_id === clusterId
      ) {
        return cluster;
      }
    }
    return undefined;
  }
  public getClusterExpansionZoom(clusterId: number): number {
    return this._index.getClusterExpansionZoom(clusterId);
  }
  public getLeaves(clusterId: number, childrens: number = Infinity): any {
    return this._index.getLeaves(clusterId, childrens);
  }
}
