import {
  VERIFY_MFA_ACTION,
  VERIFY_MFA_ACTION_SUCCESS,
  VERIFY_MFA_ACTION_FAILURE,
  ENABLE_MFA_FOR_USER,
  ENABLE_MFA_FOR_USER_SUCCESS,
  ENABLE_MFA_FOR_USER_FAILURE,
  DISABLE_MFA_FOR_USER,
  DISABLE_MFA_FOR_USER_SUCCESS,
  DISABLE_MFA_FOR_USER_FAILURE,
} from "../constants/actionTypes";

const initialState: any = {
  step: 0,
  isLoading: false,
};

const mfa = (state = initialState, action: any) => {
  switch (action.type) {
    case VERIFY_MFA_ACTION: {
      return { ...state, isLoading: true };
    }
    case VERIFY_MFA_ACTION_SUCCESS: {
      return { ...state, step: 1, isLoading: false };
    }
    case VERIFY_MFA_ACTION_FAILURE: {
      return { ...state, isLoading: false };
    }
    case ENABLE_MFA_FOR_USER: {
      return { ...state, isLoading: true };
    }
    case ENABLE_MFA_FOR_USER_SUCCESS: {
      return {
        ...state,
        step: 0,
        isLoading: false,
      };
    }
    case ENABLE_MFA_FOR_USER_FAILURE: {
      return { ...state, isLoading: false };
    }
    case DISABLE_MFA_FOR_USER: {
      return { ...state, isLoading: true };
    }
    case DISABLE_MFA_FOR_USER_SUCCESS: {
      return {
        ...state,
        step: 0,
        isLoading: false,
      };
    }
    case DISABLE_MFA_FOR_USER_FAILURE: {
      return { ...state, isLoading: false };
    }
    default: {
      return state;
    }
  }
};

export default mfa;
