import { Component } from "react";
import { DeviceType, MachineType } from "../interfaces/types";
import { GoogleMarkerInfoWindow } from "./GoogleMarkerInfoWindow";
import styled from "styled-components";

import icon_alert from "../assets/icon-alert.svg";
import icon_skip_location from "../assets/icon-skip-location.svg";
import * as constants from "../constants/map";

const AlertIcon = styled.img`
  position: absolute;
  object-fit: contain;
  bottom: 65%;
  left: 20px;
`;

const BaleCounterBalesReady = styled.div``;
const BaleCounterPickupLimit = styled.div``;

type BaleCounterProps = { pickupLimit: number };
const BaleCounterIcon = styled.div<BaleCounterProps>`
  position: absolute;
  bottom: 65%;
  right: 20px;
  width: ${(props) => (props.pickupLimit < 10 ? 24 : 28)}px;
  height: ${(props) => (props.pickupLimit < 10 ? 24 : 28)}px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: ${(props) => (props.pickupLimit < 10 ? "row" : "column")};
  border-radius: 100%;
  background-color: white;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  line-height: 1;
  color: var(--col-214269);
  font-family: Roboto;
  padding: 4px ${(props) => (props.pickupLimit < 10 ? "0" : "4")}px;
  font-size: ${(props) => (props.pickupLimit < 100 ? "12" : "10")}px;

  ${BaleCounterBalesReady} {
    align-self: ${(props) =>
      props.pickupLimit < 10 ? "center" : "flex-start"};
  }
  ${BaleCounterPickupLimit} {
    align-self: ${(props) => (props.pickupLimit < 10 ? "center" : "flex-end")};
  }
`;

const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 100%;
  width: ${constants.CIRCLE_MARKER_WIDTH}px;
  height: ${constants.CIRCLE_MARKER_HEIGHT}px;
  // By default top-left corner is at the coordinates;
  // ensure center of the circle is at the coordinates instead
  transform: translate(-50%, -50%);
  background-color: var(--twilight-blue);
  text-align: center;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.5);
  &:hover {
    z-index: 1;
  }
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fafbfc;
`;

const SkipLocationIcon = styled.img`
  width: ${constants.PIN_MARKER_WIDTH}px;
  height: ${constants.PIN_MARKER_HEIGHT}px;
  // By default top-left corner is at the coordinates;
  // ensure bottom-center of the icon is at the coordinates instead
  transform: translate(-50%, -100%);
`;

function BaleCounterInfo({ device }: { device: DeviceType }) {
  if (!device.baleCounter) {
    return null;
  }

  return (
    <BaleCounterIcon pickupLimit={device.baleCounter.pickupLimit}>
      <BaleCounterBalesReady>
        {device.baleCounter.balesReady ?? 0}
      </BaleCounterBalesReady>
      <BaleCounterPickupLimit>
        /{device.baleCounter.pickupLimit}
      </BaleCounterPickupLimit>
    </BaleCounterIcon>
  );
}

interface MarkerProps {
  lat: number;
  lng: number;
  warning: boolean;
  fillLevel: string;
  device: DeviceType;
  zoomIn: boolean;
  type: MachineType;
  handleClickToZoom(center: number[]): void;
  onEditDevice(device: DeviceType): void;
  onShowAnalytics(device: DeviceType): void;
}

export class GoogleMarker extends Component<MarkerProps, any> {
  constructor(props: MarkerProps) {
    super(props);
    this.state = {
      popupOpen: false,
    };
  }
  private handleClick = (): void => {
    this.setState({ popupOpen: !this.state.popupOpen });
  };
  public render(): JSX.Element {
    return (
      <>
        {this.state.popupOpen && (
          <GoogleMarkerInfoWindow
            device={this.props.device}
            handlePopupClose={this.handleClick}
            zoomIn={this.props.zoomIn}
            lat={this.props.lat}
            lng={this.props.lng}
            handleClickToZoom={this.props.handleClickToZoom}
            onEditDevice={this.props.onEditDevice}
            onShowAnalytics={this.props.onShowAnalytics}
          />
        )}
        {this.props.type === MachineType.Skip ? (
          <SkipLocationIcon
            src={icon_skip_location}
            onClick={this.handleClick}
          />
        ) : (
          <Circle onClick={this.handleClick}>
            <BaleCounterInfo device={this.props.device} />
            {this.props.warning && <AlertIcon src={icon_alert} />}
            {this.props.fillLevel}
          </Circle>
        )}
      </>
    );
  }
}
