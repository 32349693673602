import React, { useState } from "react";
import styled from "styled-components";
import { ItemList } from "../../../components/Common/ItemList";
import { ItemListHeader } from "../../../components/Common/ItemListHeader";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { ScaleManageButton } from "./ScaleManageButton";
import { DarkButton } from "../../../components/Common/Button";
import UserGroupSchema from "./UserGroupSchema";
import { getValidationErrorText } from "../../../utils/i18nUtils";

const UserGroupListWrapper = styled.div`
  padding: 10px;
  min-height: 540px;
  display: flex;
  flex: 1;
  min-width: 0;
  flex-direction: column;
`;
const GroupFormTitle = styled.div`
  font-family: Poppins;
  font-weight: 500;
`;
const GroupFormLabel = styled.label`
  font-family: Poppins;
  opacity: 0.8;
  font-size: 0.875em;
`;
const GroupFormLabelSpace = styled(GroupFormLabel)`
  margin: 0 0.5rem;
`;
const GroupFormErrorLabel = styled(GroupFormLabel)`
  color: red;
`;
const GroupFormInput = styled.input`
  display: block;
  width: 100%;
  border: 0;
  border-radius: 1em;
  padding: 0.3rem 0.5rem;
  font-size: 1em;

  :focus {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 0 3px rgba(0, 126, 255, 0.1);
    outline: none;
  }
`;
const CreateGroupForm = styled.form`
  background-color: #eff3f6;
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
  font-size: 0.875rem;

  > ${GroupFormInput} {
    margin: 0.25em 0 0.5em;
  }

  > ${GroupFormTitle} {
    margin: 0 0 0.625rem;
  }

  > ${DarkButton} {
    margin-top: 0.625rem;
    text-transform: uppercase;

    &.cancelEdit {
      margin-right: 0.625rem;
    }
  }
`;

const ButtonWrapper = styled.div`
  margin-top: auto;
`;

const formDefaultValues = { name: "", customerId: "" };

interface UserGroupListProps {
  items: any[];
  selectedId: number | undefined;
  activeId: number | undefined;
  onClick: (groupId: number) => void;
  handleUserGroupCreation: (values: typeof formDefaultValues) => void;
  handleUserGroupDeletion: (id: number) => void;
  handleUserGroupEditing: (
    id: number,
    values: typeof formDefaultValues
  ) => void;
}

export function UserGroupList(props: UserGroupListProps) {
  const { items, selectedId, onClick, activeId } = props;
  const { t } = useTranslation();
  const handleUserGroupDeletion = (id: number) => () => {
    props.handleUserGroupDeletion(id);
  };
  const [editMode, setEditMode] = useState(false);
  const [formInitialValues, setFormInitialValues] = useState(formDefaultValues);

  return (
    <UserGroupListWrapper>
      <ItemListHeader text={t("scale.manage.userGroups.group", "User group")} />
      <ItemList
        items={items}
        selectedId={selectedId}
        activeId={activeId}
        onClick={onClick}
        noDataText={t("scale.manage.userGroups.noGroups", "No groups")}
      />
      <ButtonWrapper>
        {selectedId && (
          <>
            <ScaleManageButton
              buttonText={t("scale.manage.userGroups.edit", "Edit")}
              helpText=""
              onClick={() => {
                const selectedGroup = items.find(
                  (group: { id: number }) => group.id === selectedId
                );
                setFormInitialValues({
                  name: selectedGroup.name || "",
                  customerId: selectedGroup.customerId || "",
                });
                setEditMode(true);
              }}
            />
            <ScaleManageButton
              buttonText={t("scale.manage.userGroups.delete", "Delete")}
              helpText=""
              onClick={handleUserGroupDeletion(selectedId)}
            />
          </>
        )}
        <Formik
          initialValues={formInitialValues}
          onSubmit={(values, actions) => {
            if (editMode) {
              props.handleUserGroupEditing(selectedId!, values);
            } else {
              props.handleUserGroupCreation(values);
            }
            actions.resetForm();
            actions.setSubmitting(false);
          }}
          validationSchema={UserGroupSchema}
          enableReinitialize
        >
          {(props) => (
            <CreateGroupForm onSubmit={props.handleSubmit}>
              <GroupFormTitle>
                {editMode
                  ? t("scale.manage.userGroups.editTitle", "Edit group")
                  : t("scale.manage.userGroups.createTitle", "Create group")}
              </GroupFormTitle>
              <GroupFormLabel htmlFor="name">
                {t("scale.manage.userGroups.groupName", "Name")}
              </GroupFormLabel>
              {props.touched.name && props.errors.name && (
                <>
                  <GroupFormLabelSpace>-</GroupFormLabelSpace>
                  <GroupFormErrorLabel>
                    {getValidationErrorText(props.errors.name)}
                  </GroupFormErrorLabel>
                </>
              )}
              <GroupFormInput
                type="text"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.name}
                id="name"
              />
              <GroupFormLabel htmlFor="customerId">
                {t("scale.manage.userGroups.customerId", "Customer id")}
              </GroupFormLabel>
              <GroupFormInput
                type="text"
                id="customerId"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.customerId}
              />
              {editMode && (
                <DarkButton
                  type="button"
                  className="cancelEdit"
                  onClick={() => {
                    setEditMode(false);
                    setFormInitialValues(formDefaultValues);
                  }}
                >
                  {t("scale.manage.userGroups.cancel", "Cancel")}
                </DarkButton>
              )}
              <DarkButton type="submit" disabled={props.isSubmitting}>
                {editMode
                  ? t("scale.manage.userGroups.save", "Save")
                  : t("scale.manage.userGroups.create", "Create")}
              </DarkButton>
            </CreateGroupForm>
          )}
        </Formik>
      </ButtonWrapper>
    </UserGroupListWrapper>
  );
}
