import * as TYPES from "../constants/actionTypes";

const initialState: any = {
  tags: [],
  adminTags: [],
  userGroups: [],
  loadingUserGroups: false,
  events: [],
  isFetching: false,
};

const scale = (state = initialState, action: any) => {
  switch (action.type) {
    case TYPES.ADMIN_ADD_UNASSIGNED_SCALE_TAG_SUCCESS: {
      const tag = action.payload.tag;
      const adminTags = state.adminTags;
      adminTags.push(tag);
      return { ...state, adminTags: [...adminTags] };
    }
    case TYPES.ADMIN_ADD_UNASSIGNED_SCALE_TAG_FAILURE: {
      return state;
    }
    case TYPES.FETCH_GROUP_SCALE_TAGS_SUCCESS: {
      const tags = action.payload;
      return { ...state, tags: tags };
    }
    case TYPES.FETCH_ADMIN_GROUP_SCALE_TAGS_SUCCESS: {
      const tags = action.payload.tags;
      return { ...state, adminTags: tags };
    }
    case TYPES.ADMIN_REMOVE_SCALE_TAG_SUCCESS: {
      const tags = action.payload.tags;
      return { ...state, adminTags: tags };
    }
    case TYPES.UPDATE_SCALE_TAG: {
      const { secondary_alias, id } = action.payload;
      return {
        ...state,
        tags: state.tags.map((tag: any) =>
          tag.id === id ? { ...tag, secondary_alias } : tag
        ),
      };
    }
    case TYPES.UPDATE_SCALE_TAG_FAILURE: {
      const { secondary_alias_current, id } = action.payload;
      return {
        ...state,
        tags: state.tags.map((tag: any) =>
          tag.id === id
            ? { ...tag, secondary_alias: secondary_alias_current }
            : tag
        ),
      };
    }
    case TYPES.REVOKE_SCALE_TAG_SUCCESS: {
      const revokedTag = action.payload;
      return {
        ...state,
        tags: state.tags.map((tag: any) =>
          tag.id === revokedTag.id ? revokedTag : tag
        ),
      };
    }
    case TYPES.UNREVOKE_SCALE_TAG_SUCCESS: {
      const unrevokedTag = action.payload;
      return {
        ...state,
        tags: state.tags.map((tag: any) =>
          tag.id === unrevokedTag.id ? unrevokedTag : tag
        ),
      };
    }
    case TYPES.UPDATE_SCALE_USER_GROUP_SUCCESS: {
      const renamedGroup = action.payload;
      return {
        ...state,
        userGroups: state.userGroups.map((group: any) =>
          group.id === renamedGroup.id ? renamedGroup : group
        ),
      };
    }
    case TYPES.UPDATE_SCALE_USER_GROUP_FAILURE:
    case TYPES.REVOKE_SCALE_TAG_FAILURE:
    case TYPES.UNREVOKE_SCALE_TAG_FAILURE:
    case TYPES.FETCH_ADMIN_GROUP_SCALE_TAGS_FAILURE:
    case TYPES.ADMIN_REMOVE_SCALE_TAG_FAILURE:
    case TYPES.FETCH_GROUP_SCALE_TAGS_FAILURE: {
      return state;
    }
    case TYPES.ASSIGN_SCALE_TAG_SUCCESS: {
      const assignedTag = action.payload;
      return {
        ...state,
        tags: state.tags.map((tag: any) =>
          tag.id === assignedTag.id ? assignedTag : tag
        ),
      };
    }
    case TYPES.ASSIGN_SCALE_TAG_FAILURE: {
      return state;
    }
    case TYPES.UNASSIGN_SCALE_TAG_SUCCESS: {
      const unassignedTag = action.payload;
      return {
        ...state,
        tags: state.tags.map((tag: any) =>
          tag.id === unassignedTag.id ? unassignedTag : tag
        ),
      };
    }
    case TYPES.UNASSIGN_SCALE_TAG_FAILURE: {
      return state;
    }
    case TYPES.FETCH_USER_GROUPS_FOR_GROUP: {
      return { ...state, loadingUserGroups: true };
    }
    case TYPES.FETCH_USER_GROUPS_FOR_GROUP_SUCCESS: {
      return {
        ...state,
        loadingUserGroups: false,
        userGroups: action.payload,
      };
    }
    case TYPES.FETCH_USER_GROUPS_FOR_GROUP_FAILURE: {
      return { ...state, loadingUserGroups: false };
    }
    case TYPES.CREATE_SCALE_USER_GROUP_SUCCESS: {
      const userGroup = action.payload;
      return {
        ...state,
        userGroups: [...state.userGroups, userGroup],
      };
    }
    case TYPES.CREATE_SCALE_USER_GROUP_FAILURE: {
      return state;
    }
    case TYPES.DELETE_SCALE_USER_GROUP_SUCCESS: {
      const deletedUserGroupId = action.payload;
      const userGroups = state.userGroups.filter(
        (userGroup: any) => userGroup.id !== deletedUserGroupId
      );
      return {
        ...state,
        userGroups,
      };
    }
    case TYPES.DELETE_SCALE_USER_GROUP_FAILURE: {
      return state;
    }
    case TYPES.FETCH_SCALE_EVENTS: {
      return { ...state, isFetching: true };
    }
    case TYPES.FETCH_SCALE_EVENTS_SUCCESS: {
      const events = action.payload.events;
      return { ...state, events: events, isFetching: false };
    }
    case TYPES.FETCH_SCALE_EVENTS_FAILURE: {
      return { ...state, isFetching: false };
    }
    case TYPES.FETCH_SCALE_REPORTS: {
      return { ...state, isFetching: true };
    }
    case TYPES.FETCH_SCALE_REPORTS_SUCCESS: {
      const reports = action.payload;
      return { ...state, reports, isFetching: false };
    }
    case TYPES.FETCH_SCALE_REPORTS_FAILURE: {
      return { ...state, isFetching: false };
    }
    case TYPES.FETCH_SCALE_RESULTS: {
      return { ...state, isFetching: true };
    }
    case TYPES.FETCH_SCALE_RESULTS_SUCCESS: {
      const results = action.payload;
      return { ...state, results, isFetching: false };
    }
    case TYPES.FETCH_SCALE_RESULTS_FAILURE: {
      return { ...state, isFetching: false };
    }
    case TYPES.FETCH_SCALE_REPORT_MONTHS: {
      return state;
    }
    case TYPES.FETCH_SCALE_REPORT_MONTHS_SUCCESS: {
      const reportMonths = action.payload;
      return { ...state, reportMonths };
    }
    case TYPES.FETCH_SCALE_REPORT_MONTHS_FAILURE: {
      return state;
    }
    case TYPES.ADMIN_IMPORT_SCALE_TAGS_SUCCESS: {
      const adminTags = action.payload;
      return { ...state, adminTags };
    }
    case TYPES.ADMIN_IMPORT_SCALE_TAGS_FAILURE: {
      return state;
    }
    case TYPES.FETCH_SCALE_REPORT_SETTINGS: {
      return state;
    }
    case TYPES.FETCH_SCALE_REPORT_SETTINGS_SUCCESS: {
      const reportSettings = action.payload;
      return { ...state, reportSettings };
    }
    case TYPES.FETCH_SCALE_REPORT_SETTINGS_FAILURE: {
      return state;
    }
    case TYPES.FETCH_SCALE_TOTAL_WEIGHT_LIMIT_NOTIFICATION_SETTINGS: {
      return state;
    }
    case TYPES.FETCH_SCALE_TOTAL_WEIGHT_LIMIT_NOTIFICATION_SETTINGS_SUCCESS: {
      const notificationSettings = action.payload;
      return { ...state, notificationSettings };
    }
    case TYPES.FETCH_SCALE_TOTAL_WEIGHT_LIMIT_NOTIFICATION_SETTINGS_FAILURE: {
      return state;
    }
    case TYPES.UPDATE_SCALE_REPORT_SETTINGS: {
      return state;
    }
    case TYPES.UPDATE_SCALE_REPORT_SETTINGS_SUCCESS: {
      return state;
    }
    case TYPES.UPDATE_SCALE_REPORT_SETTINGS_FAILURE: {
      return state;
    }
    case TYPES.RESEND_SCALE_REPORT: {
      return state;
    }
    case TYPES.RESEND_SCALE_REPORT_SUCCESS: {
      return state;
    }
    case TYPES.RESEND_SCALE_REPORT_FAILURE: {
      return state;
    }
    default: {
      return state;
    }
  }
};

export default scale;
