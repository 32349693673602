import {
  FETCH_USER_FOR_REGISTRATION_SUCCESS,
  FETCH_USER_FOR_REGISTRATION_FAILURE,
  FETCH_USER_FOR_REGISTRATION,
  REGISTER_USER,
  REGISTER_USER_FAILURE,
  REGISTER_USER_SUCCESS,
} from "../constants/actionTypes";

const initialState: any = {
  name: "",
  loading: false,
};

const users = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_USER_FOR_REGISTRATION: {
      return { ...state, loading: true };
    }
    case FETCH_USER_FOR_REGISTRATION_SUCCESS: {
      const { data } = action.payload;
      const name = data.name;
      return {
        ...state,
        name,
        loading: false,
      };
    }
    case FETCH_USER_FOR_REGISTRATION_FAILURE: {
      return { ...state, loading: false };
    }
    case REGISTER_USER: {
      return { ...state, loading: true };
    }
    case REGISTER_USER_FAILURE: {
      return { ...state, loading: false };
    }
    case REGISTER_USER_SUCCESS: {
      return { ...state, loading: false };
    }
    default: {
      return state;
    }
  }
};

export default users;
