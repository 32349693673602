import { useMutation, useQuery } from "react-query";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  getGroupForAdmin,
  patchGroupForAdmin,
  postGroupForAdmin,
} from "../api/groups";

const adminKeys = {
  admin: ["admin"] as const,
  groupDetails: () => [...adminKeys.admin, "group", "detail"] as const,
  groupDetail: (id: number) => [...adminKeys.groupDetails(), id] as const,
};

export function useAdminGroupUpdateFormData(groupId: number) {
  const token = useSelector((state: any) => state.token.key);

  return useQuery(
    adminKeys.groupDetail(groupId),
    () => getGroupForAdmin({ token, groupId }),
    {
      refetchOnMount: "always",
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );
}

export function useAdminCreateGroup() {
  const token = useSelector((state: any) => state.token.key);

  return useMutation(
    (data: { group: any }) => postGroupForAdmin({ token, group: data.group }),
    {
      onSuccess: (group) => {
        toast.success(`Created ${group.name} group`);
      },
    }
  );
}

export function useAdminUpdateGroup() {
  const token = useSelector((state: any) => state.token.key);

  return useMutation(
    (data: { group: any; groupId: number }) =>
      patchGroupForAdmin({ token, group: data.group, groupId: data.groupId }),
    {
      onSuccess: (group) => {
        toast.success(`Updated ${group.name} group`);
      },
    }
  );
}
