import { useState } from "react";
import styled from "styled-components";
import { Icon } from "./Common";
import icon_tree from "../assets/icon-tree.svg";
import { Search, SearchUsage } from "./Search";
import { FilterButton } from "./FiltterButton";
import { ClusterDevices } from "./ClusterDevices";
import { GroupSelectionPopup } from "./GroupSelectionPopup";
import { FilterPopupType } from "../interfaces/types";

import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectedGroupNamePathSelector } from "../selectors/groups";

const NavigationContainer = styled.div`
  grid-area: navigation;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--seafoam-blue);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
  @media (min-width: 768px) {
    flex-flow: row wrap;
    align-items: none;
  }
`;

const GroupSelectionElement = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--col-4-f-8-a-89);

  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: condensed;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #e9eef3;

  margin-top: 14px;
  margin-bottom: 7px;
  margin-left: 5px;
  width: 12.3%;
  min-width: 200px;
  min-height: 38px;

  cursor: pointer;

  @media (min-width: 768px) {
    margin-left: 10px;
  }
`;

const TitleElement = styled.div`
  margin-right: 12px;
`;

const NavigationElement = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  @media (min-width: 768px) {
    margin-bottom: 0px;
  }
`;

const GroupPathElement = styled.div`
  display: flex;
  align-items: center;
  align-content: space-between;
  margin-top: 21px;
  margin-bottom: 12px;
  flex-wrap: wrap;
  @media (min-width: 768px) {
    margin-left: 18px;
    margin-top: 14px;
    margin-bottom: 12px;
  }
`;

const SearchElement = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 40px;
  margin-bottom: 5px;
  @media (min-width: 768px) {
    margin-left: auto;
    margin-bottom: 0px;
  }
`;

interface TextProps {
  color?: string | undefined;
  bolded?: boolean;
}

const Text = styled.div<TextProps>`
  font-family: Poppins;
  font-size: 16px;
  font-weight: ${(props) => (props.bolded ? "bold" : "normal")};
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-left: 10px;
  color: ${(props) => (props.color ? props.color : "var(--col-1-c-2020)")};
`;

const DeviceCount = styled.div<{ noMargin: boolean }>`
  font-size: 0.875rem;
  margin-left: ${(props) => (props.noMargin ? 0 : "1.25rem")};
`;

interface SelectedGroupInfoProps {
  path: string[];
  deviceCount?: number | null;
}

function SelectedGroupInfo(props: SelectedGroupInfoProps) {
  const { t } = useTranslation();

  let pathElems: JSX.Element | JSX.Element[] | undefined = undefined;
  if (props.path && props.path.length > 1) {
    pathElems = props.path.map((element: any, index: number) => (
      <Text key={uuidv4()} bolded={index === props.path.length - 1}>
        {element + (index !== props.path.length - 1 ? " \\" : " ")}
      </Text>
    ));
  } else if (props.path.length === 1) {
    pathElems = <Text bolded={true}>{props.path}</Text>;
  }

  return (
    <>
      {pathElems}
      {props.deviceCount != null && (
        <DeviceCount noMargin={pathElems === undefined}>
          {t("navigation.deviceCount", {
            count: props.deviceCount,
            defaultValue: `{{count}} devices`,
          })}
        </DeviceCount>
      )}
    </>
  );
}

interface NavigationProps {
  visibleDevicesCount?: number | null;
  searchActive: boolean;
  hideFilters?: boolean;
}
export function Navigation(props: NavigationProps) {
  const namePath = useSelector(selectedGroupNamePathSelector);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <NavigationContainer>
      <GroupSelectionElement
        className="select-group-step"
        onClick={() => setIsModalOpen(!isModalOpen)}
      >
        <Icon
          src={icon_tree}
          alt={"tree icon"}
          height={24}
          width={24}
          marginRight={10}
          marginLeft={24}
        />
        <TitleElement>
          <GroupSelectionPopup
            onClose={() => setIsModalOpen(false)}
            isModalOpen={isModalOpen}
          />
        </TitleElement>
      </GroupSelectionElement>
      <GroupPathElement>
        <SelectedGroupInfo
          path={namePath}
          deviceCount={props.visibleDevicesCount}
        />
      </GroupPathElement>
      <SearchElement>
        <Search active={props.searchActive} type={SearchUsage.DEVICES} />
      </SearchElement>
      {!props.hideFilters && (
        <NavigationElement>
          <FilterButton
            title={t("navigation.machineTypeButton", "MACHINE TYPE")}
            type={FilterPopupType.MACHINE_TYPE}
          />
          <FilterButton
            title={t("navigation.statusButton", "STATUS")}
            type={FilterPopupType.STATUS}
            className="status-step"
          ></FilterButton>
          <FilterButton
            title={t("navigation.fillLevelButton", "FILL LEVEL")}
            type={FilterPopupType.FILL_LEVEL}
            className="fill-level-step"
          />
        </NavigationElement>
      )}
      <NavigationElement>
        <ClusterDevices />
      </NavigationElement>
    </NavigationContainer>
  );
}
