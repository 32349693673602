import styled from "styled-components";
import { Modal } from "./Modal";
import { FormSubmitButton } from "./FormSubmitButton";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Message = styled.p`
  margin: 0 2rem 1rem;
  text-align: center;
`;

interface ConfirmationModalProps {
  isOpen: boolean;
  message: string;
  onConfirm: (result: boolean) => void;
}
export function ConfirmationModal(props: ConfirmationModalProps) {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={props.isOpen}
      handleClose={() => props.onConfirm(false)}
      title="Confirmation"
      width="30rem"
      height="15rem"
      content={
        <Wrapper>
          <Message>{props.message}</Message>
          <div>
            <FormSubmitButton onClick={() => props.onConfirm(false)}>
              {t("confirmationModal.no", "No")}
            </FormSubmitButton>
            <FormSubmitButton
              margin="0 1rem 0"
              onClick={() => props.onConfirm(true)}
            >
              {t("confirmationModal.yes", "Yes")}
            </FormSubmitButton>
          </div>
        </Wrapper>
      }
    ></Modal>
  );
}
