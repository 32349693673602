import React, { useEffect, useState } from "react";
import styled from "styled-components";
import _ from "lodash/fp";
import { useDispatch, useSelector } from "react-redux";
import { HTMLInput } from "../../../components/Common/FormInput";
import { AdminState } from "../../../reducers/admin";
import * as actions from "../../../constants/actionTypes";
import { FillLevels, PressureData } from "./types";
import { Spinner } from "../../../components/Spinner";
import { Line } from "react-chartjs-2";
import { pressureDataErrorCodeToText } from "./pressureDataErrorCodeToText";

const Container = styled.div`
  width: 100%;
  ${HTMLInput} {
    margin-bottom: 1rem;
    width: 33%;
  }
`;

const Info = styled.div`
  font-family: "Roboto";
  font-size: 0.875rem;
`;

function PressureDateSelect({
  dates,
  onSelect,
}: {
  dates: { measurementDate: string; id: number }[];
  onSelect: (id: number | undefined) => void;
}) {
  const [pressureDate, setPressureDate] = useState("");
  const datesSorted = _.orderBy(["measurementDate"], ["desc"], dates);
  return (
    <>
      <HTMLInput
        type="text"
        list="pressureDataDates"
        value={pressureDate}
        placeholder="Search by date"
        onChange={(e) => {
          setPressureDate(e.target.value);
        }}
        onBlur={() => {
          const selectedEntry = dates.find(
            (fillLevel) => fillLevel.measurementDate === pressureDate
          );
          onSelect(selectedEntry?.id);
        }}
      />
      <datalist id="pressureDataDates">
        {datesSorted.map((date) => (
          <option key={date.id} value={date.measurementDate} />
        ))}
      </datalist>
    </>
  );
}

function Graph({
  pressures,
}: {
  pressures: { pressPercent: number; pressBar: number }[];
}) {
  return (
    <Line
      data={{
        labels: pressures.map((pressure) => pressure.pressPercent),
        datasets: [
          {
            data: pressures.map((pressure) => pressure.pressBar),
          },
        ],
      }}
      options={{
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: "Press percent",
            },
          },
          y: {
            title: {
              display: true,
              text: "Pressure (bar}",
            },
          },
        },
      }}
    />
  );
}

function getRegenerativeText(pressureData: PressureData) {
  const meta = pressureData.fillLevelMeta;
  if ("regenerativeIndex" in meta && meta.regenerativeIndex !== undefined) {
    const pressures = _.sortBy("pressPercent", pressureData.pressureData);
    return `Regenerative found at ${
      pressures[meta.regenerativeIndex].pressPercent
    }%.`;
  } else {
    return "No regenerative found.";
  }
}

function getFillLevelText(pressureData: PressureData) {
  const meta = pressureData.fillLevelMeta;

  if (!meta) {
    return "Fill level has not yet been calculated";
  } else if (!pressureData.didFillLevelError) {
    return `Fill level calculation succesful.`;
  } else if ("errorCode" in meta && meta.errorCode !== undefined) {
    const errorText = pressureDataErrorCodeToText(meta.errorCode);
    return `Fill level calculation error: ${errorText}`;
  }
  return;
}

function PressureDataInfo({ pressureData }: { pressureData: PressureData }) {
  return (
    <div>
      <Info>{getFillLevelText(pressureData)}</Info>
      <Info>{getRegenerativeText(pressureData)}</Info>
      <Info>{`Full detect pressure: ${pressureData.headerData.fullDetectPressure}`}</Info>
    </div>
  );
}

export function PressureDataGraph({
  fillLevels,
  deviceSerial,
}: {
  fillLevels: FillLevels;
  deviceSerial: string;
}) {
  const [pressureId, setPressureId] = useState<undefined | number>();
  const token = useSelector((state: any) => state.token.key);
  const pressureRowData = useSelector((state: { admin: AdminState }) =>
    pressureId !== undefined
      ? state.admin.pressureData.devices[deviceSerial].pressureData[pressureId]
      : undefined
  );
  const pressureDataLoading = useSelector(
    (state: { admin: AdminState }) =>
      state.admin.pressureData.devices[deviceSerial].pressureDataLoading
  );
  const dispatch = useDispatch();

  const pressureData = pressureRowData?.pressureData;
  useEffect(() => {
    if (pressureData === undefined && pressureId !== undefined) {
      dispatch({
        type: actions.GET_PRESSURE_DATA,
        payload: { token, id: pressureId, serialNumber: deviceSerial },
      });
    }
  }, [pressureId, deviceSerial, token, dispatch, pressureData]);

  useEffect(() => {
    setPressureId(undefined);
  }, [deviceSerial]);

  const pressuresSorted = _.sortBy("pressPercent", pressureData);

  return (
    <Container>
      <PressureDateSelect dates={fillLevels} onSelect={setPressureId} />
      {(() => {
        if (!pressureId) {
          return;
        } else if (pressureDataLoading) {
          return <Spinner />;
        } else if (pressureRowData !== undefined) {
          return (
            <>
              <Graph pressures={pressuresSorted} />
              <PressureDataInfo
                pressureData={pressureRowData}
              ></PressureDataInfo>
            </>
          );
        }
        return;
      })()}
    </Container>
  );
}
