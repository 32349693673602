import { useState, useEffect } from "react";

// React hook to include an external script
export const useScript = (url: string, namespace: string, options: any) => {
  const [script, setScript] = useState({});

  useEffect(() => {
    if ((window as any)[namespace]) {
      console.info(`Script '${namespace}' already loaded, skipping.`);
      return;
    }

    const _script = document.createElement("script");

    _script.src = url;
    _script.onload = () =>
      setScript({ [namespace]: (window as any)[namespace] });
    Object.assign(script, options);

    document.body.appendChild(_script);

    return () => {
      document.body.removeChild(_script);
    };

    // TODO: Could move the script loading function into a useCallback hook, and _actually_ fix the eslint warning.
    // Not maybe necessarily worth the time now, since the function arguments are unlikely to change during runtime.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (script) {
    return script;
  }

  return null;
};
