import DropdownTreeSelect from "react-dropdown-tree-select";
import "react-dropdown-tree-select/dist/styles.css";
import {
  InputFeedback,
  FormLabel,
  InputBox,
} from "../../components/Common/FormInput";
import styled from "styled-components";
import { TreeNode } from "../../reducers/groups";

export const DropdownTree = styled(DropdownTreeSelect)`
  display: flex;
  justify-content: center;
  flex: 1;

  input[type="text"] {
    border-radius: 17px;
    margin: 0 auto;
    border-style: none;
    min-width: 320px;
    width: 100%;
    margin-bottom: 0.8rem;

    padding: 0.5rem;
    font-size: 16px;
    font-family: "Roboto";
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--col-214269);
  }

  .dropdown-trigger {
    width: 100%;
    border: none !important;
    padding: 0 !important;
  }

  .tag-list {
    display: flex;
    flex-direction: column;
  }

  .tag-item {
    flex: 1;
  }

  .arrow:after {
    display: none;
  }
`;

interface GroupSelectProps {
  value: number | null | undefined;
  options: TreeNode[];
  id: string;
  onChange: (id: string, groupId: number | undefined) => void;
  onBlur?: (id: string) => void;
  error?: string;
  placeholder?: string;
  disabledGroup?: (group: TreeNode) => boolean;
  required?: boolean;
  label?: string;
}
export function GroupSelect(props: GroupSelectProps) {
  return (
    <InputBox>
      <FormLabel>
        {props.label || "Group"} {props.required ? " *" : null}
      </FormLabel>
      <DropdownTree
        data={getGroupOptions(props.value, props.options, props.disabledGroup)}
        mode="radioSelect"
        keepTreeOnSearch={true}
        onChange={(_, selectedGroups) => {
          const groupId =
            selectedGroups && selectedGroups.length
              ? ((selectedGroups[0].value as unknown) as number)
              : undefined;

          props.onChange(props.id, groupId);
        }}
        onBlur={() => props.onBlur?.(props.id)}
        texts={{ placeholder: props.placeholder }}
      />
      <InputFeedback error={props.error} />
    </InputBox>
  );
}

type MultiGroupSelectProps = Omit<GroupSelectProps, "onChange" | "value"> & {
  onChange: (id: string, groupIds: number[]) => void;
  values: number[];
};
export function MultiGroupSelect(props: MultiGroupSelectProps) {
  return (
    <InputBox>
      <FormLabel>
        {props.label || "Group"} {props.required ? " *" : null}
      </FormLabel>
      <DropdownTree
        data={getGroupOptions(props.values, props.options, props.disabledGroup)}
        mode="multiSelect"
        keepTreeOnSearch={true}
        onChange={(_, selectedGroups) => {
          const groupIds = selectedGroups.map(
            (group) => (group.value as unknown) as number
          );

          console.log(groupIds);

          props.onChange(props.id, groupIds);
        }}
        onBlur={() => props.onBlur?.(props.id)}
        texts={{ placeholder: props.placeholder }}
      />
      <InputFeedback error={props.error} />
    </InputBox>
  );
}

export const getGroupOptions = (
  selectedGroupId: number[] | number | null | undefined,
  hierarchy: TreeNode[],
  disabledGroup: ((group: TreeNode) => boolean) | undefined
) => {
  return hierarchy.map(function recursion(child): any {
    return {
      children: child.children.map(recursion).sort((a, b) => {
        return a.label.localeCompare(b.label);
      }),
      label: child.name,
      value: child.groupId,
      checked: Array.isArray(selectedGroupId)
        ? selectedGroupId.includes(child.groupId)
        : // eslint-disable-next-line
          selectedGroupId == child.groupId,
      disabled: disabledGroup ? disabledGroup(child) : false,
    };
  });
};
