import styled from "styled-components";
import GoogleMapReact, { MapOptions } from "google-map-react";

const Wrapper = styled.main`
  width: 100%;
  height: 100%;
`;

const options: MapOptions = {
  streetViewControl: true,
  disableDefaultUI: false,
  mapTypeControl: true,
  clickableIcons: false,
  styles: [
    {
      featureType: "poi",
      elementType: "labels",
      stylers: [{ visibility: "on" }],
    },
  ],
};

export const GoogleMap = ({ children, ...props }: any) => (
  <Wrapper>
    <GoogleMapReact
      bootstrapURLKeys={{
        key: "AIzaSyDohwVPP6ptotVAWImSGfj9fn6oD9McfQ0",
        libraries: ["places", "geometry", "visualization", "drawing"],
      }}
      options={options}
      {...props}
    >
      {children}
    </GoogleMapReact>
  </Wrapper>
);
