import { useState } from "react";
import styled from "styled-components";
import { ItemListHeader } from "../../../components/Common/ItemListHeader";
import { ScaleManageButton } from "./ScaleManageButton";
import { useTranslation } from "react-i18next";
import { KeyList } from "./KeyList";

const UnassignedKeyListWrapper = styled.div`
  padding: 10px;
  min-height: 250px;
  display: flex;
  flex-direction: column;
`;
const ButtonWrapper = styled.div`
  margin-top: auto;
`;

interface UnassignedKeyListProps {
  keys: any[];
  handleRevoke: any;
}

export function UnassignedKeyList(props: UnassignedKeyListProps) {
  const { t } = useTranslation();
  const [selectedId, setSelectedId] = useState(undefined);
  const { keys } = props;

  const handleOnClick = (keyId: any) => {
    setSelectedId(keyId);
    // Allow deleselecting
    if (selectedId === keyId) {
      setSelectedId(undefined);
    }
  };

  const handleRevoke = (id: any) => () => {
    props.handleRevoke(id);
  };

  return (
    <UnassignedKeyListWrapper>
      <ItemListHeader text={t("scale.manage.revoke.keys", "Unassigned keys")} />
      <KeyList
        keys={keys}
        selectedId={selectedId}
        editMode
        onClick={handleOnClick}
        noDataText={t(
          "scale.manage.revoke.noKeys",
          "The group has no unassigned keys"
        )}
      />
      {selectedId && (
        <ButtonWrapper>
          <ScaleManageButton
            buttonText={t("scale.manage.revoke.button", "Revoke")}
            helpText={t(
              "scale.manage.revoke.description",
              "Stops a lost key from working until reactivated."
            )}
            onClick={handleRevoke(selectedId)}
          />
        </ButtonWrapper>
      )}
    </UnassignedKeyListWrapper>
  );
}
