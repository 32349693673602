import { DeviceType } from "../../interfaces/types";

interface ServiceRequestsProps {
  device: DeviceType;
}

export function ServiceRequests(props: ServiceRequestsProps) {
  if (!props.device) {
    return null;
  }
  return <div>Service requests</div>;
}
