import { createSelector } from "reselect";
import { UsersState } from "../reducers/users";

export const userStateSelector = (state: any): UsersState => state.users;

export const isEuropressUserSelector = createSelector(
  userStateSelector,
  (usersState) => usersState.groups.includes("Europress")
);

export const selectedColumnsSelector = createSelector(
  userStateSelector,
  (usersState) => ({
    deviceColumns: usersState.deviceColumnsSelected,
    skipColumns: usersState.skipColumnsSelected,
  })
);
