import { Component } from "react";
import styled from "styled-components";
import icon_alert from "../assets/icon-alert.svg";

interface AlertIconProps {
  width?: number;
  height?: number;
}
const AlertIcon = styled.img<AlertIconProps>`
  position: absolute;
  object-fit: contain;
  bottom: 66%;
  right: 0px;
  width: ${(props) => (props.width ? props.width : 24)}px;
  height: ${(props) => (props.height ? props.height : 24)}px;
`;
interface OuterCircleProps {
  width: number;
  height: number;
  fontSize: number;
  pointGroup?: boolean;
}
const OuterCircle = styled.div<OuterCircleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 100%;
  font-family: Roboto;
  font-size: ${(props) => props.fontSize}px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fafbfc;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  // By default top-left corner is at the coordinates;
  // ensure center of the circle is at the coordinates instead
  transform: translate(-50%, -50%);
  background-color: var(--seafoam-blue);
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.5);
  text-align: center;
  &:hover {
    z-index: 1;
  }
  opacity: ${(props) => (props.pointGroup ? 0.5 : 1)};
`;
const InnerCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 90%;
  border-radius: 100%;
  border: solid white;
  background-color: var(--col-214269);
`;

interface GoogleClusterMarkerProps {
  id: number;
  onClicked: (id: number) => void;
  devices: number;
  zoom: number;
  lat: number;
  lng: number;
  active: boolean;
  warning: boolean;
  pointGroup?: boolean;
}

export class GoogleClusterMarker extends Component<
  GoogleClusterMarkerProps,
  {}
> {
  render() {
    let width: number = 52;
    let height: number = 52;
    const fontSize: number = 20;
    if (this.props.devices >= 1000) {
      width = 72;
      height = 72;
    } else if (this.props.devices >= 100) {
      width = 62;
      height = 62;
    }
    return (
      <OuterCircle
        width={width}
        height={height}
        fontSize={fontSize}
        pointGroup={this.props.pointGroup}
        onClick={() => {
          this.props.onClicked(this.props.id);
        }}
      >
        {this.props.warning ? (
          <AlertIcon
            width={this.props.devices < 100 ? 22 : 24}
            height={this.props.devices < 100 ? 22 : 24}
            src={icon_alert}
          />
        ) : undefined}
        <InnerCircle>{this.props.devices}</InnerCircle>
      </OuterCircle>
    );
  }
}
