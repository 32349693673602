import styled from "styled-components";
import { Link } from "react-router-dom";

export const FooterLink = styled(Link)`
    margin-top: auto;
    font-size: 14px;
    font-weight: 500;
    font-family: "Poppins"
    line-height: 1.71;
    background-color: white;
    color: var(--col-214269);
    text-decoration: none;
    display: block;
    width: 100%;
    text-align: center;
    padding: 1.5rem 0;
    margin-top: 1.5rem;
`;
