import { useEffect, useState } from "react";

/**
 * Get the width of the scrollbar on the current browser.
 *
 * src: https://davidwalsh.name/detect-scrollbar-width
 */
export const getScrollbarWidth = () => {
  const scrollDiv = document.createElement("div");
  scrollDiv.setAttribute(
    "style",
    "width: 100px; height: 100px; overflow: scroll; position:absolute; top:-9999px;"
  );
  document.body.appendChild(scrollDiv);
  const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  document.body.removeChild(scrollDiv);
  return scrollbarWidth;
};

/**
 * Custom hook for getting the size of a scrollbar.
 */
export const useScrollbarWidth = () => {
  const [scrollbarWidth, setScrollbarWidth] = useState(0);

  useEffect(() => {
    setScrollbarWidth(getScrollbarWidth());
  }, []);

  return scrollbarWidth;
};
