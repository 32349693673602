import { Table } from "../../../components/Common/Table";
import { pressureDataErrorCodeToText } from "./pressureDataErrorCodeToText";
import { FillLevels } from "./types";

const constantTableProps = {
  columns: [
    { Header: "Measurement date", accessor: "measurementDate", width: 2 },
    {
      Header: "Error code",
      accessor: (row: any) =>
        pressureDataErrorCodeToText(row.fillLevelMeta.errorCode),
      id: "errorCode",
      width: 3,
      disableFilters: true,
    },
  ],
  initialSortBy: [{ id: "measurementDate" }],
  enableFilters: true,
  infiniteScroll: true,
};

export function FillLevelErrorsTable({
  fillLevels,
}: {
  fillLevels: FillLevels;
}) {
  const errorFillLevels = fillLevels.filter(
    (fillLevel) => fillLevel.didFillLevelError
  );

  if (errorFillLevels.length === 0) {
    return <div>No fill levels with errors</div>;
  }
  return <Table data={errorFillLevels} {...constantTableProps} />;
}
