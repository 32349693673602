import styled from "styled-components";

interface StyledProps {
  secondary?: boolean;
}

const Title = styled.div<StyledProps>`
  background-color: ${(props) =>
    props.secondary ? "var(--col-214269)" : "var(--seafoam-blue)"};
  border-radius: 2px;
  box-shadow: ${(props) =>
    !props.secondary ? "0 0px 20px 0px rgba(0, 0, 0, 0.203)" : "none"};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TitleText = styled.div<StyledProps>`
  font-family: "Roboto";
  font-size: 28px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: ${(props) => (props.secondary ? "#ffffff" : "var(--col-214269)")};
  padding: 1rem;
`;

export const FormTitle = ({
  title,
  secondary,
}: {
  title: string;
  secondary?: boolean;
}) => {
  return (
    <Title secondary={secondary}>
      <TitleText secondary={secondary}>{title}</TitleText>
    </Title>
  );
};
