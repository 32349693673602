import { HistoryDetailsTable } from "./HistoryDetailsTable";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { DeviceType } from "../../interfaces/types";
import { useTranslation } from "react-i18next";

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

const ContainerRow = styled(Row)`
  padding-top: 16px;
  padding-bottom: 16px;
`;

const TitleColumn = styled(Column)`
  font-weight: 500;
`;

const HistoryDetailsContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
`;

interface HistoryDetailsProps {
  device: DeviceType;
}

export function HistoryDetails(props: HistoryDetailsProps) {
  const { t } = useTranslation();
  if (!props.device) {
    return null;
  }

  const infoData = [
    [
      {
        title: t("deviceModal.historyDetails.serialNumber", "Serial number"),
        value: props.device.serialNumber,
      },
      {
        title: t("deviceModal.historyDetails.alias", "Alias"),
        value: props.device.alias,
      },
      {
        title: t("deviceModal.historyDetails.ownerGroup", "Owner group"),
        value: props.device.ownerGroup,
      },
    ],
    [
      {
        title: t("deviceModal.historyDetails.site", "Site"),
        value: props.device.site,
      },
      {
        title: t("deviceModal.historyDetails.wasteFraction", "Waste fraction"),
        value: props.device.wasteFraction,
      },
      {
        title: t(
          "deviceModal.historyDetails.coordinates",
          "Home location coordinates"
        ),
        value:
          props.device.homeLatitude && props.device.homeLongitude
            ? `${props.device.homeLatitude} ${props.device.homeLongitude}`
            : null,
      },
    ],
  ];

  return (
    <HistoryDetailsContainer>
      <ContainerRow>
        {infoData.map((column: any) => {
          return (
            <Column key={uuidv4()}>
              {column.map((row: any) => {
                return (
                  <Row key={uuidv4()}>
                    <TitleColumn>{row.title}</TitleColumn>
                    <Column>{row.value}</Column>
                  </Row>
                );
              })}
            </Column>
          );
        })}
      </ContainerRow>
      <HistoryDetailsTable
        serialNumber={props.device.serialNumber}
        machineType={props.device.machineType}
      />
    </HistoryDetailsContainer>
  );
}
