import { Component } from "react";
import { DeviceType, MachineType } from "../interfaces/types";
import { getStatusIcon } from "../utils/utils";
import styled from "styled-components";

import icon_close from "../assets/icon-close-blue.svg";
import icon_analytics from "../assets/icon-analytics.svg";
import icon_info from "../assets/icon-info.svg";
import icon_zoom_in from "../assets/icon-zoom-in.svg";
import icon_zoom_out from "../assets/icon-zoom-out.svg";
import { RentalPeriod } from "./RentalPeriod";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getPrimaryStatus, getStatusMessage } from "../utils/deviceStatus";
import * as constants from "../constants/map";
import { isEuropressUserSelector } from "../selectors/users";

const Background = styled.div<{ xOffset: number; yOffset: number }>`
  display: block;
  position: absolute;
  z-index: 1;
  opacity: 1;
  top: ${(props) => -138 + props.yOffset}px;
  left: ${(props) => -54 + props.xOffset}px;
  @media (min-width: 768px) {
    top: ${(props) => -273 + props.yOffset}px;
    left: ${(props) => -110 + props.xOffset}px;
  }
  @media (max-width: 767px) {
    .hide-sm {
      display: none !important;
    }
  }
`;

const Content = styled.div`
  background-color: var(--ice-blue);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  text-align: left;
  border-radius: 4px;
  height: 120px;
  width: 140px;
  z-index: 1;
  @media (min-width: 768px) {
    height: 263px;
    width: 253px;
  }
`;
const Title = styled.div`
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: var(--col-1-c-2020);
  margin-left: 12px;
  @media (min-width: 768px) {
    font-size: 18px;
    height: 26px;
  }
`;
const Header = styled.div`
  display: flex;
  border-radius: 4px 4px 0px 0px;
  justify-content: right;
  align-items: center;
  flex-direction: row-reverse;
  height: 14px;
  width: 100%;
  @media (min-width: 768px) {
    height: 18px;
  }
`;
const Footer = styled.div`
  height: 14px;
  width: 100%;
  z-index: 1;
  @media (min-width: 768px) {
    height: 28px;
  }
`;
const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  margin: 6px 12px 12px;
  height: 50px;

  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;

  text-align: left;
  @media (min-width: 768px) {
    height: 160px;
    font-size: 14px;
  }
`;

const Icon = styled.img`
  object-fit: contain;
  width: 18px;
  height: 18px;
  margin-left: 5px;
`;
const IconCell = styled.div`
  display: flex;
  height: 20px;
`;
interface CellProps {
  marginLeft?: number;
  textColor?: string;
}
const Cell = styled.div<CellProps>`
  height: 20px;
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : 0)}px;
  color: ${(props) => (props.textColor ? props.textColor : "black")};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: default;
`;

const Square = styled.div`
  width: 20px;
  height: 20px;
  padding: 1px;
  z-index: 0;
  margin: -15px auto 0;
  transform: rotate(45deg);
  background-color: var(--ice-blue);
`;
const IconClose = styled.img`
  object-fit: contain;
  height: 12px;
  width: 12px;
  margin-top: 5px;
  margin-right: 5px;
  @media (min-width: 768px) {
    width: 20px;
    height: 20px;
  }
`;
const ToolRect = styled.div`
  display: flex;
  justify-content: center;
  z-index: 1;
  margin-top: -2px;
  margin-left: 8px;
  @media (min-width: 768px) {
    margin-left: 18px;
    margin-top: 18px;
  }
`;
interface IconToolProps {
  marginRight?: number;
  marginLeft?: number;
}
const IconTool = styled.img<IconToolProps>`
  object-fit: contain;
  cursor: pointer;
  outline: none;
  width: 18px;
  height: 18px;
  z-index: 1;
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : 0)}px;
  margin-right: ${(props) => (props.marginRight ? props.marginRight : 8)}px;
  @media (min-width: 768px) {
    width: 28px;
    height: 28px;
    margin-right: 19px;
  }
`;

function mapStateToProps(state: any) {
  return {
    isEuropressUser: isEuropressUserSelector(state),
  };
}

interface InfoWindowProps extends WithTranslation {
  device: DeviceType;
  handlePopupClose(): void;
  zoomIn: boolean;
  lat: number;
  lng: number;
  handleClickToZoom(center: number[]): void;
  onEditDevice(device: DeviceType): void;
  onShowAnalytics(device: DeviceType): void;
  isEuropressUser: boolean;
}

class GoogleMarkerInfoWindowComponent extends Component<InfoWindowProps, any> {
  private getDeviceToolBar(): JSX.Element {
    // TODO check from user permissions which tool items should be visible
    return (
      <ToolRect>
        <IconTool
          src={icon_info}
          onClick={() => {
            this.props.onEditDevice(this.props.device);
          }}
        />
        {(this.props.device.machineType !== undefined ||
          this.props.device.machineType !== null) &&
        this.props.device.machineType !== MachineType.Skip ? (
          <IconTool
            src={icon_analytics}
            onClick={() => {
              this.props.onShowAnalytics(this.props.device);
            }}
          />
        ) : undefined}
        <IconTool
          src={this.props.zoomIn ? icon_zoom_in : icon_zoom_out}
          onClick={() => {
            this.props.handleClickToZoom([this.props.lat, this.props.lng]);
          }}
        />
      </ToolRect>
    );
  }
  private getStatus() {
    const text = getStatusMessage(
      this.props.device,
      this.props.isEuropressUser
    );
    if (text !== null) {
      return (
        <Cell marginLeft={5} title={text}>
          {text}
        </Cell>
      );
    }
    return <Icon src={getStatusIcon(getPrimaryStatus(this.props.device))} />;
  }
  private getMachineType(type: number | undefined): string {
    const { t } = this.props;

    if (type !== undefined) {
      switch (type) {
        case 0: {
          return t("markerInfoWindow.machineType.compactor", "Compactor");
        }
        case 1: {
          return t("markerInfoWindow.machineType.baler", "Baler");
        }
        case 2: {
          return t(
            "markerInfoWindow.machineType.liquidExtractor",
            "Liquid-extractor"
          );
        }
        case 13: {
          return t("markerInfoWindow.machineType.baler", "Baler");
        }
        case 100: {
          return t("markerInfoWindow.machineType.skip", "Skip");
        }
        default: {
          return "";
        }
      }
    }
    return "";
  }
  private getSkipInfo(): JSX.Element {
    const { t } = this.props;

    return (
      <Background
        xOffset={-(constants.PIN_MARKER_WIDTH / 2)}
        yOffset={-constants.PIN_MARKER_HEIGHT}
      >
        <Content>
          <Header>
            <IconClose onClick={this.props.handlePopupClose} src={icon_close} />
          </Header>
          <Title>{t("markerInfoWindow.skipInfo.title", "Container")}</Title>
          <Body>
            <Cell title={this.props.device.alias}>
              {t("markerInfoWindow.skipInfo.assetName", "Asset Name")}:{" "}
              {this.props.device.alias}
            </Cell>
            <Cell title={this.props.device.serialNumber}>
              {t("markerInfoWindow.skipInfo.serialNumber", "Serial Number")}:{" "}
              {this.props.device.serialNumber}
            </Cell>
            <Cell title={this.props.device.customerName} className="hide-sm">
              {t("markerInfoWindow.skipInfo.customer", "Customer")}:{" "}
              {this.props.device.customerName}
            </Cell>
            <Cell title={this.props.device.rentalAddress} className="hide-sm">
              {t("markerInfoWindow.skipInfo.rentalAddress", "Rental Address")}:{" "}
              {this.props.device.rentalAddress}
            </Cell>
            <div className="hide-sm">
              <RentalPeriod {...this.props.device} showText={true} />
            </div>
            <Cell title={this.props.device.address ?? ""} className="hide-sm">
              {t("markerInfoWindow.skipInfo.address", "Address")}:{" "}
              {this.props.device.address}
            </Cell>
          </Body>
          <Footer className="hide-sm">{this.getDeviceToolBar()}</Footer>
        </Content>
        <Square />
      </Background>
    );
  }
  public render(): JSX.Element {
    const { t } = this.props;

    if (window.innerWidth < 768) {
      if (
        this.props.device.machineType &&
        this.props.device.machineType === MachineType.Skip
      ) {
        return <>{this.getSkipInfo()}</>;
      }
      return (
        <Background
          xOffset={-(constants.CIRCLE_MARKER_WIDTH / 2)}
          yOffset={-constants.CIRCLE_MARKER_HEIGHT / 2}
        >
          <Content>
            <Header>
              <IconClose
                onClick={this.props.handlePopupClose}
                src={icon_close}
              />
            </Header>
            <Title>
              {t("markerInfoWindow.small.title", "Device")}{" "}
              {this.props.device.serialNumber}
            </Title>
            <Body>
              <IconCell>
                {" "}
                {t("markerInfoWindow.small.status", "Status")}:{" "}
                {this.getStatus()}
              </IconCell>
              <Cell title={this.props.device.alias}>
                {" "}
                {t("markerInfoWindow.small.asset", "Asset")}:{" "}
                {this.props.device.alias}
              </Cell>
              {this.props.device.alias === undefined ||
              this.props.device.alias === "" ? (
                <Cell title={this.props.device.machineType?.toString()}>
                  {" "}
                  {t(
                    "markerInfoWindow.small.machineType",
                    "Machine Type"
                  )}: {this.props.device.machineType}
                </Cell>
              ) : undefined}
            </Body>
            <Footer>{this.getDeviceToolBar()}</Footer>
          </Content>
          <Square />
        </Background>
      );
    }
    if (
      this.props.device.machineType &&
      this.props.device.machineType === MachineType.Skip
    ) {
      return <>{this.getSkipInfo()}</>;
    }
    return (
      <Background
        xOffset={-(constants.CIRCLE_MARKER_WIDTH / 2)}
        yOffset={-(constants.CIRCLE_MARKER_HEIGHT / 2)}
      >
        <Content>
          <Header>
            <IconClose onClick={this.props.handlePopupClose} src={icon_close} />
          </Header>
          <Title>
            {t("markerInfoWindow.normal.title", "Device")}{" "}
            {this.props.device.serialNumber}
          </Title>
          <Body>
            <Cell>
              {t("markerInfoWindow.normal.assetName", "Asset Name")}:{" "}
              {this.props.device.alias}
            </Cell>
            <Cell title={this.props.device.site}>
              {t("markerInfoWindow.normal.site", "Site")}:{" "}
              {this.props.device.site}
            </Cell>
            <Cell>
              {t("markerInfoWindow.normal.machineType", "Machine Type")}:{" "}
              {this.getMachineType(this.props.device.machineType)}
            </Cell>
            <Cell title={this.props.device.productType} className="hide-sm">
              {t("markerInfoWindow.normal.modelName", "Model Name")}:{" "}
              {this.props.device.productType}
            </Cell>
            <Cell>
              {t("markerInfoWindow.connectionState.title", "Connection State")}:{" "}
              {this.props.device.onlineNow === true
                ? t("markerInfoWindow.connectionState.online", " Online")
                : t("markerInfoWindow.connectionState.offline", " Offline")}
            </Cell>
            <Cell className="hide-sm">
              {t("markerInfoWindow.normal.lastOnline", "Last Online")}:{" "}
              {this.props.device.lastOnline
                ? new Date(this.props.device.lastOnline).toLocaleDateString()
                : undefined}
            </Cell>
            <IconCell>
              {t("markerInfoWindow.status", "Status")}:{this.getStatus()}
            </IconCell>
            <Cell
              title={
                this.props.device.wasteFraction ||
                this.props.device.wasteFractionAlternative ||
                ""
              }
            >
              {t("markerInfoWindow.wasteFraction", "Waste Fraction")}:{" "}
              {this.props.device.wasteFraction
                ? this.props.device.wasteFraction
                : this.props.device.wasteFractionAlternative}
            </Cell>
          </Body>
          <Footer>{this.getDeviceToolBar()}</Footer>
        </Content>
        <Square />
      </Background>
    );
  }
}

export const GoogleMarkerInfoWindow = connect(mapStateToProps)(
  withTranslation()(GoogleMarkerInfoWindowComponent)
);
