import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";

interface TabProps {
  selected: boolean;
}

const separator = css`
  &:not(:last-child):after {
    content: "|";
    font-weight: 500;
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const Tab = styled.span<TabProps>`
  font-weight: ${(props) => (props.selected ? 700 : 500)};
  cursor: pointer;
  ${separator}
`;

export const NavTab = styled(NavLink)`
  text-decoration: none;
  color: #000000;
  font-weight: 500;
  ${separator}

  &.active {
    font-weight: 700;
  }
`;
