import * as Yup from "yup";

const MinimumWeightSchema = Yup.object().shape({
  active: Yup.boolean(),
  weight: Yup.number().required().min(0),
  buttonThreshold: Yup.number().required().min(0),
});

const ScaleFirmwareSchema = Yup.object().shape({
  firmware: Yup.string().required("Scale firmware needs to be selected"),
  scales: Yup.array(Yup.string()).min(
    1,
    "At least one scale needs to be selected"
  ),
});

export { MinimumWeightSchema, ScaleFirmwareSchema };
