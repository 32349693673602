import styled from "styled-components";
import { SCALE_TAG_STATE } from "../interfaces/types";

const Tag = styled.div`
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin-top: 11px;
  margin-left: 16px;
`;

const Operation = styled.a`
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0d467d;
  cursor: pointer;
  &:hover {
    color: blue;
  }
`;

interface ScaleTagProps {
  tag: string;
  tag_id: string;
  tag_status: SCALE_TAG_STATE;
  tag_owner: string;
  operation?: string;
  onOperation(tag: string, tag_status: SCALE_TAG_STATE): void;
}

export const ScaleTag = (props: ScaleTagProps) => {
  function handleClick(event: any) {
    event.stopPropagation();
    props.onOperation(props.tag_id, props.tag_status);
  }
  return (
    <Tag>
      {props.tag}{" "}
      {props.operation ? (
        <Operation onClick={handleClick}>{" " + props.operation}</Operation>
      ) : (
        <>{"in " + props.tag_owner}</>
      )}
    </Tag>
  );
};
