import React from "react";
import { Link, Route, RouteComponentProps, Switch } from "react-router-dom";
import styled from "styled-components";
import { FillLevelTasks } from "./FillLevelTasks";
import {
  CreateFillLevelTaskForm,
  UpdateFillLevelTaskForm,
} from "./FillLevelTaskForm";
import { Title } from "../../../components/Common/Title";
import { ErrorTaskList } from "./ErrorTasks/ErrorTaskList";
import { ErrorTaskCreate } from "./ErrorTasks/ErrorTaskCreate";
import { ErrorTaskExecutionHistory } from "./ErrorTasks/ErrorTaskExecutionHistory";
import { ErrorTaskUpdate } from "./ErrorTasks/ErrorTaskUpdate";

const Page = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  background-color: var(--ice-blue);
`;

export function DeviceTasks(props: RouteComponentProps) {
  const { path } = props.match;

  return (
    <Page>
      <Switch>
        <Route path={`${path}/fill-level`} component={FillLevelTasks} exact />
        <Route
          path={`${path}/fill-level/create`}
          component={CreateFillLevelTaskForm}
          exact
        />
        <Route
          path={`${path}/fill-level/update/:id`}
          component={UpdateFillLevelTaskForm}
          exact
        />
        <Route path={`${path}/device-error`} component={ErrorTaskList} exact />
        <Route
          path={`${path}/device-error/history`}
          component={ErrorTaskExecutionHistory}
        />
        <Route
          path={`${path}/device-error/create`}
          component={ErrorTaskCreate}
          exact
        />
        <Route
          path={`${path}/device-error/update/:id`}
          component={ErrorTaskUpdate}
          exact
        />
        <Route
          render={() => (
            <>
              <Title>Select task type</Title>
              <Link to={`${path}/fill-level`}>Fill level</Link>
              <Link to={`${path}/device-error`}>Device error</Link>
            </>
          )}
        />
      </Switch>
    </Page>
  );
}
