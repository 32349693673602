import { UserRole, GroupType } from "../interfaces/types";

export const clusterOptions = {
  defaultRadius: 100,
  defaultMinZoom: 3,
  defaultMaxZoom: 22,
  showPointGroupsMaxZoom: 18,
  defaultAutomaticUnclusterZoomLevel: 16,
};

export const mapOptions = {
  defaultZoom: 5,
  defaultCenter: [60.1665986, 24.939106],
  // lat - lng for bounds:
  defaultMapSouthWest: [35.960222, -9.376831],
  defaultMapNorthEast: [70.289116, 46.790772],
  // Defines when map should be zoomed out (e.x when user click infowindow zoom-button)
  zoomOut: 15,
  // Target out zoom
  zoomOutTarget: 5,
  // Target zoom in
  zoomInTarget: 16,
};
/**
 * Defines how often devices states are updated from backend
 */
export const periodicUpdateCycle = {
  // Timevalue is in ms -> now 1 min
  defaultUpdateCycle: 60000,
};
/**
 * Defines how many elements are added on device list when scroll is on end
 */
export const inifinityScrollOptions = {
  nbElementsToAdd: 50,
};

export const roleOptions = [
  { value: UserRole.NO_ROLE, text: "No role" },
  { value: UserRole.MOBILE_ROLE, text: "Mobile user" },
  { value: UserRole.FACTORY_ROLE, text: "Factory user" },
];

export const groupTypeOptions = [
  { value: GroupType.REGULAR, text: "Regular" },
  { value: GroupType.OPERATOR, text: "Operator" },
];
