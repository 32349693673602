import { Component } from "react";
import { connect } from "react-redux";
import { Page } from "./Page";
import { DevicesPage, DeviceMapSplit } from "../components/Common";
import * as TYPES from "../constants/actionTypes";
import { MachineType, DeviceType } from "../interfaces/types";
import { Header } from "../components/Header";
import { Map } from "./Map";
import { Navigation } from "../components/Navigation";
import { DeviceList } from "../components/DeviceList";
import { Spinner } from "../components/Spinner";
import { EditDevicePopup } from "../components/EditDevicePopup";
import { visibleContainersSelector } from "../selectors/devices";

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchDevices: (token: string, initialFetch: boolean) => {
      dispatch({
        type: TYPES.FETCH_DEVICES,
        payload: {
          token: token,
          initialFetch: initialFetch,
        },
      });
    },
    fetchSkipsRoutes: (token: string, devices: Array<string>) => {
      dispatch({
        type: TYPES.FETCH_DEVICES_ROUTES,
        payload: {
          token: token,
          devices: devices,
        },
      });
    },
  };
};

const mapStateToProps = (state: any) => {
  return {
    token: state.token,
    isFetching: state.devices.isFetching,
    skipDevices: visibleContainersSelector(state),
    skipColumnsSelected: state.users.skipColumnsSelected,
    loadedDevices: state.devices.loadedDevices,
  };
};
interface ContainerState {
  openPopup: boolean;
  activeDevice?: DeviceType;
}
class ContainersConnected extends Component<any, ContainerState> {
  constructor(props: any) {
    super(props);
    this.state = {
      openPopup: false,
      activeDevice: undefined,
    };
  }
  public componentDidMount(): void {
    if (this.props.token.key !== "" && this.props.loadedDevices === 0) {
      this.props.fetchDevices(this.props.token.key, true);
    } else if (
      this.props.token.key !== "" &&
      this.props.skipDevices.length > 0
    ) {
      // Get skips routes
      this.props.fetchSkipsRoutes(
        this.props.token.key,
        this.props.skipDevices.map((device: DeviceType): string => {
          return device.serialNumber;
        })
      );
    }
  }
  public componentDidUpdate(prevProps: any) {
    if (
      this.props.isFetching !== prevProps.isFetching &&
      !this.props.isFetching
    ) {
      // Get skips routes
      this.props.fetchSkipsRoutes(
        this.props.token.key,
        this.props.skipDevices.map((device: DeviceType): string => {
          return device.serialNumber;
        })
      );
    }
  }
  private handleEditDevice = (device: DeviceType): void => {
    this.setState({ openPopup: true, activeDevice: device });
  };
  private handleEditDeviceClose = (): void => {
    this.setState({ openPopup: false, activeDevice: undefined });
  };
  public render(): JSX.Element {
    let spinnerOverlay = this.props.isFetching ? <Spinner /> : undefined;
    return (
      <Page>
        <DevicesPage>
          {spinnerOverlay}
          <Header />
          <Navigation
            visibleDevicesCount={this.props.skipDevices.length}
            searchActive
            hideFilters
          />
          <DeviceMapSplit
            renderDeviceTable={(contentRef) => (
              <>
                <EditDevicePopup
                  open={this.state.openPopup}
                  device={this.state.activeDevice}
                  onClose={this.handleEditDeviceClose}
                />
                <DeviceList
                  devices={this.props.skipDevices}
                  columnsSelected={this.props.skipColumnsSelected}
                  contentRef={contentRef}
                  contentType={MachineType.Skip}
                  onEditDevice={this.handleEditDevice}
                  onShowAnalytics={() => {}}
                  onShowServiceRequests={() => {}}
                />
              </>
            )}
            mapChildren={
              <Map
                deviceTypesOnMap={MachineType.Skip}
                onEditDevice={this.handleEditDevice}
                onShowAnalytics={() => {}}
              />
            }
          />
        </DevicesPage>
      </Page>
    );
  }
}

export const Containers = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContainersConnected);
