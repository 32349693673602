import { combineReducers } from "redux";
import { LOGOUT } from "../constants/actionTypes";
import token from "./token";
import devices from "./devices";
import deviceTasks from "./deviceTasks";
import groups from "./groups";
import users from "./users";
import map from "./map";
import register from "./register";
import admin from "./admin";
import scale from "./scale";
import mfa from "./mfa";
import { connectRouter } from "connected-react-router";

const appReducer = (history: any) =>
  combineReducers({
    token,
    devices,
    deviceTasks,
    groups,
    users,
    map,
    register,
    admin,
    router: connectRouter(history),
    scale,
    mfa,
  });

const root = (history: any) => (state: any, action: any) => {
  if (action.type === LOGOUT) {
    state = undefined;
  }
  return appReducer(history)(state, action);
};

export default root;
