/**
 * Utility function which loads state from browser localstorage
 */
export const loadState = () => {
  try {
    const serializedState = localStorage.getItem("state");
    if (!serializedState || serializedState === "") {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};
/**
 * Utility function which saves state to browser localstorage
 */
export const saveState = (state: any) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (err) {
    // Ignore write errors.
  }
};
/**
 * Utility function which removes state from browser localstorage
 */
export const removeState = () => {
  try {
    localStorage.clear();
  } catch (err) {
    // Ignore remove errors.
  }
};
