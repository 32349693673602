import { useSelector } from "react-redux";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Rect, FlexBoxWrapper } from "./Common";
import { PageSelection } from "./PageSelection";

import icon_logo from "../assets/europress-logo.svg";
import { Icon } from "./Common/Icon";
import { UserElement } from "./UserElement";
import { SurveyPopup } from "./SurveyPopup";

const HeaderElement = styled.header`
  grid-area: header;
  display: flex;
  flex-direction: column;
  background-color: var(--col-214269);
  @media (min-width: 768px) {
    flex-wrap: wrap;
    flex-direction: row;
  }
`;

const PageSelectionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 5px;
  @media (min-width: 768px) {
    margin-left: 42px;
  }
`;

export function Header() {
  const user = useSelector((state: any) => state.users.userName);

  if (!user) {
    return (
      <header>
        <Rect
          justifyContent={"center"}
          alignItems={"center"}
          color={"var(--col-214269)"}
          alignContent={undefined}
          minHeight={81}
        >
          <Icon
            src={icon_logo}
            height={72}
            width={125}
            cursor={"pointer"}
            onClick={() => {
              window.open("https://europressgroup.com");
            }}
            alt="logo"
          />
        </Rect>
      </header>
    );
  }
  return (
    <HeaderElement>
      <FlexBoxWrapper>
        <Link to={"/"}>
          <Icon
            src={icon_logo}
            height={72}
            width={125}
            marginLeft={27}
            marginTop={10}
            marginBottom={5}
            cursor={"pointer"}
            alt="logo"
          />
        </Link>
      </FlexBoxWrapper>
      <PageSelectionWrapper>
        <PageSelection />
      </PageSelectionWrapper>
      <UserElement />
      <SurveyPopup />
    </HeaderElement>
  );
}
