import { Line } from "react-chartjs-2";
import { PressureAnomaly } from "src/interfaces/types";

interface AnomalyChartProps {
  anomalies: PressureAnomaly[];
}

const colors = [
  ["#0362fc", "#9ac0fe"],
  ["#fc0303", "#fe9a9a"],
  ["#fce803", "#fef69a"],
  ["#06c951", "#92fcbb"],
  ["#0487a4", "#74e3fb"],
  ["#ff5500", "#f79463"],
  ["#ff36eb", "#ff8ff4"],
  ["#056934", "#338f5e"],
  ["#4f4f4f", "#8a8a8a"],
  ["#00946f", "#00ffbf"],
];

const AnomalyChart = (props: AnomalyChartProps) => {
  const datasets = props.anomalies.flatMap((a, i) => {
    return [
      {
        id: `${a.id}_i`,
        label: `${a.id} (interpolated)`,
        data: a.metaData.PressureCurvesInterpolated,
        borderWidth: 2,
        backgroundColor: colors[i % colors.length][0], // TODO: Colors shouldn't be based on the array index, because the colors will change (e.g. from red to blue) when adding more lines to the graph. Perhaps generate a deterministic color index based on the anomaly ID or something? Or find a way to not reindex every time, idk.
        borderColor: colors[i % colors.length][0],
      },
      {
        id: `${a.id}_r`,
        label: `${a.id} (reconstructed)`,
        data: a.metaData.ReconstructedPressureCurves[0],
        borderDash: [5, 2],
        borderWidth: 2,
        backgroundColor: colors[i % colors.length][1],
        borderColor: colors[i % colors.length][1],
      },
    ];
  });
  return (
    <Line
      datasetIdKey="id"
      data={{
        labels: [...Array(128).keys()],
        datasets,
      }}
      options={{
        datasets: {
          line: {
            pointStyle: false,
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: "Press %",
            },
            ticks: {
              maxTicksLimit: 10,
              callback: (value) => {
                return Math.round(((value as number) / 130) * 100); // 0 is 0%, 128 is 100%. The +2 fixes some odd rounding error with the labels. I don't know why.
              },
            },
          },
          y: {
            title: {
              display: true,
              text: "Pressure (bar)",
            },
          },
        },
        plugins: {
          colors: {
            enabled: false,
            forceOverride: true, // Always use colors for dynamically added lines
          },
          legend: {
            onClick: () => {},
            position: "right",
            align: "start",
            labels: {
              usePointStyle: true,
              pointStyle: "circle",
            },
          },
        },
      }}
    />
  );
};

export default AnomalyChart;
