import i18next from "i18next";
import { DeviceStates, DeviceStatus, DeviceType } from "../interfaces/types";

type Device = Pick<DeviceType, "EStopOn" | "status" | "statusBitmask">;

// END-USER TECHICAL ERRORS
// Displayed to end-users as a simple "technical error" message without additional details.
const bitmaskEndUserTechnicalErrorStatuses = [
  DeviceStates.PARAMS_UNSAFE,
  DeviceStates.OIL_LEVEL_LOW,
  DeviceStates.OIL_TEMP_HIGH,
  DeviceStates.OIL_LEAKAGE_DETECTED,
  DeviceStates.MOTOR_TEMP_HIGH,
  DeviceStates.FRONT_BLOCK_ERROR,
  DeviceStates.MOTOR_PROTECT,
  DeviceStates.OIL_LEVEL_AND_TEMP,
  DeviceStates.BACK_BLOCK_DETECT_ERROR,
  DeviceStates.INPUT_TIMING_ERROR,
  DeviceStates.TRAVEL_TIMES_ERROR,
  DeviceStates.MACHINE_FAULT,
];

// END-USER WARNINGS
// Displayed to end-users with specific error messages.
const bitmaskEndUserWarningStatusesRanked = [
  DeviceStates.CONTAINER_NOT_PRESENT,
  DeviceStates.ESTOP_PRESSED,
  DeviceStates.BACK_BLOCK_DETECT_WARNING,
  DeviceStates.MACHINE_IS_OVERFULL,
  DeviceStates.WIRE_HAS_RUN_OUT,
];

function getRankedWarningStatusTranslations(statuses: DeviceStates[]) {
  const rankedStatuses = bitmaskEndUserWarningStatusesRanked.filter((status) =>
    statuses.includes(status)
  );
  if (0 === rankedStatuses.length) {
    return [];
  }

  const warningStatusToText = {
    [DeviceStates.ESTOP_PRESSED]: i18next.t(
      "device.status.estopPressed",
      "E-Stop active"
    ),
    [DeviceStates.CONTAINER_NOT_PRESENT]: i18next.t(
      "device.status.containerNotPresent",
      "Container not present"
    ),
    [DeviceStates.BACK_BLOCK_DETECT_WARNING]: i18next.t(
      "device.status.backBlockDetectWarning",
      "Back block warning"
    ),
    [DeviceStates.MACHINE_IS_OVERFULL]: i18next.t(
      "device.status.machineIsOverfull",
      "Machine is overfull"
    ),
    [DeviceStates.WIRE_HAS_RUN_OUT]: i18next.t(
      "device.status.wireHasRunOut",
      "Bale wire has run out"
    ),
  };

  return rankedStatuses.map(
    (status) => warningStatusToText[status as keyof typeof warningStatusToText]
  );
}

function bitmaskStatusToString(status: DeviceStates): string | null {
  for (const stateVal in DeviceStates) {
    if (isNaN(Number(stateVal)) && Number(DeviceStates[stateVal]) === status) {
      const val = stateVal.replace(/_/g, " ").toLowerCase();
      return val.charAt(0).toUpperCase() + val.substring(1);
    }
  }
  return null;
}

function getBitmaskStatuses(device: Device): DeviceStates[] {
  if (
    device.statusBitmask === undefined ||
    device.statusBitmask === DeviceStates.NO_ERRORS_DETECTED
  ) {
    return [];
  }

  const statuses = [];
  for (const bitmaskStatusStr in DeviceStates) {
    const bitmaskStatus = Number(bitmaskStatusStr);
    if (!isNaN(bitmaskStatus)) {
      // STATUS_UNKNOWN requires special handling because it is 0x0000, and therefore
      // always returns true if checked with bitwise AND like the rest of the statuses
      const otherThanUnknownStatusMatch =
        bitmaskStatus !== DeviceStates.STATUS_UNKNOWN &&
        (bitmaskStatus & device.statusBitmask) === bitmaskStatus;
      const unknownStatusMatch =
        bitmaskStatus === DeviceStates.STATUS_UNKNOWN &&
        device.statusBitmask === DeviceStates.STATUS_UNKNOWN;

      if (otherThanUnknownStatusMatch || unknownStatusMatch) {
        statuses.push(bitmaskStatus);
      }
    }
  }

  return statuses;
}

function hasBitmaskEndUserWarning(device: Device) {
  const bitmaskStatuses = getBitmaskStatuses(device);
  return bitmaskStatuses.some((status) =>
    bitmaskEndUserWarningStatusesRanked.includes(status)
  );
}

function hasBitmaskEndUserError(device: Device) {
  const bitmaskStatuses = getBitmaskStatuses(device);
  return bitmaskStatuses.some((status) =>
    bitmaskEndUserTechnicalErrorStatuses.includes(status)
  );
}

/**
 * Get a device's primary status, i.e., the status that is ranked highest according to priority rules.
 */
export function getPrimaryStatus(device: Device): DeviceStatus {
  // Check for different statuses in prioritized order
  if (hasBitmaskEndUserError(device)) {
    return DeviceStatus.Error;
  } else if (device.status === DeviceStatus.InTransit) {
    return DeviceStatus.InTransit;
  } else if (device.status === DeviceStatus.PowerOff) {
    return DeviceStatus.PowerOff;
  } else if (hasBitmaskEndUserWarning(device)) {
    return DeviceStatus.Warning;
  }
  return DeviceStatus.OK;
}

/**
 * Get a device's formatted status message.
 */
export function getStatusMessage(
  device: Device,
  forEuropress: boolean
): string | null {
  if (forEuropress) {
    const bitmaskStatuses = getBitmaskStatuses(device);
    const firstStatusText =
      0 < bitmaskStatuses.length
        ? bitmaskStatusToString(bitmaskStatuses[0])
        : "";
    const additionalStatusText =
      1 < bitmaskStatuses.length ? `(+${bitmaskStatuses.length - 1} more)` : "";
    return firstStatusText !== ""
      ? `${firstStatusText} ${additionalStatusText}`
      : null;
  }

  // End-users have limited visibility to status details
  if (hasBitmaskEndUserError(device)) {
    return i18next.t("device.status.technicalProblem", "Technical problem");
  } else if (hasBitmaskEndUserWarning(device)) {
    const [highestRankedWarning] = getRankedWarningStatusTranslations(
      getBitmaskStatuses(device)
    );
    return highestRankedWarning ?? null;
  }
  return null;
}

/**
 * Return all warnings and errors that are visible to end-users in prioritized order.
 */
export function getEndUserWarningsAndErrors(device: Device): string[] {
  const bitmaskStatuses = getBitmaskStatuses(device);
  const warnings = bitmaskStatuses.filter((status) =>
    bitmaskEndUserWarningStatusesRanked.includes(status)
  );
  const errors = bitmaskStatuses.filter((status) =>
    bitmaskEndUserTechnicalErrorStatuses.includes(status)
  );

  const errorText =
    0 < errors.length
      ? i18next.t("device.status.technicalProblem", "Technical problem")
      : null;
  const warningTexts = getRankedWarningStatusTranslations(warnings);

  return errorText !== null ? [errorText, ...warningTexts] : [...warningTexts];
}

export function getEuropressWarningsAndErrors(device: Device): string[] {
  const bitmaskStatuses = getBitmaskStatuses(device);
  return bitmaskStatuses
    .map((status) => bitmaskStatusToString(status))
    .filter((text): text is string => text !== null);
}

export function hasErrorOrWarning(device: Device) {
  const status = getPrimaryStatus(device);
  return status === DeviceStatus.Error || status === DeviceStatus.Warning;
}
