import { call } from "redux-saga/effects";
import { removeState } from "../store/localStorage";
import { doLogout } from "../api/users";

export function* logout(action: any) {
  try {
    yield call(doLogout, action);
    yield call(removeState);
  } catch (err) {
    // Currently ignore logout failures
  }
}
