import { Table } from "../../../../components/Common/Table";
import { Spinner } from "../../../../components/Spinner";
import {
  useDeleteDeviceErrorTask,
  useDeviceErrorTaskList,
} from "../../../../queries/deviceErrorTask";
import styled from "styled-components";
import { Title } from "../../../../components/Common/Title";
import editIcon from "../../../../assets/icon-edit.svg";
import trashIcon from "../../../../assets/icon-trash.svg";
import { Link, RouteComponentProps } from "react-router-dom";
import { PrimaryButtonLarge } from "../../../../components/Common/Button";

const EditIcon = styled.img.attrs({ src: editIcon })`
  height: 1.6rem;
  cursor: pointer;
`;
const TrashIcon = styled.img.attrs({ src: trashIcon })`
  height: 1.1rem;
  cursor: pointer;
`;
const RowActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  ${TrashIcon} {
    margin-left: 0.2rem;
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  > .action {
    margin-left: 0.5rem;
  }
`;
const View = styled.div`
  display: flex;
  flex-direction: column;
  width: 75rem;

  ${Title} {
    margin: 2rem 0;
  }
  ${ActionsContainer} {
    margin-bottom: 1rem;
  }
`;
const NoTasksMessage = styled.div`
  font-family: Poppins;
  color: var(--col-1-c-2020);
`;

type ErrorEntry = NonNullable<
  ReturnType<typeof useDeviceErrorTaskList>["data"]
>[number];

function getTableColumns(onDeleteClick: (taskId: number) => void) {
  return {
    columns: [
      { Header: "Name", accessor: "name", width: 3 },
      { Header: "Group", accessor: "groupName", width: 3 },
      {
        Header: "Parent groups",
        accessor: (entry: ErrorEntry) => {
          const sliceFrom = Math.max(entry.parentGroupNames.length - 2, 0);
          return entry.parentGroupNames.slice(sliceFrom).join("->");
        },
        id: "parentGroupNames",
        width: 5,
      },
      {
        Header: "Shared",
        accessor: (row: ErrorEntry) => (row.sharedForAllDevices ? "Yes" : "No"),
        id: "sharedForAllDevices",
        width: 1,
      },
      {
        Header: "Inherited",
        accessor: (row: ErrorEntry) =>
          row.includeChildGroupDevices ? "Yes" : "No",
        id: "includeChildGroupDevices",
        width: 1,
      },
      { Header: "Last executed", accessor: "lastExecutedAt", width: 3 },
      {
        Header: "",
        accessor: "actions",
        width: 1,
        Cell: ({ row }: { row: { original: ErrorEntry } }) => (
          <RowActionContainer>
            <Link
              to={`/admin/devices/tasks/device-error/update/${row.original.id}`}
            >
              <EditIcon />
            </Link>
            <TrashIcon onClick={() => onDeleteClick(row.original.id)} />
          </RowActionContainer>
        ),
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    initialSortBy: [{ id: "Name" }],
    enableFilters: true,
    infiniteScroll: true,
  };
}

export function ErrorTaskList(props: RouteComponentProps) {
  const errorTasks = useDeviceErrorTaskList();
  const deleteTask = useDeleteDeviceErrorTask();

  if (errorTasks.status === "loading") {
    return <Spinner />;
  } else if (errorTasks.data) {
    const tableColumns = getTableColumns((taskId) => {
      const task = errorTasks.data?.find((task) => task.id === taskId);
      if (window.confirm(`Do you want to delete task ${task?.name}?`)) {
        deleteTask.mutate(taskId);
      }
    });

    return (
      <View>
        <Title>Device error tasks</Title>
        <ActionsContainer>
          <Link className="action" to={`${props.match.path}/create`}>
            <PrimaryButtonLarge>Create task</PrimaryButtonLarge>
          </Link>
          <Link className="action" to={`${props.match.path}/history`}>
            <PrimaryButtonLarge>Execution history</PrimaryButtonLarge>
          </Link>
        </ActionsContainer>
        {errorTasks.data.length === 0 ? (
          <View>
            <NoTasksMessage>There are no error tasks</NoTasksMessage>
          </View>
        ) : (
          <Table data={errorTasks.data} {...tableColumns} />
        )}
      </View>
    );
  }

  return (
    <View>
      <NoTasksMessage>An error occurred while fetching data</NoTasksMessage>
    </View>
  );
}
