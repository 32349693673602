import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Modal from "react-modal";
import "../css/modal.css";
import icon_tree from "../assets/icon-tree.svg";
import icon_close from "../assets/icon-close.svg";
import * as TYPES from "../constants/actionTypes";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import {
  groupHierarchiesSelector,
  selectedGroupIdSelector,
} from "../selectors/groups";
import { TreeNode } from "../reducers/groups";
import { findGroupById, getPathToGroup } from "../utils/group";
import { isDefined } from "../utils/utils";
import _ from "lodash/fp";

const Header = styled.div`
  display: flex;
  align-items: center;
  align-content: space-between;
  grid-area: header;
  background-color: var(--seafoam-blue);
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  align-content: space-between;
  grid-area: footer;
  background-color: var(--ice-blue);
`;

interface IconProps {
  cursor?: string;
  marginLeft?: number;
  marginRight?: number;
}
const Icon = styled.img<IconProps>`
  object-fit: contain;
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : 0)}%;
  margin-right: ${(props) => (props.marginRight ? props.marginRight : 0)}px;
  cursor: ${(props) => (props.cursor ? props.cursor : "auto")};
  min-width: 24px;
  min-height: 24px;
`;

interface TitleProps {
  color: string | undefined;
  bolded: boolean;
  marginRight: number | undefined;
  cursor: string | undefined;
}
const Title = styled.div<TitleProps>`
  font-family: Roboto;
  font-size: 16px;
  font-weight: ${(props) => (props.bolded ? "bold" : "normal")};
  font-style: normal;
  font-stretch: condensed;
  line-height: normal;
  letter-spacing: 0.5px;
  margin-left: 10px;
  margin-right: ${(props) => (props.marginRight ? props.marginRight : 0)}px;
  color: ${(props) => (props.color ? props.color : "#e9eef3")};
  cursor: ${(props) => (props.cursor ? props.cursor : "auto")};
`;

interface TitleTextProps {
  marginLeft?: number;
  fontStyle?: string;
  selected?: boolean;
  selectable?: boolean;
}
const TitleText = styled.div<TitleTextProps>`
  font-family: Poppins;
  font-size: 16px;
  font-weight: ${(props) => (props.selected ? "bold" : "normal")};
  font-style: ${(props) => (props.fontStyle ? props.fontStyle : "normal")};
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--col-1-c-2020);
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : 0)}px;
  background-color: ${(props) =>
    props.selected ? "var(--seafoam-blue-with-opacity)" : "initial"};
  cursor: ${(props) => (props.selectable ? "pointer" : "normal")};
`;

const SelectButton = styled.button`
  width: 115px;
  height: 34px;
  border-radius: 17px;
  background-color: var(--col-11-a-3-c-7);
  border: none;
  outline: none;
  cursor: pointer;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  @media (min-width: 768px) {
    margin-right: 83px;
  }
`;

interface ContentProps {
  columns: number;
}
const Content = styled.div<ContentProps>`
  grid-area: content;
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns}, auto);
  grid-column-gap: 1px;
`;
const Container = styled.div`
  display: grid;
  height: 100%;
  grid-template-areas:
    "header"
    "content"
    "footer";
  @media (min-width: 768px) {
    grid-template-rows: 10.2% auto 14%;
  }
`;
const Column = styled.div`
  background-color: var(--ice-blue);
  display: flex;
  justify-content: rigth;
`;

const Rect = styled.div<{ selected: boolean }>`
  width: 100%;
  background-color: ${(props) =>
    props.selected ? "var(--seafoam-blue-with-opacity)" : "initial"};
`;

const Name = styled.h1<{ selected: boolean }>`
  font-family: Poppins;
  font-size: 14px;
  font-weight: ${(props) => (props.selected ? "bold" : "normal")};
  font-style: normal;
  font-stretch: normal;
  line-height: 2.29;
  letter-spacing: normal;
  color: var(--col-1-c-2020);
  cursor: pointer;
  margin-left: 35px;
`;

const List = styled.ul`
  list-style-type: none;
  width: 100%;
  margin: 0;
  padding: 0;
`;

interface GroupProps {
  selected: boolean;
  onClick: () => void;
  group: { name: string };
}
function Group(props: GroupProps) {
  return (
    <Rect selected={props.selected}>
      <Name onClick={props.onClick} selected={props.selected}>
        {" "}
        {props.group.name}{" "}
      </Name>
    </Rect>
  );
}

interface GroupListProps {
  groups: TreeNode[];
  toggleGroupSelection: (groupId: number) => void;
  selectedIds: Array<any>;
}
function GroupList(props: GroupListProps) {
  return (
    <List>
      {props.groups.map((group) => (
        <li key={group.groupId}>
          <Group
            group={group}
            onClick={() => props.toggleGroupSelection(group.groupId)}
            selected={props.selectedIds.some((id) => id === group.groupId)}
          />
        </li>
      ))}
    </List>
  );
}

function getGroupPathNames(
  hierarchies: TreeNode[],
  selectedIds: number[]
): string[] {
  const lastSelectedGroupId = _.last(selectedIds);
  if (lastSelectedGroupId === undefined) {
    if (hierarchies.length === 1) {
      return [hierarchies[0].name];
    }
    return [];
  }

  return getPathToGroup(hierarchies, lastSelectedGroupId).map(
    (group) => group.name
  );
}

function getVisibleGroupsByLevel(
  hierarchies: TreeNode[],
  selectedIds: number[]
): TreeNode[][] {
  if (selectedIds.length === 0) {
    return hierarchies.length === 1 ? [hierarchies[0].children] : [hierarchies];
  }

  const selectedGroups = selectedIds
    .map((groupId) => findGroupById(hierarchies, groupId))
    .filter(isDefined);
  if (selectedGroups.length !== selectedIds.length) {
    return [];
  }

  const selectableChildGroups = _.last(selectedGroups)?.children;
  if (selectableChildGroups && 0 < selectableChildGroups.length) {
    return [...selectedGroups.map((group) => [group]), selectableChildGroups];
  }
  return selectedGroups.map((group) => [group]);
}

function getInitialSelectedIdPath(
  hierarchies: TreeNode[],
  selectedGroupId: number | undefined
) {
  if (selectedGroupId === undefined) {
    return [];
  }

  const groupIdPath = getPathToGroup(hierarchies, selectedGroupId).map(
    (group) => group.groupId
  );

  return hierarchies.length === 1 ? _.tail(groupIdPath) : groupIdPath;
}

Modal.setAppElement("#root");

interface GroupSelectionProps {
  hideLabel?: boolean;
  isModalOpen: boolean;
  /**
   * The parent component should fetch the selected group with selectors from redux state. This
   * callback only lets the parent know when the popup is closed.
   */
  onClose: () => void;
  hideSelectAllDevicesButton?: boolean;
}
export function GroupSelectionPopup(props: GroupSelectionProps) {
  const dispatch = useDispatch();
  const initialSelectedGroupId = useSelector(selectedGroupIdSelector);
  const hierarchies = useSelector(groupHierarchiesSelector);
  const { t } = useTranslation();

  const [selectedIdPath, setSelectedIdPath] = useState<number[]>(
    getInitialSelectedIdPath(hierarchies, initialSelectedGroupId)
  );

  useEffect(() => {
    if (props.isModalOpen) {
      setSelectedIdPath(
        getInitialSelectedIdPath(hierarchies, initialSelectedGroupId)
      );
    }
    // eslint-disable-next-line
  }, [props.isModalOpen]);

  const selectedNames = getGroupPathNames(hierarchies, selectedIdPath).join(
    " \\ "
  );
  const visibleGroupsByLevel = getVisibleGroupsByLevel(
    hierarchies,
    selectedIdPath
  );

  return (
    <div>
      <div>
        {props.hideLabel
          ? undefined
          : t("groupSelection.title", "SELECT GROUP")}
      </div>
      <Modal
        isOpen={props.isModalOpen}
        contentLabel="Select Group"
        overlayClassName={"modal-group-overlay"}
        className={"modal-group-content"}
      >
        {/* Prevent clicks inside the modal from being propagated.
                  This allows clicks on the outside of the modal to be captured
                  by a DOM parent element and used to close the modal. */}
        <Container onClick={(e) => e.stopPropagation()}>
          <Header>
            <Icon src={icon_tree} marginLeft={5.2} />
            <Title
              marginRight={undefined}
              color={"#e9eef3"}
              bolded={true}
              cursor={undefined}
            >
              {t("groupSelection.popup.title", "SELECT GROUP")}{" "}
            </Title>
            <TitleText marginLeft={30}> {selectedNames} </TitleText>
            <div className="push">
              <Icon
                src={icon_close}
                onClick={() => props.onClose()}
                cursor={"pointer"}
                marginRight={15}
              />
            </div>
          </Header>
          <Content columns={visibleGroupsByLevel.length}>
            {visibleGroupsByLevel.map((siblingGroups) => (
              <Column key={uuidv4()}>
                <GroupList
                  groups={siblingGroups}
                  toggleGroupSelection={(groupId) =>
                    selectedIdPath.includes(groupId)
                      ? setSelectedIdPath(
                          _.takeWhile((id) => id !== groupId, selectedIdPath)
                        )
                      : setSelectedIdPath([...selectedIdPath, groupId])
                  }
                  selectedIds={selectedIdPath}
                />
              </Column>
            ))}
          </Content>
          <Footer>
            {!props.hideSelectAllDevicesButton && (
              <TitleText
                marginLeft={30}
                fontStyle={"italic"}
                onClick={() => setSelectedIdPath([])}
                selected={selectedIdPath.length === 0}
                selectable={true}
              >
                {" "}
                {t("groupSelection.popup.selectAll", "Select All")}{" "}
                {hierarchies.length === 1 ? `${hierarchies[0].name} ` : ""}
                {t("groupSelection.popup.devices", "devices")}{" "}
              </TitleText>
            )}
            <div className="push">
              <SelectButton
                onClick={() => {
                  const groupId = _.last(selectedIdPath);
                  dispatch({
                    type: TYPES.GROUP_SELECTED,
                    payload: {
                      groupId,
                    },
                  });
                  props.onClose();
                }}
              >
                {" "}
                {t("groupSelection.popup.selectButton", "Select")}{" "}
              </SelectButton>
            </div>
          </Footer>
        </Container>
      </Modal>
    </div>
  );
}
