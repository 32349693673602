import _ from "lodash/fp";
import { SelectableColumns } from "../interfaces/types";

export function toDisplayOrder(
  columns: SelectableColumns[],
  displayOrder: SelectableColumns[]
) {
  const addDisplayIdx = _.map<SelectableColumns, any>((column) => ({
    displayIdx: displayOrder.indexOf(column),
    column,
  }));
  const sortByDisplayIdx = _.sortBy("displayIdx");
  const getColumn = _.map<any, SelectableColumns>((colData) => colData.column);

  return _.pipe(addDisplayIdx, sortByDisplayIdx, getColumn)(columns);
}
