import { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { FilterPopup } from "./FilterPopup";
import { FilterPopupType } from "../interfaces/types";
import { filterCountsSelector } from "../selectors/devices";

const DropDown = styled.div`
  position: relative;
  display: inline-block;
`;
const Circle = styled.div`
  flex: 0 0 auto;
  position: relative;
  left: 5px;
  top: -5px;
  width: 14px;
  height: 14px;
  border-radius: 100%;
  z: 1;
  background-color: var(--col-4-f-8-a-89);
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
`;
const FilterText = styled.div`
  flex: 0 1 content;
`;
const Button = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  min-height: 26px;
  width: 120px;

  background-color: #ffffff;

  border-style: none;
  padding: 3px 8px;
  margin-left: 5px;

  text-align: center;

  font-family: Roboto;
  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  font-stretch: condensed;
  line-height: normal;
  letter-spacing: normal;
  color: var(--col-214269);

  cursor: pointer;
  outline: none;
`;

function getFilterCount(
  counts: {
    fillLevelCount: number;
    statusCount: number;
    machineTypeCount: number;
  },
  popupType: FilterPopupType
): number | null {
  switch (popupType) {
    case FilterPopupType.FILL_LEVEL:
      return counts.fillLevelCount;
    case FilterPopupType.STATUS:
      return counts.statusCount;
    case FilterPopupType.MACHINE_TYPE:
      return counts.machineTypeCount;
    default:
      return null;
  }
}

interface FilterButtonProps {
  type: FilterPopupType;
  title: string;
  className?: string;
}
export function FilterButton(props: FilterButtonProps) {
  const [isOpen, setIsOpen] = useState(false);
  const filterCounts = useSelector(filterCountsSelector);

  const filterCount = getFilterCount(filterCounts, props.type);

  return (
    <DropDown className={props.className}>
      <Button onClick={() => setIsOpen((prevIsOpen) => !prevIsOpen)}>
        <FilterText>{props.title}</FilterText>
        {filterCount !== null && 0 < filterCount && (
          <Circle>{filterCount}</Circle>
        )}
      </Button>
      {isOpen && (
        <FilterPopup type={props.type} onClose={() => setIsOpen(false)} />
      )}
    </DropDown>
  );
}
