import { ScaleButton } from "@shared/types";
import { Line } from "react-chartjs-2";
import _ from "lodash";

const chartOptions = {
  elements: { point: { radius: 0 } },
};

function scaleButtonPresses(data: number[], type: ScaleButton) {
  const offset = type === ScaleButton.UP ? 150 : 100;
  return data.map((val) => val * 30 + offset);
}

function parseButtonDatasets(buttonPresses: ScaleButton[]) {
  try {
    const upButton = buttonPresses.map((button) =>
      button === ScaleButton.UP || button === ScaleButton.BOTH ? 1 : 0
    );
    const downButton = buttonPresses.map((button) =>
      button === ScaleButton.DOWN || button === ScaleButton.BOTH ? 1 : 0
    );

    return [
      {
        label: "Up",
        data: scaleButtonPresses(upButton, ScaleButton.UP),
        borderColor: "rgb(255, 152, 0, 1)",
        backgroundColor: "rgba(0, 0, 0, 0)",
      },
      {
        label: "Down",
        data: scaleButtonPresses(downButton, ScaleButton.DOWN),
        borderColor: "rgb(13, 71, 125, 1)",
        backgroundColor: "rgba(0, 0, 0, 0)",
      },
    ];
  } catch (error) {
    return undefined;
  }
}

function getWeightSignalDataset(weightSignal: number[]) {
  return {
    label: "Weight (mV)",
    data: weightSignal,
    borderColor: "rgb(114, 192, 162)",
    backgroundColor: "rgba(0, 0, 0, 0)",
  };
}

export function EventModal({
  event,
}: {
  event: {
    button_presses: ScaleButton[] | null;
    weight_signal: number[] | null;
    algorithm_method: string | null;
  };
}) {
  const buttonDatasets = event.button_presses
    ? parseButtonDatasets(event.button_presses)
    : undefined;
  const weightDataset = event.weight_signal
    ? getWeightSignalDataset(event.weight_signal)
    : undefined;

  const datasets = [
    ...(buttonDatasets !== undefined ? buttonDatasets : []),
    ...(weightDataset !== undefined ? [weightDataset] : []),
  ];

  if (datasets.length === 0) {
    return (
      <div>
        <div>No tipping data for this event.</div>
        <span>Alrogithm method: {event.algorithm_method ?? ""}</span>
      </div>
    );
  }

  const maxDatapointCount = Math.max(...datasets.map((set) => set.data.length));
  // Each data point represents a 0.1s interval
  const labels = _.range(0, maxDatapointCount / 10, 0.1).map((val) =>
    _.round(val, 1)
  );
  return (
    <div>
      <Line
        data={{
          labels,
          datasets,
        }}
        options={chartOptions}
      />
      <span>Alrogithm method: {event.algorithm_method ?? ""}</span>
    </div>
  );
}
