import * as Yup from "yup";

const OperatorDevicesSchema = Yup.object().shape({
  serialNumbers: Yup.string().required(
    "At least one serial number is required"
  ),
  selectedGroupId: Yup.number(),
});

const OwnerDevicesSchema = Yup.object().shape({
  serialNumbers: Yup.string().required(
    "At least one serial number is required"
  ),
  selectedGroupId: Yup.number().required("Owner group needs to be specified"),
});

export { OperatorDevicesSchema, OwnerDevicesSchema };
