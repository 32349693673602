import { createRef, Component } from "react";
import { connect } from "react-redux";
import { Device } from "./Device";
import {
  DeviceType,
  SelectableColumns,
  MachineType,
  FilterPopupType,
} from "../interfaces/types";
import * as TYPES from "../constants/actionTypes";
import styled from "styled-components";
import { inifinityScrollOptions } from "../constants/options";
import { deviceColumnToString } from "../utils/utils";

import icon_settings from "../assets/icon-settings.svg";
import icon_sort from "../assets/icon-sort.svg";
import { withTranslation, WithTranslation } from "react-i18next";
import { FilterPopup } from "./FilterPopup";
import { ConfirmationModal } from "./Common/ConfirmationModal";

// TODO: Use the common Table component instead of implementing its own table?
const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

const TableHeader = styled.thead`
  display: none;
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: condensed;
  line-height: normal;
  letter-spacing: normal;
  background-color: var(--col-214269);
  color: var(--ice-blue);
  @media (min-width: 768px) {
    display: table-header-group;
  }
`;
const TableHeaderRow = styled.tr`
  height: 60px;
`;
interface TableHeaderCellProps {
  clickable?: boolean;
}
const TableHeaderCell = styled.th<TableHeaderCellProps>`
  position: sticky;
  top: 0;
  background-color: var(--col-214269);
  color: var(--ice-blue);
  cursor: ${(props) => (props.clickable ? "pointer" : "initial")};
  outline: none;
  padding-left: 8px;
  padding-right: 8px;
  z-index: 2;
`;
const CellContent = styled.div<{ minWidth?: string }>`
  display: flex;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  min-width: ${(props) => props.minWidth ?? "initial"};
  height: 100%;
  white-space: nowrap;
`;
const TableBody = styled.tbody``;

interface SortIconProps {
  marginLeft?: number;
}
const SortIcon = styled.img<SortIconProps>`
  object-fit: contain;
  cursor: pointer;
  outline: none;
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : 0)}px;
  margin-top: 1px;
  margin-left: 5px;
`;

const mapDispatchToProps = (dispatch: any) => {
  return {
    sortDevices(sortColumn: SelectableColumns, order: number): void {
      dispatch({
        type: TYPES.SORT_DEVICES,
        payload: {
          sortColumn: sortColumn,
          sortOrder: order,
        },
      });
    },
    showDeviceRoute(device: DeviceType, visible: boolean): void {
      dispatch({
        type: TYPES.SHOW_DEVICE_ROUTE,
        payload: { device: device, visible: visible },
      });
    },
    changeBalesReady(token: string, device: DeviceType, newBalesReady: number) {
      dispatch({
        type: TYPES.CHANGE_BALES_READY,
        payload: {
          token,
          serialNumber: device.serialNumber,
          newBalesReady,
          oldBalesReady: device.baleCounter?.balesReady,
        },
      });
    },
    resetBaleCounter(token: string, device: DeviceType) {
      dispatch({
        type: TYPES.RESET_BALE_COUNTER,
        payload: {
          token,
          serialNumber: device.serialNumber,
          oldBalesReady: device.baleCounter?.balesReady,
        },
      });
    },
  };
};

const mapStateToProps = (state: any) => {
  return {
    sortColumn: state.devices.sortColumn,
    sortOrder: state.devices.sortOrder,
    token: state.token.key,
  };
};

interface DeviceListState {
  visibleDevices: Array<DeviceType>;
  selectColumnsPopupOpen: boolean;
  confirmBaleCounterResetOpen: boolean;
  baleCounterResetSerial: string | null;
}
interface DeviceListProps extends WithTranslation {
  devices: Array<any>;
  columnsSelected: SelectableColumns[];
  sortColumn: SelectableColumns;
  sortOrder: number;
  contentType: MachineType;
  contentRef: React.RefObject<HTMLDivElement>;
  sortDevices(sortColumn: SelectableColumns, sortOrder: number): void;
  showDeviceRoute(device: DeviceType, visible: boolean): void;
  resetBaleCounter(token: string, device: DeviceType): void;
  changeBalesReady(
    token: string,
    device: DeviceType,
    newBalesReady: number
  ): void;
  onEditDevice(device: DeviceType): void;
  onShowServiceRequests(device: DeviceType): void;
  onShowAnalytics(device: DeviceType): void;
  token: string;
}
export class DeviceListConnected extends Component<
  DeviceListProps,
  DeviceListState
> {
  private _tableContent: any = undefined;
  constructor(props: DeviceListProps) {
    super(props);
    this.state = {
      visibleDevices: [],
      selectColumnsPopupOpen: false,
      confirmBaleCounterResetOpen: false,
      baleCounterResetSerial: null,
    };
    this._tableContent = createRef();
  }

  private handleScroll = () => {
    if (
      this.props.contentRef &&
      this.props.contentRef.current &&
      this.props.contentRef.current.clientHeight &&
      this.props.contentRef.current.clientHeight +
        this.props.contentRef.current.scrollTop +
        10 <=
        this.props.contentRef.current.scrollHeight
    ) {
      return;
    } else {
      if (this.state.visibleDevices.length === this.props.devices.length) {
        return;
      } else {
        let visibleDevices: Array<DeviceType> = [];
        if (
          this.props.devices.length >=
          this.state.visibleDevices.length +
            inifinityScrollOptions.nbElementsToAdd
        ) {
          visibleDevices = this.props.devices.slice(
            this.state.visibleDevices.length,
            this.state.visibleDevices.length +
              inifinityScrollOptions.nbElementsToAdd
          );
        } else {
          visibleDevices = this.props.devices.slice(
            this.state.visibleDevices.length
          );
        }
        this.setState({
          visibleDevices: [...this.state.visibleDevices, ...visibleDevices],
        });
      }
    }
  };
  public componentDidMount(): void {
    if (
      this.state.visibleDevices.length === 0 &&
      this.props.devices.length > 0
    ) {
      this.setState({
        visibleDevices: this.props.devices.slice(
          0,
          inifinityScrollOptions.nbElementsToAdd
        ),
      });
    }
    window.addEventListener("scroll", this.handleScroll, true);
  }
  public componentWillUnmount(): void {
    window.removeEventListener("scroll", this.handleScroll, true);
  }
  public componentDidUpdate(prevProps: DeviceListProps): void {
    if (
      this.state.visibleDevices.length === 0 &&
      this.props.devices.length > 0
    ) {
      this.setState({
        visibleDevices: this.props.devices.slice(
          0,
          inifinityScrollOptions.nbElementsToAdd
        ),
      });
    } else if (this.props.devices.length !== prevProps.devices.length) {
      // Filtering is done!
      if (this.props.contentRef.current) {
        this.props.contentRef.current.scrollTop = 0;
      }
      this.setState({
        visibleDevices: this.props.devices.slice(
          0,
          inifinityScrollOptions.nbElementsToAdd
        ),
      });
    } else if (prevProps.devices !== this.props.devices) {
      // There has happened a device update
      this.setState({
        visibleDevices: this.props.devices.slice(
          0,
          this.state.visibleDevices.length
        ),
      });
    }
  }
  private handleColumnClick = (e: any, column: number): void => {
    e.preventDefault();
    // Not sortable column
    if (column === SelectableColumns.PIC) {
      return;
    }
    let sortOrder: number = 0;
    if (this.props.sortOrder === 0 || this.props.sortOrder === -1) {
      sortOrder = 1;
    } else if (this.props.sortOrder === 1) {
      sortOrder = -1;
    }
    this.props.sortDevices(column as SelectableColumns, sortOrder);
  };
  private handleResetBaleCounter = (device: DeviceType): void => {
    this.setState((state) => ({
      ...state,
      confirmBaleCounterResetOpen: true,
      baleCounterResetSerial: device.serialNumber,
    }));
  };
  private getBaleCounterResetPromptText = (): string => {
    const serialNumber = this.state.baleCounterResetSerial;
    const device = this.props.devices.find(
      (dev) => dev.serialNumber === serialNumber
    );
    if (!serialNumber || !device) {
      return "";
    }

    const commonText = this.props.t("device.baleCounter.confirmReset", {
      defaultValue: `Are you sure you want to reset bale counter for device {{serialNumber}}?`,
      serialNumber: serialNumber,
    });

    let additionalText = this.props.t(
      "device.baleCounter.confirmResetNoEmail",
      "Mobile notifications will be sent, and the counter is reset to 0."
    );
    if (0 < (device.baleCounter?.emailRecipients.length ?? 0)) {
      additionalText = this.props.t(
        "device.baleCounter.confirmResetEmail",
        "Mobile notifications and emails will be sent, and the counter is reset to 0."
      );
    }

    return `${commonText} ${additionalText}`;
  };
  private onBaleCounterResetConfirm = async (didConfirm: boolean) => {
    const serialNumber = this.state.baleCounterResetSerial;
    const device = this.props.devices.find(
      (dev) => dev.serialNumber === serialNumber
    );

    this.setState((state) => ({
      ...state,
      confirmBaleCounterResetOpen: false,
      baleCounterResetSerial: null,
    }));

    if (didConfirm && device) {
      this.props.resetBaleCounter(this.props.token, device);
    }
  };
  private handleChangeBalesReady = (
    device: DeviceType,
    newBalesReady: number
  ) => {
    this.props.changeBalesReady(this.props.token, device, newBalesReady);
  };
  private getHeaderColumn(column: SelectableColumns): JSX.Element | undefined {
    let clickable = true;
    if (column === SelectableColumns.PIC) {
      clickable = false;
    } else if (
      this.props.contentType === MachineType.Skip &&
      column === SelectableColumns.SERIAL_NUMBER
    ) {
      clickable = false;
    }
    return (
      <TableHeaderCell
        key={"header-" + column}
        clickable={clickable}
        onClick={(e: any) => {
          this.handleColumnClick(e, column);
        }}
      >
        {/* Ensure that bale counter column is wide enough for bales ready edit form */}
        <CellContent
          minWidth={
            column === SelectableColumns.BALE_COUNTER ? "120px" : undefined
          }
        >
          {deviceColumnToString(column)}
          {clickable && <SortIcon src={icon_sort} />}
        </CellContent>
      </TableHeaderCell>
    );
  }
  public render(): JSX.Element {
    return (
      <>
        {this.state.selectColumnsPopupOpen && (
          <FilterPopup
            type={
              this.props.contentType === MachineType.Skip
                ? FilterPopupType.EDIT_SKIP_COLUMNS
                : FilterPopupType.EDIT_COLUMNS
            }
            onClose={() => this.setState({ selectColumnsPopupOpen: false })}
          />
        )}
        <ConfirmationModal
          isOpen={this.state.confirmBaleCounterResetOpen}
          onConfirm={this.onBaleCounterResetConfirm}
          message={this.getBaleCounterResetPromptText()}
        />
        <Table>
          <TableHeader>
            <TableHeaderRow>
              <TableHeaderCell
                clickable
                onClick={() =>
                  this.setState({
                    selectColumnsPopupOpen: true,
                  })
                }
              >
                <img
                  src={icon_settings}
                  alt="settings"
                  title={this.props.t(
                    "deviceTable.tooltip.columnSelection",
                    "Edit visible columns"
                  )}
                />
              </TableHeaderCell>
              {this.props.columnsSelected.map((column) =>
                this.getHeaderColumn(column)
              )}
            </TableHeaderRow>
          </TableHeader>
          <TableBody ref={this._tableContent}>
            {this.state.visibleDevices.map((device) => (
              <Device
                key={device.serialNumber}
                columns={this.props.columnsSelected}
                onShowRoute={this.props.showDeviceRoute}
                onEditDevice={this.props.onEditDevice}
                onResetBaleCounter={this.handleResetBaleCounter}
                onChangeBalesReady={this.handleChangeBalesReady}
                onShowServiceRequests={this.props.onShowServiceRequests}
                onShowAnalytics={this.props.onShowAnalytics}
                {...device}
              />
            ))}
          </TableBody>
        </Table>
      </>
    );
  }
}
export const DeviceList = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(DeviceListConnected));
