import { useEffect, useState } from "react";
import styled from "styled-components";
import { Line } from "react-chartjs-2";
import _ from "lodash/fp";
import { DateRangeInput } from "../../../components/Common/DateRangeInput";
import { FillLevels } from "./types";
import { startOfDay } from "date-fns/esm";
import { endOfDay } from "date-fns";
import { PressureDataErrorType } from "@shared/types";

const DateRangeStyled = styled(DateRangeInput)`
  align-self: flex-end;
`;

export function FillLevelsGraph({ fillLevels }: { fillLevels: FillLevels }) {
  const validFillLevels = fillLevels
    .filter((fillLevel) => fillLevel.fillLevel != null)
    .map((fillLevel) => ({
      ...fillLevel,
      measurementDate: new Date(fillLevel.measurementDate),
    }));
  const minEntry = _.minBy(
    (fillLevel) => fillLevel.measurementDate,
    validFillLevels
  );
  const maxEntry = _.maxBy(
    (fillLevel) => fillLevel.measurementDate,
    validFillLevels
  );
  const [startDate, setStartDate] = useState<Date | null>(
    minEntry?.measurementDate ?? null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    maxEntry?.measurementDate ?? null
  );

  useEffect(() => {
    setStartDate(minEntry?.measurementDate ?? null);
    setEndDate(maxEntry?.measurementDate ?? null);
    // eslint-disable-next-line
  }, [fillLevels.length]);

  if (validFillLevels.length === 0) {
    return <div>This device has no fill level data.</div>;
  }

  const dateRangeFillLevels =
    startDate === null || endDate === null
      ? []
      : validFillLevels.filter(
          (fillLevel) =>
            startOfDay(startDate) <= fillLevel.measurementDate &&
            fillLevel.measurementDate <= endOfDay(endDate) &&
            (fillLevel.didFillLevelError === undefined ||
              fillLevel.didFillLevelError === null ||
              fillLevel.fillLevelMeta.errorCode ===
                PressureDataErrorType.NEGATIVE_FILL_LEVEL)
        );

  return (
    <>
      <DateRangeStyled
        startDate={startDate}
        endDate={endDate}
        onStartDateChange={setStartDate}
        onEndDateChange={setEndDate}
        minDate={minEntry!.measurementDate}
        maxDate={maxEntry!.measurementDate}
      />
      <Line
        data={{
          labels: dateRangeFillLevels.map((level) => level.measurementDate),
          datasets: [
            {
              label: "Calculated fill level",
              data: dateRangeFillLevels.map((level) => level.fillLevel),
              fill: false,
              backgroundColor: "rgba(255, 152, 0, 0.8)",
              borderColor: "rgba(255, 152, 0, 0.8)",
              pointBackgroundColor: "rgba(255, 152, 0, 0.8)",
              pointBorderColor: "rgba(255, 152, 0, 0.8)",
              pointBorderWidth: 0,
              pointRadius: 1,
              pointHitRadius: 3,
            },
            {
              label: "Calculated fill level raw",
              data: dateRangeFillLevels.map((level) => level.fillLevelRaw),
              fill: false,
              backgroundColor: "rgba(100, 255, 0, 0.8)",
              borderColor: "rgba(100, 255, 0, 0.8)",
              pointBackgroundColor: "rgba(0, 255, 0, 1)",
              pointBorderColor: "rgba(0, 255, 0, 1)",
              pointBorderWidth: 0,
              pointRadius: 1,
              pointHitRadius: 3,
            },
            {
              label: "Controller's fill level",
              data: dateRangeFillLevels.map(
                (level) => level.fillLevelController
              ),
              pointRadius: 0,
            },
          ],
        }}
        options={{
          elements: {
            line: {
              tension: 0,
            },
          },
          scales: {
            x: {
              type: "time",
              title: {
                display: true,
                text: "Date",
              },
              ticks: {
                callback: (value, i, ticks) => {
                  // Show date label only if the date has changed since last datapoint
                  const dateTime = new Date(value);
                  const prevDate = i > 0 ? new Date(ticks[i - 1].value) : null;
                  let renderDate = "";
                  if (
                    i === 0 ||
                    (prevDate &&
                      (dateTime.getFullYear() !== prevDate.getFullYear() ||
                        dateTime.getMonth() !== prevDate.getMonth() ||
                        dateTime.getDate() !== prevDate.getDate()))
                  ) {
                    renderDate = `${dateTime.getFullYear()}-${(
                      dateTime.getMonth() + 1
                    )
                      .toString()
                      .padStart(2, "0")}-${dateTime.getDate()}`;
                  }

                  return [
                    `${dateTime
                      .getHours()
                      .toString()
                      .padStart(
                        2,
                        "0"
                      )}:${dateTime.getMinutes().toString().padStart(2, "0")}`,
                    renderDate,
                  ];
                },
              },
            },
            y: {
              title: {
                display: true,
                text: "Fill Level (%)",
              },
            },
          },
          events: ["click"],
        }}
      />
    </>
  );
}
