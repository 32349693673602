import styled from "styled-components";
import ReactDatePicker from "react-datepicker";
import { formFieldCss } from "./FormInput";
import { endOfDay, startOfDay } from "date-fns";

const DatePicker = styled(ReactDatePicker)`
  ${formFieldCss};
  width: 8rem;
  text-align: center;
`;

const Separator = styled.span`
  margin: 0 1rem;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

export function DateRangeInput({
  minDate,
  maxDate,
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  className,
}: {
  minDate?: Date;
  maxDate?: Date;
  startDate: Date | null;
  endDate: Date | null;
  onStartDateChange: (date: Date) => void;
  onEndDateChange: (date: Date) => void;
  className?: string;
}) {
  return (
    <Container className={className}>
      <DatePicker
        onChange={(date) =>
          !Array.isArray(date) && date && onStartDateChange(startOfDay(date))
        }
        selected={startDate}
        minDate={minDate}
        maxDate={endDate !== null ? endDate : maxDate}
        showDisabledMonthNavigation
      />
      <Separator>-</Separator>
      <DatePicker
        onChange={(date) =>
          !Array.isArray(date) && date && onEndDateChange(endOfDay(date))
        }
        selected={endDate}
        minDate={startDate}
        maxDate={maxDate}
        showDisabledMonthNavigation
      />
    </Container>
  );
}
