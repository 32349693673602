import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import { Page } from "../Page";
import { Header } from "../../components/Header";
import { NavTab } from "../../components/Common/Tab";
import { Navigation } from "../../components/Common/Navigation";
import { useTranslation } from "react-i18next";
import { ScaleManage } from "./Manage/ScaleManage";
import { EventLog } from "./EventLog";
import { ScaleReports } from "./ScaleReports";
import { ScaleOverview } from "./ScaleOverview";
import { useSelector } from "react-redux";

const Scale = () => {
  const match = useRouteMatch();
  const path = match && match.path;
  const { t } = useTranslation();
  const user = useSelector((state: any) => state.users);

  const tabs = [
    {
      path: `${path}/overview`,
      title: t("scale.navigation.overview", "Overview"),
    },
    {
      path: `${path}/log`,
      title: t("scale.navigation.log", "Event log"),
    },
    {
      path: `${path}/manage`,
      title: t("scale.navigation.manage", "User groups and keys"),
    },
    {
      path: `${path}/reports`,
      title: t("scale.navigation.reports", "Reports"),
    },
  ];

  const navigation: JSX.Element = (
    <Navigation>
      {tabs.map((tab: any) => {
        if (
          tab.path === `${path}/manage` &&
          !(
            user.admin ||
            user.groups.includes("Europress") ||
            (user.scaleAdmin && user.licenses.insights)
          )
        ) {
          return (
            <Redirect
              key={"/devices"}
              to={{
                pathname: "/devices",
              }}
            />
          );
        } else {
          return (
            <NavTab key={tab.title} to={tab.path} activeClassName="active">
              {tab.title}
            </NavTab>
          );
        }
      })}
    </Navigation>
  );

  return (
    <Page>
      <Header />
      {navigation}

      <Switch>
        <Route path={`${path}/overview`} component={ScaleOverview} />
        <Route path={`${path}/log`} component={EventLog} />
        <Route path={`${path}/manage`} component={ScaleManage} />
        <Route path={`${path}/reports`} component={ScaleReports} />
        <Redirect from={`${path}`} to={`${path}/overview`} exact />
      </Switch>
    </Page>
  );
};

export default Scale;
