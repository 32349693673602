import { Component } from "react";
import { Formik, Field, Form } from "formik";

import styled from "styled-components";
import { DeviceType, Customer } from "../interfaces/types";
import { DateTimePicker } from "./DateTimePicker";
import { WithTranslation, withTranslation } from "react-i18next";
import { DeviceImageCarousel } from "./DeviceImageCarousel";

const Dialog = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledForm = styled(Form)`
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 2fr 1fr;
`;

const LeftPanel = styled.div`
  margin-top: 1rem;
  display: grid;
  grid-template-rows: repeat(8, auto);
  grid-template-columns: auto auto;
  grid-gap: 10px;
  height: 100%;
  width: 100%;
`;

interface ButtonProps {
  background?: string;
}

const Button = styled.button<ButtonProps>`
  width: 89px;
  height: 34px;
  border-radius: 17px;
  background-color: ${(props) =>
    props.background ? props.background : "#ffffff"};
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  outline: none;
  border: none;
  color: var(--col-214269);
  cursor: pointer;
`;

const RightPanel = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-right: 50px;
`;

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 35px;
  width: 100%;

  margin-top: 2rem;

  ${Button}:first-child {
    margin-right: 1rem;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

interface LabelProps {
  marginLeft?: number;
}

const TextLabel = styled.div<LabelProps>`
  display: flex;
  justify-content: left;
  align-items: center;
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : 50)}px;
  width: 100%;
  min-height: 25px;
  object-fit: contain;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--col-1-c-2020);
`;

const Input = styled(Field)`
  display: block;
  border-radius: 17px;
  width: 362px;
  height: 34px;
  border-style: none;

  padding: 0.5rem;
  font-size: 16px;
  font-family: "Roboto";
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--col-214269);

  :focus {
    border-color: #007eff;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 0 3px rgba(0, 126, 255, 0.1);
    outline: none;
  }

  :disabled {
    opacity: 0.7;
  }
`;

function hasCustomerInfoChanged(
  device: DeviceType,
  rentalStartDate: Date | undefined,
  rentalEndDate: Date | undefined,
  values: any
) {
  let startDate: Date | undefined;
  let endDate: Date | undefined;
  if (device.rentalStartDate) {
    startDate = new Date(device.rentalStartDate);
  }
  if (device.rentalEndDate) {
    endDate = new Date(device.rentalEndDate);
  }

  return (
    startDate?.getTime() !== rentalStartDate?.getTime() ||
    endDate?.getTime() !== rentalEndDate?.getTime() ||
    device.rentalAddress !== values.rental_address ||
    device.customerName !== values.customer
  );
}

function getDate(date: Date | string | undefined): Date | undefined {
  if (date) {
    return new Date(date);
  }
  return undefined;
}

interface EditContainerProps extends WithTranslation {
  token: string;
  device: DeviceType;
  onClose(customer: Customer | undefined, alias: string | undefined): void;
}
interface EditContainerState {
  rentalStartDate: Date | undefined;
  rentalEndDate: Date | undefined;
}
class EditContainerFormComponent extends Component<
  EditContainerProps,
  EditContainerState
> {
  constructor(props: EditContainerProps) {
    super(props);
    this.state = {
      rentalStartDate: getDate(props.device.rentalStartDate),
      rentalEndDate: getDate(props.device.rentalEndDate),
    };
  }

  private onChangeRentalStartDate = (date: Date) => {
    if (date && this.state.rentalEndDate && date > this.state.rentalEndDate) {
      // Change automatically rentalEndDate empty
      this.setState({
        rentalStartDate: date,
        rentalEndDate: undefined,
      });
      return;
    }
    this.setState({
      rentalStartDate: date,
    });
  };
  private onChangeRentalEndDate = (date: Date) => {
    if (
      date &&
      this.state.rentalStartDate &&
      date < this.state.rentalStartDate
    ) {
      return;
    }
    this.setState({
      rentalEndDate: date,
    });
  };
  public render(): JSX.Element {
    const { t } = this.props;

    const margin = 10;
    return (
      <Dialog>
        <Formik
          initialValues={{
            asset_name: this.props.device.alias ? this.props.device.alias : "",
            rental_address: this.props.device.rentalAddress
              ? this.props.device.rentalAddress
              : "",
            customer: this.props.device.customerName
              ? this.props.device.customerName
              : "",
          }}
          onSubmit={(values) => {
            // Has any value changed?
            let customer: Customer | undefined = undefined;
            if (
              hasCustomerInfoChanged(
                this.props.device,
                this.state.rentalStartDate,
                this.state.rentalEndDate,
                values
              )
            ) {
              customer = {
                customerName: values.customer,
                rentalAddress: values.rental_address,
                rentalStartDate: this.state.rentalStartDate,
                rentalEndDate: this.state.rentalEndDate,
              };
            }
            let alias: string | undefined = undefined;
            if (this.props.device.alias !== values.asset_name) {
              alias = values.asset_name;
            }
            this.props.onClose(customer, alias);
          }}
          onReset={(_values) => {
            this.setState({
              rentalStartDate: getDate(this.props.device.rentalStartDate),
              rentalEndDate: getDate(this.props.device.rentalEndDate),
            });
          }}
          render={({ isSubmitting }) => (
            <StyledForm>
              <LeftPanel>
                <TextLabel>
                  {t("editContainerForm.serialNumber", "Serial Number")}
                </TextLabel>
                <TextLabel marginLeft={margin}>
                  {this.props.device.serialNumber}
                </TextLabel>
                <TextLabel>
                  {t("editContainerForm.ownerGroup", "Owner group")}
                </TextLabel>
                <TextLabel marginLeft={margin}>
                  {this.props.device.ownerGroup}
                </TextLabel>
                <TextLabel>
                  {t(
                    "editContainerForm.locationLastUpdated",
                    "Location last updated"
                  )}
                </TextLabel>
                <TextLabel marginLeft={margin}>
                  {this.props.device.lastOnline
                    ? new Date(this.props.device.lastOnline).toLocaleString()
                    : undefined}
                </TextLabel>
                <TextLabel>
                  {t("editContainerForm.assetName", "Asset name")}
                </TextLabel>
                <Input type="text" name="asset_name" />
                <TextLabel>
                  {t("editContainerForm.customer", "Customer")}
                </TextLabel>
                <Input type="text" name="customer" />
                <TextLabel>
                  {t("editContainerForm.rentalStartDate", "Rental start date")}
                </TextLabel>
                {/* Div wrappers are needed to prevent the grid from being messed up
                when date picker opens and injects a new element */}
                <div>
                  <DateTimePicker
                    id={"rental_start"}
                    selectedDate={this.state.rentalStartDate}
                    handleChange={this.onChangeRentalStartDate}
                    name={"rental_start"}
                  />
                </div>
                <TextLabel>
                  {t("editContainerForm.rentalEndDate", "Rental end date")}
                </TextLabel>
                <div>
                  <DateTimePicker
                    selectedDate={this.state.rentalEndDate}
                    handleChange={this.onChangeRentalEndDate}
                    name={"rental_end"}
                  />
                </div>
                <TextLabel>
                  {t("editContainerForm.rentalAddress", "Rental address")}
                </TextLabel>
                <Input type="text" name="rental_address" />
              </LeftPanel>
              <RightPanel>
                <ImageWrapper>
                  <DeviceImageCarousel
                    serialNumber={this.props.device.serialNumber}
                    token={this.props.token}
                    width="15rem"
                    height="12.5rem"
                  />
                </ImageWrapper>
              </RightPanel>
              <Container>
                <Button type="reset" name="reset" disabled={isSubmitting}>
                  {t("editContainerForm.resetButton", "Reset")}
                </Button>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  background={"var(--col-11-a-3-c-7)"}
                  name="ok"
                >
                  {t("editContainerForm.submitButton", "Ok")}
                </Button>
              </Container>
            </StyledForm>
          )}
        />
      </Dialog>
    );
  }
}

export const EditContainerForm = withTranslation()(EditContainerFormComponent);
