import { DeviceErrorTaskStates } from "@shared/types";

export const triggerStateToString: Record<DeviceErrorTaskStates, string> = {
  OIL_LEAKAGE_DETECTED: "Oil leakage detected",
  TRAVEL_TIMES_ERROR: "Travel times error",
  INPUT_TIMING_ERROR: "Input timing error",
  MOTOR_THERMO_NOT_OK: "Motor temperature error",
  CONTACTOR_FAILURE: "Contactor failure",
  PRESSURE_SENSOR_ERROR: "Pressure sensor error",
};

export const triggerStateToFormString: Record<DeviceErrorTaskStates, string> = {
  ...triggerStateToString,
  CONTACTOR_FAILURE: `${triggerStateToString.CONTACTOR_FAILURE} [1]`,
  PRESSURE_SENSOR_ERROR: `${triggerStateToString.PRESSURE_SENSOR_ERROR} [1]`,
};
