import { parseISO } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";

const Container = styled.div`
  padding: 0;
`;

function DateInput(props: any) {
  const { value, onChange, onSelect, className, useISO, ...rest } = props;

  const handleOnChange = (date: Date) => {
    if (onChange !== undefined) {
      const dateVal = useISO && date ? date.toISOString() : date;
      onChange(props.id, dateVal);
    }
  };

  const handleOnSelect = (date: Date) => {
    if (onSelect !== undefined) {
      const dateVal = useISO && date ? date.toISOString() : date;
      onSelect(props.id, dateVal);
    }
  };

  return (
    <Container className={className}>
      <DatePicker
        selected={useISO && value ? parseISO(value) : value}
        onChange={handleOnChange}
        onSelect={handleOnSelect}
        className={className}
        {...rest}
      />
    </Container>
  );
}

export default styled(DateInput)`
  .react-datepicker-wrapper {
    display: flex;
    flex: 1;
  }
  .react-datepicker__input-container {
    display: flex;
    flex: 1;
  }

  input {
    flex: 1;
    padding: 0 0.5rem;
  }
`;
