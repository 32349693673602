import _ from "lodash/fp";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../constants/actionTypes";
import styled from "styled-components";
import { HTMLInput } from "../../../components/Common/FormInput";
import { DropdownCombobox } from "../../../components/Common/DropdownCombobox";
import { AdminState } from "../../../reducers/admin";
import { Title } from "../../../components/Common/Title";
import { Subtitle } from "../../../components/Common/Title";
import { FillLevelsGraph } from "./FillLevelsGraph";
import { Spinner } from "../../../components/Spinner";
import { FillLevelErrorsTable } from "./FillLevelErrorsTable";
import { PressureDataGraph } from "./PressureDataGraph";
import { DeviceType } from "../../../interfaces/types";

const Select = styled(HTMLInput).attrs({ as: "select" })``;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 50rem;

  ${Select} {
    align-self: flex-start;
  }
`;

const Page = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  background-color: var(--ice-blue);
`;

const deviceToString = (device: DeviceType | null): string => {
  if (!device) {
    return "";
  }

  const siteStr = device.site ? ` / ${device.site}` : "";
  return `${device.serialNumber}${siteStr}`;
};

function DeviceSelect({
  devices,
  onSelect,
}: {
  devices: DeviceType[];
  onSelect: (device: DeviceType | undefined | null) => void;
}) {
  const [searchDevices, setSearchDevices] = useState(devices);

  useEffect(() => {
    setSearchDevices(devices);
  }, [devices]);

  return (
    <DropdownCombobox
      // Show at most 50 elements to prevent slowness for thousands of devices
      items={searchDevices.slice(0, 50)}
      itemToString={deviceToString}
      onSelectedItemChange={({ selectedItem }) => onSelect(selectedItem)}
      labelString="Select device"
      placeholder="Enter serial number or alias"
      width="33%"
      onInputValueChange={({ inputValue }) => {
        if (inputValue === undefined) {
          setSearchDevices(devices);
        } else {
          setSearchDevices(
            devices.filter((device) =>
              deviceToString(device)
                .toLowerCase()
                .includes(inputValue?.toLowerCase())
            )
          );
        }
      }}
    />
  );
}

export function DevicePressureData() {
  const [deviceSerial, setDeviceSerial] = useState<undefined | string>();
  const token = useSelector((state: any) => state.token.key);
  const devices: DeviceType[] = useSelector((state: any) =>
    _.sortBy("serialNumber", state.devices.elements)
  );
  const pressureData = useSelector((state: { admin: AdminState }) =>
    deviceSerial !== undefined
      ? state.admin.pressureData.devices[deviceSerial]
      : undefined
  );
  const fillLevels = pressureData?.fillLevels;

  const dispatch = useDispatch();

  useEffect(() => {
    if (devices.length === 0) {
      dispatch({
        type: actions.FETCH_DEVICES,
        payload: { token, initialFetch: false },
      });
    }
    // eslint-disable-next-line
  }, [dispatch, token]);

  useEffect(() => {
    if (fillLevels === undefined && deviceSerial !== undefined) {
      dispatch({
        type: actions.GET_DEVICE_FILL_LEVELS,
        payload: {
          token,
          serialNumber: deviceSerial,
        },
      });
    }
    // eslint-disable-next-line
  }, [deviceSerial, fillLevels, token, dispatch]);

  return (
    <Page>
      <Container>
        <Title>Device pressure and fill level data</Title>
        <DeviceSelect
          devices={devices}
          onSelect={(device) => setDeviceSerial(device?.serialNumber)}
        />
        {(() => {
          if (deviceSerial === undefined) {
            return undefined;
          } else if (pressureData?.fillLevelsLoading) {
            return <Spinner />;
          } else if (fillLevels !== undefined) {
            return (
              <>
                <Subtitle>Fill level values</Subtitle>
                <FillLevelsGraph fillLevels={fillLevels} />
                <Subtitle>Pressure data</Subtitle>
                <PressureDataGraph
                  fillLevels={fillLevels}
                  deviceSerial={deviceSerial}
                />
                <Subtitle>Fill level errors</Subtitle>
                <FillLevelErrorsTable fillLevels={fillLevels} />
              </>
            );
          }
          return;
        })()}
      </Container>
    </Page>
  );
}
