import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";

interface ItemListProps {
  items: any;
  selectedId: number | undefined;
  activeId: number | undefined;
  onClick: (id: number) => void;
  onChange?: (name: string) => void;
  noDataText?: string;
  editMode?: boolean;
  editableValue?: string;
}

interface ListItemProps {
  selected: boolean;
  active: boolean;
}

interface EditableListItemProps {
  value: string | undefined;
  onChange?: (name: string) => void;
}

export const Item = styled.span<ListItemProps>`
  display: flex;
  align-items: center;
  background-color: ${(props) => {
    if (props.active) {
      return "#4e9bf9";
    } else if (props.selected) {
      return "var(--ice-blue);";
    } else {
      return "transparent";
    }
  }};
  color: ${(props) => (props.active ? "#ffffff" : "#000000")};
  cursor: pointer;
  border-radius: 4px;
  padding: 5px;
  font-size: 14px;
`;

const EditableItem = styled.input<EditableListItemProps>`
  display: block;
  width: calc(100% - 10px);
  border: solid 1px var(--light-blue-grey);
  background-color: var(--ice-blue);
  margin: 5px 0;
  border-radius: 12px;
  line-height: 20px;
  padding-left: 5px;
`;

export const List = styled.div`
  max-height: 500px;
  overflow-y: auto;
`;

export const NoData = styled.span`
  padding: 5px;
  font-size: 14px;
`;

const NumberOfKeys = styled.span`
  margin-left: auto;
`;

export function ItemList(props: ItemListProps) {
  const onClick = (id: number) => () => {
    props.onClick(id);
  };

  return (
    <List>
      {(!props.items || !props.items.length) && (
        <NoData>{props.noDataText}</NoData>
      )}
      {props.items.map((item: any) => {
        if (props.editMode && item.id === props.selectedId) {
          return (
            <EditableItem
              type="text"
              key={uuidv4()}
              value={props.editableValue}
              autoFocus={true}
              onChange={(e: any) =>
                props.onChange ? props.onChange(e.target.value) : null
              }
            />
          );
        } else {
          return (
            <Item
              key={uuidv4()}
              selected={props.selectedId === item.id}
              active={props.activeId === item.id}
              onClick={onClick(item.id)}
            >
              {item.name}
              {item.numberOfKeys !== undefined && (
                <NumberOfKeys>{item.numberOfKeys}</NumberOfKeys>
              )}
            </Item>
          );
        }
      })}
    </List>
  );
}
