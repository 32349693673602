import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useLocation } from "react-router-dom";
import styled from "styled-components";
import * as Yup from "yup";
import * as TYPES from "../constants/actionTypes";
import { Page } from "./Page";
import { Header } from "../components/Header";
import { FormSubmitButton } from "../components/Common/FormSubmitButton";
import { FormInput } from "../components/Common/FormInput";
import { FormBackground } from "../components/Common/FormBackground";
import { FormRectangle } from "../components/Common/FormRectangle";
import { FormTitle } from "../components/Common/FormTitle";

function getSchema(errorMessage: string) {
  return Yup.object().shape({
    userCode: Yup.string()
      .required(errorMessage)
      .matches(/\w{4}-\w{4}/, errorMessage),
  });
}

const SignInPage = styled.section`
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  height: 100vh;
  width: 100%;
  z-index: 1;
`;

const InfoText = styled.p<{ margin: string }>`
  margin: ${(props) => props.margin};
  font-size: 1rem;
  font-family: "Roboto";
  color: white;
`;

function ServiceToolLoginForm() {
  const token = useSelector((state: any) => state.token.key);
  const location = useLocation();
  const isSubmitting = useSelector(
    (state: any) => state.users.serviceToolLoginOngoing
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const prefilledUserCode = new URLSearchParams(location.search).get(
    "user_code"
  );

  return (
    <Page>
      <SignInPage>
        <Header />
        <FormBackground cursor={isSubmitting ? "wait" : "default"}>
          <FormRectangle>
            <FormTitle
              title={t("serviceTool.login.title", "Login to service tool")}
            />
            <Formik
              initialValues={{ userCode: prefilledUserCode ?? "" }}
              validationSchema={getSchema(
                t(
                  "serviceTool.login.userCode.formatError",
                  "Code must be of form XXXX-XXXX."
                )
              )}
              onSubmit={(values) => {
                dispatch({
                  type: TYPES.DO_SERVICE_TOOL_LOGIN,
                  payload: { token, userCode: values.userCode },
                });
              }}
            >
              {({ values, handleChange, errors, submitCount }) => (
                <Form>
                  <InfoText margin="2.5rem 12.5% 2rem">
                    {prefilledUserCode
                      ? t(
                          "serviceTool.login.infoPrefilled",
                          "Confirm that the code below matches the code displayed in your service tool."
                        )
                      : t(
                          "serviceTool.login.info",
                          "Enter the code displayed in your service tool to complete the login."
                        )}
                  </InfoText>
                  <FormInput
                    id="userCode"
                    value={values.userCode}
                    onChange={handleChange}
                    label=""
                    error={0 < submitCount ? errors.userCode : undefined}
                    type="text"
                  />
                  <FormSubmitButton
                    margin="0.5rem 12.5% 0"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {t("serviceTool.login.login", "Login")}
                  </FormSubmitButton>
                </Form>
              )}
            </Formik>
          </FormRectangle>
        </FormBackground>
      </SignInPage>
    </Page>
  );
}

function ServiceToolLoginSuccess() {
  const { t } = useTranslation();

  return (
    <Page>
      <SignInPage>
        <Header />
        <FormBackground>
          <FormRectangle>
            <FormTitle
              title={t("serviceTool.login.title", "Login to service tool")}
            />
            <InfoText margin="auto 12.5%">
              {t(
                "serviceTool.login.success",
                "Succesfully logged in to service tool. You can close the browser window."
              )}
            </InfoText>
          </FormRectangle>
        </FormBackground>
      </SignInPage>
    </Page>
  );
}

export function ServiceToolLogin() {
  return (
    <Switch>
      <Route exact path="/servicetool/login" component={ServiceToolLoginForm} />
      <Route
        exact
        path="/servicetool/login/success"
        component={ServiceToolLoginSuccess}
      />
    </Switch>
  );
}
