import { DeviceTasksApi } from "@shared/apiTypes";
import _ from "lodash/fp";
import * as actions from "../constants/actionTypes";
import { arrayToIdObject } from "../utils/utils";

export type DeviceTasks = {
  tasks: { [id: number]: DeviceTasksApi[0] };
  isLoading: boolean;
};

const initialState = {
  tasks: {},
  isLoading: false,
};

const deviceTasks = (state: DeviceTasks = initialState, action: any) => {
  switch (action.type) {
    case actions.GET_ALL_DEVICE_TASKS: {
      return { ...state, isLoading: true };
    }
    case actions.GET_ALL_DEVICE_TASKS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        tasks: arrayToIdObject(action.payload.tasks),
      };
    }
    case actions.GET_ALL_DEVICE_TASKS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case actions.DELETE_DEVICE_TASK_SUCCESS: {
      return {
        ...state,
        tasks: _.omit(action.payload.taskId, state.tasks),
      };
    }
    default: {
      return state;
    }
  }
};

export default deviceTasks;
