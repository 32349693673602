import styled from "styled-components";

const Page = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  background-color: var(--ice-blue);
  white-space: pre-wrap;
`;

export function MobilePrivacyPolicy() {
  return (
    <Page>
      {`EUROPRESS SMARTWASTE MOBILE APP - PRIVACY POLICY

This privacy policy describes the principles of processing the personal data of the SMARTwaste mobile application users.

1. Data controller
Europress Group Oyj, tietosuojavastaava
Huhtimontie 16
04420 Kerava

Europress Group Oyj - phone: 020 737 3131

2. Contacting the register data protection officer
Email: support.smart@europress.fi

3. Purpose and justification for the use of personal information
Europress collects the information about device and user logins and uses that for analytical and development purposes. Following data is collected during the application usage:
* email addresses and login times
* phone number
* name
* device model and type of operating system

 4. Regular source of information
SMARTWaste.

5.  Regular information sharing
Collected data is processed by third party companies that are approved development and maintenance partners of Europress. Europress does not share any information for outsiders.
6. Transfer of information outside of EU or EEA
Information is not transferred outside the EU or EEA.

7. Retention
Information is retained the time it is necessary for the original purpose or legislative obligations.

---

EUROPRESS SMARTWASTE MOBIILISOVELLUS - TIETOSUOJASELOSTE

Tämä tietosuojaseloste kertoo, kuinka Europress Group Oyj käsittelee SMARTWaste-sovelluksen keräämiä käyttäjää koskevia tietoja.

1. Rekisterinpitäjän yhteystiedot
Europress Group Oyj, Tietosuojavastaava
Huhtimontie 16
04420 Kerava

Europress Group Oyj - puhelinvaihde: 020 737 3131

2. Rekisteriasioista vastaavan kontaktointi
Sähköposti: support.smart@europress.fi

3. Henkilötietojen käsittelyn tarkoitus ja peruste
Käyttäjien laitteista kerätään yksilöivät tunnisteet sekä kirjautumistiedot sovelluksen käytön analytiikkaa ja kehittämistä varten. Sovellus tallentaa käytön aikana seuraavat tiedot:
* sähköpostiosoitteet ja sisäänkirjautumiskellonajat
* puhelinnumero
* nimi
* laitteen malli ja käyttöjärjestelmän tyyppi

 4. Säännönmukaiset tietolähteet
SMARTWaste.

5.  Säännönmukaiset tietojen luovutukset
Sovelluksen keräämiä tietoja käsittelevät Europressille sovelluksen kehittämis- ja ylläpitotyötä tarjoavat yhteistyökumppanit. Europress ei luovuta tietoja ulkopuolisille.

6. Tietojen siirto EU:n tai ETA:n ulkopuolelle
Tietoja ei siirretä EU:n tai ETA:n ulkopuolelle.

7. Tietojen säilytysaika
Tietoja käsitellään sen ajan, kun se on tarpeen alkuperäisen käyttötarkoituksen tai lainsäädännöllisten velvoitteiden hoitamiseksi.
      `}
    </Page>
  );
}
