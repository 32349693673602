import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { formFieldCss } from "../../../components/Common/FormInput";
import { Item, List, NoData } from "../../../components/Common/ItemList";
import * as actionTypes from "../../../constants/actionTypes";

const Name = styled.span`
  white-space: nowrap;
`;

const SecondaryAlias = styled.span`
  margin-left: 1rem;
  font-size: 0.875em;
  color: var(--col-214269);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SecondaryAliasInput = styled.input`
  ${formFieldCss};

  width: 50%;
  max-width: 10rem;
  margin-left: 1rem;
  border-radius: 4px;
  font-size: 0.875em;
  padding: 0.25em 0.3em;
`;

function SecondaryAliasForm(props: { tag: any; onClick: () => void }) {
  const { t } = useTranslation();
  const [secondaryAlias, setSecondaryAlias] = useState(
    props.tag.secondary_alias || null
  );
  const token = useSelector((state: any) => state.token.key);
  const dispatch = useDispatch();

  return (
    <SecondaryAliasInput
      placeholder={t("scale.manage.keys.addAlias", "Give an optional alias")}
      value={secondaryAlias ?? ""}
      onChange={(e) => setSecondaryAlias(e.target.value || null)}
      onClick={(e) => {
        e.stopPropagation();
      }}
      onBlur={() => {
        dispatch({
          type: actionTypes.UPDATE_SCALE_TAG,
          payload: {
            token,
            secondary_alias: secondaryAlias,
            id: props.tag.id,
            secondary_alias_current: props.tag.secondary_alias,
          },
        });
        /**
         * We need to manually call the parent component's onClick handler, because it is not
         * correctly called when the user clicks on the parent component when this input has focus.
         * This seems to be a bug (or undesired feature) with React.
         * */
        props.onClick();
      }}
    />
  );
}

interface Props {
  keys: any[];
  selectedId: number | undefined;
  onClick: (id: number) => void;
  onChange?: (name: string) => void;
  noDataText?: string;
  editMode?: boolean;
}
export function KeyList(props: Props) {
  const onClick = (id: number) => () => {
    props.onClick(id);
  };

  return (
    <List>
      {(!props.keys || !props.keys.length) && (
        <NoData>{props.noDataText}</NoData>
      )}
      {props.keys.map((key) => (
        <Item
          key={key.id}
          selected={props.selectedId === key.id}
          active={props.selectedId === key.id}
          onClick={onClick(key.id)}
        >
          <Name>{key.alias}</Name>
          {props.editMode && key.id === props.selectedId ? (
            <SecondaryAliasForm tag={key} onClick={onClick(key.id)} />
          ) : (
            <SecondaryAlias>{key.secondary_alias}</SecondaryAlias>
          )}
        </Item>
      ))}
    </List>
  );
}
