import styled from "styled-components";
import icon_toggle_on from "../../assets/icon-toggle-on.svg";
import icon_toggle_off from "../../assets/icon-toggle-off.svg";

const ToggleSwitch = styled.img`
  height: 24px;
  width: 36px;
  cursor: pointer;
  outline: none;
`;

interface ToggleProps {
  selected: Boolean;
  onToggle: (selected: Boolean) => any;
  alt?: string;
}
export function Toggle(props: ToggleProps) {
  return (
    <ToggleSwitch
      src={props.selected ? icon_toggle_on : icon_toggle_off}
      alt={props.alt}
      onClick={() => props.onToggle(!props.selected)}
    />
  );
}
