import { Component } from "react";
import { connect } from "react-redux";
import * as queryString from "query-string";

import * as TYPES from "../constants/actionTypes";
import { periodicUpdateCycle } from "../constants/options";

import { Map } from "./Map";
import { Navigation } from "../components/Navigation";
import { DeviceList } from "../components/DeviceList";
import { Page } from "./Page";

import { Spinner } from "../components/Spinner";
import {
  DeviceType,
  MachineType,
  SelectableColumns,
} from "../interfaces/types";
import { Header } from "../components/Header";
import { DevicesPage, DeviceMapSplit } from "../components/Common";
import { isTokenExpired } from "../utils/utils";
import { Redirect } from "react-router";
import { DeviceModal, TabType } from "../components/DeviceModal/DeviceModal";
import { visibleDevicesSelector } from "../selectors/devices";

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchDevices: (token: string, initialFetch: boolean) => {
      dispatch({
        type: TYPES.FETCH_DEVICES,
        payload: {
          token: token,
          initialFetch: initialFetch,
        },
      });
    },
  };
};

const mapStateToProps = (state: any) => {
  return {
    token: state.token,
    isFetching: state.devices.isFetching,
    devices: visibleDevicesSelector(state),
    loadedDevices: state.devices.loadedDevices,
    users: state.users,
    deviceColumnsSelected: state.users.deviceColumnsSelected,
  };
};

interface DevicesProps {
  fetchDevices(token: string, initialFetch: boolean): void;
  token: any;
  isFetching: boolean;
  devices: Array<DeviceType>;
  loadedDevices: number;
  users: any;
  statusUpdate: boolean;
  deviceColumnsSelected: SelectableColumns[];
  match: any;
  history: any;
  location: any;
}
interface DeviceState {
  openPopup: boolean;
  activeDevice?: DeviceType;
  showAnalytics: boolean;
  activeModalTab: number;
}

class DevicesConnected extends Component<DevicesProps, DeviceState> {
  private _intervalID: any = undefined;

  constructor(props: DevicesProps) {
    super(props);
    this.state = {
      openPopup: false,
      activeDevice: undefined,
      showAnalytics: false,
      activeModalTab: TabType.DEVICE_DETAILS,
    };
  }

  public componentDidMount(): void {
    if (this.props.token.key !== "" && this.props.loadedDevices === 0) {
      this.props.fetchDevices(this.props.token.key, true);
    }
    this._intervalID = setInterval(
      this.updateDevices,
      periodicUpdateCycle.defaultUpdateCycle
    );
    window.addEventListener(
      "visibilitychange",
      this.handleVisibilityChange,
      true
    );
  }

  public componentWillUnmount() {
    window.removeEventListener(
      "visibilitychange",
      this.handleVisibilityChange,
      true
    );
    clearInterval(this._intervalID);
  }

  public handleVisibilityChange = (): void => {
    if (document.visibilityState === "visible") {
      clearInterval(this._intervalID);
      this._intervalID = setInterval(
        this.updateDevices,
        periodicUpdateCycle.defaultUpdateCycle
      );
    } else {
      clearInterval(this._intervalID);
    }
  };

  public componentDidUpdate(prevProps: DevicesProps) {
    if (
      this.props.isFetching !== prevProps.isFetching &&
      !this.props.isFetching
    ) {
      // Handle opening device modal if there's deviceId in url
      const { location } = this.props;

      if (0 < location.search.length) {
        const { serialNumber } = queryString.parse(location.search.slice(1));
        this.setState({
          openPopup: serialNumber !== undefined,
          activeDevice: this.props.devices.find(
            (device: DeviceType) => device.serialNumber === serialNumber
          ),
        });
      }
    }
  }

  private updateDevices = (): void => {
    if (
      this.props.token.key !== "" &&
      !this.props.isFetching &&
      !isTokenExpired(this.props.token)
    ) {
      this.props.fetchDevices(this.props.token.key, false);
    } else if (isTokenExpired(this.props.token)) {
      // Now this causes that page is updated which causes that Page-component moves user to login screen (token expired)
      this.forceUpdate();
    }
  };
  private handleEditDevice = (device: DeviceType): void => {
    this.setState({
      openPopup: true,
      activeDevice: device,
      activeModalTab: TabType.DEVICE_DETAILS,
    });
    this.props.history.push({
      pathname: `/devices`,
      search: queryString.stringify({
        serialNumber: device.serialNumber,
      }),
    });
  };
  private handleShowServiceRequests = (device: DeviceType): void => {
    this.setState({
      openPopup: true,
      activeDevice: device,
      activeModalTab: TabType.SERVICE_REQUESTS,
    });
    this.props.history.push({
      pathname: `/devices`,
      search: queryString.stringify({
        serialNumber: device.serialNumber,
      }),
    });
  };
  private handleEditDeviceClose = (): void => {
    this.setState({ openPopup: false, activeDevice: undefined });
  };
  private handleShowAnalytics = (device: DeviceType): void => {
    this.setState({
      openPopup: true,
      activeDevice: device,
      activeModalTab: TabType.DATA_ANALYTICS,
    });
    this.props.history.push({
      pathname: `/devices`,
      search: queryString.stringify({
        serialNumber: device.serialNumber,
      }),
    });
  };
  public render(): JSX.Element {
    let spinnerOverlay = this.props.isFetching ? <Spinner /> : undefined;
    if (this.state.showAnalytics) {
      let guid = "";
      if (this.state.activeDevice && this.state.activeDevice.guid) {
        guid = this.state.activeDevice.guid;
      }
      return (
        <Redirect
          to={{
            pathname: `/analytics/${guid}`,
          }}
        />
      );
    }
    return (
      <Page>
        <DevicesPage>
          {spinnerOverlay}
          <Header />
          <Navigation
            searchActive={true}
            visibleDevicesCount={this.props.devices.length}
          />
          <DeviceMapSplit
            renderDeviceTable={(contentRef) => (
              <>
                <DeviceModal
                  deviceOpen={this.state.openPopup}
                  device={this.state.activeDevice}
                  activeTab={this.state.activeModalTab}
                  handleCloseDevice={this.handleEditDeviceClose}
                  user={this.props.users}
                />
                <DeviceList
                  devices={this.props.devices}
                  contentRef={contentRef}
                  columnsSelected={this.props.deviceColumnsSelected}
                  contentType={MachineType.Compactor}
                  onEditDevice={this.handleEditDevice}
                  onShowServiceRequests={this.handleShowServiceRequests}
                  onShowAnalytics={this.handleShowAnalytics}
                />
              </>
            )}
            mapChildren={
              <Map
                deviceTypesOnMap={MachineType.Compactor}
                onEditDevice={this.handleEditDevice}
                onShowAnalytics={this.handleShowAnalytics}
              />
            }
          />
        </DevicesPage>
      </Page>
    );
  }
}

export const Devices = connect(
  mapStateToProps,
  mapDispatchToProps
)(DevicesConnected);
