import { RouteComponentProps } from "react-router-dom";
import { FormRectangle } from "../../../../components/Common/FormRectangle";
import { FormTitle } from "../../../../components/Common/FormTitle";
import {
  useDeviceErrorTaskUpdateFormData,
  useUpdateDeviceErrorTask,
} from "../../../../queries/deviceErrorTask";
import { DeviceErrorTaskUpdateApi } from "@shared/apiTypes";
import { Spinner } from "../../../../components/Spinner";
import { ErrorTaskForm, ErrorTaskFormValues } from "./ErrorTaskForm";

function formValuesToApi(
  values: ErrorTaskFormValues
): DeviceErrorTaskUpdateApi {
  const commonValues = {
    name: values.name,
    includeChildGroupDevices: values.includeChildGroupDevices,
    emailRecipients: values.emailRecipients
      .split("\n")
      .filter(Boolean)
      .map((email) => email.trim()),
    emailTexts: values.emailTexts,
    errorThresholdSeconds: values.errorThresholdSeconds,
  };

  return values.sharedForAllDevices
    ? {
        sharedForAllDevices: values.sharedForAllDevices,
        sharedTriggerStates: values.sharedTriggerStates,
        ...commonValues,
      }
    : {
        sharedForAllDevices: values.sharedForAllDevices,
        deviceTriggerStates: values.deviceTriggerStates
          .filter(
            (devStates) =>
              values.includeChildGroupDevices || devStates.owner === "self"
          )
          .map((devStates) => ({
            deviceId: devStates.id,
            triggerStates: [...devStates.triggerStates.entries()]
              .filter(([, enabled]) => enabled)
              .map(([state]) => state),
          })),
        ...commonValues,
      };
}

export function ErrorTaskUpdate(props: RouteComponentProps<{ id: string }>) {
  const errorTaskIdParam = props.match.params.id;
  const errorTaskId = parseInt(errorTaskIdParam, 10);
  const updateTask = useUpdateDeviceErrorTask();
  const formData = useDeviceErrorTaskUpdateFormData(errorTaskId);

  if (formData.isLoading) {
    return <Spinner />;
    // Don't show old data from the cache
  } else if (formData.data && formData.isFetchedAfterMount) {
    const initialValues = formData.data.sharedForAllDevices
      ? {
          ...formData.data,
          emailRecipients: formData.data.emailRecipients.join("\n"),
        }
      : {
          ...formData.data,
          emailRecipients: formData.data.emailRecipients.join("\n"),
          sharedTriggerStates: [],
        };

    return (
      <div>
        <FormRectangle style={{ width: "60rem", marginTop: "2rem" }}>
          <FormTitle title="Update error task" />
          <ErrorTaskForm
            initialValues={initialValues}
            groupName={formData.data.groupName ?? "Name not available"}
            submitText="Update"
            onBackClick={() => props.history.goBack()}
            onSubmit={(values, stopSubmitting) =>
              updateTask.mutate(
                { taskId: errorTaskId, task: formValuesToApi(values) },
                {
                  onSettled: stopSubmitting,
                  onSuccess: () =>
                    props.history.replace("/admin/devices/tasks/device-error"),
                }
              )
            }
          />
        </FormRectangle>
      </div>
    );
  }

  return <div>Unable to load form</div>;
}
