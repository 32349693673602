import * as Yup from "yup";
import { possibleDeviceErrorTaskStates } from "@shared/constants";

export const BaseFormSchema = Yup.object().shape({
  name: Yup.string().required().min(1),
  includeChildGroupDevices: Yup.boolean(),
  sharedForAllDevices: Yup.boolean(),
  emailRecipients: Yup.string().required(),
  emailTexts: Yup.object(
    Object.fromEntries(
      possibleDeviceErrorTaskStates.map((state) => [
        state,
        Yup.string().required(),
      ])
    )
  ),
});
