import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import logger from "redux-logger";
import throttle from "lodash/throttle";

import rootReducer from "../reducers/root";
import { loadState, saveState } from "./localStorage";
import sagas from "./sagas";
import { isTokenExpired } from "../utils/utils";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";

// Add Redux devtools to the window object
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

export const history = createBrowserHistory();

const configureStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares: any[] = [sagaMiddleware];
  if (process.env.NODE_ENV !== "production") {
    middlewares.push(logger);
  }
  const persistedState = loadState();
  if (
    persistedState &&
    persistedState.token &&
    isTokenExpired(persistedState.token)
  ) {
    persistedState.token = undefined;
  }

  // Only enable redux devtools in development
  const composeEnhancers =
    process.env.NODE_ENV !== "production"
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
      : compose;

  const store = createStore(
    rootReducer(history),
    persistedState,
    composeEnhancers(applyMiddleware(...middlewares, routerMiddleware(history)))
  );

  store.subscribe(
    throttle(() => {
      saveState({
        token: store.getState().token,
      });
    }, 1000)
  );
  sagaMiddleware.run(sagas);
  return store;
};

export default configureStore;
