import { useTranslation } from "react-i18next";
import { DesktopPrivacyPolicy } from "../containers/PrivacyPolicy/DesktopPrivacyPolicy";
import { Modal } from "./Common/Modal";

interface PrivacyStatementModalProps {
  privacyStatementOpen: boolean;
  handleClosePrivacyStatement: () => void;
}

export function PrivacyStatementModal(props: PrivacyStatementModalProps) {
  const { t } = useTranslation();

  const content: JSX.Element = (
    <>
      <div>
        <DesktopPrivacyPolicy />
      </div>
    </>
  );

  return (
    <Modal
      isOpen={props.privacyStatementOpen}
      handleClose={props.handleClosePrivacyStatement}
      content={content}
      title={t("header.userMenu.privacyStatement", "Privacy Statement")}
    />
  );
}
