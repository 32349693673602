import { createRef, Component, RefObject } from "react";
import { connect } from "react-redux";

import styled from "styled-components";

import { Header } from "../components/Header";
import { Spinner } from "../components/Spinner";
import { Page } from "./Page";
import { withTranslation, WithTranslation } from "react-i18next";
import { getDataAnalyticsToken } from "../api/data-analytics";
import { singleEffectiveAnalyticsGroupSelector } from "../selectors/groups";

const AnalyticsPage = styled.div`
  display: grid;
  height: 100vh;
  grid-template-areas: "header" "content";
  grid-template-rows: 1fr auto;
  @media (min-width: 768px) and (max-width: 1024px) {
    grid-template-rows: 1fr;
  }
`;

const Content = styled.div`
  height: 100vh;
`;
const Container = styled.div`
  margin-top: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Box = styled.div`
  padding: 1rem;
`;

const mapStateToProps = (state: any) => {
  return {
    token: state.token.key,
    users: state.users,
    analyticsGroup: singleEffectiveAnalyticsGroupSelector(state),
  };
};

interface AnalyticsProps extends WithTranslation {
  token: string;
  users: any;
  analyticsGroup: ReturnType<typeof singleEffectiveAnalyticsGroupSelector>;
  match: any;
}
interface AnalyticsState {
  fetchState: "initial" | "fetching" | "done" | "error";
  analyticsOrigin: string | null;
  analyticsToken: string | null;
}

class AnalyticsConnected extends Component<AnalyticsProps, AnalyticsState> {
  constructor(props: AnalyticsProps) {
    super(props);
    this.state = {
      fetchState: "initial",
      analyticsOrigin: null,
      analyticsToken: null,
    };
  }

  private readonly analyticsFrameRef: RefObject<HTMLIFrameElement> = createRef<HTMLIFrameElement>();

  private async fetchAnalyticsToken() {
    try {
      this.setState((prevState) => ({
        ...prevState,
        fetchState: "fetching",
      }));
      const res = await getDataAnalyticsToken(this.props.token, null);
      this.setState((prevState) => ({
        ...prevState,
        fetchState: "done",
        analyticsOrigin: res.origin,
        analyticsToken: res.access_token,
      }));
    } catch (error) {
      this.setState((prevState) => ({ ...prevState, fetchState: "error" }));
    }
  }

  public componentDidMount(): void {
    if (this.props.token && this.props.users.dataAnalyticsAllowed) {
      this.fetchAnalyticsToken();
      window.addEventListener("message", this.handleMessage);
    }
  }

  public componentDidUpdate(_prevProps: AnalyticsProps) {
    // Handle case in which this component is mounted before `this.props.token`
    // or `this.props.users.dataAnalyticsAllowed` exists, i.e., if we hard-refresh this page
    const fetchNotStarted = this.state.fetchState === "initial";
    const canFetchAnalyticsToken =
      this.props.token && this.props.users.dataAnalyticsAllowed;

    if (fetchNotStarted && canFetchAnalyticsToken) {
      this.fetchAnalyticsToken();
      window.addEventListener("message", this.handleMessage);
    }
  }

  public componentWillUnmount(): void {
    window.removeEventListener("message", this.handleMessage);
  }

  public render(): JSX.Element {
    if (!this.props.users.dataAnalyticsAllowed) {
      const { t } = this.props;
      return (
        <Page>
          <Header />
          <Content>
            <Container>
              <Box>
                {t(
                  "analytics.license_needed",
                  "Accessing Data Analytics needs active insights license. Please contact Europress."
                )}
              </Box>
            </Container>
          </Content>
        </Page>
      );
    }
    return (
      <Page>
        <AnalyticsPage>
          <Header />
          <Content>{this.getContent()}</Content>
        </AnalyticsPage>
      </Page>
    );
  }

  private handleMessage = (e: any): void => {
    if (e.origin !== this.state.analyticsOrigin || !e.data) {
      return;
    }
    const data = JSON.parse(e.data);
    const analyticsFrame: HTMLIFrameElement | null = this.analyticsFrameRef
      .current;
    if (data.height && !isNaN(data.height) && analyticsFrame) {
      analyticsFrame.height = data.height + "px";
    } else if (data.scroll && data.scroll === "top") {
      window.scrollTo(0, 0);
    }
  };

  private getContent(): JSX.Element {
    if (
      this.state.fetchState === "fetching" ||
      this.state.fetchState === "initial"
    ) {
      return <Spinner />;
    }

    if (this.state.fetchState === "error") {
      return (
        <Box>
          {this.props.t(
            "analytics.couldNotConnect",
            "Could not connect to data analytics. Please try again later."
          )}
        </Box>
      );
    }

    const url = this.getAnalyticsUrl();
    if (url !== "") {
      return (
        <iframe
          title={"Data Analytics"}
          ref={this.analyticsFrameRef}
          src={this.getAnalyticsUrl()}
          height="100%"
          width="100%"
        />
      );
    }
    return <></>;
  }

  private getAnalyticsUrl() {
    const language = this.props.users.language || "en";
    const server = this.state.analyticsOrigin;
    const groupGuid = this.props.analyticsGroup?.guid;

    const token = this.state.analyticsToken;
    if (this.props.match.params.deviceId) {
      return `${server}/europress/dashboard/device/${this.props.match.params.deviceId}/?access_token=${token}&lang=${language}`;
    } else if (groupGuid) {
      return `${server}/europress/dashboard/group/${groupGuid}/?access_token=${token}&lang=${language}`;
    }
    return "";
  }
}

export const Analytics = connect(mapStateToProps)(
  withTranslation()(AnalyticsConnected)
);
