import Axios, { AxiosPromise } from "axios";

export function getScaleEvents(action: any): AxiosPromise {
  let request = process.env.REACT_APP_LATEST_API + "/scale/events";
  const guid = action.payload.group;
  request = request + "/" + guid + "?";
  const params = new URLSearchParams({
    start: action.payload.start,
    end: action.payload.end,
  });
  if (action.payload.limit !== undefined) {
    params.append("limit", action.payload.limit);
  }
  request = request + params.toString();
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    // timeout 30 s
    timeout: 30000,
  };
  return Axios.get(request, config);
}

/**
 * Returns all scales which have been created to backend
 *
 * @export
 * @param {*} action
 * @returns {AxiosPromise}
 */
export function getScales(action: any): AxiosPromise {
  let request = process.env.REACT_APP_LATEST_API + "/admin/scales";
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    // timeout 30 s
    timeout: 30000,
  };
  return Axios.get(request, config);
}

export function patchScale(action: any) {
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: 30000,
  };
  return Axios.patch(
    `${process.env.REACT_APP_LATEST_API}/admin/scales/${action.payload.scaleId}`,
    action.payload.data,
    config
  );
}

/**
 * Returns all firmwares which have been set to bucket
 *
 * @export
 * @param {*} action
 * @returns {AxiosPromise}
 */
export function getFirmwares(action: any): AxiosPromise {
  let request = process.env.REACT_APP_LATEST_API + "/admin/scale/firmwares";
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    // timeout 30 s
    timeout: 30000,
  };
  return Axios.get(request, config);
}

export function postScaleUploadFile(action: any): AxiosPromise {
  let request =
    process.env.REACT_APP_LATEST_API + "/admin/scale/update/firmware";

  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    // timeout 20 s
    timeout: 20000,
  };
  const body = {
    firmware: action.payload.firmware,
    scales: action.payload.scales,
  };

  return Axios.post(request, body, config);
}

/**
 * Download a scale report.
 *
 * @param guid guid of the group whose report to download
 * @param start The first day to include in the report in format yyyy-MM-dd
 * @param end The last day to include in the report in format yyyy-MM-dd
 * @param token JWT access token
 */
export function downloadReport(
  guid: string,
  start: string,
  end: string,
  token: string
): AxiosPromise {
  let request = `${process.env.REACT_APP_LATEST_API}/scale/groups/${guid}/reports?`;
  const params = new URLSearchParams({
    start,
    end,
    download: "true",
  });
  request = request + params.toString();
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    // timeout 30 s
    timeout: 30000,
    responseType: "blob",
  };
  // @ts-ignore
  return Axios.get(request, config);
}

export function patchScaleTag(action: any): AxiosPromise | any {
  const { id, secondary_alias, token } = action.payload;
  let request = `${process.env.REACT_APP_LATEST_API}/scale/tags/${id}`;

  const config = {
    headers: { Authorization: `Bearer ${token}` },
    timeout: 10000, // Use a shorter timeout because the value is updated optimistically
  };

  return Axios.patch(request, { secondary_alias }, config);
}
