import { Component } from "react";
import { Redirect, withRouter } from "react-router";
import { connect } from "react-redux";
import * as TYPES from "../constants/actionTypes";
import { isTokenExpired } from "../utils/utils";
import { Login } from "../components/Login";

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchToken: (email: string, password: string, otp?: string) => {
      dispatch({
        type: TYPES.FETCH_TOKEN,
        payload: { user: email, password: password, otp: otp },
      });
    },
  };
};
const mapStateToProps = (state: any) => {
  return {
    token: state.token.key,
    isFetching: state.token.isFetching,
    expiresIn: state.token.expiresIn,
    mfaRequired: state.token.mfaRequired,
  };
};

interface LoginFormState {
  redirect: boolean;
  submit: boolean;
}

class LoginPage extends Component<any, LoginFormState> {
  constructor(props: any) {
    super(props);
    if (!isTokenExpired({ key: props.token, expiresIn: props.expiresIn })) {
      this.state = {
        redirect: true,
        submit: false,
      };
    } else {
      this.state = {
        redirect: false,
        submit: false,
      };
    }
  }

  public componentDidUpdate(prevProps: any): void {
    if (this.props.isFetching !== prevProps.isFetching) {
      this.setState({
        redirect: this.props.token ? true : false,
        submit: this.props.isFetching,
      });
    }
  }

  public handleValidate = (values: any): any => {
    let errors: any = {};
    if (
      values.email !== "" &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
    ) {
      //TODO
    } else if (values.password === "") {
      //TODO
    }
    return errors;
  };

  public handleSubmit = (values: any): any => {
    this.setState({ redirect: false, submit: true });
    this.props.fetchToken(values.email.trim(), values.password, values.otp);
    return;
  };

  // UGLY HACK ALERT: Re-sending an OTP is actually secretly just logging in again without an OTP specified.
  public handleResendOTP = (values: any): any => {
    this.setState({ redirect: false, submit: true });
    this.props.fetchToken(values.email.trim(), values.password);
    return;
  };

  public render(): JSX.Element | null {
    if (this.state.redirect) {
      return this.props.location.state?.referrer?.pathname ? (
        <Redirect to={this.props.location.state.referrer} />
      ) : (
        <Redirect to={{ pathname: "/devices" }} />
      );
    }
    return (
      <>
        <Login
          validate={this.handleValidate}
          onSubmit={this.handleSubmit}
          submit={this.state.submit}
          showOTP={this.props.mfaRequired}
          resendOTP={this.handleResendOTP}
        />
      </>
    );
  }
}

export const LoginForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LoginPage));
