import styled from "styled-components";

export const FormRectangle = styled.section`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  background-color: var(--col-214269);
  border-radius: 2px;
  box-shadow: 0 0px 20px 0px rgba(0, 0, 0, 0.203);
  margin-left: auto;
  margin-right: auto;
  min-height: 315px;
  @media (min-width: 768px) {
    width: 430px;
  }
`;
