import { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import icon_close from "../assets/icon-close.svg";
import * as TYPES from "../constants/actionTypes";
import Modal from "react-modal";
import "../css/modal.css";
import { EditContainerForm } from "./EditContainerForm";
import { Customer, DeviceType } from "../interfaces/types";
import { withTranslation } from "react-i18next";

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateDeviceDetails(device: DeviceType, token: string): void {
      dispatch({
        type: TYPES.PATCH_DEVICE_DETAIL,
        payload: {
          token: token,
          device: device,
        },
      });
    },
  };
};
const mapStateToProps = (state: any) => {
  return {
    token: state.token.key,
  };
};
interface IconProps {
  cursor?: string;
  marginLeft?: number;
  marginRight?: number;
}
const Icon = styled.img<IconProps>`
  object-fit: contain;
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : 0)}%;
  margin-right: ${(props) => (props.marginRight ? props.marginRight : 0)}px;
  cursor: ${(props) => (props.cursor ? props.cursor : "auto")};
  min-width: 24px;
  min-height: 24px;
`;

interface HeaderProps {
  width?: number;
}
const Header = styled.header<HeaderProps>`
  display: flex;
  align-items: center;
  grid-area: header;
  background-color: var(--seafoam-blue);

  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: var(--1-c-2020);
  width: 100%;
  min-height: 48px;
`;

const Title = styled.div`
  margin-left: 10px;
`;

const PopupRect = styled.div`
  display: grid;
  height: 100%;
  grid-template-areas:
    "header"
    "navigation"
    "content";
  grid-template-columns: 100%;
  grid-gap: 1px;
  grid-template-rows: 1fr 1fr 9fr;
  background-color: var(--ice-blue);
`;

const NavigationRect = styled.div`
  grid-area: navigation;
  background-color: white;
  width: 100%;
  min-height: 45px;
`;
const NavigationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  margin-left: 24px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--col-214269);
`;
const Content = styled.div`
  grid-area: content;
  height: 100%;
  background-color: var(--ice-blue);
`;

class EditDevicePopupConnected extends Component<any> {
  private handleClose = () => {
    this.props.onClose();
  };

  private handleCloseModal = (
    customer: Customer | undefined = undefined,
    alias: string | undefined = undefined
  ): void => {
    let device = {
      serialNumber: this.props.device.serialNumber,
      alias: this.props.device.alias,
      customerName: this.props.device.customerName,
      rentalAddress: this.props.device.rentalAddress,
      rentalStartDate: this.props.device.rentalStartDate,
      rentalEndDate: this.props.device.rentalEndDate,
    };
    if (alias !== undefined) {
      device.alias = alias;
    }
    if (customer !== undefined) {
      device = Object.assign(device, customer);
    }
    this.props.updateDeviceDetails(device, this.props.token);
    this.props.onClose();
  };

  public render(): JSX.Element {
    const { t } = this.props;

    if (this.props.open) {
      return (
        <Modal
          isOpen={this.props.open}
          contentLabel="Edit device"
          overlayClassName={"modal-edit-device-overlay"}
          className={"modal-edit-device-content"}
        >
          <PopupRect>
            <Header>
              <Title>
                {t("editDevicePopup.title", "Container")}:{" "}
                {this.props.device.alias}
              </Title>
              <div className="push">
                <Icon
                  src={icon_close}
                  onClick={this.handleClose}
                  cursor={"pointer"}
                  marginRight={15}
                />
              </div>
            </Header>
            <NavigationRect>
              <NavigationContainer>
                {t("editDevicePopup.deviceDetails", "Device details")}
              </NavigationContainer>
            </NavigationRect>
            <Content>
              <EditContainerForm
                token={this.props.token}
                device={this.props.device}
                onClose={this.handleCloseModal}
              />
            </Content>
          </PopupRect>
        </Modal>
      );
    }
    return <> </>;
  }
}

export const EditDevicePopup = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(EditDevicePopupConnected));
