import Axios, { AxiosPromise } from "axios";

/**
 * Returns dashboard which have been created to backend
 *
 * @export
 * @param {*} action
 * @returns {AxiosPromise}
 */
export function getDashboard(action: any): AxiosPromise {
  let request = process.env.REACT_APP_LATEST_API + "/admin/dashboard";
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    // timeout 30 s
    timeout: 30000,
  };
  return Axios.get(request, config);
}

export function postDashboardWorkLogUpdate(action: any): AxiosPromise {
  let request =
    process.env.REACT_APP_LATEST_API + "/admin/dashboard/update/worklog";

  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    // timeout 20 s
    timeout: 20000,
  };
  const body = {
    deviceId: action.payload.deviceId,
    issueType: action.payload.issueType,
    checked: action.payload.checked,
  };

  return Axios.post(request, body, config);
}
