import styled from "styled-components";
import { ScaleHeader } from "./ScaleHeader";
import { FlexBoxWrapper } from "../../components/Common";
import { v4 as uuid4 } from "uuid";

const Page = styled.div`
  display: grid;
  grid-template-rows: 55px 1fr;
  background-color: var(--ice-blue);
`;
const PageBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

interface BodyProps {
  flex?: number;
  backgroundColor?: string;
}

const Body = styled.div<BodyProps>`
  margin-left: 32px;
  margin-right: 32px;
  margin-top: 24px;
  margin-bottom: 24px;
  border-radius: 4px;
  border: 1px solid #bfcfde;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "#ffffff"};
  flex: ${(props) => (props.flex ? props.flex : 1)};
  min-width: 0;
`;

interface ScalePageProps {
  onClose?: () => void;
  children: any;
  bodyBackgroundColor?: string;
}

export const ScalePage = (props: ScalePageProps): JSX.Element => {
  const renderChild = (child: JSX.Element) => {
    return (
      <Body
        flex={child.props.flex}
        backgroundColor={props.bodyBackgroundColor}
        key={uuid4()}
      >
        <PageBody>{child}</PageBody>
      </Body>
    );
  };

  return (
    <Page>
      <ScaleHeader onClose={props.onClose} />
      <FlexBoxWrapper alignItems="stretch">
        {!Array.isArray(props.children) && renderChild(props.children)}
        {Array.isArray(props.children) &&
          props.children.map((child: any) => {
            return renderChild(child);
          })}
      </FlexBoxWrapper>
    </Page>
  );
};
