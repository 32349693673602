import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { DeviceFillLevelTask } from "@shared/types";
import * as actions from "../../../constants/actionTypes";
import { DeviceTasks } from "../../../reducers/deviceTasks";
import editIcon from "../../../assets/icon-edit.svg";
import trashIcon from "../../../assets/icon-trash.svg";
import { FlexBoxWrapper } from "../../../components/Common";
import { ConfirmationModal } from "../../../components/Common/ConfirmationModal";
import { weekdayToString } from "../../../utils/utils";
import { PrimaryButtonLarge } from "../../../components/Common/Button";
import { Link, RouteComponentProps } from "react-router-dom";
import { Spinner } from "../../../components/Spinner";
import { HTMLInput } from "../../../components/Common/FormInput";
import { Title } from "../../../components/Common/Title";

const TaskSearch = styled(HTMLInput)`
  padding: 0.5em 0.7em;
`;
const TaskActions = styled.div`
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > ${TaskSearch} {
    width: 45%;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 50rem;

  ${Title} {
    margin: 2rem 0;
  }

  ${TaskActions} {
    margin-bottom: 1rem;
  }
`;
const NoTasksMessage = styled.div`
  font-family: Poppins;
  color: var(--col-1-c-2020);
`;

const Task = styled.div`
  padding: 0.7rem 1rem;
  background-color: white;
  border-radius: 4px;
  font-size: 1rem;
  font-family: "Roboto";
  color: var(--col-214269);
  cursor: pointer;
  &:hover {
    background-color: var(--ice-blue-light);
  }
`;
const TaskDevice = styled.div`
  font-weight: 500;
`;
const TaskDescription = styled.div`
  margin-left: auto;
  flex-shrink: 0;
  font-weight: 300;
`;
const TaskDetails = styled.div`
  font-size: 0.875em;
  margin-top: 0.35rem;
`;
const TaskDetailLabel = styled.span`
  font-weight: 300;
`;
const TaskDelete = styled.img`
  height: 1.1rem;
`;
const TaskEdit = styled.img`
  margin-left: auto;
  height: 1.8rem;
`;
const TaskList = styled.div`
  width: 100%;
  ${Task} ~${Task} {
    margin-top: 0.7rem;
  }
`;

function fillLevelToString(fillLevel: DeviceFillLevelTask) {
  switch (fillLevel) {
    case DeviceFillLevelTask.LEVEL_1:
      return "Level 1";
    case DeviceFillLevelTask.LEVEL_2:
      return "Level 2";
    default:
      return `Unknown fill level ${fillLevel}`;
  }
}

function getTaskDescription(task: DeviceTasks["tasks"][0]) {
  const weekdays = task.config.weekdays
    .sort()
    .map((weekday) => weekdayToString(weekday))
    .join(" / ");
  const fillLevel = fillLevelToString(task.config.fillLevel);

  return `${weekdays} - ${fillLevel}`;
}

function getTaskActiveHours(task: DeviceTasks["tasks"][0]) {
  const start = _.padStart(task.config.startHour.toString(), 2, "0");
  const end = _.padStart(task.config.endHour.toString(), 2, "0");

  return `${start} - ${end} (${task.config.timeZone})`;
}

function FillLevelTask({
  task,
  routeProps,
}: {
  task: DeviceTasks["tasks"][0];
  routeProps: RouteComponentProps;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const token = useSelector((state: any) => state.token.key);
  const dispatch = useDispatch();

  return (
    <>
      <ConfirmationModal
        isOpen={showConfirmDelete}
        onConfirm={(wasConfirmed) => {
          if (wasConfirmed) {
            dispatch({
              type: actions.DELETE_DEVICE_TASK,
              payload: {
                token,
                taskId: task.id,
              },
            });
          }
          setShowConfirmDelete(false);
        }}
        message={`Are you sure you want to delete task "${getTaskDescription(
          task
        )}" for device ${task.device.serialNumber}?`}
      />
      <Task onClick={() => setIsOpen(!isOpen)}>
        <FlexBoxWrapper>
          <TaskDevice>{`${task.device.serialNumber}${
            task.device.site ? ` - ${task.device.site}` : ""
          }`}</TaskDevice>
          <TaskDescription>{getTaskDescription(task)}</TaskDescription>
        </FlexBoxWrapper>
        {isOpen && (
          <FlexBoxWrapper>
            <TaskDetails>
              <div>
                <TaskDetailLabel>Last executed: </TaskDetailLabel>
                {task.config.lastExecuted
                  ? new Date(task.config.lastExecuted).toLocaleString()
                  : "-"}
              </div>
              <div>
                <TaskDetailLabel>Checked after: </TaskDetailLabel>
                {task.config.executeAfter !== null
                  ? new Date(task.config.executeAfter).toLocaleString()
                  : "After emptied"}
              </div>
              <div>
                <TaskDetailLabel>Active hours: </TaskDetailLabel>
                {getTaskActiveHours(task)}
              </div>
              <div>
                <TaskDetailLabel>Resend: </TaskDetailLabel>
                {task.config.resendHours !== null
                  ? `${task.config.resendHours}h`
                  : "None"}
              </div>
              <div>
                <TaskDetailLabel>Message: </TaskDetailLabel>
                {task.config.message}
              </div>
              <div>
                <TaskDetailLabel>Recipients: </TaskDetailLabel>
                {task.config.emailRecipients.join(", ")}
              </div>
            </TaskDetails>
            <TaskEdit
              src={editIcon}
              onClick={(event) => {
                event.stopPropagation();
                routeProps.history.push(
                  `${routeProps.match.path}/update/${task.id}`
                );
              }}
            />
            <TaskDelete
              src={trashIcon}
              onClick={(event) => {
                event.stopPropagation();
                setShowConfirmDelete(true);
              }}
            />
          </FlexBoxWrapper>
        )}
      </Task>
    </>
  );
}

export function FillLevelTasks(props: RouteComponentProps) {
  const [searchString, setSearchString] = useState("");
  const token = useSelector((state: any) => state.token.key);
  const tasks = useSelector((state: { deviceTasks: DeviceTasks }) =>
    Object.values(state.deviceTasks.tasks).filter(
      (task) =>
        searchString === "" ||
        task.device.serialNumber
          .toLowerCase()
          .includes(searchString.toLowerCase()) ||
        task.device.site?.toLowerCase()?.includes(searchString.toLowerCase())
    )
  );
  const isLoading = useSelector(
    (state: { deviceTasks: DeviceTasks }) => state.deviceTasks.isLoading
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: actions.GET_ALL_DEVICE_TASKS, payload: { token } });
  }, [dispatch, token]);

  return (
    <Container>
      <Title>Fill level tasks</Title>
      <TaskActions>
        <TaskSearch
          placeholder="Serial number or site"
          onChange={(e) => setSearchString(e.target.value)}
          value={searchString}
        />
        <Link to={`${props.match.path}/create`} className="createTask">
          <PrimaryButtonLarge>Create task</PrimaryButtonLarge>
        </Link>
      </TaskActions>
      {(() => {
        if (isLoading) {
          return <Spinner />;
        } else if (0 === tasks.length && searchString === "") {
          return <NoTasksMessage>There are no fill level tasks</NoTasksMessage>;
        } else if (0 === tasks.length && searchString !== "") {
          return <NoTasksMessage>No tasks found</NoTasksMessage>;
        }
        return (
          <TaskList>
            {tasks.map((task) => (
              <FillLevelTask task={task} key={task.id} routeProps={props} />
            ))}
          </TaskList>
        );
      })()}
    </Container>
  );
}
