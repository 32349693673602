import * as Yup from "yup";

const commonFields = {
  weekdays: Yup.array(Yup.string()).min(
    1,
    "At least one weekday needs to be selected"
  ),
  startHour: Yup.number()
    .required()
    .integer()
    .min(0, "Hour needs to be between 0 and 24")
    .max(24, "Hour needs to be between 0 and 24"),
  endHour: Yup.number()
    .required()
    .integer()
    .moreThan(Yup.ref("startHour"), "End cannot be before start")
    .max(24, "Hour needs to be between 0 and 24"),
  resendHours: Yup.number().integer().positive(),
  message: Yup.string(),
  fillLevel: Yup.string().required(),
  emailRecipients: Yup.string(),
};

export const CreateFillLevelTaskSchema = Yup.object().shape({
  deviceSerialNumbers: Yup.string().required(
    "At least one serial number is required"
  ),
  ...commonFields,
});

export const UpdateFillLevelTaskSchema = Yup.object().shape(commonFields);
