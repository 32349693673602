import React, { useRef } from "react";
import { useMediaQuery } from "react-responsive";
import Split from "react-split";
import styled from "styled-components";

interface FlexBoxProps {
  marginTop?: number;
  alignItems?: string;
}

export const FlexBoxWrapper = styled.div<FlexBoxProps>`
  display: flex;
  align-items: ${(props) => (props.alignItems ? props.alignItems : "center")};
  justify-content: center;
  margin-top: ${(props) => props.marginTop}px;
`;

interface LabelProps {
  marginTop: number;
  color: any;
  lineHeight: number;
  height: number;
  width: number;
  fontSize: number;
  textAlign: string | undefined;
}

export const Label = styled.div<LabelProps>`
    position: relative;
    margin-top: ${(props) => props.marginTop}px;
    height: ${(props) => props.height}%
    width: ${(props) => props.width}%
    font-family: "Roboto", sans-serif;
    font-size: ${(props) => props.fontSize}px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: ${(props) => props.lineHeight};
    text-align: ${(props) =>
      props.textAlign !== undefined ? props.textAlign : "center"};
    letter-spacing: normal;
    color: ${(props) => props.color};
`;

export interface RectProps {
  justifyContent?: string;
  alignItems?: string;
  color?: string;
  alignContent?: string;
  minHeight?: number;
}

export const Rect = styled.section<RectProps>`
  display: flex;
  align-items: ${(props) => (props.alignItems ? props.alignItems : "none")};
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "none"};
  height: 100%;
  min-height: ${(props) => (props.minHeight ? props.minHeight : 48)}px;
  background: ${(props) =>
    props.color ? props.color : "var(--twilight-blue)"};
  align-content: ${(props) =>
    props.alignContent ? props.alignContent : "space-around"};
  flex-wrap: wrap;
`;

export interface IconProps {
  marginLeft?: number;
  marginRight?: number;
  paddingTop?: number;
  marginTop?: number;
  marginBottom?: number;
  height?: number;
  width?: number;
}

export const Icon = styled.img<IconProps>`
  object-fit: contain;
  margin-right: ${(props) => (props.marginRight ? props.marginRight : 0)}px;
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : 0)}px;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : 0)}px;
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : 0)}px;
  padding-top: ${(props) => (props.paddingTop ? props.paddingTop : 0)}px;
  height: ${(props) => (props.height ? props.height : 0)}px;
  width: ${(props) => (props.width ? props.width : 0)}px;
`;

const DeviceTable = styled.div`
  box-shadow: 0 -0.25px 0 0 #0d467d;
  overflow-x: auto;
  overflow-y: scroll;
`;

const MapWrapper = styled.div.attrs({ className: "map-step" })``;

const DeviceMapSplitStyled = styled(Split).attrs({
  sizes: [50, 50],
  minSize: 0,
})`
  display: ${(props) => (props.direction === "horizontal" ? "flex" : "")};
  flex-direction: ${(props) => (props.direction === "horizontal" ? "row" : "")};

  ${DeviceTable}, ${MapWrapper} {
    height: 100%;
  }

  > .gutter {
    background-color: var(--ice-blue-light);
    background-repeat: no-repeat;
    background-position: 50%;
  }

  > .gutter.gutter-horizontal {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==");
    cursor: col-resize;
  }

  > .gutter.gutter-vertical {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAFAQMAAABo7865AAAABlBMVEVHcEzMzMzyAv2sAAAAAXRSTlMAQObYZgAAABBJREFUeF5jOAMEEAIEEFwAn3kMwcB6I2AAAAAASUVORK5CYII=");
    cursor: row-resize;
  }
`;

export const DeviceMapSplit = ({
  renderDeviceTable: deviceChildren,
  mapChildren,
}: {
  renderDeviceTable: (
    contentRef: React.RefObject<HTMLDivElement>
  ) => React.ReactNode;
  mapChildren: React.ReactNode;
}) => {
  const isSmall = useMediaQuery({ query: "(max-width: 1024px)" });
  const devicesTableContent = useRef<HTMLDivElement>(null);

  // Use the key prop to force react to create a new component subtree instead of updating the existing tree
  // This ensures the split is layed out correctly when screen dimensions change
  return (
    <DeviceMapSplitStyled
      direction={isSmall ? "vertical" : "horizontal"}
      key={isSmall.toString()}
    >
      <DeviceTable ref={devicesTableContent}>
        {deviceChildren(devicesTableContent)}
      </DeviceTable>
      <MapWrapper>{mapChildren}</MapWrapper>
    </DeviceMapSplitStyled>
  );
};

/**
 * We want the page to not have vertical or horizontal scrollbars, unless there is too little
 * space for the device-map combo, in which case a vertical scrollbar may be used.
 */
export const DevicesPage = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;

  ${DeviceMapSplitStyled} {
    flex: 1;
    /*
     * Just an arbitrary number to ensure that there is enough height for the device/map combo to be usable
     * even in the worst case when they are stacked vertically.
     *
     * Note that min-height needs to be set to some value to override the default "min-height: auto
     * caused by flexbox, which results in the view not being resized at all.
     */
    min-height: 400px;
  }

  > * {
    // Override the default "min-width: auto" to ensure all subviews can be smaller than their content size
    // This way, content should be at most viewport width
    min-width: 0;
  }
`;
