import styled from "styled-components";
import bg from "../../assets/bg-map.png";

interface FormBackgroundProps {
  cursor?: string;
}

export const FormBackground = styled.div<FormBackgroundProps>`
  min-height: 100%;
  background-image: url(${bg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 3rem 2rem;
  cursor: ${(props) => (props.cursor ? props.cursor : "auto")};
`;
