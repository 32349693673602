import { call, put } from "redux-saga/effects";
import * as api from "../api/anomalies";
import * as TYPES from "../constants/actionTypes";
import { NotifyError, NotifySuccess } from "../components/Common/Notify";

/**
 * Fetch anomaly data.
 * @export
 * @param {*} action
 */
export function* fetchAnomalies(action: any) {
  try {
    const result = yield call(api.getAnomalies, action);
    yield put({
      type: TYPES.FETCH_ANOMALIES_SUCCESS,
      payload: result.data,
    });
  } catch (err) {
    NotifyError(TYPES.FETCH_ANOMALIES_FAILURE);
    yield put({ type: TYPES.FETCH_ANOMALIES_FAILURE });
  }
}

// Update Anomaly data
export function* updateAnomalies(action: any) {
  try {
    yield call(api.updateAnomaly, action);
    yield put({
      type: TYPES.UPDATE_ANOMALIES_SUCCESS,
      payload: action.payload.data,
    });
    NotifySuccess(TYPES.UPDATE_ANOMALIES_SUCCESS);
  } catch (err) {
    yield put({
      type: TYPES.UPDATE_ANOMALIES_FAILURE,
    });
    NotifyError(TYPES.UPDATE_ANOMALIES_FAILURE);
  }
}
