import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as TYPES from "../../constants/actionTypes";
import { GroupsTable } from "./GroupsTable";
import { GroupSelect } from "./GroupSelect";
import { ConfirmationModal } from "../../components/Common/ConfirmationModal";
import { findGroupById } from "../../utils/group";
import { groupHierarchiesSelector } from "../../selectors/groups";

export function Groups() {
  const groups = useSelector(groupHierarchiesSelector);
  const token = useSelector((state: any) => state.token.key);
  const [selectedGroup, setSelectedGroup] = useState<number | null>(null);
  const [deleteGroupId, setDeleteGroupId] = useState<number | null>(null);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: TYPES.FETCH_GROUP_HIERARCHY,
      payload: {
        token,
      },
    });
  }, [token, dispatch]);

  useEffect(() => {
    if (groups.length > 0) {
      setSelectedGroup(groups[0].groupId);
    }
  }, [groups]);

  const groupChanged = (_: string, groupId: number | undefined) => {
    setSelectedGroup(groupId ?? null);
  };

  const tableGroups =
    selectedGroup == null ? groups : [findGroupById(groups, selectedGroup)];

  return (
    <>
      <GroupSelect
        value={selectedGroup}
        options={groups}
        onChange={groupChanged}
        id="groupSelect"
        placeholder="Search group..."
      />
      {deleteGroupId !== null && (
        <ConfirmationModal
          isOpen={deleteConfirmationOpen}
          message={`Are you sure you want to delete ${
            findGroupById(groups, deleteGroupId)?.name
          }? This will delete all subgroups, users and settings of this group.
                    Also, all of the group's devices will be moved to Europress group.`}
          onConfirm={(didConfirm) => {
            setDeleteConfirmationOpen(false);
            if (didConfirm) {
              dispatch({
                type: TYPES.DELETE_GROUP_FOR_ADMIN,
                payload: {
                  groupId: deleteGroupId,
                  token,
                },
              });
            }
            setDeleteGroupId(null);
          }}
        />
      )}
      <GroupsTable
        data={tableGroups}
        topLevel={true}
        deleteGroup={(groupId: number) => {
          setDeleteGroupId(groupId);
          setDeleteConfirmationOpen(true);
        }}
      />
    </>
  );
}
