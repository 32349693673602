import Axios from "axios";

const DATA_ANALYTICS_TOKEN_ENDPOINT = "/analytics/token";

export async function getDataAnalyticsToken(
  token: string,
  deviceSerialNumber: null | string
): Promise<{ access_token: string; expires_in: number; origin: string }> {
  let url = process.env.REACT_APP_LATEST_API + DATA_ANALYTICS_TOKEN_ENDPOINT;
  const config = {
    headers: { Authorization: "Bearer " + token },
    // timeout 20 s
    timeout: 20000,
    params: {
      serial_number: deviceSerialNumber,
    },
  };
  const res = await Axios.get(url, config);

  return res.data;
}
