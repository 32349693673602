import styled from "styled-components";
import infoIcon from "../../assets/icon-info-transparent.svg";
import infoIconWhite from "../../assets/icon-info-white-transparent.svg";

const InfoImage = styled.img<{ width: string }>`
  height: ${(props) => props.width};
  margin-left: 1rem;
`;

export function InfoTooltip(props: {
  text: string;
  size?: string;
  whiteIcon?: boolean;
}) {
  return (
    <InfoImage
      className="infoTooltip"
      alt="info tooltip"
      src={props.whiteIcon ? infoIconWhite : infoIcon}
      title={props.text}
      width={props.size ?? "24px"}
    />
  );
}
