import {
  FETCH_TOKEN_SUCCESS,
  FETCH_TOKEN_FAILURE,
  FETCH_TOKEN,
  SET_TOKEN_EXPIRED,
  MFA_REQUIRED,
  MFA_FAILURE,
  LOCKOUT,
} from "../constants/actionTypes";

export type TokenState = {
  expiresIn: number;
  isFetching: boolean;
  key: string;
  mfaRequired: boolean;
  error?: string;
};
const initialState: TokenState = {
  expiresIn: 0,
  isFetching: false,
  key: "",
  mfaRequired: false,
  error: "",
};
const token = (state = initialState, action: any): TokenState => {
  switch (action.type) {
    case FETCH_TOKEN: {
      return { ...state, expiresIn: 0, isFetching: true, key: "" };
    }
    case FETCH_TOKEN_SUCCESS: {
      // Payload expiresIn is in seconds. Here is used ms.
      const expiresIn = Date.now() + action.payload.expiresIn * 1000;
      return {
        ...state,
        expiresIn: expiresIn,
        isFetching: false,
        key: action.payload.token,
        mfaRequired: action.payload.mfaRequired,
      };
    }
    case FETCH_TOKEN_FAILURE: {
      return {
        ...state,
        expiresIn: 0,
        isFetching: false,
        key: "",
      };
    }
    case SET_TOKEN_EXPIRED: {
      return { ...state, expiresIn: 0 };
    }
    case MFA_REQUIRED: {
      return { ...state, mfaRequired: true, isFetching: false };
    }
    case MFA_FAILURE: {
      return { ...state, isFetching: false };
    }
    case LOCKOUT: {
      // Resets the login page if the user gets locked out.
      return { ...state, mfaRequired: false };
    }
    default: {
      return state;
    }
  }
};
export default token;
