import styled from "styled-components";

const Header = styled.div`
  height: 16px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  font-stretch: condensed;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.25px;
  color: #000000;
  text-transform: uppercase;
  padding: 0 5px 10px 5px;
`;

interface ItemListHeaderProps {
  text: string;
}

export function ItemListHeader(props: ItemListHeaderProps) {
  return <Header>{props.text}</Header>;
}
