import styled from "styled-components";

export interface IconProps {
  marginLeft?: number;
  marginRight?: number;
  paddingTop?: number;
  marginTop?: number;
  marginBottom?: number;
  height?: number;
  width?: number;
  cursor?: string;
  rotate?: number;
}

export const Icon = styled.img<IconProps>`
  object-fit: contain;
  margin-right: ${(props) => (props.marginRight ? props.marginRight : 0)}px;
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : 0)}px;
  cursor: ${(props) => (props.cursor ? props.cursor : "auto")};
  @media (min-width: 768px) {
    margin-right: ${(props) => (props.marginRight ? props.marginRight : 0)}px;
    margin-left: ${(props) => (props.marginLeft ? props.marginLeft : 0)}px;
    margin-top: ${(props) => (props.marginTop ? props.marginTop : 0)}px;
    margin-bottom: ${(props) =>
      props.marginBottom ? props.marginBottom : 0}px;
    padding-top: ${(props) => (props.paddingTop ? props.paddingTop : 0)}px;
    height: ${(props) => (props.height ? props.height : 24)}px;
    width: ${(props) => (props.width ? props.width : 24)}px;
  }
  transform: ${(props) =>
    props.rotate ? `rotate(${props.rotate}deg)` : "none"};
`;
