export const passwordLength = 12;

export const maximumDevicesPageSize = 1000;

export const possibleDeviceErrorTaskStates = [
  "OIL_LEAKAGE_DETECTED",
  "TRAVEL_TIMES_ERROR",
  "INPUT_TIMING_ERROR",
  "MOTOR_THERMO_NOT_OK",
  "CONTACTOR_FAILURE",
  "PRESSURE_SENSOR_ERROR",
] as const;

export const defaultDeviceErrorTaskEmailTexts: Record<
  typeof possibleDeviceErrorTaskStates[number],
  string
> = {
  OIL_LEAKAGE_DETECTED: "Oil leakage detected",
  TRAVEL_TIMES_ERROR: "Incorrect phase wire connection",
  INPUT_TIMING_ERROR: "Security circuit failure",
  MOTOR_THERMO_NOT_OK: "Motor temperature error",
  CONTACTOR_FAILURE: "Contactor failure",
  PRESSURE_SENSOR_ERROR: "Pressure sensor error",
};

export const defaultDeviceErrorTaskErrorThresholds: Record<
  typeof possibleDeviceErrorTaskStates[number],
  number | null
> = {
  OIL_LEAKAGE_DETECTED: null,
  TRAVEL_TIMES_ERROR: null,
  INPUT_TIMING_ERROR: null,
  MOTOR_THERMO_NOT_OK: null,
  CONTACTOR_FAILURE: null,
  PRESSURE_SENSOR_ERROR: null,
};
