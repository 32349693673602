import { Component, Fragment } from "react";
import * as TYPES from "../../constants/actionTypes";
import { connect } from "react-redux";
import { Formik } from "formik";
import { FormTitle } from "../../components/Common/FormTitle";
import { FormInput } from "../../components/Common/FormInput";
import { FormSubmitButton } from "../../components/Common/FormSubmitButton";
import { FormBackground } from "../../components/Common/FormBackground";
import { FormRectangle } from "../../components/Common/FormRectangle";
import { FlexBoxWrapper } from "../../components/Common";
import { Header } from "../../components/Header";
import { FormWrapper } from "../../components/Common/FormWrapper";
import { FooterLink } from "../../components/Common/FooterLink";
import { withTranslation } from "react-i18next";

export class ForgotPasswordConnected extends Component<any, any> {
  private initialValues: {
    name: string;
  };

  constructor(props: any) {
    super(props);

    this.initialValues = {
      name: "",
    };
  }

  public render(): JSX.Element {
    const { t } = this.props;

    return (
      <Fragment>
        <Header />
        <FormBackground>
          <FormRectangle>
            <FormTitle
              title={t("forgotPassword.title", "Forgot your password?")}
              secondary
            />
            <Formik
              initialValues={this.initialValues}
              onSubmit={this.onSubmit}
              render={this.onRender}
            />
            <FooterLink to="/">
              {t("forgotPassword.footerLink", "Login")}
            </FooterLink>
          </FormRectangle>
        </FormBackground>
      </Fragment>
    );
  }

  private onSubmit = (values: any, actions: any) => {
    this.props.forgotPassword({ name: values.name.trim() });
    actions.setSubmitting(false);
  };

  private onRender = (formikProps: any) => {
    const {
      isSubmitting,
      errors,
      touched,
      values,
      handleBlur,
      handleChange,
      isValid,
    } = formikProps;

    const { t } = this.props;

    return (
      <FormWrapper>
        <FormInput
          id="name"
          type="email"
          label={t("forgotPassword.email", "Email")}
          error={touched.name && errors.name}
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          required
        />
        <FlexBoxWrapper marginTop={20}>
          <FormSubmitButton type="submit" disabled={isSubmitting || !isValid}>
            {t("forgotPassword.submit", "Submit")}
          </FormSubmitButton>
        </FlexBoxWrapper>
      </FormWrapper>
    );
  };
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    forgotPassword: (data: any) => {
      dispatch({
        type: TYPES.FORGOT_PASSWORD,
        payload: { data },
      });
    },
  };
};

export const ForgotPassword = connect(
  undefined,
  mapDispatchToProps
)(withTranslation()(ForgotPasswordConnected));
