import { Component, ReactNode } from "react";
import { connect } from "react-redux";
import { format, subDays, startOfMonth, addDays } from "date-fns";

import { insertIf } from "../../utils/utils";
import * as TYPES from "../../constants/actionTypes";
import { ScalePage } from "./ScalePage";
import { Event } from "../../components/Event";
import styled from "styled-components";
import { inifinityScrollOptions } from "../../constants/options";
import { DateTimePicker } from "../../components/DateTimePicker";
import { Spinner } from "../../components/Spinner";
import { withTranslation } from "react-i18next";
import * as XLSX from "xlsx";
import { ScaleResultType } from "../../../../server/src/shared/types";
import { Modal } from "../../components/Common/Modal";
import { EventModal } from "./EventModal";
import analyticsIcon from "../../assets/icon-analytics.svg";
import { singleEffectiveGroupSelector } from "../../selectors/groups";

type Column =
  | "eventType"
  | "date"
  | "time"
  | "propertyGroup"
  | "userGroup"
  | "userGroupCustomerId"
  | "key"
  | "keyAlias"
  | "deviceSerialNumber"
  | "wasteFraction"
  | "netWeight"
  | "binWeight"
  | "diagnosticsButton";

const TableContainer = styled.div`
  margin-left: 16px;
  margin-right: 30px;
  min-height: 500px;
  overflow-x: hidden;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

const TableHeader = styled.thead`
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  font-stretch: condensed;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  position: sticky;
  top: 0;
`;

const TableHeaderRow = styled.tr`
  height: 60px;
`;

interface TableHeaderCellProps {
  clickable?: boolean;
}

const TableHeaderCell = styled.th<TableHeaderCellProps>`
  position: sticky;
  top: 0;
  box-shadow: inset 0 -1px 0 0 var(--light-blue-grey),
    inset -1px 0 0 0 var(--ice-blue);
  background-color: #ffffff;
  cursor: ${(props) => (props.clickable ? "pointer" : "intial")};
  outline: none;
  padding-left: 8px;
  padding-right: 8px;
  z-index: 0;
`;
const CellContent = styled.div`
  width: 100%;
  height: 100%;
`;

const TableBody = styled.tbody``;

const Container = styled.div`
  width: 100%;
  padding-bottom: 16px;
  padding-top: 26px;
`;

const DateTimeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 2px;
`;

interface TextLinkProps {
  selected: boolean;
}

const TextLink = styled.div<TextLinkProps>`
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${(props) => (props.selected ? "#000000" : "#0d467d")};
  padding-right: 14px;
  text-decoration: ${(props) => (props.selected ? "initial" : "underline")};
  cursor: pointer;
`;

const Text = styled.div`
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
`;
interface RowProps {
  justifyContent?: string;
}
const Row = styled.div<RowProps>`
  display: flex;
  width: calc(100% - 32px);
  flex-direction: row;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "space-evenly"};
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
`;
enum SelectedTimeFrame {
  THIS_MONTH = 0,
  LAST_30_DAYS = 1,
  LAST_90_DAYS = 2,
  CUSTOM = 3,
}
const ShowEventsWithoutResult = styled.button`
  margin-left: auto;
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  background-color: #0d467d;
  color: #ffffff;
  height: 30px;
  font-weight: bold;
  cursor: pointer;
  text-transform: uppercase;

  :disabled {
    opacity: 0.4;
    cursor: default;
  }
`;
const DownloadEventsButton = styled.button`
  margin-left: auto;
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  background-color: #0d467d;
  color: #ffffff;
  height: 30px;
  font-weight: bold;
  cursor: pointer;
  text-transform: uppercase;

  :disabled {
    opacity: 0.4;
    cursor: default;
  }
`;

const Icon = styled.img`
  cursor: pointer;
`;

function showDiagnosticsColumnForEvent(event: any) {
  return (
    (event.button_presses && 0 < event.button_presses.length) ||
    (event.weight_signal && 0 < event.weight_signal.length) ||
    event.algorithm_method
  );
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchGroupEvents: (
      group: any,
      token: string,
      start: string = "",
      end: string = ""
    ) => {
      dispatch({
        type: TYPES.FETCH_SCALE_EVENTS,
        payload: {
          group: group.guid,
          token: token,
          start: start,
          end: end,
        },
      });
    },
  };
};

const mapStateToProps = (state: any) => {
  return {
    events: state.scale.events,
    group: singleEffectiveGroupSelector(state),
    token: state.token.key,
    isFetching: state.scale.isFetching,
    user: state.users,
  };
};

class EventLogConnected extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      visibleEvents: [],
      selectedTimeFrame: SelectedTimeFrame.THIS_MONTH,
      startDate: startOfMonth(new Date()),
      endDate: new Date(),
      eventModal: { isOpen: false, id: undefined },
      showEventsWithoutResult: false,
    };
  }
  private handleScroll = () => {
    if (
      this.props.contentRef &&
      this.props.contentRef.current &&
      this.props.contentRef.current.clientHeight &&
      this.props.contentRef.current.clientHeight +
        this.props.contentRef.current.scrollTop +
        10 <=
        this.props.contentRef.current.scrollHeight
    ) {
      return;
    } else {
      if (this.state.visibleEvents.length === this.props.events.length) {
        return;
      } else {
        let visibleEvents: Array<any> = [];
        if (
          this.props.events.length >=
          this.state.visibleEvents.length +
            inifinityScrollOptions.nbElementsToAdd
        ) {
          visibleEvents = this.props.events.slice(
            this.state.visibleEvents.length,
            this.state.visibleEvents.length +
              inifinityScrollOptions.nbElementsToAdd
          );
        } else {
          visibleEvents = this.props.events.slice(
            this.state.visibleEvents.length
          );
        }
        this.setState({
          visibleEvents: [...this.state.visibleEvents, ...visibleEvents],
        });
      }
    }
  };
  private getStartEndTime(): Array<string> {
    const now = new Date();
    let end: string = "";
    let start: string = "";
    switch (this.state.selectedTimeFrame) {
      case SelectedTimeFrame.THIS_MONTH: {
        end = format(addDays(now, 1), "yyyy-MM-dd");
        start = format(startOfMonth(now), "yyyy-MM-dd");
        this.setState({
          startDate: startOfMonth(now),
          endDate: addDays(now, 1),
        });
        break;
      }
      case SelectedTimeFrame.LAST_30_DAYS: {
        start = format(subDays(now, 30), "yyyy-MM-dd");
        end = format(addDays(now, 1), "yyyy-MM-dd");
        this.setState({
          startDate: subDays(now, 30),
          endDate: addDays(now, 1),
        });
        break;
      }
      case SelectedTimeFrame.LAST_90_DAYS: {
        start = format(subDays(now, 90), "yyyy-MM-dd");
        end = format(addDays(now, 1), "yyyy-MM-dd");
        this.setState({
          startDate: subDays(now, 90),
          endDate: addDays(now, 1),
        });
        break;
      }
      case SelectedTimeFrame.CUSTOM: {
        start = format(this.state.startDate, "yyyy-MM-dd");
        end = format(this.state.endDate, "yyyy-MM-dd");
        break;
      }
      default: {
        break;
      }
    }
    return [start, end];
  }
  private onStartDateChange = (date: Date) => {
    if (date) {
      this.setState({
        startDate: date,
        selectedTimeFrame: SelectedTimeFrame.CUSTOM,
      });
      const start = format(date, "yyyy-MM-dd");
      const end = format(this.state.endDate, "yyyy-MM-dd");
      this.getGroupEvents(start, end);
    }
  };
  private onEndDateChange = (date: Date) => {
    if (date) {
      this.setState({
        endDate: date,
        selectedTimeFrame: SelectedTimeFrame.CUSTOM,
      });
      const start = format(this.state.startDate, "yyyy-MM-dd");
      const end = format(date, "yyyy-MM-dd");
      this.getGroupEvents(start, end);
    }
  };
  public componentDidMount(): void {
    if (this.props.group && this.props.token) {
      this.getGroupEvents();
    }
    if (this.state.visibleEvents.length === 0 && this.props.events.length > 0) {
      this.setState({
        visibleEvents: this.props.events.slice(
          0,
          inifinityScrollOptions.nbElementsToAdd
        ),
      });
    }
    window.addEventListener("scroll", this.handleScroll, true);
  }
  public componentWillUnmount(): void {
    window.removeEventListener("scroll", this.handleScroll, true);
  }
  private getGroupEvents(
    startDate: string | undefined = undefined,
    endDate: string | undefined = undefined
  ): void {
    if (!this.props.isFetching && this.props.group) {
      if (!startDate && !endDate) {
        const [start, end] = this.getStartEndTime();
        this.props.fetchGroupEvents(
          this.props.group,
          this.props.token,
          start,
          end
        );
      } else {
        this.props.fetchGroupEvents(
          this.props.group,
          this.props.token,
          startDate,
          endDate
        );
      }
    }
  }
  public componentDidUpdate(prevProps: any, prevState: any): void {
    if (
      (this.props.group &&
        this.props.token &&
        (!prevProps.group ||
          prevProps.group.groupId !== this.props.group.groupId)) ||
      (prevState.selectedTimeFrame !== this.state.selectedTimeFrame &&
        this.props.group &&
        this.props.token)
    ) {
      this.getGroupEvents();
    }
    if (this.state.visibleEvents.length === 0 && this.props.events.length > 0) {
      this.setState({
        visibleEvents: this.props.events.slice(
          0,
          inifinityScrollOptions.nbElementsToAdd
        ),
      });
    } else if (this.props.events.length !== prevProps.events.length) {
      // Filtering is done!
      if (this.props.contentRef && this.props.contentRef.current) {
        this.props.contentRef.current.scrollTop = 0;
      }
      this.setState({
        visibleEvents: this.props.events.slice(
          0,
          inifinityScrollOptions.nbElementsToAdd
        ),
      });
    } else if (prevProps.events !== this.props.events) {
      // There has happen a device update
      this.setState({
        visibleEvents: this.props.events.slice(
          0,
          this.state.visibleEvents.length
        ),
      });
    }
  }

  private getVisibleColumns(): Column[] {
    const { user } = this.props;
    const events = this.state.visibleEvents;
    const showDiagnosticsColumn =
      (user.admin || user.groups.includes("Europress")) &&
      events.some((event: any) => showDiagnosticsColumnForEvent(event));

    return [
      "eventType",
      "date",
      "time",
      "propertyGroup",
      "userGroup",
      "key",
      "keyAlias",
      "deviceSerialNumber",
      "wasteFraction",
      "netWeight",
      "binWeight",
      ...insertIf(showDiagnosticsColumn, "diagnosticsButton"),
    ];
  }

  private getColumnHeaderText(column: Column): string {
    const { t } = this.props;
    switch (column) {
      case "eventType": {
        return t("eventlog.type", "TYPE");
      }
      case "date": {
        return t("eventlog.date", "DATE");
      }
      case "time": {
        return t("eventlog.time", "TIME");
      }
      case "propertyGroup": {
        return t("eventlog.property_group", "PROPERTY GROUP");
      }
      case "userGroup": {
        return t("eventlog.user_group", "USER GROUP");
      }
      case "userGroupCustomerId": {
        return t("eventlog.user_group_customer_id", "CUSTOMER ID");
      }
      case "key": {
        return t("eventlog.key", "KEY");
      }
      case "keyAlias": {
        return t("eventlog.key_alias", "KEY ALIAS");
      }
      case "deviceSerialNumber": {
        return t("eventlog.device", "DEVICE");
      }
      case "wasteFraction": {
        return t("eventlog.waste_fraction", "WASTE FRACTION");
      }
      case "netWeight": {
        return t("eventlog.net_weight", "NET WEIGHT (kg)");
      }
      case "binWeight": {
        return t("eventlog.bin_weight", "BIN WEIGHT (kg)");
      }
      case "diagnosticsButton": {
        return "";
      }
      default: {
        return "";
      }
    }
  }

  private getColumnContent(
    column: Column,
    event: any
  ): string | number | undefined {
    switch (column) {
      case "eventType": {
        const { t } = this.props;
        switch (event.type) {
          case ScaleResultType.BALER_START:
            return t("eventlog.type_baler", "Baler");
          default:
            return t("eventlog.type_scale", "Scale");
        }
      }
      case "date": {
        return format(new Date(event.measurement_time), "dd.MM.yyyy");
      }
      case "time": {
        return format(new Date(event.measurement_time), "HH:mm");
      }
      case "propertyGroup": {
        return event.property_group ? event.property_group : undefined;
      }
      case "userGroup": {
        return event.user_group.name;
      }
      case "userGroupCustomerId": {
        return event.user_group.customerId;
      }
      case "key": {
        return event.tag_alias;
      }
      case "keyAlias": {
        return event.tag_secondary_alias;
      }
      case "deviceSerialNumber": {
        return event.compactor_id;
      }
      case "wasteFraction": {
        return event.waste_fraction;
      }
      case "netWeight": {
        return event.weight ? Math.round(event.weight) : event.weight;
      }
      case "binWeight": {
        return event.weight_empty_bin
          ? Math.round(event.weight_empty_bin)
          : event.weight_empty_bin;
      }
      case "diagnosticsButton": {
        return;
      }
      default: {
        return;
      }
    }
  }

  private getColumnElement(column: Column, event: any): ReactNode {
    if (column === "diagnosticsButton") {
      return showDiagnosticsColumnForEvent(event) ? (
        <Icon
          src={analyticsIcon}
          onClick={() =>
            this.setState({
              eventModal: {
                isOpen: true,
                id: event.id,
              },
            })
          }
        />
      ) : undefined;
    }
    return this.getColumnContent(column, event);
  }
  private handleShowFailsToggle = () => {
    this.setState((prevState: { showEventsWithoutResult: boolean }) => ({
      showEventsWithoutResult: !prevState.showEventsWithoutResult,
    }));
  };
  private handleEventsDownload = () => {
    if (!this.props.group) {
      return;
    }
    const spreadsheetName = `${this.props.group.name} - Scale event log.xlsx`;
    const spreadsheet = XLSX.utils.json_to_sheet(
      this.getDownloadableLogRows(this.state.visibleEvents)
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, spreadsheet, "Scale event log");
    XLSX.writeFile(workbook, spreadsheetName);
  };
  private getDownloadableLogRows = (events: any[]) => {
    const downloadableColumns: Column[] = [
      "eventType",
      "date",
      "time",
      "propertyGroup",
      "userGroup",
      "userGroupCustomerId",
      "key",
      "keyAlias",
      "deviceSerialNumber",
      "wasteFraction",
      "netWeight",
      "binWeight",
    ];

    return events.map((event) =>
      Object.fromEntries(
        downloadableColumns.map((column) => [
          this.getColumnHeaderText(column),
          this.getColumnContent(column, event),
        ])
      )
    );
  };
  public render(): JSX.Element {
    let columns = this.getVisibleColumns();
    const { t } = this.props;
    return (
      <>
        <Modal
          isOpen={this.state.eventModal.isOpen}
          handleClose={() =>
            this.setState({
              eventModal: { isOpen: false, id: undefined },
            })
          }
          title="Additional event data"
          content={
            <EventModal
              event={
                this.state.eventModal.isOpen &&
                this.props.events.find(
                  (event: any) => event.id === this.state.eventModal.id
                )
              }
            />
          }
        />
        <ScalePage bodyBackgroundColor={"#fafbfc"}>
          <>
            <Container>
              <Row justifyContent={"flex-start"}>
                <TextLink
                  selected={
                    this.state.selectedTimeFrame ===
                    SelectedTimeFrame.THIS_MONTH
                  }
                  onClick={() => {
                    this.setState({
                      selectedTimeFrame: SelectedTimeFrame.THIS_MONTH,
                    });
                  }}
                >
                  {t("eventlog.this_month", "This Month")}
                </TextLink>
                <TextLink
                  selected={
                    this.state.selectedTimeFrame ===
                    SelectedTimeFrame.LAST_30_DAYS
                  }
                  onClick={() => {
                    this.setState({
                      selectedTimeFrame: SelectedTimeFrame.LAST_30_DAYS,
                    });
                  }}
                >
                  {t("eventlog.last_30_days", "Last 30 days")}
                </TextLink>
                <TextLink
                  selected={
                    this.state.selectedTimeFrame ===
                    SelectedTimeFrame.LAST_90_DAYS
                  }
                  onClick={() => {
                    this.setState({
                      selectedTimeFrame: SelectedTimeFrame.LAST_90_DAYS,
                    });
                  }}
                >
                  {t("eventlog.last_90_days", "Last 90 days")}
                </TextLink>
                <DateTimeContainer>
                  <DateTimePicker
                    eventLog={true}
                    selectedDate={this.state.startDate}
                    handleChange={this.onStartDateChange}
                    name={"start_date"}
                  />
                  <div
                    style={{
                      width: "32px",
                      textAlign: "center",
                    }}
                  >
                    -
                  </div>
                  <DateTimePicker
                    eventLog={true}
                    selectedDate={this.state.endDate}
                    handleChange={this.onEndDateChange}
                    name={"end_date"}
                  />
                </DateTimeContainer>
                {this.props.user.admin ||
                this.props.user.groups.includes("Europress") ? (
                  <ShowEventsWithoutResult
                    onClick={this.handleShowFailsToggle}
                    disabled={
                      !this.props.events || this.props.events.length === 0
                    }
                  >
                    {this.state.showEventsWithoutResult
                      ? t("eventlog.hideEventsWithoutResult", "Hide Failed")
                      : t("eventlog.showEventsWithoutResult", "Show Failed")}
                  </ShowEventsWithoutResult>
                ) : null}
                <DownloadEventsButton
                  onClick={this.handleEventsDownload}
                  disabled={
                    !this.props.events || this.props.events.length === 0
                  }
                >
                  {t("eventlog.download", "Download as Excel")}
                </DownloadEventsButton>
              </Row>
              <Row justifyContent={"flex-start"}>
                <Text>
                  {this.props.events.length} {t("eventlog.results", "Results")}
                </Text>
              </Row>
            </Container>
            {this.props.isFetching ? (
              <Spinner position={"relative"} z={0} />
            ) : (
              <TableContainer>
                <Table>
                  <TableHeader>
                    <TableHeaderRow>
                      {columns.map((column) => (
                        <TableHeaderCell key={`header-${column}`}>
                          <CellContent>
                            {this.getColumnHeaderText(column)}
                          </CellContent>
                        </TableHeaderCell>
                      ))}
                    </TableHeaderRow>
                  </TableHeader>
                  <TableBody>
                    {this.state.visibleEvents
                      .filter(
                        (event: any) =>
                          this.state.showEventsWithoutResult ||
                          event.weight > 0 ||
                          event.type === ScaleResultType.BALER_START
                      )
                      .map((event: any, index: number) => (
                        <Event
                          key={event.id}
                          event={event}
                          columns={columns.map((column) =>
                            this.getColumnElement(column, event)
                          )}
                          highlight={index % 2 === 0}
                        />
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </>
        </ScalePage>
      </>
    );
  }
}
export const EventLog = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(EventLogConnected));
