import { mapOptions } from "../constants/options";
// Return map bounds based on list of places
const getMapBounds = (maps: any, places: any) => {
  const bounds = new maps.LatLngBounds();

  places.forEach((place: any) => {
    bounds.extend(
      new maps.LatLng(
        place.homeLatitude ? place.homeLatitude : place.latitude,
        place.homeLongitude ? place.homeLongitude : place.longitude
      )
    );
  });
  return bounds;
};

// Re-center map when resizing the window
const bindResizeListener = (map: any, maps: any, bounds: any) => {
  maps.event.addDomListenerOnce(map, "idle", () => {
    maps.event.addDomListener(window, "resize", () => {
      map.fitBounds(bounds);
    });
  });
};

const bindZoomListener = (map: any, maps: any, zoom: number) => {
  maps.event.addListenerOnce(map, "bounds_changed", () => {
    map.setZoom(zoom);
  });
};

// Fit map to its bounds after the api is loaded
export const apiIsLoaded = (
  map: any,
  maps: any,
  places: any,
  boundsProps: any,
  zoom: number
) => {
  // Get bounds by our places
  let bounds = undefined;
  if (!boundsProps) {
    if (places.length > 0) {
      bounds = getMapBounds(maps, places);
    } else {
      bounds = new maps.LatLngBounds(
        new maps.LatLng(
          mapOptions.defaultMapSouthWest[0],
          mapOptions.defaultMapSouthWest[1]
        ),
        new maps.LatLng(
          mapOptions.defaultMapNorthEast[0],
          mapOptions.defaultMapNorthEast[1]
        )
      );
    }
  } else {
    bounds = new maps.LatLngBounds(
      new maps.LatLng(boundsProps.sw.lat, boundsProps.sw.lng),
      new maps.LatLng(boundsProps.ne.lat, boundsProps.ne.lng)
    );
  }
  // Bind zoom listener if bounds are defined
  if (boundsProps) {
    bindZoomListener(map, maps, zoom);
  }
  // Fit map to bounds
  map.fitBounds(bounds);
  // Bind the resize listener
  bindResizeListener(map, maps, bounds);
};

export const latLngToPoint = (latLng: google.maps.LatLng, map: any) => {
  var topRight = map
    .getProjection()
    .fromLatLngToPoint(map.getBounds().getNorthEast());
  var bottomLeft = map
    .getProjection()
    .fromLatLngToPoint(map.getBounds().getSouthWest());
  var scale = Math.pow(2, map.getZoom());
  var worldPoint = map.getProjection().fromLatLngToPoint(latLng);
  return new google.maps.Point(
    (worldPoint.x - bottomLeft.x) * scale,
    (worldPoint.y - topRight.y) * scale
  );
};

export const pointToLatLng = (point: any, map: any) => {
  var topRight = map
    .getProjection()
    .fromLatLngToPoint(map.getBounds().getNorthEast());
  var bottomLeft = map
    .getProjection()
    .fromLatLngToPoint(map.getBounds().getSouthWest());
  var scale = Math.pow(2, map.getZoom());
  var worldPoint = new google.maps.Point(
    point.x / scale + bottomLeft.x,
    point.y / scale + topRight.y
  );
  return map.getProjection().fromPointToLatLng(worldPoint);
};

// Figure out point by angle and distance
export const getPointByAngleAndDistance = (angle: number, distance: number) => {
  return {
    x: Math.round(Math.cos((angle * Math.PI) / 180) * distance),
    y: Math.round(Math.sin((angle * Math.PI) / 180) * distance),
  };
};

export const translatePoint = (point: any, x: number, y: number) => {
  return {
    x: point.x + x,
    y: point.y + y,
  };
};
