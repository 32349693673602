import styled from "styled-components";
import { ToastContainer, ToastContainerProps } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const WrappedToastContainer = ({
  className,
  ...rest
}: ToastContainerProps & { className?: string }) => (
  <div className={className}>
    <ToastContainer {...rest} />
  </div>
);

export default styled(WrappedToastContainer)`
  .Toastify__toast-container {
    font-family: Poppins;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
  }
  .Toastify__toast {
    padding-left: 16px;
    padding-right: 16px;
    min-height: 58px;
  }
  .Toastify__toast--error {
    background-color: var(--df-6940);
  }
  .Toastify__toast--warning {
  }
  .Toastify__toast--success {
    color: black;
    background-color: var(--seafoam-blue);
  }
  .Toastify__toast-body {
  }
  .Toastify__progress-bar {
  }
`;
