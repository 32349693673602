import React from "react";
import styled from "styled-components";
import { ItemListHeader } from "../../../components/Common/ItemListHeader";
import { ScaleManageButton } from "./ScaleManageButton";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { KeyList } from "./KeyList";

const UserGroupKeyListWrapper = styled.div`
  padding: 10px;
  min-height: 540px;
  display: flex;
  flex: 1;
  min-width: 0;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  margin-top: auto;
`;

const AssignKeyWrapper = styled.div`
  background-color: #eff3f6;
  margin-top: 15px;
`;

const AssignKeyForm = styled.form`
  background-color: #eff3f6;
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
`;

const AssignKeyLabel = styled.label`
  display: block;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
`;

const AssignKeyDropdown = styled.select`
  display: block;
  width: calc(100% - 5px);
  margin: 5px 0;
  border: 0;
  border-radius: 12px;
  height: 25px;
  padding-left: 5px;
`;

function getDropdownItemText(key: any) {
  return key.secondary_alias
    ? `${key.alias} - ${key.secondary_alias}`
    : key.alias;
}

interface UserGroupKeyListProps {
  keys: any[];
  unassignedKeys: any[];
  selectedId: number | undefined;
  selectedUserGroup: any;
  onClick: (groupId: number) => void;
  handleRevoke: (selectedId: any) => void;
  handleUnassign: (selectedId: any) => void;
  handleAssign: (selectedId: any) => void;
}

export function UserGroupKeyList(props: UserGroupKeyListProps) {
  const { t } = useTranslation();
  const {
    keys,
    selectedId,
    selectedUserGroup,
    onClick,
    unassignedKeys,
  } = props;
  const handleRevoke = (id: any) => () => {
    props.handleRevoke(id);
  };
  const handleUnassign = (id: any) => () => {
    props.handleUnassign(id);
  };

  return (
    <UserGroupKeyListWrapper>
      <ItemListHeader text={t("scale.manage.unassign.keys", "Keys")} />
      <KeyList
        keys={keys}
        selectedId={selectedId}
        onClick={onClick}
        editMode
        noDataText={t("scale.manage.unassign.noKeys", "The group has no keys")}
      />
      {selectedUserGroup && (
        <ButtonWrapper>
          <ScaleManageButton
            buttonText={t("scale.manage.unassign.button", "Remove from group")}
            helpText={t(
              "scale.manage.unassign.description",
              "Returns key to the pool for reuse."
            )}
            onClick={handleUnassign(selectedId)}
          />
          <ScaleManageButton
            buttonText={t("scale.manage.revoke.button", "Revoke")}
            helpText={t(
              "scale.manage.revoke.description",
              "Stops a lost key from working until reactivated."
            )}
            onClick={handleRevoke(selectedId)}
          />
        </ButtonWrapper>
      )}
      {selectedUserGroup && (
        <AssignKeyWrapper>
          <Formik
            initialValues={{ key: undefined }}
            onSubmit={(values, actions) => {
              if (values.key) {
                props.handleAssign(values.key);
              }
              actions.setSubmitting(false);
            }}
          >
            {(props) => (
              <AssignKeyForm onSubmit={props.handleSubmit}>
                <AssignKeyLabel>
                  {t("scale.manage.assign.description", "Assign key to") + " "}
                  <i>{selectedUserGroup.name}</i> :
                </AssignKeyLabel>
                <AssignKeyDropdown
                  onChange={(e) => {
                    props.handleChange(e);
                    props.submitForm();
                  }}
                  onBlur={props.handleBlur}
                  name="key"
                >
                  {[{ alias: "" }, ...unassignedKeys].map(
                    (key: any, i: number) => (
                      <option key={i} value={key.id}>
                        {getDropdownItemText(key)}
                      </option>
                    )
                  )}
                </AssignKeyDropdown>
              </AssignKeyForm>
            )}
          </Formik>
        </AssignKeyWrapper>
      )}
    </UserGroupKeyListWrapper>
  );
}
