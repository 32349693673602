import styled from "styled-components";

interface FormSubmitButtonProps {
  cursor?: string;
  margin?: string;
}

export const FormSubmitButton = styled.button<FormSubmitButtonProps>`
  margin: ${(props) => props.margin || 0};
  border-radius: 1rem;
  background-color: var(--col-11-a-3-c-7);
  min-width: 7rem;
  min-height: 2rem;
  cursor: ${(props) => (props.cursor ? props.cursor : "pointer")};
  outline: none;
  border-style: none;

  font-size: 0.875rem;
  font-weight: 500;
  font-family: "Poppins";
  line-height: 1.71;
  text-align: center;
  color: #ffffff;
  transition: background-color 0.1s linear;

  :hover {
    background-color: var(--col-11-a-3-c-7-dark);
  }

  :disabled {
    opacity: 0.5;
    cursor: ${(props) =>
      props.cursor ? props.cursor : "not-allowed !important"};
  }
`;
