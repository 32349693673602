import { Component, Fragment } from "react";
import * as TYPES from "../../constants/actionTypes";
import { connect } from "react-redux";
import { Formik } from "formik";
import { FormTitle } from "../../components/Common/FormTitle";
import { FormPasswordInput } from "../../components/Common/FormInput";
import { FormSubmitButton } from "../../components/Common/FormSubmitButton";
import { FormBackground } from "../../components/Common/FormBackground";
import { FormRectangle } from "../../components/Common/FormRectangle";
import { FlexBoxWrapper } from "../../components/Common";
import { Header } from "../../components/Header";
import { FormWrapper } from "../../components/Common/FormWrapper";
import ResetPasswordSchema from "./ResetPasswordSchema";
import { FooterLink } from "../../components/Common/FooterLink";
import { withTranslation } from "react-i18next";
import { getValidationErrorText } from "../../utils/i18nUtils";

export class ResetPasswordConnected extends Component<any, any> {
  private initialValues: {
    newPassword: string;
    newPasswordConfirm: string;
  };

  constructor(props: any) {
    super(props);

    this.initialValues = {
      newPassword: "",
      newPasswordConfirm: "",
    };
  }

  public componentDidMount(): void {
    const token = this.props.location.search.split("token=")[1];
    this.props.verifyToken(token);
  }

  public render(): JSX.Element {
    const { t } = this.props;

    return (
      <Fragment>
        <Header />
        <FormBackground>
          <FormRectangle>
            <FormTitle
              title={t("resetPassword.title", "Reset password")}
              secondary
            />
            <Formik
              enableReinitialize={true}
              initialValues={this.initialValues}
              onSubmit={this.onSubmit}
              render={this.onRender}
              validationSchema={ResetPasswordSchema}
            />
            <FooterLink to="/">
              {t("resetPassword.footerLink", "Login")}
            </FooterLink>
          </FormRectangle>
        </FormBackground>
      </Fragment>
    );
  }

  private onSubmit = (values: any, actions: any) => {
    const token = this.props.location.search.split("token=")[1];
    this.props.resetPassword(
      {
        newPassword: values.newPassword,
      },
      token
    );
    actions.setSubmitting(false);
  };

  private onRender = (formikProps: any) => {
    const {
      isSubmitting,
      errors,
      touched,
      values,
      handleBlur,
      handleChange,
      isValid,
    } = formikProps;

    const { t } = this.props;

    return (
      <FormWrapper>
        <FormPasswordInput
          id="newPassword"
          label={t("resetPassword.newPassword", "New password")}
          error={
            touched.newPassword && getValidationErrorText(errors.newPassword)
          }
          value={values.newPassword}
          onChange={handleChange}
          onBlur={handleBlur}
          required={true}
        />
        <FormPasswordInput
          id="newPasswordConfirm"
          label={t("resetPassword.confirmPassword", "Confirm password")}
          error={
            touched.newPasswordConfirm &&
            getValidationErrorText(errors.newPasswordConfirm)
          }
          value={values.newPasswordConfirm}
          onChange={handleChange}
          onBlur={handleBlur}
          required={true}
        />
        <FlexBoxWrapper marginTop={20}>
          <FormSubmitButton type="submit" disabled={isSubmitting || !isValid}>
            {t("resetPassword.submit", "Submit")}
          </FormSubmitButton>
        </FlexBoxWrapper>
      </FormWrapper>
    );
  };
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    verifyToken: (token: string) => {
      dispatch({
        type: TYPES.VERIFY_RESET_PASSWORD_TOKEN,
        payload: { token },
      });
    },
    resetPassword: (data: object, token: string) => {
      dispatch({
        type: TYPES.RESET_PASSWORD,
        payload: { token, data },
      });
    },
  };
};

export const ResetPassword = connect(
  null,
  mapDispatchToProps
)(withTranslation()(ResetPasswordConnected));
