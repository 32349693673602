import { createRef, RefObject, useEffect, useState } from "react";
import ChartDataLabels from "chartjs-plugin-datalabels";
import styled from "styled-components";
import "chart.js/auto";
import { Doughnut } from "react-chartjs-2";
import { useForceUpdate } from "../../utils/forceUpdate";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";

const DoughnutChartWrapper = styled.div`
  width: 400px;
  height: 300px;
  position: relative;
  margin: 1rem;
`;

const getDoughnutChartOptions = (title: string) => ({
  responsive: true,
  maintainAspectRatio: false,
  title: {
    display: true,
    text: title,
  },
  legend: {
    display: false,
  },
  tooltips: {
    callbacks: {
      label: function (item: any, data: any) {
        const group = data.labels[item.index];
        const dataset = data.datasets[item.datasetIndex];
        const totalWeight = dataset._meta[Object.keys(dataset._meta)[0]].total;
        const groupWeight = dataset.data[item.index];
        const percentage = (groupWeight / totalWeight) * 100;

        return `${group} ${percentage.toFixed(1)}% (${groupWeight}kg)`;
      },
    },
  },
  plugins: {
    datalabels: {
      formatter: (groupWeight: any, ctx: any) => {
        const totalWeight = ctx.dataset.data[0];
        const percentage = (groupWeight * 100) / totalWeight;
        return 5 <= percentage ? `${percentage.toFixed(1)}%` : "";
      },
      color: "#fff",
      textStrokeColor: "black",
      textStrokeWidth: 1,
      font: { weight: "bold" as const },
    },
  },
});

export function WasteFractionDoughnutCharts(scaleResults: any) {
  const forceUpdate = useForceUpdate();
  const chartCount = scaleResults?.wasteFractions?.length ?? 0;
  const [chartRefs, setChartRefs] = useState<
    RefObject<ChartJSOrUndefined<"doughnut", number[], null>>[]
  >([]);

  useEffect(() => {
    setChartRefs((chartRefs) =>
      Array(chartCount)
        .fill(null)
        .map((_, i) => chartRefs[i] || createRef())
    );
  }, [chartCount]);

  useEffect(() => {
    forceUpdate();
  }, [chartRefs, forceUpdate]);

  const groupTotalsByWasteFraction = scaleResults?.wasteFractions?.map(
    (wasteFraction: any, idx: number) => ({
      title: wasteFraction.label,
      chartData: scaleResults.groupTotalsByWasteFraction[idx],
    })
  );

  return (
    <>
      {groupTotalsByWasteFraction?.map(
        ({ chartData, title }: any, idx: number) => (
          <DoughnutChartWrapper key={title}>
            <Doughnut
              data={chartData}
              options={getDoughnutChartOptions(title)}
              plugins={[ChartDataLabels]}
              ref={chartRefs[idx]}
            />
          </DoughnutChartWrapper>
        )
      )}
    </>
  );
}
