import { GroupHierarchyApi } from "../../../server/src/shared/apiTypes";
import * as TYPES from "../constants/actionTypes";
import { findGroupById } from "../utils/group";

export type TreeNode = GroupHierarchyApi["hierarchies"][number];

export type GroupState = {
  hierarchies: GroupHierarchyApi["hierarchies"];
  selectedGroupId: number | undefined;
};

const initialState: GroupState = {
  hierarchies: [],
  selectedGroupId: undefined,
};

const groups = (state = initialState, action: any): GroupState => {
  switch (action.type) {
    case TYPES.FETCH_GROUP_HIERARCHY: {
      return state;
    }
    case TYPES.FETCH_GROUP_HIERARCHY_SUCCESS: {
      const hierarchies = (action.payload as GroupHierarchyApi).hierarchies;
      if (hierarchies && hierarchies.length !== 0) {
        let selectedGroupId = undefined;
        if (
          state.selectedGroupId &&
          findGroupById(hierarchies, state.selectedGroupId) !== undefined
        ) {
          selectedGroupId = state.selectedGroupId;
        }

        return {
          ...state,
          hierarchies,
          selectedGroupId,
        };
      }
      return state;
    }
    case TYPES.FETCH_GROUP_HIERARCHY_FAILURE: {
      return state;
    }
    case TYPES.GROUP_SELECTED: {
      let selectedGroupId = action.payload.groupId;
      return { ...state, selectedGroupId };
    }
    default: {
      return state;
    }
  }
};

export default groups;
