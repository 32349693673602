import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Modal } from "./Common/Modal";
import { FormInput } from "./Common/FormInput";
import { FormWrapper } from "./Common/FormWrapper";
import { FlexBoxWrapper } from "./Common";
import { FormSubmitButton } from "./Common/FormSubmitButton";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import MFAForm from "./../components/MFAForm";

const Divider = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
`;

interface ProfileModalProps {
  profileOpen: boolean;
  handleCloseProfile: () => void;
  handleChangeLanguage: (language: string) => void;
}

export function ProfileModal(props: ProfileModalProps) {
  const { t } = useTranslation();
  const language = useSelector((state: any) => state.users.language);
  const languageOptions = [
    {
      value: "en",
      text: t("language.english", "English"),
    },
    {
      value: "ru",
      text: t("language.russian", "Russian"),
    },
    {
      value: "fi",
      text: t("language.finnish", "Finnish"),
    },
    {
      value: "pl",
      text: t("language.polish", "Polish"),
    },
  ];

  const initialValues = {
    language: language ? language : languageOptions[0].value,
  };

  const onSubmit = (values: any) => {
    props.handleChangeLanguage(values.language);
  };

  const onRender = (formikProps: any) => {
    const { errors, values, touched, handleChange, handleBlur } = formikProps;

    return (
      <>
        <MFAForm />
        <Divider />
        <FormWrapper>
          <FormInput
            id="language"
            type="select"
            label={t("header.userMenu.profileModal.language", "Language")}
            component="select"
            options={languageOptions}
            error={touched.language && errors.language}
            value={values.language}
            onChange={handleChange}
            onBlur={handleBlur}
            labelColor={"#000"}
          />
          <FlexBoxWrapper marginTop={20}>
            <FormSubmitButton onClick={props.handleCloseProfile}>
              {t("header.userMenu.profileModal.cancelButton", "Cancel")}
            </FormSubmitButton>
            &nbsp;
            <FormSubmitButton type="submit">
              {t("header.userMenu.profileModal.submitButton", "Submit")}
            </FormSubmitButton>
          </FlexBoxWrapper>
        </FormWrapper>
      </>
    );
  };

  const content: JSX.Element = (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={onRender}
    />
  );

  return (
    <Modal
      isOpen={props.profileOpen}
      handleClose={props.handleCloseProfile}
      content={content}
      title={t("header.userMenu.profileModal.header", "Profile")}
    />
  );
}
