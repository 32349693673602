import { memo } from "react";
import { MachineType, DeviceStatus } from "../../interfaces/types";
import { Circle } from "../Device";
import styled from "styled-components";

import icon_status_ok from "../../assets/icon-status-ok.svg";
import icon_status_warning from "../../assets/icon-status-warning.svg";
import icon_status_error from "../../assets/icon-status-error.svg";
import icon_status_intransit from "../../assets/icon-status-intransit.svg";
import icon_power_off from "../../assets/icon-status-off-selected.svg";
import icon_skip from "../../assets/icon-skip.svg";
import { useTranslation } from "react-i18next";

interface StatusIconProps {
  machineType: MachineType | undefined;
  deviceStatus: DeviceStatus | undefined;
}

const IconStatus = styled.img`
  object-fit: contain;
`;

const IconSkip = styled.img`
  height: 16px;
  width: 16px;
`;

function StatusIconPure(props: StatusIconProps) {
  const { t } = useTranslation();

  if (props.machineType === MachineType.Skip) {
    return (
      <Circle>
        <IconSkip src={icon_skip} />
      </Circle>
    );
  }
  switch (props.deviceStatus) {
    case DeviceStatus.OK: {
      return (
        <IconStatus
          src={icon_status_ok}
          title={t("filterItem.status.ok", "OK")}
        />
      );
    }
    case DeviceStatus.Warning: {
      return (
        <IconStatus
          src={icon_status_warning}
          title={t("filterItem.status.warning", "Warning")}
        />
      );
    }
    case DeviceStatus.Error: {
      return (
        <IconStatus
          src={icon_status_error}
          title={t("filterItem.status.error", "Error")}
        />
      );
    }
    case DeviceStatus.InTransit: {
      return (
        <IconStatus
          src={icon_status_intransit}
          title={t("filterItem.status.inTransit", "In transit")}
        />
      );
    }
    case DeviceStatus.PowerOff: {
      return (
        <IconStatus
          src={icon_power_off}
          title={t("filterItem.status.off", "Off")}
        />
      );
    }
  }
  return <></>;
}

export const StatusIcon = memo(StatusIconPure);
