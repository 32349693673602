import styled from "styled-components";

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  // Hide checkbox visually but remain accessible to screen readers.
  // Source: https://polished.js.org/docs/#hidevisually
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

interface StyledProps {
  checked?: boolean;
}

const Icon = styled.svg`
  fill: none;
  stroke: var(--seafoam-blue);
  stroke-width: 2px;
  margin-bottom: 18px;
`;
const StyledCheckbox = styled.div<StyledProps>`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: #ffffff;
  border-radius: 2px;
  border: solid 1px var(--light-blue-grey);
  transition: all 150ms;
  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px transparent;
  }
  ${Icon} {
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

/**
 * NOTE: You must wrap this component in a <label> element, because the native
 * <input type="checkbox"> element is hidden, so the <label> element is solely
 * responsible for triggering the onChange event.
 */
export const Checkbox = ({ className, checked, ...props }: any) => (
  <CheckboxContainer className={className}>
    <HiddenCheckbox checked={checked} {...props} />
    <StyledCheckbox checked={checked}>
      <Icon viewBox="0 0 24 24">
        <polyline points="20 6 9 17 4 12" />
      </Icon>
    </StyledCheckbox>
  </CheckboxContainer>
);
