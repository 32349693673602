import { call, put } from "redux-saga/effects";
import { getToken } from "../api/token";
import * as TYPES from "../constants/actionTypes";
import { NotifyError } from "../components/Common/Notify";
/**
 * Fetch token action. IF fetch fails puts action FETCH_TOKEN_FAILURE. For success passes data
 * through FETCH_TOKEN_SUCCESS action.
 * @export
 * @param {*} action
 */
export function* fetchToken(action: any) {
  try {
    const result = yield call(getToken, action);
    if (result.data.mfaRequired) {
      yield put({ type: TYPES.MFA_REQUIRED, payload: result.data });
    } else {
      yield put({ type: TYPES.FETCH_TOKEN_SUCCESS, payload: result.data });
    }
  } catch (err) {
    if (err?.response?.data?.error === "user.login.lockout") {
      // User was locked out, return to login page.
      yield put({ type: TYPES.LOCKOUT });
    }
    yield put({ type: TYPES.FETCH_TOKEN_FAILURE });
    NotifyError(TYPES.FETCH_TOKEN_FAILURE, err.response.data.error);
  }
}
