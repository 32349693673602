import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { PropertyGroupList } from "./PropertyGroupList";
import { UserGroupList } from "./UserGroupList";
import { UserGroupKeyList } from "./UserGroupKeyList";
import { UnassignedKeyList } from "./UnassignedKeyList";
import { RevokedKeyList } from "./RevokedKeyList";
import { ScalePage } from "../ScalePage";
import styled from "styled-components";
import { FlexBoxWrapper } from "../../../components/Common";
import { SCALE_TAG_STATE } from "../../../interfaces/types";
import * as TYPES from "../../../constants/actionTypes";
import { singleEffectiveGroupSelector } from "../../../selectors/groups";

interface CardWrapperProps {
  flex: number;
}

const CardWrapper = styled.div<CardWrapperProps>`
  min-width: 0;
`;

export function ScaleManage() {
  const selectedGroup = useSelector(singleEffectiveGroupSelector);
  const [selectedPropertyGroup, setSelectedPropertyGroup] = useState(undefined);
  const [selectedUserGroup, setSelectedUserGroup] = useState(undefined);
  const [selectedUserGroupKey, setSelectedUserGroupKey] = useState(undefined);
  const keys = useSelector((state: any) => state.scale.tags);
  const userGroups = useSelector((state: any) => state.scale.userGroups);
  const token = useSelector((state: any) => state.token.key);
  const dispatch = useDispatch();

  const handleGroupSelection = useCallback(() => {
    if (!selectedGroup) {
      return;
    }

    setSelectedPropertyGroup(undefined);
    setSelectedUserGroup(undefined);
    setSelectedUserGroupKey(undefined);
    dispatch({
      type: TYPES.FETCH_GROUP_SCALE_TAGS,
      payload: {
        group: selectedGroup.guid,
        token: token,
      },
    });
    dispatch({
      type: TYPES.FETCH_USER_GROUPS_FOR_GROUP,
      payload: {
        groupId: selectedGroup.groupId,
        token,
      },
    });
  }, [selectedGroup, token, dispatch]);

  useEffect(() => {
    if (selectedGroup) {
      handleGroupSelection();
    }
  }, [selectedGroup, token, dispatch, handleGroupSelection]);

  const handlePropertyGroupSelection = (groupId: any) => {
    // Allow deleselecting a property group so that the
    // parent group becomes selected.
    if (selectedPropertyGroup === groupId) {
      setSelectedPropertyGroup(undefined);
      setSelectedUserGroup(undefined);
      setSelectedUserGroupKey(undefined);
      handleGroupSelection();
      return;
    }
    setSelectedPropertyGroup(groupId);
    setSelectedUserGroup(undefined);
    setSelectedUserGroupKey(undefined);

    const selectedGroupGuid = selectedGroup?.children.find(
      (group) => group.groupId === groupId
    )?.guid;
    if (selectedGroupGuid) {
      dispatch({
        type: TYPES.FETCH_GROUP_SCALE_TAGS,
        payload: {
          group: selectedGroupGuid,
          token: token,
        },
      });
    }

    dispatch({
      type: TYPES.FETCH_USER_GROUPS_FOR_GROUP,
      payload: {
        groupId,
        token,
      },
    });
  };

  const getPropertyGroups = () => {
    if (!selectedGroup) {
      return [];
    }
    return selectedGroup.children.map((child: any) => {
      return {
        id: child.groupId,
        name: child.name,
      };
    });
  };

  const handleUserGroupCreation = (values: {
    name: string;
    customerId: string;
  }) => {
    const groupId = selectedPropertyGroup || selectedGroup?.groupId;
    if (groupId != null) {
      dispatch({
        type: TYPES.CREATE_SCALE_USER_GROUP,
        payload: {
          groupId,
          name: values.name,
          customerId: values.customerId,
          token,
        },
      });
    }
  };

  const handleUserGroupDeletion = (id: number) => {
    const propertyGroup = selectedGroup?.children.find(
      (group: any) => group.groupId === selectedPropertyGroup
    );
    const groupGuid = propertyGroup?.guid ?? selectedGroup?.guid;

    dispatch({
      type: TYPES.DELETE_SCALE_USER_GROUP,
      payload: {
        id,
        token,
      },
    });
    if (groupGuid) {
      dispatch({
        type: TYPES.FETCH_GROUP_SCALE_TAGS,
        payload: {
          group: groupGuid,
          token: token,
        },
      });
    }
    setSelectedUserGroup(undefined);
    setSelectedUserGroupKey(undefined);
  };

  const handleUserGroupEditing = (
    id: number,
    { name, customerId }: { name: string; customerId: string }
  ) => {
    dispatch({
      type: TYPES.UPDATE_SCALE_USER_GROUP,
      payload: {
        id,
        name,
        customerId,
        token,
      },
    });
  };

  const handleUserGroupSelection = (groupId: any) => {
    setSelectedUserGroup(groupId);
    setSelectedUserGroupKey(undefined);
  };

  const handleRevokeKey = (id: number) => {
    dispatch({
      type: TYPES.REVOKE_SCALE_TAG,
      payload: {
        id,
        token,
      },
    });
  };

  const handleUnrevokeKey = (id: number) => {
    dispatch({
      type: TYPES.UNREVOKE_SCALE_TAG,
      payload: {
        id,
        token,
      },
    });
  };

  const handleAssignKey = (id: number) => {
    dispatch({
      type: TYPES.ASSIGN_SCALE_TAG,
      payload: {
        id,
        userGroupId: selectedUserGroup,
        token,
      },
    });
  };

  const handleUnassignKey = (id: number) => {
    dispatch({
      type: TYPES.UNASSIGN_SCALE_TAG,
      payload: {
        id,
        token,
      },
    });
  };

  const getUserGroupsForPropertyGroup = () => {
    return userGroups
      .filter((userGroup: any) => {
        if (selectedPropertyGroup != null) {
          return selectedPropertyGroup === userGroup.groupId;
        }
        return (
          selectedGroup?.groupId != null &&
          selectedGroup.groupId === userGroup.groupId
        );
      })
      .map((userGroup: any) => {
        return {
          ...userGroup,
          numberOfKeys: getKeysForUserGroup(userGroup.id).length,
        };
      });
  };

  const getSelectedUserGroup = () => {
    return userGroups.find(
      (userGroup: any) => userGroup.id === selectedUserGroup
    );
  };

  const handleUserGroupKeySelection = (keyId: any) => {
    if (selectedUserGroupKey === keyId) {
      setSelectedUserGroupKey(undefined);
    } else {
      setSelectedUserGroupKey(keyId);
    }
  };

  const getKeysForUserGroup = (userGroup: any) => {
    return keys
      .filter(
        (key: any) =>
          key.scale_user_group_id &&
          userGroup === key.scale_user_group_id &&
          key.tag_status === SCALE_TAG_STATE.ASSIGNED
      )
      .sort((a: any, b: any) => (a.alias > b.alias ? 1 : -1));
  };

  const getUnassignedKeys = () => {
    const groupId = selectedPropertyGroup ?? selectedGroup?.groupId;

    return keys
      .filter(
        (key: any) =>
          key.tag_status === SCALE_TAG_STATE.UNASSIGNED &&
          key.property_group_id === groupId
      )
      .sort((a: any, b: any) => (a.alias > b.alias ? 1 : -1));
  };

  const getRevokedKeys = () => {
    const groupId = selectedPropertyGroup ?? selectedGroup?.groupId;

    return keys
      .filter(
        (key: any) =>
          key.tag_status === SCALE_TAG_STATE.REVOKED &&
          key.property_group_id === groupId
      )
      .sort((a: any, b: any) => (a.alias > b.alias ? 1 : -1));
  };

  const getActiveId = (type: string) => {
    if (type === "key" && selectedUserGroupKey !== undefined) {
      return selectedUserGroupKey;
    } else if (
      type === "userGroup" &&
      selectedUserGroup !== undefined &&
      selectedUserGroupKey === undefined
    ) {
      return selectedUserGroup;
    } else if (
      type === "propertyGroup" &&
      selectedPropertyGroup !== undefined &&
      selectedUserGroup === undefined &&
      selectedUserGroupKey === undefined
    ) {
      return selectedPropertyGroup;
    }
    return undefined;
  };

  return (
    <ScalePage>
      <CardWrapper flex={3}>
        <FlexBoxWrapper alignItems={"stretch"}>
          <PropertyGroupList
            items={getPropertyGroups()}
            selectedId={selectedPropertyGroup}
            activeId={getActiveId("propertyGroup")}
            onClick={handlePropertyGroupSelection}
          />
          <UserGroupList
            items={getUserGroupsForPropertyGroup()}
            selectedId={selectedUserGroup}
            activeId={getActiveId("userGroup")}
            onClick={handleUserGroupSelection}
            handleUserGroupCreation={handleUserGroupCreation}
            handleUserGroupDeletion={handleUserGroupDeletion}
            handleUserGroupEditing={handleUserGroupEditing}
          />
          <UserGroupKeyList
            keys={getKeysForUserGroup(selectedUserGroup)}
            selectedId={selectedUserGroupKey}
            selectedUserGroup={getSelectedUserGroup()}
            onClick={handleUserGroupKeySelection}
            handleRevoke={handleRevokeKey}
            handleAssign={handleAssignKey}
            handleUnassign={handleUnassignKey}
            unassignedKeys={getUnassignedKeys()}
          />
        </FlexBoxWrapper>
      </CardWrapper>
      <CardWrapper flex={1}>
        <UnassignedKeyList
          keys={getUnassignedKeys()}
          handleRevoke={handleRevokeKey}
        />
        <RevokedKeyList
          keys={getRevokedKeys()}
          handleUnrevoke={handleUnrevokeKey}
        />
      </CardWrapper>
    </ScalePage>
  );
}
