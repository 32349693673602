import styled from "styled-components";

export const FormInfoText = styled.p`
  font-size: 0.875rem;
  text-transform: uppercase;
  margin: 0.5rem 0 1rem 12.5%;
  font-weight: 500;
  font-family: "Roboto";
  color: white;
`;
