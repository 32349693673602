import * as Yup from "yup";

Yup.setLocale({
  mixed: { notType: ({ type }: any) => `formError.${type}.notType` },
  number: {
    /**
     * TODO: Translation for `max` and `min` need to be changed from
     * being non-inclusive of the max/min value to including them. The
     * old translations should be used for lessThan and moreThan.
     */
    min: ({ min }: any) => ({ key: "formError.number.min", value: min }),
    max: ({ max }: any) => ({ key: "formError.number.max", value: max }),
    moreThan: ({ more }: any) => ({
      key: "formError.number.min",
      value: more,
    }),
    lessThan: ({ less }: any) => ({
      key: "formError.number.max",
      value: less,
    }),
  },
});

const DeviceDetailsSchema = Yup.object().shape({
  alias: Yup.string(),
  homeLatitude: Yup.number().min(-90).max(90),
  homeLongitude: Yup.number().min(-180).max(180),
});

const BaleCounterSchema = Yup.object().shape({
  useBaleCounter: Yup.boolean(),
  pickupLimit: Yup.number().when("useBaleCounter", {
    is: true,
    then: Yup.number().required().min(1),
  }),
});

export { DeviceDetailsSchema, BaleCounterSchema };
