import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as TYPES from "../constants/actionTypes";
import { FilterItem } from "./FilterItem";
import {
  DeviceStatus,
  FillState,
  FilterPopupType,
  SelectableColumns,
  MachineType,
} from "../interfaces/types";

import styled from "styled-components";
import Modal from "react-modal";
import "../css/modal.css";
import { useTranslation } from "react-i18next";
import * as selectableColumnConstants from "../constants/selectableColumns";
import { filterSelector } from "../selectors/devices";
import { selectedColumnsSelector, userStateSelector } from "../selectors/users";
import { tokenSelector } from "../selectors/token";
import { UsersState } from "../reducers/users";

interface ContainerProps {
  marginLeft?: number;
  marginTop?: number;
  positionRight?: number;
  position?: string;
}
const Container = styled.div<ContainerProps>`
  position: ${(props) => props.position || "absolute"};
  right: ${(props) =>
    props.positionRight ? props.positionRight + "px" : "initial"};
  z-index: 1;
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : 0)}px;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : 0)}px;
`;
const Rect = styled.div`
  background-color: #ffffff;
`;
const LineWrapper = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 1px;
`;

const Line = styled.div`
  width: 180px;
  height: 0.1px;
  margin-bottom: 18px;
  border: 1px solid rgb(17, 163, 199);
`;
const Header = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--col-214269);
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffff;
  height: 54px;
  white-space: nowrap;
  padding: 0 26px;
`;
const ResetButton = styled.button`
  width: 89px;
  height: 34px;
  border-radius: 17px;
  background-color: var(--ice-blue);
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: var(--col-214269);
  border: none;
  cursor: pointer;
  outline: none;
`;
const SelectButton = styled.button`
  width: 89px;
  height: 34px;
  border-radius: 17px;
  background-color: var(--col-11-a-3-c-7);
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  border: none;
  cursor: pointer;
  outline: none;
`;
const Footer = styled.footer`
  display: flex;
  justify-content: space-around;
  background-color: #ffffff;
  margin-top: auto;
  height: 58px;
`;

type PopupData = {
  getItems: (user: UsersState) => number[];
  getInitialItems: (data: {
    filters: {
      status: DeviceStatus[];
      fillLevel: FillState[];
      machineType: MachineType[];
    };
    selectedColumns: {
      deviceColumns: SelectableColumns[];
      skipColumns: SelectableColumns[];
    };
  }) => number[];
  resetItems: number[];
  submitAction: (data: {
    selected: number[];
    token: string;
    userName: string;
  }) => { type: string; payload: any };
};

const popupTypeToData: {
  [K in FilterPopupType]: PopupData;
} = {
  [FilterPopupType.EDIT_COLUMNS]: {
    getItems: (user) => {
      if (
        (user.groups.includes("Europress") && user.admin) ||
        user.licenses?.insights
      ) {
        return selectableColumnConstants.DEVICE_DISPLAY_ORDER;
      }
      return selectableColumnConstants.DEVICE_DISPLAY_ORDER.filter(
        (col) => col !== SelectableColumns.PREDICTED_FILL_UP
      );
    },
    getInitialItems: ({ selectedColumns }) => selectedColumns.deviceColumns,
    resetItems: selectableColumnConstants.DEFAULT_DEVICE,
    submitAction: ({ selected, userName, token }) => ({
      type: TYPES.DEVICES_COLUMNS_SELECTED,
      payload: {
        data: {
          columns: selected,
          type: "device",
        },
        user: userName,
        token,
      },
    }),
  },
  [FilterPopupType.EDIT_SKIP_COLUMNS]: {
    getItems: () => selectableColumnConstants.SKIP_DISPLAY_ORDER,
    getInitialItems: ({ selectedColumns }) => selectedColumns.skipColumns,
    resetItems: selectableColumnConstants.DEFAULT_SKIP,
    submitAction: ({ selected, userName, token }) => ({
      type: TYPES.SKIP_COLUMNS_SELECTED,
      payload: {
        data: {
          columns: selected,
          type: "skip",
        },
        user: userName,
        token,
      },
    }),
  },
  [FilterPopupType.FILL_LEVEL]: {
    getItems: () => [
      FillState.Empty,
      FillState.Level_1,
      FillState.Level_2,
      FillState.AlmostFull,
      FillState.Full,
      FillState.OverFull,
      FillState.Unknown,
    ],
    getInitialItems: ({ filters }) => filters.fillLevel,
    resetItems: [],
    submitAction: ({ selected }) => ({
      type: TYPES.FILTER_DEVICES_BY_FILL_LEVEL,
      payload: { fillLevels: selected },
    }),
  },
  [FilterPopupType.STATUS]: {
    getItems: () => [
      DeviceStatus.OK,
      DeviceStatus.InTransit,
      DeviceStatus.PowerOff,
      DeviceStatus.Warning,
      DeviceStatus.Error,
    ],
    getInitialItems: ({ filters }) => filters.status,
    resetItems: [],
    submitAction: ({ selected }) => ({
      type: TYPES.FILTER_DEVICES_BY_STATUS,
      payload: { statuses: selected },
    }),
  },
  [FilterPopupType.MACHINE_TYPE]: {
    getItems: () => [MachineType.Compactor, MachineType.Baler],
    getInitialItems: ({ filters }) => filters.machineType,
    resetItems: [],
    submitAction: ({ selected }) => ({
      type: TYPES.FILTER_DEVICES_BY_MACHINE_TYPE,
      payload: {
        machineTypes: selected,
      },
    }),
  },
};

function useFilterPopupState(popupType: FilterPopupType) {
  const dispatch = useDispatch();
  const filters = useSelector(filterSelector);
  const selectedColumns = useSelector(selectedColumnsSelector);
  const token = useSelector(tokenSelector);
  const user = useSelector(userStateSelector);

  const popupData = popupTypeToData[popupType];

  const [selectedItems, setSelectedItems] = useState(
    popupData.getInitialItems({ filters, selectedColumns })
  );

  return {
    items: popupData.getItems(user),
    selectedItems,
    onSelect: (val: number, selected: boolean) => {
      if (selected) {
        setSelectedItems((oldItems) => [...oldItems, val]);
      } else {
        setSelectedItems((oldItems) => oldItems.filter((item) => item !== val));
      }
    },
    onReset: () => setSelectedItems(popupData.resetItems),
    onSubmit: () =>
      dispatch(
        popupData.submitAction({
          selected: selectedItems,
          token,
          userName: user.userName,
        })
      ),
  };
}

function useFilterPopupAttributes(popupType: FilterPopupType) {
  const { t } = useTranslation();

  const baseFilterAttrs = {
    containerProps:
      window.innerWidth < 768
        ? {}
        : {
            marginLeft:
              window.innerWidth === 768 && window.innerHeight === 1024
                ? 0
                : -55,
            marginTop: 1,
          },
    inModal: window.innerWidth < 768,
  };

  const baseColumnAttrs = {
    containerProps: {
      position: "relative",
    },
    inModal: true,
  };

  const attributes = {
    [FilterPopupType.STATUS]: {
      headerText: t("filterPopup.status.header", "Filter devices by Status"),
      modalProps: {
        overlayClassName: "modal-status-overlay",
        className: "modal-status-content",
      },
      ...baseFilterAttrs,
    },
    [FilterPopupType.FILL_LEVEL]: {
      headerText: t(
        "filterPopup.fillLevel.header",
        "Filter devices by Fill level"
      ),
      modalProps: {
        overlayClassName: "modal-status-overlay",
        className: "modal-filllevel-content",
      },
      ...baseFilterAttrs,
    },
    [FilterPopupType.MACHINE_TYPE]: {
      headerText: t("filterPopup.machineType.header", "Filter by machine type"),
      modalProps: {
        overlayClassName: "modal-status-overlay",
        className: "modal-status-content",
      },
      ...baseFilterAttrs,
    },
    [FilterPopupType.EDIT_COLUMNS]: {
      headerText: t("filterPopup.editColumns.header", "Edit Columns"),
      modalProps: {
        overlayClassName: "modal-columns-overlay",
        className: "modal-columns-content",
      },
      ...baseColumnAttrs,
    },
    [FilterPopupType.EDIT_SKIP_COLUMNS]: {
      headerText: t("filterPopup.editColumns.header", "Edit Columns"),
      modalProps: {
        overlayClassName: "modal-columns-overlay",
        className: "modal-columns-skip-content",
      },
      ...baseColumnAttrs,
    },
  };

  return attributes[popupType];
}

interface FilterPopupProps {
  type: FilterPopupType;
  onClose: () => void;
}
export function FilterPopup(props: FilterPopupProps) {
  const { t } = useTranslation();
  const {
    items,
    selectedItems,
    onSelect,
    onReset,
    onSubmit,
  } = useFilterPopupState(props.type);

  const attrs = useFilterPopupAttributes(props.type);

  const container = (
    <Container {...attrs.containerProps}>
      <Header>{attrs.headerText}</Header>
      <Rect>
        {items.map((item) => (
          <FilterItem
            type={props.type}
            item={item}
            key={item}
            onSelection={onSelect}
            checked={selectedItems.includes(item)}
          />
        ))}
        <LineWrapper>
          <Line />
        </LineWrapper>
        <Footer>
          <ResetButton onClick={onReset}>
            {t("filterPopup.fillLevel.resetButton", "Reset")}
          </ResetButton>
          <SelectButton
            onClick={() => {
              onSubmit();
              props.onClose();
            }}
          >
            {t("filterPopup.fillLevel.okButton", "OK")}
          </SelectButton>
        </Footer>
      </Rect>
    </Container>
  );

  return attrs.inModal ? (
    <Modal isOpen {...attrs.modalProps}>
      {container}
    </Modal>
  ) : (
    container
  );
}
