import { DeviceErrorTaskStates } from "@shared/types";
import { possibleDeviceErrorTaskStates } from "@shared/constants";
import styled from "styled-components";
import { BaseFormSchema } from "./schemas";
import { Formik } from "formik";
import { FormWrapper as FormWrapperBase } from "../../../../components/Common/FormWrapper";
import {
  CheckboxGroup,
  FormInput,
  FormLabel,
  FormNote,
  FormToggle,
  FormValue,
  InputBox,
} from "../../../../components/Common/FormInput";
import { useDeviceErrorTaskCreateFormData } from "../../../../queries/deviceErrorTask";
import { getValidationErrorText } from "../../../../utils/i18nUtils";
import { Divider } from "../../../../components/Common/Divider";
import { ErrorTaskDeviceTable } from "./ErrorTaskDeviceTable";
import { triggerStateToFormString } from "./constants";
import { FlexBoxWrapper } from "../../../../components/Common";
import { FormSubmitButton } from "../../../../components/Common/FormSubmitButton";
import { FormInfoText } from "../../../../components/Common/FormInfoText";

const STATE_FOOTNOTE =
  "[1] State is only checked for devices connected to new JE938 modems";

const DeviceTableWrapper = styled.div`
  margin: 0 6.25% 1rem;
`;

const triggerStateCheckboxes = possibleDeviceErrorTaskStates.map((state) => ({
  text: triggerStateToFormString[state],
  value: state.toString(),
}));

const FormWrapper = styled(FormWrapperBase)`
  #emailRecipients {
    height: unset;
    padding: 0.7rem;
  }
`;

type UpdateFormData = NonNullable<
  ReturnType<typeof useDeviceErrorTaskCreateFormData>["data"]
>;

export type ErrorTaskFormValues = {
  name: string;
  sharedForAllDevices: boolean;
  includeChildGroupDevices: boolean;
  sharedTriggerStates: DeviceErrorTaskStates[];
  deviceTriggerStates: UpdateFormData["deviceTriggerStates"];
  emailTexts: { [K in DeviceErrorTaskStates]: string };
  emailRecipients: string;
  errorThresholdSeconds: {
    [K in DeviceErrorTaskStates]: number | null;
  };
};
export type ErrorTaskFormProps = {
  initialValues: ErrorTaskFormValues;
  groupName: string;
  submitText: string;
  onSubmit: (values: ErrorTaskFormValues, stopSubmitting: () => void) => void;
  onBackClick: () => void;
};
export function ErrorTaskForm(props: ErrorTaskFormProps) {
  return (
    <Formik<ErrorTaskFormValues>
      initialValues={props.initialValues}
      onSubmit={(values, helpers) => {
        props.onSubmit(values, () => helpers.setSubmitting(false));
      }}
      validationSchema={BaseFormSchema}
    >
      {({
        values,
        touched,
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        isSubmitting,
      }) => (
        <FormWrapper>
          <InputBox>
            <FormLabel>Group</FormLabel>
            <FormValue>{props.groupName}</FormValue>
          </InputBox>
          <FormInput
            id="name"
            required
            type=""
            label="Name"
            error={
              touched.name && errors.name
                ? getValidationErrorText(errors.name)
                : ""
            }
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <FormToggle
            id="sharedForAllDevices"
            label="Shared for all devices"
            onChange={setFieldValue}
            toggleText={values.sharedForAllDevices ? "Yes" : "No"}
            selected={values.sharedForAllDevices}
            toggleTextColor="#ffffff"
          />
          <FormToggle
            id="includeChildGroupDevices"
            label="Include devices from child groups"
            onChange={setFieldValue}
            toggleText={values.includeChildGroupDevices ? "Yes" : "No"}
            selected={values.includeChildGroupDevices}
            toggleTextColor="#ffffff"
          />
          {values.sharedForAllDevices ? (
            <>
              <CheckboxGroup
                id="sharedTriggerStates"
                label="Trigger states"
                checkboxes={triggerStateCheckboxes}
                error={
                  touched.sharedTriggerStates &&
                  typeof errors.sharedTriggerStates === "string"
                    ? getValidationErrorText(errors.sharedTriggerStates)
                    : ""
                }
              />
              <FormNote>{STATE_FOOTNOTE}</FormNote>
            </>
          ) : (
            <>
              <Divider />
              <FormInfoText>Configure triggers for each device</FormInfoText>
              <DeviceTableWrapper>
                <ErrorTaskDeviceTable
                  devices={values.deviceTriggerStates}
                  includeChildGroupDevices={values.includeChildGroupDevices}
                  onToggle={(devId, state, value) => {
                    setFieldValue(
                      "deviceTriggerStates",
                      values.deviceTriggerStates.map((devStates) =>
                        devStates.id === devId
                          ? {
                              ...devStates,
                              triggerStates: devStates.triggerStates.set(
                                state,
                                value
                              ),
                            }
                          : devStates
                      )
                    );
                  }}
                />
              </DeviceTableWrapper>
              <FormNote>{STATE_FOOTNOTE}</FormNote>
              <Divider />
            </>
          )}
          <FormInput
            id="emailRecipients"
            type=""
            component="textarea"
            componentProps={{ rows: 5 }}
            label="Email recipients (one per line)"
            error={
              touched.emailRecipients && errors.emailRecipients
                ? getValidationErrorText(errors.emailRecipients)
                : ""
            }
            value={values.emailRecipients}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Divider />
          <FormInfoText>Trigger state email descriptions</FormInfoText>
          {possibleDeviceErrorTaskStates.map((state) => (
            <FormInput
              id={`triggerState_${state}`}
              key={state.toString()}
              required
              type=""
              label={`${triggerStateToFormString[state]}`}
              error={
                touched.emailTexts?.[state] &&
                typeof errors.emailTexts?.[state] === "string"
                  ? getValidationErrorText(errors.emailTexts[state])
                  : ""
              }
              value={values.emailTexts[state]}
              onChange={(event: any) =>
                setFieldValue("emailTexts", {
                  ...values.emailTexts,
                  [state]: event.target.value,
                })
              }
              onBlur={handleBlur}
            />
          ))}
          <FormNote>{STATE_FOOTNOTE}</FormNote>
          <Divider />
          <FormInfoText>Error state trigger threshold (seconds)</FormInfoText>
          {possibleDeviceErrorTaskStates.map((state) => (
            <FormInput
              id={`errorThreshold_${state}`}
              key={state.toString()}
              type="number"
              label={`${triggerStateToFormString[state]}`}
              error={""}
              value={values.errorThresholdSeconds[state] ?? ""}
              onChange={(event: any) =>
                setFieldValue("errorThresholdSeconds", {
                  ...values.errorThresholdSeconds,
                  [state]:
                    event.target.value === ""
                      ? null
                      : parseInt(event.target.value),
                })
              }
              onBlur={handleBlur}
            />
          ))}
          <FormNote>{STATE_FOOTNOTE}</FormNote>
          <FlexBoxWrapper>
            <FormSubmitButton
              type="button"
              onClick={() => {
                props.onBackClick();
              }}
            >
              Back
            </FormSubmitButton>
            &nbsp;
            <FormSubmitButton type="submit" disabled={isSubmitting}>
              {props.submitText}
            </FormSubmitButton>
          </FlexBoxWrapper>
        </FormWrapper>
      )}
    </Formik>
  );
}
