import { Formik } from "formik";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { FlexBoxWrapper } from "../../../../components/Common";
import { FormSubmitButton } from "../../../../components/Common/FormSubmitButton";
import { FormWrapper } from "../../../../components/Common/FormWrapper";
import { groupHierarchiesSelector } from "../../../../selectors/groups";
import { findGroupById } from "../../../../utils/group";
import { GroupSelect } from "../../GroupSelect";

interface SelectGroupFormProps {
  onSelect: (group: { id: number; name: string }) => void;
  disabledGroupIds: Set<number>;
}
interface SelectGroupFormValues {
  groupId: number | null;
  groupName: string | null;
}
const SelectGroupFormSchema = Yup.object().shape({
  groupId: Yup.mixed().required("Group needs to be selected"),
});

export function SelectGroupForm(props: SelectGroupFormProps) {
  const groupHierarchy = useSelector(groupHierarchiesSelector);
  const history = useHistory();

  return (
    <Formik<SelectGroupFormValues>
      initialValues={{
        groupId: null,
        groupName: null,
      }}
      onSubmit={(values) =>
        values.groupId !== null
          ? props.onSelect({
              id: values.groupId,
              name: findGroupById(groupHierarchy, values.groupId)?.name ?? "",
            })
          : undefined
      }
      validationSchema={SelectGroupFormSchema}
    >
      {({ values, setFieldValue, errors, touched }) => (
        <FormWrapper>
          <GroupSelect
            id="groupId"
            label="Group (groups that already have an error task are disabled)"
            required
            value={values.groupId}
            onChange={setFieldValue}
            error={
              touched.groupId && errors.groupId ? errors.groupId : undefined
            }
            options={groupHierarchy}
            disabledGroup={(group) => props.disabledGroupIds.has(group.groupId)}
          />
          <FlexBoxWrapper>
            <FormSubmitButton onClick={() => history.goBack()}>
              Back
            </FormSubmitButton>
            &nbsp;
            <FormSubmitButton type="submit">Next</FormSubmitButton>
          </FlexBoxWrapper>
        </FormWrapper>
      )}
    </Formik>
  );
}
