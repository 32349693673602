import styled from "styled-components";
import {
  FormInput,
  FieldArrayFormInput,
  FormLabel,
  FormToggle,
  FormValue,
  InputBox,
} from "../Common/FormInput";
import { FlexBoxWrapper } from "../Common";
import { FormSubmitButton } from "../Common/FormSubmitButton";
import { Spinner } from "../Spinner";
import { FieldArray, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as TYPES from "../../constants/actionTypes";
import { DeviceDetailsSchema, BaleCounterSchema } from "./DeviceDetailsSchema";
import { getValidationErrorText } from "../../utils/i18nUtils";
import { isEmpty, isNil } from "lodash";
import { DeviceType, MachineType } from "../../interfaces/types";
import {
  getEndUserWarningsAndErrors,
  getEuropressWarningsAndErrors,
} from "../../utils/deviceStatus";
import { DarkButton } from "../Common/Button";
import * as deviceApi from "../../api/devices";
import { NotifyError, NotifySuccess } from "../Common/Notify";
import { InfoTooltip } from "../Common/InfoTooltip";
import { DeviceImageCarousel } from "../DeviceImageCarousel";
import { useState } from "react";

interface DeviceDetailsProps {
  device: DeviceType;
  handleClose: () => void;
  isEuropressUser: boolean;
  admin: boolean;
}

const StatusValue = styled(FormValue)`
  height: unset;
  color: #000;
`;

const SectionWrapper = styled.div`
  margin: 2rem 0;
`;

const SectionTitle = styled.span`
  font-size: 1.125rem;
  font-family: "Roboto";
  text-transform: uppercase;
`;

const SectionTitleWrapper = styled.div`
  margin: 0 10% 1rem 10%;
  display: flex;
  align-items: center;
`;

const AddEmailButton = styled(DarkButton)`
  margin: 0.4rem 12.5% 1.6rem;
`;

const FormValueDark = styled(FormValue)`
  color: #000;
  height: initial;
  margin-bottom: 0;
`;

const DeviceImages = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DetailsContainer = styled.div`
  margin: 0 12.5%;
  display: flex;
  align-items: center;

  ${DeviceImages}, form {
    width: 50%;
  }

  ${DeviceImages} {
    margin-left: 1rem;
  }

  form {
    margin-right: 1rem;
  }
`;

function Section(props: {
  title: string;
  children: React.ReactNode;
  infoTooltipText?: string;
}) {
  return (
    <SectionWrapper>
      <SectionTitleWrapper>
        <SectionTitle>{props.title}</SectionTitle>
        {props.infoTooltipText && <InfoTooltip text={props.infoTooltipText} />}
      </SectionTitleWrapper>
      {props.children}
    </SectionWrapper>
  );
}

function DetailsForm(props: DeviceDetailsProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const token = useSelector((state: any) => state.token.key);

  const initialValues = {
    alias: props.device.alias ? props.device.alias : "",
    homeLatitude: props.device.homeLatitude ? props.device.homeLatitude : "",
    homeLongitude: props.device.homeLongitude ? props.device.homeLongitude : "",
    latitude: props.device.latitude,
    longitude: props.device.longitude,
  };

  const showHomeLatLngField: boolean =
    isNil(props.device.latitude) && isNil(props.device.longitude);

  const onSubmit = async (values: typeof initialValues) => {
    let device: any = {
      serialNumber: props.device.serialNumber,
      alias: values.alias,
    };
    if (showHomeLatLngField) {
      device.homeLatitude = values.homeLatitude;
      device.homeLongitude = values.homeLongitude;
    }

    try {
      const result = await deviceApi.postDeviceDetail({
        payload: {
          token,
          device,
        },
      });
      dispatch({
        type: TYPES.PATCH_DEVICE_DETAIL_SUCCESS,
        payload: { ...result.data },
      });
    } catch (e) {
      NotifyError("DEFAULT_ERROR");
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={DeviceDetailsSchema}
    >
      {({
        errors,
        values,
        touched,
        handleChange,
        handleBlur,
        isSubmitting,
      }) => (
        <Form>
          <FormInput
            id="alias"
            type="text"
            label={t("deviceModal.deviceDetails.alias.label", "Alias")}
            error={touched.alias ? errors.alias : undefined}
            value={values.alias}
            onChange={handleChange}
            onBlur={handleBlur}
            labelColor={"#000"}
            noHorizontalMargin
          />
          {showHomeLatLngField && (
            <>
              <FormInput
                id="homeLatitude"
                type="text"
                label={t(
                  "deviceModal.deviceDetails.homeLatitude.label",
                  "Latitude"
                )}
                error={
                  touched.homeLatitude
                    ? getValidationErrorText(errors.homeLatitude)
                    : undefined
                }
                value={values.homeLatitude}
                onChange={handleChange}
                onBlur={handleBlur}
                labelColor={"#000"}
                noHorizontalMargin
              />
              <FormInput
                id="homeLongitude"
                type="text"
                label={t(
                  "deviceModal.deviceDetails.homeLongitude.label",
                  "Longitude"
                )}
                error={
                  touched.homeLongitude
                    ? getValidationErrorText(errors.homeLongitude)
                    : undefined
                }
                value={values.homeLongitude}
                onChange={handleChange}
                onBlur={handleBlur}
                labelColor={"#000"}
                noHorizontalMargin
              />
            </>
          )}
          {!showHomeLatLngField && (
            <>
              <FormInput
                id="latitude"
                type="text"
                label={t(
                  "deviceModal.deviceDetails.latitude.label",
                  "Latitude (GPS)"
                )}
                error={touched.latitude ? errors.latitude : undefined}
                value={values.latitude!}
                onChange={handleChange}
                onBlur={handleBlur}
                labelColor={"#000"}
                noHorizontalMargin
                disabled
              />
              <FormInput
                id="longitude"
                type="text"
                label={t(
                  "deviceModal.deviceDetails.longitude.label",
                  "Longitude (GPS)"
                )}
                error={touched.longitude ? errors.longitude : undefined}
                value={values.longitude!}
                onChange={handleChange}
                onBlur={handleBlur}
                labelColor={"#000"}
                noHorizontalMargin
                disabled
              />
            </>
          )}
          <FlexBoxWrapper marginTop={20}>
            <FormSubmitButton
              type="submit"
              disabled={!isEmpty(errors) || isSubmitting}
            >
              {isSubmitting ? (
                <Spinner height="100%" />
              ) : (
                t("common.save", "Save")
              )}
            </FormSubmitButton>
          </FlexBoxWrapper>
        </Form>
      )}
    </Formik>
  );
}

function StatusTexts(props: DeviceDetailsProps) {
  const { t } = useTranslation();
  const statusTexts = props.isEuropressUser
    ? getEuropressWarningsAndErrors(props.device)
    : getEndUserWarningsAndErrors(props.device);

  return 0 < statusTexts.length ? (
    <InputBox>
      {statusTexts.map((text) => (
        <StatusValue key={text}>{text}</StatusValue>
      ))}
    </InputBox>
  ) : (
    <FormValueDark>
      {t(
        "deviceModal.deviceDetails.statusTexts.noErrors",
        "No errors or warnings"
      )}
    </FormValueDark>
  );
}

function BaleCounterForm(props: DeviceDetailsProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const token = useSelector((state: any) => state.token.key);

  const initialValues = {
    useBaleCounter: props.device.baleCounter !== undefined,
    pickupLimit: props.device.baleCounter?.pickupLimit ?? 5,
    emailRecipients: props.device.baleCounter?.emailRecipients ?? [],
  };

  const onSubmit = async (values: typeof initialValues) => {
    const counter = {
      pickupLimit: values.pickupLimit,
      emailRecipients: values.emailRecipients.filter(
        (recipient) => 0 < recipient.length
      ),
    };

    try {
      if (values.useBaleCounter) {
        await deviceApi.putBaleCounter({
          payload: {
            token,
            serialNumber: props.device.serialNumber,
            counter,
          },
        });
      } else {
        await deviceApi.deleteBaleCounter({
          payload: { token, serialNumber: props.device.serialNumber },
        });
      }
    } catch (e) {
      NotifyError("DEFAULT_ERROR");
    }

    const updatedBaleCounter = values.useBaleCounter
      ? {
          ...counter,
          balesReady: props.device.baleCounter?.balesReady ?? null,
        }
      : undefined;
    dispatch({
      type: TYPES.UPDATE_BALE_COUNTER,
      payload: {
        serialNumber: props.device.serialNumber,
        baleCounter: updatedBaleCounter,
      },
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={BaleCounterSchema}
    >
      {({
        errors,
        values,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
        isSubmitting,
      }) => (
        <Form>
          <FormToggle
            id="useBaleCounter"
            label={t("deviceModal.deviceDetails.baleCounter.status", "Status")}
            toggleText={
              values.useBaleCounter
                ? t("deviceModal.deviceDetails.baleCounter.enabled", "Enabled")
                : t(
                    "deviceModal.deviceDetails.baleCounter.disabled",
                    "Disabled"
                  )
            }
            onChange={setFieldValue}
            selected={values.useBaleCounter}
            labelColor="#000000"
          />
          {values.useBaleCounter && (
            <>
              <FormInput
                id="pickupLimit"
                type="number"
                label={t(
                  "deviceModal.deviceDetails.baleCounter.pickupLimit.label",
                  "Pickup limit"
                )}
                labelInfoTooltip={t(
                  "deviceModal.deviceDetails.baleCounter.pickupLimit.info",
                  "Once this number of bales is produced, emails and mobile notifications are sent, and the counter is reset."
                )}
                error={
                  touched.pickupLimit
                    ? getValidationErrorText(errors.pickupLimit)
                    : undefined
                }
                value={values.pickupLimit}
                onChange={handleChange}
                onBlur={handleBlur}
                labelColor={"#000"}
              />
              <FieldArray name="emailRecipients">
                {({ push, remove }) => (
                  <div>
                    <FormLabel color="#000">
                      {t(
                        "deviceModal.deviceDetails.baleCounter.emailRecipients.label",
                        "Email recipients"
                      )}
                    </FormLabel>
                    {values.emailRecipients.length === 0 ? (
                      <FormValueDark>
                        {t(
                          "deviceModal.deviceDetails.baleCounter.emailRecipients.noRecipients",
                          "No email recipients"
                        )}
                      </FormValueDark>
                    ) : (
                      values.emailRecipients.map((recipient, idx) => (
                        <div key={idx}>
                          <FieldArrayFormInput
                            id={`emailRecipients[${idx}]`}
                            type="string"
                            value={recipient}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            buttonProps={{
                              onClick: () => remove(idx),
                              type: "button",
                              text: t("common.remove", "Remove"),
                            }}
                          />
                        </div>
                      ))
                    )}
                    <AddEmailButton type="button" onClick={() => push("")}>
                      {t("common.add", "Add")}
                    </AddEmailButton>
                  </div>
                )}
              </FieldArray>
              <InputBox>
                <FormLabel color="#000">
                  {t(
                    "deviceModal.deviceDetails.baleCounter.mobileNotifications.label",
                    "Mobile notifications"
                  )}
                </FormLabel>
                <FormValueDark>
                  {t(
                    "deviceModal.deviceDetails.baleCounter.mobileNotifications.text",
                    "Notifications are automatically sent to users who have enabled bale counter notifications from the SMARTwaste application."
                  )}
                </FormValueDark>
              </InputBox>
            </>
          )}
          <FlexBoxWrapper marginTop={20}>
            <FormSubmitButton
              type="submit"
              disabled={!isEmpty(errors) || isSubmitting}
            >
              {isSubmitting ? (
                <Spinner height="100%" />
              ) : (
                t("common.save", "Save")
              )}
            </FormSubmitButton>
          </FlexBoxWrapper>
        </Form>
      )}
    </Formik>
  );
}

export function DeviceDetails(props: DeviceDetailsProps) {
  const { t } = useTranslation();
  const token = useSelector((state: any) => state.token.key);
  const dispatch = useDispatch();

  const [fillLevelMode, setFillLevelMode] = useState(
    props.device?.fillLevelMode
  );

  if (!props.device) {
    return null;
  }

  const changeFillLevelMode = async () => {
    if (fillLevelMode === 0) {
      try {
        const serialNumber = props.device?.serialNumber;
        const result = await deviceApi.patchFillLevelMode({
          payload: {
            token,
            serialNumber,
            fillLevelMode: 1,
          },
        });
        dispatch({
          type: TYPES.PATCH_DEVICE_FILL_LEVEL_MODE_SUCCESS,
          payload: { ...result.data, serialNumber },
        });
        NotifySuccess(TYPES.PATCH_DEVICE_FILL_LEVEL_MODE_SUCCESS);
        setFillLevelMode(1);
      } catch (e) {
        NotifyError("DEFAULT_ERROR");
      }
    } else {
      try {
        const serialNumber = props.device?.serialNumber;
        const result = await deviceApi.patchFillLevelMode({
          payload: {
            token,
            serialNumber,
            fillLevelMode: 0,
          },
        });
        dispatch({
          type: TYPES.PATCH_DEVICE_FILL_LEVEL_MODE_SUCCESS,
          payload: { ...result.data, serialNumber },
        });
        NotifySuccess(TYPES.PATCH_DEVICE_FILL_LEVEL_MODE_SUCCESS);
        setFillLevelMode(0);
      } catch (e) {
        NotifyError("DEFAULT_ERROR");
      }
    }
  };

  return (
    <div>
      <Section
        title={t(
          "deviceModal.deviceDetails.statusTexts.sectionTitle",
          "Current status"
        )}
      >
        <StatusTexts {...props} />
      </Section>
      <Section
        title={t(
          "deviceModal.deviceDetails.basicDetails.sectionTitle",
          "Basic details"
        )}
      >
        <DetailsContainer>
          <DetailsForm {...props} />
          <DeviceImages>
            <DeviceImageCarousel
              serialNumber={props.device.serialNumber}
              token={token}
              width="15rem"
              height="12.5rem"
            />
          </DeviceImages>
        </DetailsContainer>
      </Section>
      {(props.device.machineType === MachineType.Baler ||
        props.device.machineType === MachineType.PullingBaler) && (
        <Section
          title={t(
            "deviceModal.deviceDetails.baleCounter.sectionTitle",
            "Bale counter"
          )}
          infoTooltipText={t(
            "deviceModal.deviceDetails.baleCounter.sectionInfo",
            "Configure mobile notifications and emails after a specified number of bales has been produced."
          )}
        >
          <BaleCounterForm {...props} />
        </Section>
      )}
      {props.admin && props.device?.pressureCurveFillPercent !== null && (
        <SectionWrapper>
          <FormToggle
            id="fillLevelToddle"
            label={"Show pressure curve based fill level percent"}
            labelColor={"#000"}
            labelInfoTooltip={`Show pressure curve based fill level percent.

This is only visible for admin users.`}
            onChange={changeFillLevelMode}
            selected={fillLevelMode === 1}
          />
        </SectionWrapper>
      )}
    </div>
  );
}
