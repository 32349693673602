import { Component } from "react";
import styled from "styled-components";

import { Checkbox } from "./Checkbox";
import {
  DeviceStatus,
  FillState,
  FilterPopupType,
  MachineType,
} from "../interfaces/types";
import { fillLevelToString, deviceColumnToString } from "../utils/utils";

import icon_status_ok from "../assets/icon-status-ok.svg";
import icon_status_warning from "../assets/icon-status-warning.svg";
import icon_status_error from "../assets/icon-status-error.svg";
import icon_status_intransit from "../assets/icon-status-intransit.svg";
import icon_power_off from "../assets/icon-status-off-selected.svg";
import { withTranslation } from "react-i18next";

const Item = styled.div`
  display: flex;
  align-items: center;
  margin: 0 26px;
`;

interface CellProps {
  marginLeft?: string;
}
const Cell = styled.div<CellProps>`
  margin-left: ${(props) => props.marginLeft};
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 3.43;
  color: var(--col-1-c-2020);
  white-space: nowrap;
`;

const Icon = styled.img`
  object-fit: contain;
  width: 26px;
  height: 26px;
  margin: 0 13px 0 13px;
`;

const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  border-radius: 12px;
  background-color: var(--twilight-blue);
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 3.33;
  letter-spacing: normal;
  color: #fafbfc;
`;

const FillLevelRect = styled.div`
  width: 108px;
  height: 24px;
  border-radius: 12px;
  border: solid 1px #e9eef3;
  background-color: #ffffff;
`;

interface FillLevelProps {
  width?: number;
  background?: string;
}

const FillLevel = styled.div<FillLevelProps>`
  width: ${(props) => props.width}%;
  height: 100%;
  border-radius: 12px;
  background-color: ${(props) => props.background};
`;
class FilterItemComponent extends Component<any, any> {
  public handleCheckboxChange = (event: any) => {
    this.props.onSelection(this.props.item, event.target.checked);
  };
  private getDeviceDataColumn(): JSX.Element {
    let columnName = deviceColumnToString(this.props.item);
    columnName = columnName
      .toLowerCase()
      .split(" ")
      .map((s: string) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");
    return <Cell marginLeft="26px">{columnName}</Cell>;
  }
  private getStatus(): JSX.Element {
    const { t } = this.props;

    if (this.props.item === DeviceStatus.OK) {
      return (
        <>
          <Icon src={icon_status_ok} />
          <Cell>{t("filterItem.status.ok", "OK")}</Cell>
        </>
      );
    } else if (this.props.item === DeviceStatus.InTransit) {
      return (
        <>
          <Icon src={icon_status_intransit} />
          <Cell>{t("filterItem.status.inTransit", "In transit")}</Cell>
        </>
      );
    } else if (this.props.item === DeviceStatus.PowerOff) {
      return (
        <>
          <Icon src={icon_power_off} />
          <Cell>{t("filterItem.status.off", "Off")}</Cell>
        </>
      );
    } else if (this.props.item === DeviceStatus.Warning) {
      return (
        <>
          <Icon src={icon_status_warning} />
          <Cell>{t("filterItem.status.warning", "Warning")}</Cell>
        </>
      );
    } else if (this.props.item === DeviceStatus.Error) {
      return (
        <>
          <Icon src={icon_status_error} />
          <Cell>{t("filterItem.status.error", "Error")}</Cell>
        </>
      );
    }
    return <></>;
  }
  private getFillLevelRect(
    state: FillState,
    color: string | undefined,
    width: number | undefined
  ): JSX.Element {
    return (
      <FillLevelRect>
        <FillLevel width={width} background={color}>
          <Circle>{state}</Circle>
        </FillLevel>
      </FillLevelRect>
    );
  }
  private getFillLevelIcon(fillLevel: number | undefined): JSX.Element {
    switch (fillLevel) {
      case FillState.Empty: {
        return this.getFillLevelRect(FillState.Empty, undefined, undefined);
      }
      case FillState.Level_1: {
        return this.getFillLevelRect(FillState.Level_1, "#bbf0c8", 42);
      }
      case FillState.Level_2: {
        return this.getFillLevelRect(FillState.Level_2, "#28d754", 60);
      }
      case FillState.AlmostFull: {
        return (
          <FillLevelRect>
            <FillLevel width={88} background={"#13862e"}>
              <Circle>3</Circle>
            </FillLevel>
          </FillLevelRect>
        );
      }
      case FillState.Full: {
        return (
          <FillLevelRect>
            <FillLevel width={100} background={"#ffe000"}>
              <Circle>F</Circle>
            </FillLevel>
          </FillLevelRect>
        );
      }
      case FillState.OverFull: {
        // Overfull
        return (
          <FillLevelRect>
            <FillLevel width={100} background={"#f51557"}>
              <Circle>X</Circle>
            </FillLevel>
          </FillLevelRect>
        );
      }
      default: {
        // Unknown
        return (
          <FillLevelRect>
            <FillLevel width={100} background={"#ffffff"}>
              <Circle>?</Circle>
            </FillLevel>
          </FillLevelRect>
        );
      }
    }
  }
  private getFillLevelItem(): JSX.Element {
    return (
      <>
        <Cell marginLeft="20px">{this.getFillLevelIcon(this.props.item)}</Cell>
        <Cell marginLeft="20px">{fillLevelToString(this.props.item)}</Cell>
      </>
    );
  }
  private getMachineTypeCell(machineType: MachineType) {
    const { t } = this.props;

    let text = "";
    if (machineType === MachineType.Compactor) {
      text = t("filterItem.machineType.compactor", "Compactor");
    } else if (
      machineType === MachineType.Baler ||
      machineType === MachineType.PullingBaler
    ) {
      text = t("filterItem.machineType.baler", "Baler");
    }

    return <Cell marginLeft="0.8rem">{text}</Cell>;
  }
  private getItemContent() {
    if (this.props.type === FilterPopupType.STATUS) {
      return this.getStatus();
    } else if (this.props.type === FilterPopupType.FILL_LEVEL) {
      return this.getFillLevelItem();
    } else if (
      [
        FilterPopupType.EDIT_COLUMNS,
        FilterPopupType.EDIT_SKIP_COLUMNS,
      ].includes(this.props.type)
    ) {
      return this.getDeviceDataColumn();
    } else if (this.props.type === FilterPopupType.MACHINE_TYPE) {
      return this.getMachineTypeCell(this.props.item);
    }
    return null;
  }

  public render(): JSX.Element {
    return (
      <Item>
        <label>
          <Checkbox
            checked={this.props.checked}
            onChange={this.handleCheckboxChange}
          ></Checkbox>
        </label>
        {this.getItemContent()}
      </Item>
    );
  }
}

export const FilterItem = withTranslation()(FilterItemComponent);
