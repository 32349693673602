import styled from "styled-components";
import { useTranslation } from "react-i18next";

interface TextProps {
  textColor?: string;
}
const Text = styled.span<TextProps>`
  color: ${(props) => (props.textColor ? props.textColor : "black")};
`;
/**
 *  Function component for showing rental period text for containers
 * @param device is skip which rental period is wanted to show
 */
export const RentalPeriod = (device: any): JSX.Element => {
  const { t } = useTranslation();

  let startDate: string = "";
  let separator: string = "";
  if (device.rentalStartDate) {
    startDate = new Date(device.rentalStartDate)
      .toLocaleDateString()
      .slice(0, 10);
    separator = "-";
  }
  let endDate: string = "";
  let textColor: string = "black";
  if (device.rentalEndDate) {
    const d = new Date(device.rentalEndDate);
    endDate = d.toLocaleDateString().slice(0, 10);
    if (d.getTime() < Date.now()) {
      textColor = "red";
    }
    separator = "-";
  }
  const label = device.showText
    ? `${t("rentalPeriod.title", "Rental Period")}: `
    : undefined;
  return (
    <>
      {label}
      <Text textColor={textColor}>
        {startDate}
        {separator}
        {endDate}
      </Text>
    </>
  );
};
