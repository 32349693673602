import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "../Common/Modal";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { DeviceDetails } from "./DeviceDetails";
import { StatusIcon } from "../Common/StatusIcon";
import { HistoryDetails } from "./HistoryDetails";
import { ServiceRequests } from "./ServiceRequests";
import { ScaleDetails } from "./ScaleDetails";
import { DataAnalytics } from "./DataAnalytics";
import { Tab } from "../Common/Tab";
import { getPrimaryStatus } from "../../utils/deviceStatus";

interface DeviceModalProps {
  deviceOpen: boolean;
  device: any;
  activeTab: TabType;
  handleCloseDevice: () => void;
  user: { groups: string[]; admin: boolean };
}

const TitleText = styled.span`
  padding-right: 16px;
`;

export enum TabType {
  DEVICE_DETAILS = 0,
  HISTORY_DETAILS = 1,
  DATA_ANALYTICS = 2,
  SERVICE_REQUESTS = 3,
  SCALE_DETAILS = 4,
}

export function DeviceModal(props: DeviceModalProps) {
  const [selectedTab, setSelectedTab] = useState(TabType.DEVICE_DETAILS);
  useEffect(() => {
    setSelectedTab(props.activeTab);
  }, [props.activeTab, props.deviceOpen]);

  const { t } = useTranslation();

  const isEuropressUser = props.user.groups.includes("Europress");
  let tabs = [
    {
      title: t("deviceModal.tabs.deviceDetails", "Device details"),
      type: TabType.DEVICE_DETAILS,
    },
    {
      title: t("deviceModal.tabs.dataAnalytics", "Data analytics"),
      type: TabType.DATA_ANALYTICS,
    },
    {
      title: t("deviceModal.tabs.historyDetails", "History details"),
      type: TabType.HISTORY_DETAILS,
    },
  ];

  if (props.user.admin || props.device?.scaleTotalWeightEmptyingLimit > 0) {
    tabs.push({
      title: t("deviceModal.tabs.scaleDetails", "Scale details"),
      type: TabType.SCALE_DETAILS,
    });
  }

  if (process.env.REACT_APP_DISABLE_NON_FUNCTIONAL === "false") {
    tabs.push({
      title: t("deviceModal.tabs.serviceRequests", "Service requests"),
      type: TabType.SERVICE_REQUESTS,
    });
  }

  const changeTab = (index: TabType) => () => setSelectedTab(index);

  const navigation: JSX.Element = (
    <>
      {tabs.map((tab: any) => {
        return (
          <Tab
            key={uuidv4()}
            selected={selectedTab === tab.type}
            onClick={changeTab(tab.type)}
          >
            {tab.title}
          </Tab>
        );
      })}
    </>
  );

  const content = (): JSX.Element => {
    switch (selectedTab) {
      case TabType.DEVICE_DETAILS:
        return (
          <DeviceDetails
            device={props.device}
            handleClose={props.handleCloseDevice}
            isEuropressUser={isEuropressUser}
            admin={props.user.admin}
          />
        );
      case TabType.HISTORY_DETAILS:
        return <HistoryDetails device={props.device} />;
      case TabType.DATA_ANALYTICS:
        return <DataAnalytics device={props.device} />;
      case TabType.SERVICE_REQUESTS:
        return <ServiceRequests device={props.device} />;
      case TabType.SCALE_DETAILS:
        return <ScaleDetails device={props.device} admin={props.user.admin} />;
      default:
        return <></>;
    }
  };

  const title = (): JSX.Element | string => {
    if (!props.device) {
      return "No device";
    }

    const status = getPrimaryStatus(props.device);
    return (
      <>
        <TitleText>
          {t("header.userMenu.deviceModal.header", "Device")}{" "}
          {props.device.serialNumber}
        </TitleText>
        <StatusIcon
          deviceStatus={status}
          machineType={props.device.machineType}
        />
      </>
    );
  };

  const getContentPadding = (): number | undefined => {
    return selectedTab === TabType.DATA_ANALYTICS ? 0 : undefined;
  };

  return (
    <Modal
      isOpen={props.deviceOpen}
      handleClose={props.handleCloseDevice}
      content={content()}
      title={title()}
      navigation={navigation}
      contentPadding={getContentPadding()}
      fullScreenEnabled={true}
    />
  );
}
