import { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import * as TYPES from "../constants/actionTypes";
import { Icon } from "./Common";

import icon_toggle_on from "../assets/icon-toggle-on.svg";
import icon_toggle_off from "../assets/icon-toggle-off.svg";
import { withTranslation } from "react-i18next";

const Title = styled.div`
  font-family: Poppins;
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--col-1-c-2020);

  margin-left: 10px;
  margin-right: 20px;
  @media (min-width: 768px) {
  }
`;
const mapDispatchToProps = (dispatch: any) => {
  return {
    cluster: (cluster: boolean) => {
      dispatch({
        type: TYPES.CLUSTER_DEVICES_ON_MAP,
        payload: { cluster: cluster },
      });
    },
  };
};

const mapStateToProps = (state: any) => {
  return {
    clusterDevices: state.devices.clusterDevices,
  };
};
class ClusterDevicesConnected extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      clusterDevices: true,
    };
  }
  private onToggle = (e: any) => {
    e.preventDefault();
    const toggled = !this.state.clusterDevices;
    this.setState({ clusterDevices: toggled });
    this.props.cluster(toggled);
  };
  public componentDidMount(): void {
    if (this.props.clusterDevices !== this.state.clusterDevices) {
      this.setState({
        ...this.state,
        clusterDevices: this.props.clusterDevices,
      });
    }
  }
  public render(): JSX.Element {
    const buttonStyle = {
      cursor: "pointer",
      outline: "none",
    };

    const { t } = this.props;

    return (
      <>
        <Icon
          src={this.state.clusterDevices ? icon_toggle_on : icon_toggle_off}
          width={36}
          height={24}
          marginLeft={30}
          alt="toggle button"
          style={buttonStyle}
          onClick={this.onToggle}
        />
        <Title>{t("common.groupDevices", "Group Devices")}</Title>
      </>
    );
  }
}

export const ClusterDevices = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ClusterDevicesConnected));
