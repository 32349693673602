import { useState } from "react";
import * as TYPES from "../constants/actionTypes";
import styled from "styled-components";

import icon_alias from "../assets/icon-alias.svg";
import icon_arrow from "../assets/icon-arrow-down.svg";
import { Icon } from "./Common/Icon";
import { useSelector, useDispatch } from "react-redux";

import { useTranslation } from "react-i18next";
import { AboutModal } from "./AboutModal";
import { ProfileModal } from "./ProfileModal";
import { PrivacyStatementModal } from "./PrivacyStatementModal";

const UserElementStyled = styled.div`
  display: flex;
  padding: 12px 0px 12px 30px;
`;

const Block = styled.div`
  display: block;
`;

const Wrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  cursor: pointer;

  margin-right: 5px;
  margin-bottom: 2px;
  @media (min-width: 768px) {
    margin-right: 22px;
    margin-left: auto;
  }
`;

const DropDownContent = styled.div<any>`
  display: ${(props) => (props.show ? "block" : "none")};
  position: absolute;
  background-color: var(--col-214269);
  width: auto;
  box-shadow: 0px 8px 16px -7px rgba(0, 0, 0, 0.2);
  z-index: 80;
  padding-right: 6px;

  @media (min-width: 768px) {
    width: 100%;
  }

  a,
  span {
    color: #ffffff;
    padding: 12px 24px;
    text-decoration: none;
    display: block;
    text-align: right;

    &:hover {
      background-color: var(--twilight-blue);
    }
  }

  hr {
    margin-right: 24px;
    margin-left: 30px;
    margin-top: 0px;
    margin-bottom: 0px;
    background-color: var(--denim);
    height: 2px;
    border: 0;
  }
`;

export function UserElement() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [aboutOpen, setAboutOpen] = useState(false);
  const [profileOpen, setProfileOpen] = useState(false);
  const [privacyStatementOpen, setPrivacyStatementOpen] = useState(false);
  const user = useSelector((state: any) => state.users.userName);
  const token = useSelector((state: any) => state.token.key);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleLogoutClick = () => {
    dispatch({ type: TYPES.LOGOUT, payload: { user: user, token: token } });
  };

  const handleAboutClick = () => {
    setMenuOpen(false);
    setAboutOpen(true);
  };

  const handleProfileClick = () => {
    setMenuOpen(false);
    setProfileOpen(true);
  };

  const handlePrivacyStatementClick = () => {
    setMenuOpen(false);
    setPrivacyStatementOpen(true);
  };

  const handleCloseAbout = () => {
    setAboutOpen(false);
  };

  const handleCloseProfile = () => {
    setProfileOpen(false);
  };

  const handleClosePrivacyStatement = () => {
    setPrivacyStatementOpen(false);
  };

  const handleChangeLanguage = (language: string) => {
    setProfileOpen(false);
    dispatch({
      type: TYPES.CHANGE_LANGUAGE,
      payload: { data: { language }, token, user },
    });
  };

  return (
    <Wrapper>
      <Block>
        <UserElementStyled onClick={() => setMenuOpen((menuOpen) => !menuOpen)}>
          <Icon
            src={icon_alias}
            width={24}
            height={24}
            marginRight={10}
            alt="usericon"
            cursor="pointer"
          />
          {user}
          <Icon
            src={icon_arrow}
            width={24}
            height={24}
            alt="userarrow"
            rotate={menuOpen ? 180 : 0}
            cursor="pointer"
          />
        </UserElementStyled>
        <DropDownContent show={menuOpen}>
          <hr />
          <span onClick={handleProfileClick}>
            {t("header.userMenu.profile", "Profile")}
          </span>
          <span onClick={handleLogoutClick}>
            {t("header.userMenu.logout", "Logout")}
          </span>
          <hr />
          <span onClick={handleAboutClick}>
            {t("header.userMenu.about", "Feedback")}
          </span>
          <span onClick={handleAboutClick}>
            {t("header.userMenu.about", "About")}
          </span>
          <span onClick={handlePrivacyStatementClick}>
            {t("header.userMenu.privacyStatement", "Privacy Statement")}
          </span>
        </DropDownContent>
        <AboutModal aboutOpen={aboutOpen} handleCloseAbout={handleCloseAbout} />
        <ProfileModal
          profileOpen={profileOpen}
          handleChangeLanguage={handleChangeLanguage}
          handleCloseProfile={handleCloseProfile}
        />
        <PrivacyStatementModal
          privacyStatementOpen={privacyStatementOpen}
          handleClosePrivacyStatement={handleClosePrivacyStatement}
        />
      </Block>
    </Wrapper>
  );
}
