import { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";

const StyledDatePicker = styled(DatePicker)`
  display: block;
  border-radius: 17px;
  width: 362px;
  height: 34px;
  border-style: none;

  padding: 0.5rem;
  font-size: 16px;
  font-family: "Roboto";
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--col-214269);

  :focus {
    border-color: #007eff;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 0 3px rgba(0, 126, 255, 0.1);
    outline: none;
  }

  :disabled {
    opacity: 0.7;
  }
`;

const DatePickerEventLog = styled(DatePicker)`
  display: block;
  width: 98px;
  height: 24px;
  border-radius: 4px;
  border: solid 1px var(--light-blue-grey);
  padding: 8px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  z-index: 15;
  :focus {
    border-color: #007eff;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 0 3px rgba(0, 126, 255, 0.1);
    outline: none;
  }

  :disabled {
    opacity: 0.7;
  }
`;

export class DateTimePicker extends Component<any, any> {
  private handleChangeRaw = (e: any) => {
    e.preventDefault();
  };
  public render(): JSX.Element {
    if (this.props.eventLog) {
      return (
        <DatePickerEventLog
          id={this.props.id}
          selected={this.props.selectedDate}
          onChange={this.props.handleChange}
          onChangeRaw={this.handleChangeRaw}
          popperPlacement={"top"}
          name={this.props.name}
          dateFormat="dd.MM.yyyy"
          fixedHeight
          isClearable={false}
        ></DatePickerEventLog>
      );
    }
    return (
      <StyledDatePicker
        id={this.props.id}
        selected={this.props.selectedDate}
        onChange={this.props.handleChange}
        popperPlacement={"top"}
        name={this.props.name}
        fixedHeight
      />
    );
  }
}
