import { createRef, Component } from "react";
import * as TYPES from "../constants/actionTypes";
import { connect } from "react-redux";
import { debounce } from "throttle-debounce";
import styled from "styled-components";
import { withTranslation, WithTranslation } from "react-i18next";

export enum SearchUsage {
  DEVICES = 0,
  ADMIN = 1,
  SCALE = 2,
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateSearchTerm: (query: string, type: SearchUsage) => {
      dispatch({
        type:
          type === SearchUsage.DEVICES
            ? TYPES.SEARCH_TERM_UPDATED
            : TYPES.SEARCH_TERM_ADMIN_UPDATED,
        payload: { query: query },
      });
    },
  };
};

const mapStateToProps = (state: any) => {
  return {
    query: state.devices.query,
  };
};
interface SearchProps extends WithTranslation {
  updateSearchTerm: any;
  active: boolean;
  query: string;
  type?: SearchUsage;
}
interface InputProps {
  backgroundColor?: string;
  placeholderColor?: string;
  textColor?: string;
}
const Input = styled.input<InputProps>`
  display: block;
  border-radius: 17px;
  width: 100%;
  height: 8.1%;
  position: relative;
  margin: 0 auto;
  border-style: none;
  padding: 0.5rem;
  font-family: Poppins;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${(props) => (props.textColor ? props.textColor : "#ffffff")};
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "var(--col-4-f-8-a-89)"};
  &:focus {
    border-color: #007eff;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 0 3px rgba(0, 126, 255, 0.1);
    outline: none;
  }
  ::placeholder {
    color: ${(props) =>
      props.placeholderColor ? props.placeholderColor : "white"};
    text-align: left;
    font-family: "Poppins", "Europress";
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
  }
`;

const Rect = styled.div`
  display: flex;
  height: 100%;
  min-width: 204px;
  min-height: 34px;
  align-items: center;
  justify-content: center;
  margin-right: 50px;
`;

class SearchComponent extends Component<SearchProps, any> {
  private _inputRef: any = undefined;
  constructor(props: SearchProps) {
    super(props);
    this._inputRef = createRef();
  }
  public componentDidMount(): void {
    if (this.props.query !== "") {
      this._inputRef.current.value = this.props.query;
    }
  }
  private handleInputChange = (e: any) => {
    if (this.props.active) {
      this.onChangeDebounce(e.target.value);
    }
  };
  private onChangeDebounce = debounce(300, (value) =>
    this.props.updateSearchTerm(value, this.props.type)
  );
  private onSubmit = (e: any) => {
    // Prevents the site form reloading when enter is pressed after searching
    e.preventDefault();
  };
  public render(): JSX.Element {
    const { t } = this.props;
    let placeholder: string = "";
    if (
      this.props.type === SearchUsage.ADMIN ||
      this.props.type === SearchUsage.SCALE
    ) {
      placeholder = `\u{e800} ${t("search.placeholder.search", "Search")}`;
      return (
        <Rect className="search-step">
          <form
            style={{ width: "248px", height: "100%" }}
            onSubmit={(e) => this.onSubmit(e)}
          >
            <Input
              placeholder={placeholder}
              onChange={this.handleInputChange}
              onFocus={(e) => (e.target.placeholder = "")}
              onBlur={(e) => (e.target.placeholder = placeholder)}
              ref={this._inputRef}
              backgroundColor={"white"}
              textColor={"black"}
              placeholderColor={"var(rgba(0, 0, 0, 0.6))"}
            />
          </form>
        </Rect>
      );
    }
    placeholder = `\u{e800} ${t(
      "search.placeholder.devices",
      "Search Devices"
    )}`;
    return (
      <Rect className="search-step">
        <form onSubmit={(e) => this.onSubmit(e)}>
          <Input
            placeholder={placeholder}
            onChange={this.handleInputChange}
            onFocus={(e) => (e.target.placeholder = "")}
            onBlur={(e) => (e.target.placeholder = placeholder)}
            ref={this._inputRef}
            backgroundColor={undefined}
          />
        </form>
      </Rect>
    );
  }
}

export const Search = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(SearchComponent));
