import Axios, { AxiosPromise } from "axios";

/**
 * Fetches token from server.
 *
 * @export
 * @param {string} user
 * @param {string} password
 * @returns {AxiosPromise}
 */
export function getToken(action: any): AxiosPromise {
  let request = process.env.REACT_APP_LATEST_API + "/token";
  const body = {
    user: action.payload.user,
    password: action.payload.password,
    web: true,
    otp: action.payload.otp,
  };
  let response = undefined;
  try {
    response = Axios.post(request, body);
  } catch (error) {
    throw error;
  }
  return response;
}
