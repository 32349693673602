import { TreeNode } from "../reducers/groups";

/**
 * Find group with `id` from nested group hierarchy `groups`.
 *
 * @param groups nested group hierarchy
 * @param id the id of the group to return
 */
export const findGroupById = (
  groups: TreeNode[],
  id: number
): TreeNode | undefined => {
  if (!groups) {
    return;
  }

  for (const group of groups) {
    // Test current object
    if (group.groupId === id) {
      return group;
    }

    // Test children recursively
    const child: any = findGroupById(group.children, id);
    if (child) {
      return child;
    }
  }

  return;
};

export const getParentChain = (
  groups: TreeNode[],
  id: number
): TreeNode[] | undefined => {
  if (!groups) {
    return undefined;
  }

  for (const group of groups) {
    if (group.groupId === id) {
      return [];
    }

    const chain = getParentChain(group.children, id);
    if (chain !== undefined) {
      return [group, ...chain];
    }
  }

  return undefined;
};

/**
 * Get child group ids if group specified by `id`.
 */
export const getChildGroupIds = (groups: TreeNode[], id: number): number[] => {
  const group = findGroupById(groups, id);
  if (!group) {
    return [];
  }

  const traverseChildren = (group: TreeNode): number[] =>
    group.children.flatMap((childGroup) => [
      childGroup.groupId,
      ...traverseChildren(childGroup),
    ]);

  return traverseChildren(group);
};

/**
 * Get path of groups from the root group to group with `id`.
 */
export const getPathToGroup = (groups: TreeNode[], id: number): TreeNode[] => {
  for (const group of groups) {
    if (group.groupId === id) {
      return [group];
    }

    const childPath = getPathToGroup(group.children, id);
    if (childPath.length !== 0) {
      return [group, ...childPath];
    }
  }

  return [];
};
