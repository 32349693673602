import * as Yup from "yup";
import { passwordLength } from "@shared/constants";

Yup.setLocale({
  mixed: {
    required: () => `formError.required`,
    oneOf: ({ path }: any) => {
      if (path === "privacyStatement") {
        return `formError.boolean.required`;
      }
      return `formError.password.match`;
    },
  },
  string: {
    min: () => ({
      key: `formError.password.length`,
      value: passwordLength,
    }),
  },
});

const RegisterSchema = Yup.object().shape({
  password: Yup.string().required().min(passwordLength),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref("password"), null])
    .required(),
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  companyName: Yup.string(),
  privacyStatement: Yup.bool().oneOf([true]),
});

export default RegisterSchema;
