import * as Yup from "yup";
import { passwordLength } from "@shared/constants";

Yup.setLocale({
  mixed: {
    required: () => `formError.required`,
    oneOf: () => `formError.password.match`,
  },
  string: {
    min: () => ({
      key: `formError.password.length`,
      value: passwordLength,
    }),
  },
});

const ResetPasswordSchema = Yup.object().shape({
  newPassword: Yup.string().required().min(passwordLength),
  newPasswordConfirm: Yup.string()
    .oneOf([Yup.ref("newPassword"), null])
    .required(),
});

export default ResetPasswordSchema;
