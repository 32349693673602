import { useEffect } from "react";
import _ from "lodash";
import { FormTitle } from "../../../components/Common/FormTitle";
import { Formik } from "formik";
import { FormRectangle } from "../../../components/Common/FormRectangle";
import { FormWrapper } from "../../../components/Common/FormWrapper";
import {
  CheckboxGroup,
  FormInput,
  ReadonlyFormInput,
} from "../../../components/Common/FormInput";
import { FlexBoxWrapper } from "../../../components/Common";
import { FormSubmitButton } from "../../../components/Common/FormSubmitButton";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../constants/actionTypes";
import {
  CreateFillLevelTaskSchema,
  UpdateFillLevelTaskSchema,
} from "./FillLevelTaskSchema";
import { DeviceFillLevelTask, DeviceTaskType } from "@shared/types";
import { weekdayToString } from "../../../utils/utils";
import { RouteComponentProps } from "react-router-dom";
import { DeviceTasks } from "../../../reducers/deviceTasks";
import { Spinner } from "../../../components/Spinner";
import { getTimeZone } from "@shared/time";

const ContentWrapper = styled.div`
  margin-top: 2rem;

  #deviceSerialNumbers,
  #message,
  #emailRecipients {
    height: unset;
    padding: 0.7rem;
  }
`;

const NotFoundMessage = styled.div`
  margin: 2rem auto;
  color: white;
  font: "Roboto";
`;

const initialValues: {
  deviceSerialNumbers: string;
  fillLevel: string;
  weekdays: string[];
  timeZone: string;
  startHour: number;
  endHour: number;
  resendHours: number | "";
  message: string;
  emailRecipients: string;
} = {
  deviceSerialNumbers: "",
  fillLevel: DeviceFillLevelTask.LEVEL_1.toString(),
  weekdays: [],
  timeZone: getTimeZone(),
  startHour: 0,
  endHour: 24,
  resendHours: 24,
  message: "",
  emailRecipients: "",
};

const weekdayValuesSundayStart = _.range(0, 7).map((weekday) => ({
  value: weekday.toString(),
  text: weekdayToString(weekday, "cccc"),
}));

const weekdayValues = [
  ...weekdayValuesSundayStart.slice(1),
  weekdayValuesSundayStart[0],
];

export function CreateFillLevelTaskForm(
  props: RouteComponentProps<{ id: string }>
) {
  const token = useSelector((state: any) => state.token.key);
  const dispatch = useDispatch();

  const onSubmit = (values: typeof initialValues, formActions: any) => {
    const tasksData = {
      type: DeviceTaskType.FILL_LEVEL_WEEKDAY_NOTIFICATION,
      deviceSerialNumbers: values.deviceSerialNumbers
        .split("\n")
        .filter(Boolean)
        .map((serial) => serial.trim()),
      config: {
        fillLevel: parseInt(values.fillLevel),
        weekdays: values.weekdays.map((weekday) => parseInt(weekday)),
        startHour: values.startHour,
        endHour: values.endHour,
        timeZone: values.timeZone,
        resendHours: values.resendHours === "" ? null : values.resendHours,
        message: values.message || undefined,
        emailRecipients: values.emailRecipients
          .split("\n")
          .filter(Boolean)
          .map((serial) => serial.trim()),
      },
    };
    dispatch({
      type: actions.CREATE_DEVICE_TASKS,
      payload: {
        tasksData,
        token,
      },
    });
    formActions.setSubmitting(false);
  };

  return (
    <ContentWrapper>
      <FormRectangle>
        <FormTitle title="Create device fill level task" />
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={CreateFillLevelTaskSchema}
        >
          {({
            errors,
            values,
            touched,
            handleChange,
            handleBlur,
            isSubmitting,
          }) => (
            <FormWrapper>
              <FormInput
                id="deviceSerialNumbers"
                type=""
                component="textarea"
                componentProps={{ rows: 5 }}
                label="Device serial numbers (one per line)"
                error={
                  touched.deviceSerialNumbers && errors.deviceSerialNumbers
                    ? errors.deviceSerialNumbers
                    : ""
                }
                value={values.deviceSerialNumbers}
                onChange={handleChange}
                onBlur={handleBlur}
                required
              />
              <FormInput
                id="fillLevel"
                type=""
                component="select"
                label="Fill level"
                error={
                  touched.fillLevel && errors.fillLevel ? errors.fillLevel : ""
                }
                value={values.fillLevel}
                options={[
                  {
                    value: DeviceFillLevelTask.LEVEL_1.toString(),
                    text: "Level 1",
                  },
                  {
                    value: DeviceFillLevelTask.LEVEL_2.toString(),
                    text: "Level 2",
                  },
                ]}
                onChange={handleChange}
                onBlur={handleBlur}
                required
              />
              <CheckboxGroup
                id="weekdays"
                label="Weekdays"
                checkboxes={weekdayValues}
                required
                error={
                  touched.weekdays && typeof errors.weekdays === "string"
                    ? errors.weekdays
                    : ""
                }
              />
              <ReadonlyFormInput label="Time zone" value={values.timeZone} />
              <FormInput
                id="startHour"
                required
                type="number"
                label="Start hour (included)"
                error={
                  touched.startHour && errors.startHour ? errors.startHour : ""
                }
                value={values.startHour}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <FormInput
                id="endHour"
                required
                type="number"
                label="End hour (not included)"
                error={touched.endHour && errors.endHour ? errors.endHour : ""}
                value={values.endHour}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <FormInput
                id="resendHours"
                type="number"
                label="Resend period (hours)"
                error={
                  touched.resendHours && errors.resendHours
                    ? errors.resendHours
                    : ""
                }
                value={values.resendHours}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <FormInput
                id="message"
                type=""
                component="textarea"
                componentProps={{ rows: 5 }}
                label="Message"
                error={touched.message && errors.message ? errors.message : ""}
                value={values.message}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <FormInput
                id="emailRecipients"
                type=""
                component="textarea"
                componentProps={{ rows: 5 }}
                label="Email recipients (one per line)"
                error={
                  touched.emailRecipients && errors.emailRecipients
                    ? errors.emailRecipients
                    : ""
                }
                value={values.emailRecipients}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <FlexBoxWrapper marginTop={20}>
                <FormSubmitButton
                  type="button"
                  onClick={() => props.history.goBack()}
                >
                  Back
                </FormSubmitButton>
                &nbsp;
                <FormSubmitButton
                  type="submit"
                  disabled={isSubmitting}
                  cursor={isSubmitting ? "wait" : undefined}
                >
                  Save
                </FormSubmitButton>
              </FlexBoxWrapper>
            </FormWrapper>
          )}
        </Formik>
      </FormRectangle>
    </ContentWrapper>
  );
}

export function UpdateFillLevelTaskForm(
  props: RouteComponentProps<{ id: string }>
) {
  const token = useSelector((state: any) => state.token.key);
  const dispatch = useDispatch();
  const taskId = parseInt(props.match.params.id);
  const task = useSelector(
    (state: { deviceTasks: DeviceTasks }) => state.deviceTasks.tasks[taskId]
  );
  const isLoading = useSelector(
    (state: { deviceTasks: DeviceTasks }) => state.deviceTasks.isLoading
  );

  const initialValues = task && {
    fillLevel: task.config.fillLevel.toString(),
    weekdays: task.config.weekdays.map((weekday) => weekday.toString()),
    startHour: task.config.startHour,
    endHour: task.config.endHour,
    timeZone: task.config.timeZone,
    resendHours:
      task.config.resendHours === null
        ? ("" as const)
        : task.config.resendHours,
    message: task.config.message ?? "",
    emailRecipients: task.config.emailRecipients.join("\n"),
  };

  useEffect(() => {
    dispatch({
      type: actions.GET_ALL_DEVICE_TASKS,
      payload: { token },
    });
  }, [token, dispatch]);

  const onSubmit = (values: typeof initialValues, formActions: any) => {
    const taskData = {
      config: {
        fillLevel: parseInt(values.fillLevel),
        weekdays: values.weekdays.map((weekday) => parseInt(weekday)),
        startHour: values.startHour,
        endHour: values.endHour,
        timeZone: values.timeZone,
        resendHours: values.resendHours === "" ? null : values.resendHours,
        message: values.message || undefined,
        emailRecipients: values.emailRecipients
          .split("\n")
          .filter(Boolean)
          .map((serial) => serial.trim()),
      },
    };
    dispatch({
      type: actions.UPDATE_DEVICE_TASK,
      payload: {
        taskData,
        taskId,
        token,
      },
    });
    formActions.setSubmitting(false);
  };

  return (
    <ContentWrapper>
      <FormRectangle>
        <FormTitle title={`Update fill level task`} />
        {isLoading ? (
          <Spinner />
        ) : !task ? (
          <NotFoundMessage>Task not found</NotFoundMessage>
        ) : (
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={UpdateFillLevelTaskSchema}
          >
            {({
              errors,
              values,
              touched,
              handleChange,
              handleBlur,
              isSubmitting,
            }) => (
              <FormWrapper>
                <ReadonlyFormInput
                  label="Device serial number"
                  value={task.device.serialNumber}
                />
                <FormInput
                  id="fillLevel"
                  type=""
                  component="select"
                  label="Fill level"
                  error={
                    touched.fillLevel && errors.fillLevel
                      ? errors.fillLevel
                      : ""
                  }
                  value={values.fillLevel}
                  options={[
                    {
                      value: DeviceFillLevelTask.LEVEL_1.toString(),
                      text: "Level 1",
                    },
                    {
                      value: DeviceFillLevelTask.LEVEL_2.toString(),
                      text: "Level 2",
                    },
                  ]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                />
                <CheckboxGroup
                  id="weekdays"
                  label="Weekdays"
                  checkboxes={weekdayValues}
                  required
                  error={
                    touched.weekdays && typeof errors.weekdays === "string"
                      ? errors.weekdays
                      : ""
                  }
                />
                <ReadonlyFormInput label="Time zone" value={values.timeZone} />
                <FormInput
                  id="startHour"
                  required
                  type="number"
                  label="Start hour (included)"
                  error={
                    touched.startHour && errors.startHour
                      ? errors.startHour
                      : ""
                  }
                  value={values.startHour}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <FormInput
                  id="endHour"
                  required
                  type="number"
                  label="End hour (not included)"
                  error={
                    touched.endHour && errors.endHour ? errors.endHour : ""
                  }
                  value={values.endHour}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <FormInput
                  id="resendHours"
                  required
                  type="number"
                  label="Resend period (hours)"
                  error={
                    touched.resendHours && errors.resendHours
                      ? errors.resendHours
                      : ""
                  }
                  value={values.resendHours}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <FormInput
                  id="message"
                  type=""
                  component="textarea"
                  componentProps={{ rows: 5 }}
                  label="Message"
                  error={
                    touched.message && errors.message ? errors.message : ""
                  }
                  value={values.message}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <FormInput
                  id="emailRecipients"
                  type=""
                  component="textarea"
                  componentProps={{ rows: 5 }}
                  label="Email recipients (one per line)"
                  error={
                    touched.emailRecipients && errors.emailRecipients
                      ? errors.emailRecipients
                      : ""
                  }
                  value={values.emailRecipients}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <FlexBoxWrapper marginTop={20}>
                  <FormSubmitButton
                    type="button"
                    onClick={() => props.history.goBack()}
                  >
                    Back
                  </FormSubmitButton>
                  &nbsp;
                  <FormSubmitButton
                    type="submit"
                    disabled={isSubmitting}
                    cursor={isSubmitting ? "wait" : undefined}
                  >
                    Save
                  </FormSubmitButton>
                </FlexBoxWrapper>
              </FormWrapper>
            )}
          </Formik>
        )}
      </FormRectangle>
    </ContentWrapper>
  );
}
