import { SelectableColumns } from "../interfaces/types";

export const DEVICE_DISPLAY_ORDER = [
  SelectableColumns.STATUS,
  SelectableColumns.STATUS_MESSAGE,
  SelectableColumns.SERIAL_NUMBER,
  SelectableColumns.ASSET_NAME,
  SelectableColumns.GROUP,
  SelectableColumns.OWNER_GROUP_UPDATED_AT,
  SelectableColumns.FILL_LEVEL,
  SelectableColumns.WASTE_FRACTION,
  SelectableColumns.PREDICTED_FILL_UP,
  SelectableColumns.LAST_EMPTIED,
  SelectableColumns.SITE,
  SelectableColumns.ONLINE,
  SelectableColumns.OPERATOR_GROUP,
  SelectableColumns.OPERATOR_GROUP_UPDATED_AT,
  SelectableColumns.EQUIPMENT_CODE,
  SelectableColumns.LAST_ONLINE,
  SelectableColumns.SIGNAL_QUALITY_RSSI,
  SelectableColumns.PHONE_NO,
  SelectableColumns.SERVICE_IS_DUE,
  SelectableColumns.CONTROLLER,
  SelectableColumns.BALE_COUNTER,
  SelectableColumns.HW_REV,
  SelectableColumns.F_W,
  SelectableColumns.LOCATION,
  SelectableColumns.PIC,
  SelectableColumns.CUSTOMER,
  SelectableColumns.RENTAL_ADDRESS,
  SelectableColumns.RENTAL_PERIOD,
];

export const SKIP_DISPLAY_ORDER = [
  SelectableColumns.SERIAL_NUMBER,
  SelectableColumns.ASSET_NAME,
  SelectableColumns.LAST_ONLINE,
  SelectableColumns.PIC,
  SelectableColumns.CUSTOMER,
  SelectableColumns.RENTAL_ADDRESS,
  SelectableColumns.RENTAL_PERIOD,
  SelectableColumns.ADDRESS,
  SelectableColumns.BATTERY_LEVEL,
];

export const DEFAULT_DEVICE = [
  SelectableColumns.STATUS,
  SelectableColumns.SERIAL_NUMBER,
  SelectableColumns.ASSET_NAME,
  SelectableColumns.GROUP,
  SelectableColumns.FILL_LEVEL,
  SelectableColumns.WASTE_FRACTION,
  SelectableColumns.LAST_EMPTIED,
];

export const DEFAULT_SKIP = [
  SelectableColumns.SERIAL_NUMBER,
  SelectableColumns.ASSET_NAME,
  SelectableColumns.LAST_ONLINE,
  SelectableColumns.PIC,
  SelectableColumns.CUSTOMER,
  SelectableColumns.RENTAL_ADDRESS,
  SelectableColumns.RENTAL_PERIOD,
];
