import { useCallback, useEffect, useState } from "react";
import { ScalePage } from "./ScalePage";
import styled from "styled-components";
import { DateTimePicker } from "../../components/DateTimePicker";
import { v4 as uuidv4 } from "uuid";
import {
  format,
  startOfMonth,
  endOfMonth,
  addDays,
  addMonths,
  subMonths,
} from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import * as TYPES from "../../constants/actionTypes";
import { Spinner } from "../../components/Spinner";
import { useTranslation } from "react-i18next";
import { downloadReport } from "../../api/scale";
import { NotifyError } from "../../components/Common/Notify";
import { Field, FieldArray, Form, Formik } from "formik";
import { Language, ScaleReportSendingSchedule } from "../../interfaces/types";
import { getDateFnsLocale } from "../../utils/i18nUtils";
import { singleEffectiveGroupSelector } from "../../selectors/groups";

interface CardWrapperProps {
  flex: number;
}

interface MonthListItemProps {
  active: boolean;
  onClick: any;
}

const CardWrapper = styled.div<CardWrapperProps>`
  padding: 20px;
`;

const CardHeading = styled.h1`
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
`;

const CardDescription = styled.p`
  font-size: 13px;
  margin: 35px 0;
`;

const ReportActionsWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const ReportContentWrapper = styled.div`
  display: flex;
`;

const ReportData = styled.div`
  flex-grow: 1;
`;

const CustomizePeriodHeading = styled.h3`
  font-size: 12px;
  font-weight: 500;
  padding-right: 10px;
`;

const DateTimeContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 2px;
`;

const TableContainer = styled.div`
  margin-left: 16px;
  margin-right: 30px;
  height: 72.5vh;
  overflow-y: scroll;
  overflow-x: hidden;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #bfcfde;
`;

const TableHeader = styled.thead`
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  font-stretch: condensed;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  position: sticky;
  top: 0;
  @media (min-width: 768px) {
    display: table-header-group;
  }
  background-color: #ffffff;
`;

const TableHeaderRow = styled.tr`
  height: 36px;
  text-transform: uppercase;
  text-align: left;
`;

const MonthList = styled.div`
  display: flex;
  flex-direction: column;
  width: 125px;
  background-color: #ffffff;
  padding: 5px;
  border: 1px solid #bfcfde;
`;

const MonthListItem = styled.span<MonthListItemProps>`
  background-color: ${(props) => {
    if (props.active) {
      return "rgba(78, 155, 249, 0.25)";
    } else {
      return "transparent";
    }
  }};
  cursor: pointer;
  border-radius: 4px;
  padding: 5px;
  font-size: 14px;
`;

const TableHeaderColumn = styled.th`
  padding-left: 5px;
  border-right: 1px solid #bfcfde;

  &:last-of-type {
    border-right: 0;
  }
`;

const TableBody = styled.tbody``;

const TableBodyRow = styled.tr`
  height: 36px;
  &:nth-child(odd) {
    background-color: var(--ice-blue);
    border-bottom: 1px solid #bfcfde;
  }

  &:nth-child(even) {
    background-color: #e1e9f1;
  }
`;

const TableBodyColumn = styled.td`
  padding-left: 5px;
  border-right: 1px solid #ffffff;

  &:last-of-type {
    border-right: 0;
  }
`;

const DownloadReportButton = styled.button`
  margin-left: auto;
  height: 30px;
  color: #ffffff;
  font-weight: bold;
  text-transform: uppercase;
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  font-stretch: condensed;
  font-style: normal;
  line-height: 30px;
  letter-spacing: 0.25px;
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  background-color: #0d467d;
  cursor: pointer;
`;

const FormSubmitButton = styled.button`
  margin: 0px auto 20px auto;
  height: 30px;
  color: #ffffff;
  font-weight: bold;
  text-transform: uppercase;
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  font-stretch: condensed;
  font-style: normal;
  line-height: 30px;
  letter-spacing: 0.25px;
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  background-color: #0d467d;
  cursor: pointer;
`;

const FormLabel = styled.label`
  min-width: 75px;
  margin-right: 20px;
  font-size: 10px;
  font-weight: bold;
  line-height: 35px;
`;

const SettingsRow = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const RecipientsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Recipient = styled.div`
  display: flex;
  margin-top: 5px;

  input {
    height: 22px;
    line-height: 22px;
    display: inline-flex;
    flex-grow: 1;
    border: solid 1px var(--light-blue-grey);
    border-radius: 4px;
    padding-left: 5px;
  }

  button {
    margin-left: 10px;
    height: 23px;
    width: 23px;
    font-weight: bold;
    text-transform: uppercase;
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    font-stretch: condensed;
    font-style: normal;
    line-height: 23px;
    letter-spacing: 0.25px;
    border-radius: 4px;
    border: solid 1px rgba(0, 0, 0, 0.1);
    color: #0d467d;
    background-color: #ffffff;
    cursor: pointer;
    border: solid 1px rgba(0, 0, 0, 0.1);
  }
`;

const Dropdown = styled.select`
  margin: 5px 0;
  border: 0;
  border-radius: 4px;
  height: 25px;
  padding-left: 5px;
  border: solid 1px var(--light-blue-grey);
  flex-grow: 1;
`;

const LanguageWrapper = styled.div`
  width: 100%;

  > select {
    width: 100%;
    margin: 5px 0;
    border: 0;
    border-radius: 4px;
    height: 25px;
    padding-left: 5px;
    border: solid 1px var(--light-blue-grey);
    flex-grow: 1;
  }
`;

const AddRecipientButton = styled.button`
  max-width: 100px;
  margin-top: 5px;
  height: 23px;
  line-height: 23px;
  font-weight: bold;
  text-transform: uppercase;
  font-family: Roboto;
  font-size: 10px;
  font-weight: bold;
  font-stretch: condensed;
  font-style: normal;
  letter-spacing: 0.25px;
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  background-color: #0d467d;
  color: #ffffff;
  cursor: pointer;
  border: solid 1px rgba(0, 0, 0, 0.1);
`;

const ResendReportText = styled.p`
  color: rgba(0, 0, 0, 0.6);
  font-size: 13px;
  margin-top: 20 0;
`;

const ResendReportButton = styled.span`
  color: #0d467d;
  font-size: 11px;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 10px;
`;

export function ScaleReports() {
  const group = useSelector(singleEffectiveGroupSelector);
  const isFetching = useSelector((state: any) => state.scale.isFetching);
  const scaleResults = useSelector((state: any) => state.scale.reports);
  const months = useSelector((state: any) => state.scale.reportMonths);
  const reportSettings = useSelector(
    (state: any) => state.scale.reportSettings
  );
  const language = useSelector((state: any) => state.users.language);
  const dateLocale = getDateFnsLocale(language);
  const [selectedMonth, setSelectedMonth] = useState(
    format(new Date(), "yyyy-MM", { locale: dateLocale })
  );
  const [startDate, setStartDate] = useState(startOfMonth(new Date()));
  const [endDate, setEndDate] = useState(new Date());
  const token = useSelector((state: any) => state.token.key);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isGeneratingReport, setIsGeneratingReport] = useState(false);

  const handleGroupSelection = useCallback(() => {
    if (!group) {
      return;
    }

    const endDateIncludingLastDay = addDays(endDate, 1);
    dispatch({
      type: TYPES.FETCH_SCALE_REPORT_MONTHS,
      payload: {
        group: group.guid,
        token,
      },
    });
    dispatch({
      type: TYPES.FETCH_SCALE_REPORTS,
      payload: {
        group: group.guid,
        start: format(startDate, "yyyy-MM-dd", { locale: dateLocale }),
        end: format(endDateIncludingLastDay, "yyyy-MM-dd", {
          locale: dateLocale,
        }),
        token,
      },
    });
    dispatch({
      type: TYPES.FETCH_SCALE_REPORT_SETTINGS,
      payload: {
        group: group.guid,
        token,
      },
    });
  }, [group, token, dispatch, endDate, startDate, dateLocale]);

  useEffect(() => {
    if (group) {
      handleGroupSelection();
    }
  }, [group, handleGroupSelection]);

  function handleStartDateChange(date: Date) {
    setStartDate(date);
  }

  function handleEndDateChange(date: Date) {
    setEndDate(date);
  }

  function handleMonthChange(month: string) {
    const newStartDate = startOfMonth(new Date(month));
    const newEndDate = endOfMonth(new Date(month));
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    setSelectedMonth(month);
  }

  async function handleReportDownload() {
    if (!group?.guid) {
      return;
    }

    setIsGeneratingReport(true);
    const endDateIncludingLastDay = addDays(endDate, 1);
    try {
      const response = await downloadReport(
        group.guid,
        format(startDate, "yyyy-MM-dd", { locale: dateLocale }),
        format(endDateIncludingLastDay, "yyyy-MM-dd", {
          locale: dateLocale,
        }),
        token
      );
      const fileName =
        `${group.name} - ${format(startDate, "yyyy-MM-dd", {
          locale: dateLocale,
        })}-` +
        `${format(endDateIncludingLastDay, "yyyy-MM-dd", {
          locale: dateLocale,
        })}.xlsx`;
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      NotifyError(TYPES.GENERATE_SCALE_REPORT_FAILURE);
    }
    setIsGeneratingReport(false);
  }

  function handleReportResend() {
    if (group) {
      dispatch({
        type: TYPES.RESEND_SCALE_REPORT,
        payload: {
          group: group.guid,
          token,
        },
      });
    }
  }

  function handleSettingsSubmit(recipients: string[], language: number) {
    if (group) {
      dispatch({
        type: TYPES.UPDATE_SCALE_REPORT_SETTINGS,
        payload: {
          group: group.guid,
          language: language,
          schedule: ScaleReportSendingSchedule.FIRST_DAY_OF_MONTH,
          recipients: recipients,
          token,
        },
      });
    }
  }

  return (
    <ScalePage bodyBackgroundColor={"#fafbfc"}>
      <CardWrapper flex={2}>
        <CardHeading>{t("scale.reports.browse", "Browse reports")}</CardHeading>
        <ReportActionsWrapper>
          <CustomizePeriodHeading>
            {t("scale.reports.customizePeriod", "Customize period")}:
          </CustomizePeriodHeading>
          <DateTimeContainer>
            <DateTimePicker
              eventLog={true}
              selectedDate={startDate}
              handleChange={handleStartDateChange}
              name={"start_date"}
            />
            <div
              style={{
                width: "32px",
                textAlign: "center",
              }}
            >
              -
            </div>
            <DateTimePicker
              eventLog={true}
              selectedDate={endDate}
              handleChange={handleEndDateChange}
              name={"end_date"}
            />
          </DateTimeContainer>
          {scaleResults && scaleResults.length > 0 && (
            <DownloadReportButton onClick={handleReportDownload}>
              {t("scale.reports.download", "Download As Excel")}
            </DownloadReportButton>
          )}
        </ReportActionsWrapper>
        <ReportContentWrapper>
          {!months || months.length === 0 ? (
            t("scale.reports.notFound", "No reports were found.")
          ) : (
            <>
              <MonthList>
                {months.map((month: any) => (
                  <MonthListItem
                    key={uuidv4()}
                    active={
                      format(new Date(month), "yyyy-MM", {
                        locale: dateLocale,
                      }) === selectedMonth
                    }
                    onClick={() => handleMonthChange(month)}
                  >
                    {format(new Date(month), "MMM yyyy", {
                      locale: dateLocale,
                    })}
                  </MonthListItem>
                ))}
              </MonthList>
              <ReportData>
                <TableContainer>
                  {isFetching || isGeneratingReport ? (
                    <Spinner />
                  ) : !scaleResults || scaleResults.length === 0 ? (
                    t("scale.reports.notFound", "No reports were found.")
                  ) : (
                    <Table>
                      <TableHeader>
                        <TableHeaderRow>
                          <TableHeaderColumn>
                            {t("scale.reports.propertyGroup", "Property group")}
                          </TableHeaderColumn>
                          <TableHeaderColumn>
                            {t("scale.reports.userGroup", "User group")}
                          </TableHeaderColumn>
                          <TableHeaderColumn>
                            {t("scale.reports.wasteFraction", "Waste fraction")}
                          </TableHeaderColumn>
                          <TableHeaderColumn>
                            {t("scale.reports.netWeightKg", "Net weight (kg)")}
                          </TableHeaderColumn>
                        </TableHeaderRow>
                      </TableHeader>
                      <TableBody>
                        {scaleResults.map((reportItem: any) => (
                          <TableBodyRow key={uuidv4()}>
                            <TableBodyColumn>
                              {reportItem.propertyGroup}
                            </TableBodyColumn>
                            <TableBodyColumn>
                              {reportItem.userGroupName ||
                                t("scale.reports.unknown", "Unknown")}
                            </TableBodyColumn>
                            <TableBodyColumn>
                              {reportItem.wasteFraction ||
                                t("scale.reports.unknown", "Unknown")}
                            </TableBodyColumn>
                            <TableBodyColumn>
                              {reportItem.weight}
                            </TableBodyColumn>
                          </TableBodyRow>
                        ))}
                      </TableBody>
                    </Table>
                  )}
                </TableContainer>
              </ReportData>
            </>
          )}
        </ReportContentWrapper>
      </CardWrapper>
      <CardWrapper flex={1}>
        <CardHeading>
          {t("scale.reports.settings.heading", "Settings")}
        </CardHeading>
        <CardDescription>
          {t(
            "scale.reports.settings.description",
            "Receive the monthly reports to your email."
          )}
        </CardDescription>
        {reportSettings && (
          <Formik
            enableReinitialize={true}
            initialValues={{
              recipients: reportSettings.recipients,
              language: reportSettings.language,
            }}
            onSubmit={(values) => {
              handleSettingsSubmit(
                values.recipients,
                values.language ?? Language.FINNISH
              );
            }}
            render={({ values }) => (
              <Form>
                <FieldArray
                  name="recipients"
                  render={(arrayHelpers) => (
                    <div>
                      <SettingsRow>
                        <FormLabel>
                          {t("scale.reports.sendOn", "Send on")}:
                        </FormLabel>
                        <Dropdown>
                          <option>
                            {t(
                              "scale.reports.schedule.firstOfMonth",
                              "Monthly 1st Day"
                            )}
                          </option>
                        </Dropdown>
                      </SettingsRow>
                      <SettingsRow>
                        <FormLabel>
                          {t("scale.reports.recipients", "Recipients")}:
                        </FormLabel>
                        <RecipientsWrapper>
                          {values.recipients && values.recipients.length > 0 ? (
                            values.recipients.map(
                              (_recipient: any, index: number) => (
                                <Recipient key={index}>
                                  <Field name={`recipients.${index}`} />
                                  <button
                                    type="button"
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    -
                                  </button>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      arrayHelpers.insert(index + 1, "")
                                    }
                                  >
                                    +
                                  </button>
                                </Recipient>
                              )
                            )
                          ) : (
                            <AddRecipientButton
                              type="button"
                              onClick={() => arrayHelpers.push("")}
                            >
                              {t("scale.reports.addRecipient", "Add recipient")}
                            </AddRecipientButton>
                          )}
                        </RecipientsWrapper>
                      </SettingsRow>
                      <SettingsRow>
                        <FormLabel>
                          {t("scale.reports.language", "Language")}:
                        </FormLabel>
                        <LanguageWrapper>
                          <Field as="select" name="language">
                            <option value={Language.FINNISH}>
                              {t("language.finnish", "Finnish")}
                            </option>
                            <option value={Language.ENGLISH}>
                              {t("language.english", "English")}
                            </option>
                            <option value={Language.RUSSIAN}>
                              {t("language.russian", "Russian")}
                            </option>
                            <option value={Language.POLISH}>
                              {t("language.polish", "Polish")}
                            </option>
                          </Field>
                        </LanguageWrapper>
                      </SettingsRow>
                      <SettingsRow>
                        <FormSubmitButton type="submit">
                          {t("scale.reports.save", "Save")}
                        </FormSubmitButton>
                      </SettingsRow>
                    </div>
                  )}
                />
              </Form>
            )}
          />
        )}
        <ResendReportText>
          {t(
            "scale.reports.nextReportSentOn",
            "The next report will be sent on"
          )}{" "}
          {format(startOfMonth(addMonths(new Date(), 1)), "MMM d, y", {
            locale: dateLocale,
          })}
          .
        </ResendReportText>
        <ResendReportText>
          {t("scale.reports.didNotGetLastReport", "Didn't get the last report")}{" "}
          (
          {format(subMonths(new Date(), 1), "MMM yyyy", {
            locale: dateLocale,
          })}
          )?
          <ResendReportButton onClick={handleReportResend}>
            {t("scale.reports.resend", "Resend")}
          </ResendReportButton>
        </ResendReportText>
      </CardWrapper>
    </ScalePage>
  );
}
