import styled from "styled-components";

export const Title = styled.h1`
  font-family: Poppins;
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--col-1-c-2020);
`;

export const Subtitle = styled.h2`
  font-family: Poppins;
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--col-1-c-2020);
`;
