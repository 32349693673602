import { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { rawContainersSelector } from "../selectors/devices";
import { withTranslation, WithTranslation } from "react-i18next";

interface PageInfo {
  readonly path: string;
  readonly name: string;
}

const activeLinkFontSize = 18;
const activeClassName = "nav-item-active";
const StyledLink = styled(NavLink).attrs({ activeClassName })`
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: condensed;
  line-height: ${activeLinkFontSize}px;
  letter-spacing: 0.5px;
  color: #e9eef3;
  text-decoration: none;
  margin-left: 16px;

  &.${activeClassName} {
    font-size: ${activeLinkFontSize}px;
  }
  margin-bottom: 6px;
`;

const SeparatorLine = styled.div`
  width: 1px;
  height: 14px;
  background-color: #e9eef3;
  margin-left: 15px;
  margin-bottom: 6px;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface PageSelectionProps extends WithTranslation {
  skipDevicesExist: boolean;
  adminAllowed: boolean;
  user: any;
}

class PageSelectionComponent extends Component<PageSelectionProps, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      menuOpen: false,
    };
  }

  private pageList(): JSX.Element {
    const { t } = this.props;

    let pages: PageInfo[] = [
      {
        path: "/devices",
        name: t("header.devices", "DEVICES"),
      },
      {
        path: "/analytics",
        name: t("header.analytics", "DATA ANALYTICS"),
      },
      {
        path: "/containers",
        name: t("header.containers", "CONTAINERS"),
      },
      {
        path: "/scale",
        name: t("header.scale", "SCALE"),
      },
      {
        path: "/admin",
        name: t("header.admin", "ADMIN"),
      },
    ];

    const links = pages.map((page: PageInfo, index: number) => {
      if (page.path === "/containers" && !this.props.skipDevicesExist) {
        return undefined;
      } else if (page.path === "/admin" && !this.props.adminAllowed) {
        return undefined;
      } else if (
        page.path === "/scale" &&
        !this.hasAccessToScaleTab(this.props.user)
      ) {
        return undefined;
      } else {
        return (
          <Container key={uuidv4()}>
            {index !== 0 ? <SeparatorLine /> : undefined}
            <StyledLink
              key={uuidv4()}
              to={page.path}
              activeClassName={activeClassName}
            >
              {page.name}
            </StyledLink>
          </Container>
        );
      }
    });
    return <>{links}</>;
  }

  public render(): JSX.Element {
    return this.pageList();
  }

  private hasAccessToScaleTab(user: any): boolean {
    return (
      user.admin || user.groups.includes("Europress") || user.licenses.insights
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    dataAnalyticsAllowed: state.users.dataAnalyticsAllowed,
    skipDevicesExist: 0 < rawContainersSelector(state).length,
    adminAllowed: state.users.admin,
    // TODO: Replace usage of adminAllowed with user
    user: state.users,
  };
};

export const PageSelection = connect(mapStateToProps)(
  withTranslation()(PageSelectionComponent)
);
