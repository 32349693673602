import { createSelector } from "reselect";
import { GroupType } from "../interfaces/types";
import { AdminState } from "../reducers/admin";
import { findGroupById, getParentChain } from "../utils/group";
import { groupHierarchiesSelector } from "./groups";

const adminSelector = (state: any): AdminState => state.admin;
const licensesSelector = createSelector(
  adminSelector,
  (state) => state.licenses.data
);
export const isLicenseLoadingSelector = createSelector(
  adminSelector,
  (state) => state.licenses.isLoading
);

export const groupLicensesSelector = createSelector(
  licensesSelector,
  groupHierarchiesSelector,
  (licenses, groups) =>
    Object.values(licenses)
      .filter((license) => license.type === "group")
      .map((license) => {
        const group = findGroupById(groups, license.groupId);
        const groupParentChain = getParentChain(groups, license.groupId);
        return {
          groupId: license.groupId,
          groupName: group?.name,
          groupType: group?.groupType as GroupType | undefined,
          groupParentChain,
          ...license.groupLicense,
        };
      })
);

export const deviceLicensesSelector = createSelector(
  licensesSelector,
  groupHierarchiesSelector,
  (licenses, groups) =>
    Object.values(licenses)
      .filter((license) => license.type === "devices")
      .flatMap((license) =>
        license.deviceLicenses.map((deviceLicense) => {
          const group = findGroupById(groups, license.groupId);
          const groupParentChain = getParentChain(groups, license.groupId);
          return {
            groupId: license.groupId,
            groupName: group?.name,
            groupType: group?.groupType as GroupType | undefined,
            groupParentChain,
            ...deviceLicense,
          };
        })
      )
);

export const adminHGDevicesSelector = createSelector(
  adminSelector,
  (adminState) => adminState.hgDevices?.data?.devices || []
);

export const adminRetrofitLightDevicesSelector = createSelector(
  adminSelector,
  (adminState) => adminState.retrofitLightDevices?.data?.devices || []
);

export const isHGDevicesLoadingSelector = createSelector(
  adminSelector,
  (state) => state.hgDevices.isLoading
);

export const isRetrofitLightDevicesLoadingSelector = createSelector(
  adminSelector,
  (state) => state.retrofitLightDevices.isLoading
);

export const adminUserSelector = createSelector(
  adminSelector,
  (adminState) => adminState.user
);

export const adminLoadedSelector = createSelector(
  adminSelector,
  (adminState) => adminState.isLoaded
);

export const adminSubmittingSelector = createSelector(
  adminSelector,
  (adminState) => adminState.isSubmitting
);
