import Axios, { AxiosPromise } from "axios";
import * as TYPES from "../constants/actionTypes";
import _ from "lodash/fp";
import { GroupApi } from "@shared/apiTypes";

const TIMEOUT = 20000; // 20sec
const LONG_TIMEOUT = 300000; // 5min

/**
 * Fetches group hierarchy from server.
 *
 * @export
 * @param {action} is fetch groups action with payload which contains token optional
 * group name param
 * @returns {AxiosPromise}
 */
export function getGroupHierarchy(action: any): AxiosPromise {
  let request = process.env.REACT_APP_LATEST_API + "/group/hierarchy/paths/";
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: TIMEOUT,
  };

  return Axios.get(request, config);
}

export async function getGroupForAdmin(data: {
  token: string;
  groupId: number;
}) {
  const url =
    process.env.REACT_APP_LATEST_API + "/admin/groups/" + data.groupId;

  const config = {
    headers: { Authorization: "Bearer " + data.token },
    timeout: TIMEOUT,
  };
  const res = await Axios.get<GroupApi>(url, config);

  return res.data;
}

export async function postGroupForAdmin(data: { token: string; group: any }) {
  const config = {
    headers: { Authorization: "Bearer " + data.token },
    timeout: LONG_TIMEOUT,
  };
  const url = `${process.env.REACT_APP_LATEST_API}/admin/groups/`;
  const res = await Axios.post<GroupApi>(url, data.group, config);

  return res.data;
}

export async function patchGroupForAdmin(data: {
  token: string;
  group: any;
  groupId: number;
}) {
  const config = {
    headers: { Authorization: "Bearer " + data.token },
    timeout: LONG_TIMEOUT,
  };
  const url = `${process.env.REACT_APP_LATEST_API}/admin/groups/${data.groupId}`;
  const res = await Axios.patch<GroupApi>(url, data.group, config);

  return res.data;
}

export function deleteGroupForAdmin(
  action: TYPES.DeleteGroupAction
): AxiosPromise | Object {
  const url =
    process.env.REACT_APP_LATEST_API +
    "/admin/groups/" +
    action.payload.groupId;
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: LONG_TIMEOUT,
  };

  return Axios.delete(url, config);
}

export function getAllLicenses(action: any): AxiosPromise {
  const url = `${process.env.REACT_APP_LATEST_API}/admin/groups/licenses`;
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: TIMEOUT,
  };

  return Axios.get(url, config);
}

export function getGroupLicenses(action: any): AxiosPromise {
  const url = `${process.env.REACT_APP_LATEST_API}/admin/groups/${action.payload.groupId}/licenses`;
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: TIMEOUT,
  };

  return Axios.get(url, config);
}

export function putGroupLicenses(action: any): AxiosPromise {
  const url = `${process.env.REACT_APP_LATEST_API}/admin/groups/${action.payload.groupId}/licenses`;
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: TIMEOUT,
  };
  const licenses = _.pipe(
    _.omit("groupId"),
    _.update("deviceLicenses", (licenses: any) =>
      licenses.map(_.omit(["deviceSerialNumber", "deviceSite"]))
    )
  )(action.payload.licenses);

  return Axios.put(url, licenses, config);
}

export function postScaleTagToGroup(action: any): AxiosPromise | Object {
  let request = process.env.REACT_APP_LATEST_API + "/admin/scale/tag/add";
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: TIMEOUT,
  };
  let data = {
    tag: action.payload.tag,
    guid: action.payload.group,
  };

  return Axios.post(request, data, config);
}
export function getAdminGroupScaleTags(action: any): AxiosPromise | Object {
  let request =
    process.env.REACT_APP_LATEST_API +
    `/admin/scale/tag/group/${action.payload.group}`;
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: TIMEOUT,
  };

  return Axios.get(request, config);
}

export function getGroupScaleTags(action: any): AxiosPromise | Object {
  let request =
    process.env.REACT_APP_LATEST_API +
    `/scale/groups/${action.payload.group}/tags`;
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: TIMEOUT,
  };

  return Axios.get(request, config);
}
export function postRemoveTagFromGroup(action: any): AxiosPromise | Object {
  let request = process.env.REACT_APP_LATEST_API + "/admin/scale/tag/remove/";
  const data = {
    guid: action.payload.guid,
    tag: action.payload.tag,
  };
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: TIMEOUT,
  };

  return Axios.post(request, data, config);
}

export function patchRevokeTag(action: any): AxiosPromise {
  const url = `${process.env.REACT_APP_LATEST_API}/scale/tags/${action.payload.id}/revoke`;
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: TIMEOUT,
  };

  return Axios.patch(url, null, config);
}

export function patchUnrevokeTag(action: any): AxiosPromise {
  const url = `${process.env.REACT_APP_LATEST_API}/scale/tags/${action.payload.id}/unrevoke`;
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: TIMEOUT,
  };

  return Axios.patch(url, null, config);
}

export function patchAssignTag(action: any): AxiosPromise {
  const url = `${process.env.REACT_APP_LATEST_API}/scale/tags/${action.payload.id}/assign`;
  const data = {
    userGroupId: action.payload.userGroupId,
  };
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: TIMEOUT,
  };

  return Axios.patch(url, data, config);
}

export function patchUnassignTag(action: any): AxiosPromise {
  const url = `${process.env.REACT_APP_LATEST_API}/scale/tags/${action.payload.id}/unassign`;
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: TIMEOUT,
  };

  return Axios.patch(url, null, config);
}

export function getUserGroupsForGroup(action: any): AxiosPromise {
  const url = `${process.env.REACT_APP_LATEST_API}/scale/groups/${action.payload.groupId}/user-groups`;
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: TIMEOUT,
  };

  return Axios.get(url, config);
}

export function postCreateScaleUserGroup(action: any): AxiosPromise {
  const url = `${process.env.REACT_APP_LATEST_API}/scale/groups/${action.payload.groupId}/user-groups`;
  const data = {
    name: action.payload.name,
    customerId: action.payload.customerId,
  };
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: TIMEOUT,
  };

  return Axios.post(url, data, config);
}

export function deleteRemoveScaleUserGroup(action: any): AxiosPromise {
  const url = `${process.env.REACT_APP_LATEST_API}/scale/user-groups/${action.payload.id}`;
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: TIMEOUT,
  };

  return Axios.delete(url, config);
}

export function putScaleUserGroup(action: any): AxiosPromise {
  const url = `${process.env.REACT_APP_LATEST_API}/scale/user-groups/${action.payload.id}`;
  const data = {
    name: action.payload.name,
    customerId: action.payload.customerId,
  };
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: TIMEOUT,
  };

  return Axios.put(url, data, config);
}

export function getScaleReports(action: any): AxiosPromise {
  const url = `${process.env.REACT_APP_LATEST_API}/scale/groups/${action.payload.group}/reports`;
  const params = new URLSearchParams({
    start: action.payload.start,
    end: action.payload.end,
  });
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: TIMEOUT,
    params,
  };

  return Axios.get(url, config);
}

export function getScaleResults(action: any): AxiosPromise {
  const url = `${process.env.REACT_APP_LATEST_API}/scale/groups/${action.payload.group}/results`;
  const params = new URLSearchParams({
    start: action.payload.start,
    end: action.payload.end,
  });
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: TIMEOUT,
    params,
  };

  return Axios.get(url, config);
}

export function getScaleReportMonths(action: any): AxiosPromise {
  const url = `${process.env.REACT_APP_LATEST_API}/scale/groups/${action.payload.group}/reports/months`;
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: TIMEOUT,
  };

  return Axios.get(url, config);
}

export function postImportScaleTags(action: any): AxiosPromise {
  const url = `${process.env.REACT_APP_LATEST_API}/admin/scale/tag/group/${action.payload.group}/import`;
  const data = {
    rows: action.payload.rows,
  };
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: TIMEOUT,
  };

  return Axios.post(url, data, config);
}

export function getScaleReportSettings(action: any): AxiosPromise {
  const url = `${process.env.REACT_APP_LATEST_API}/scale/groups/${action.payload.group}/reports/settings`;
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: TIMEOUT,
  };

  return Axios.get(url, config);
}

export function getScaleTotalWeightLimitNotificationSettings(
  action: any
): AxiosPromise {
  const url = `${process.env.REACT_APP_LATEST_API}/scale/notifications/${action.payload.deviceSerialNumber}/settings`;
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: TIMEOUT,
  };

  return Axios.get(url, config);
}

export function patchUpdateScaleReportSettings(action: any): AxiosPromise {
  const url = `${process.env.REACT_APP_LATEST_API}/scale/groups/${action.payload.group}/reports/settings`;
  const data = {
    language: action.payload.language,
    schedule: action.payload.schedule,
    recipients: action.payload.recipients,
  };
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: TIMEOUT,
  };

  return Axios.patch(url, data, config);
}

export function patchScaleTotalWeightLimitNotificationSettings(
  action: any
): AxiosPromise {
  const url = `${process.env.REACT_APP_LATEST_API}/scale/notifications/${action.payload.deviceSerialNumber}/settings`;
  const data = {
    recipients: action.payload.recipients,
  };
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: TIMEOUT,
  };

  return Axios.patch(url, data, config);
}

export function postResendScaleReport(action: any): AxiosPromise {
  const url = `${process.env.REACT_APP_LATEST_API}/scale/groups/${action.payload.group}/reports/resend`;
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: TIMEOUT,
  };

  return Axios.post(url, null, config);
}
