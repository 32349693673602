import { Modal } from "./Common/Modal";
import styled from "styled-components";
import { Formik } from "formik";

interface ImportScaleKeysModalProps {
  modalOpen: boolean;
  handleCloseModal: () => void;
  handleSubmit: (rows: string[]) => void;
  groupName: string;
}

const ModalContent = styled.div`
  padding: 16px;
`;

const StepList = styled.ol`
  padding-left: 16px;
`;

const DescriptionParagraph = styled.p`
  color: rgba(0, 0, 0, 0.6);
`;

const GroupNameParagraph = styled.p`
  margin: 30px 0;
  color: rgba(0, 0, 0, 0.6);
`;

const ImportKeysForm = styled.form``;

const ImportKeysTextarea = styled.textarea`
  width: 100%;
  min-height: 100px;
  border-radius: 12px;
  padding: 8px;
  border: 0;
`;

const ButtonsRow = styled.div`
  margin-top: 16px;
`;

const Button = styled.button`
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  background-color: #0d467d;
  color: #ffffff;
  height: 30px;
  font-weight: bold;
  cursor: pointer;
  text-transform: uppercase;
  display: inline;
  margin-left: 16px;
`;

export function ImportScaleKeysModal(props: ImportScaleKeysModalProps) {
  const content: JSX.Element = (
    <ModalContent>
      <DescriptionParagraph>
        You must import a key list before scanning individual keys.
      </DescriptionParagraph>
      <br />
      <DescriptionParagraph>
        The key list tells Europress SMART what keys exist. It also links
        together the 6-digit alias ID numbers (printed on the keys) and the
        8-digit unique ID numbers (inside the key).
      </DescriptionParagraph>
      <br />
      <StepList>
        <li>Open the key list file (.xls) from the key supplier in Excel</li>
        <li>Select all</li>
        <li>Copy</li>
        <li>Paste here:</li>
      </StepList>
      <Formik
        initialValues={{ rows: "" }}
        onSubmit={(values, actions) => {
          const rows = values.rows.split("\n");
          // Replace tab characters (from Excel files) with spaces so
          // that key IDs and aliases are separated by spaces.
          const keys = rows.map((row: any) => row.replace(/\t/g, " "));
          if (rows.length > 0 && rows[0] !== "") {
            props.handleSubmit(keys);
          }
          actions.setSubmitting(false);
          props.handleCloseModal();
        }}
      >
        {(formikProps) => (
          <ImportKeysForm onSubmit={formikProps.handleSubmit}>
            <ImportKeysTextarea
              onChange={formikProps.handleChange}
              onBlur={formikProps.handleBlur}
              value={formikProps.values.rows}
              name="rows"
            />
            <GroupNameParagraph>
              Keys will be imported into {props.groupName}.
            </GroupNameParagraph>
            <ButtonsRow>
              <Button type="submit">Import keys</Button>
              <Button onClick={props.handleCloseModal}>Cancel</Button>
            </ButtonsRow>
          </ImportKeysForm>
        )}
      </Formik>
    </ModalContent>
  );

  return (
    <Modal
      isOpen={props.modalOpen}
      handleClose={props.handleCloseModal}
      content={content}
      title={"Import key list"}
      fullScreenEnabled={true}
    />
  );
}
