import React, { useState } from "react";
import { FormTitle } from "../../../components/Common/FormTitle";
import { Formik } from "formik";
import { FormRectangle } from "../../../components/Common/FormRectangle";
import { FormWrapper } from "../../../components/Common/FormWrapper";
import { FormInput } from "../../../components/Common/FormInput";
import { GroupSelect } from "../GroupSelect";
import { FlexBoxWrapper } from "../../../components/Common";
import { FormSubmitButton } from "../../../components/Common/FormSubmitButton";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import * as TYPES from "../../../constants/actionTypes";
import { GroupType } from "../../../interfaces/types";
import { Toggle } from "../../../components/Common/Toggle";
import * as schemas from "./DevicesSchema";
import { groupHierarchiesSelector } from "../../../selectors/groups";

const ContentWrapper = styled.div`
  margin-top: 2rem;

  #serialNumbers {
    height: unset;
    padding: 0.7rem;
  }
`;

const ToggleSwitchWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 1.2rem;
  color: white;
`;

export function Devices() {
  const token = useSelector((state: any) => state.token.key);
  const groupOptions = useSelector(groupHierarchiesSelector);
  const isSubmitting = useSelector((state: any) => state.admin.isSubmitting);
  const dispatch = useDispatch();
  const [operatorUpdate, setOperatorUpdate] = useState(false);

  const onSubmit = (values: any, actions: any) => {
    const data = {
      serialNumbers: (values.serialNumbers as string)
        .split("\n")
        .filter(Boolean)
        .map((serial) => serial.trim()),
      groupId: values.selectedGroupId,
    };
    const dispatchType = operatorUpdate
      ? TYPES.UPDATE_OPERATOR_GROUP_FOR_DEVICES
      : TYPES.UPDATE_OWNER_GROUP_FOR_DEVICES;
    dispatch({
      type: dispatchType,
      payload: {
        data,
        token,
      },
    });
    actions.setSubmitting(false);
  };

  const disableNothing = (_group: any) => {
    return false;
  };

  const disableNonOperatorGroup = (group: any) => {
    return group.groupType !== GroupType.OPERATOR;
  };

  return (
    <ContentWrapper>
      <FormRectangle>
        <FormTitle
          title={
            operatorUpdate ? "Update operator group" : "Update owner group"
          }
        />
        <Formik<{ serialNumbers: string; selectedGroupId: undefined | number }>
          enableReinitialize={true}
          initialValues={{
            serialNumbers: "",
            selectedGroupId: undefined,
          }}
          onSubmit={onSubmit}
          validationSchema={
            operatorUpdate
              ? schemas.OperatorDevicesSchema
              : schemas.OwnerDevicesSchema
          }
        >
          {({
            errors,
            values,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            setFieldTouched,
          }) => (
            <FormWrapper>
              <ToggleSwitchWrapper>
                Owner group
                <Toggle
                  selected={operatorUpdate}
                  onToggle={() => setOperatorUpdate(!operatorUpdate)}
                />
                Operator group
              </ToggleSwitchWrapper>
              <FormInput
                id="serialNumbers"
                type=""
                component="textarea"
                componentProps={{ rows: 5 }}
                label="Serial numbers (one per line)"
                error={
                  touched.serialNumbers && errors.serialNumbers
                    ? errors.serialNumbers
                    : ""
                }
                value={values.serialNumbers}
                onChange={handleChange}
                onBlur={handleBlur}
                required
              />
              <GroupSelect
                id="selectedGroupId"
                label={
                  operatorUpdate ? "New operator group" : "New owner group"
                }
                value={values.selectedGroupId}
                onChange={setFieldValue}
                error={
                  touched.selectedGroupId ? errors.selectedGroupId : undefined
                }
                options={groupOptions}
                disabledGroup={
                  operatorUpdate ? disableNonOperatorGroup : disableNothing
                }
                required={!operatorUpdate}
                onBlur={setFieldTouched}
              />
              <FlexBoxWrapper marginTop={20}>
                <FormSubmitButton
                  type="submit"
                  disabled={isSubmitting}
                  cursor={isSubmitting ? "wait" : undefined}
                >
                  Save
                </FormSubmitButton>
              </FlexBoxWrapper>
            </FormWrapper>
          )}
        </Formik>
      </FormRectangle>
    </ContentWrapper>
  );
}
