import styled from "styled-components";

export const Navigation = styled.div`
  padding: 12px 16px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
  background-color: var(--seafoam-blue);
  font-family: Poppins;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #000000;
  text-transform: uppercase;
`;
