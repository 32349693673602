import { Component } from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";

import icon_tree from "../../assets/icon-tree.svg";
import { connect } from "react-redux";
import { GroupSelectionPopup } from "../../components/GroupSelectionPopup";
import {
  EffectiveGroups,
  effectiveGroupsSelector,
  groupHierarchiesSelector,
  selectedGroupNamePathSelector,
} from "../../selectors/groups";
import { TreeNode } from "../../reducers/groups";

const Header = styled.div`
  display: flex;
  padding-left: 32px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  background-color: #d2dde7;
`;

const Icon = styled.img`
  object-fit: contain;
`;
const GroupButton = styled.button`
  width: 61px;
  height: 39px;
  border-radius: 4px;
  background-color: var(--seafoam-blue);
  border: none;
  cursor: pointer;
  outline: none;
`;
interface GroupTitleProps {
  bolded: boolean;
}
const GroupTitle = styled.div<GroupTitleProps>`
  font-family: Poppins;
  font-size: 16px;
  font-weight: ${(props) => (props.bolded ? "bold" : "normal")};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--1-c-2020);
  padding-left: 12px;
`;

const mapDispatchToProps = (_dispatch: any) => {
  return {};
};

const mapStateToProps = (state: any) => {
  return {
    effectiveGroups: effectiveGroupsSelector(state),
    groupHierarchies: groupHierarchiesSelector(state),
    path: selectedGroupNamePathSelector(state),
  };
};

interface AdminProps {
  effectiveGroups: EffectiveGroups;
  groupHierarchies: TreeNode[];
  path: string[];
  onClose?: () => void;
}
interface AdminState {
  openModal: boolean;
}

class ScaleHeaderConnected extends Component<AdminProps, AdminState> {
  constructor(props: AdminProps) {
    super(props);
    this.state = {
      openModal: false,
    };
  }

  private onClose = (): void => {
    this.setState({
      ...this.state,
      openModal: false,
    });
    this.props.onClose?.();
  };
  private toggleGroupSelection = () => {
    this.setState((prevState) => ({
      ...this.state,
      openModal: !prevState.openModal,
    }));
  };
  public render(): JSX.Element {
    /**
     * Scale pages can only display data for one group at a time. If the user belongs to many
     * groups, not all of them can be selected simultaneously.
     */
    const hideSelectAllDevicesButton = 1 < this.props.groupHierarchies.length;

    return (
      <Header>
        <GroupButton onClick={this.toggleGroupSelection}>
          <Icon src={icon_tree} />
          <GroupSelectionPopup
            hideLabel={true}
            isModalOpen={this.state.openModal}
            onClose={this.onClose}
            hideSelectAllDevicesButton={hideSelectAllDevicesButton}
          />
        </GroupButton>
        {this.getGroupName()}
      </Header>
    );
  }
  private getGroupName = (): JSX.Element | undefined => {
    const { path, effectiveGroups } = this.props;

    /**
     * Scale views need to have one group selected. If the user belongs to many groups and has not
     * selected any of them, prompt them to select a group.
     */
    if (
      effectiveGroups.type === "root" &&
      1 < effectiveGroups.hierarchies.length
    ) {
      return <GroupTitle bolded>Select a group</GroupTitle>;
    }

    if (path && path.length > 1) {
      return (
        <>
          {path.map((element: any, index: number) => (
            <GroupTitle key={uuidv4()} bolded={index === path.length - 1}>
              {element + (index !== path.length - 1 ? " \\" : " ")}
            </GroupTitle>
          ))}
        </>
      );
    } else if (path.length === 1) {
      return <GroupTitle bolded={true}>{this.props.path}</GroupTitle>;
    }
    return undefined;
  };
}
export const ScaleHeader = connect(
  mapStateToProps,
  mapDispatchToProps
)(ScaleHeaderConnected);
