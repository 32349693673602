import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ItemList } from "../../../components/Common/ItemList";
import { ItemListHeader } from "../../../components/Common/ItemListHeader";

const PropertyGroupListWrapper = styled.div`
  padding: 10px;
  flex: 1;
  min-width: 0;
  border-right: 1px solid #bfcfde;
`;

interface PropertyGroupListProps {
  items: any[];
  selectedId: number | undefined;
  activeId: number | undefined;
  onClick: (groupId: number) => void;
}

export function PropertyGroupList(props: PropertyGroupListProps) {
  const { items, selectedId, onClick, activeId } = props;
  const { t } = useTranslation();

  return (
    <PropertyGroupListWrapper>
      <ItemListHeader
        text={t("scale.manage.propertyGroups.group", "Property group")}
      />
      <ItemList
        items={items}
        selectedId={selectedId}
        activeId={activeId}
        onClick={onClick}
        noDataText={t("scale.manage.propertyGroups.noGroups", "No groups")}
      />
    </PropertyGroupListWrapper>
  );
}
