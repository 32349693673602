import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { findGroupById, getParentChain } from "../utils/group";
import {
  deleteDeviceErrorTask,
  getDeviceErrorTaskAffectedDevices,
  getDeviceErrorTaskDetail,
  getDeviceErrorTaskExecutionHistory,
  getDeviceErrorTaskList,
  patchDeviceErrorTask,
  postDeviceErrorTask,
} from "../api/devices";
import { groupHierarchiesSelector } from "../selectors/groups";
import {
  DeviceErrorTaskAffectedDevicesApi,
  DeviceErrorTaskCreateApi,
} from "@shared/apiTypes";
import { toast } from "react-toastify";
import { DeviceErrorTaskStates } from "@shared/types";
import { DeviceErrorTaskUpdateApi } from "@shared/apiTypes";
import { possibleDeviceErrorTaskStates } from "@shared/constants";

const deviceErrorTaskKeys = {
  all: ["deviceErrorTasks"] as const,
  lists: () => [...deviceErrorTaskKeys.all, "list"] as const,
  details: () => [...deviceErrorTaskKeys.all, "detail"] as const,
  detail: (id: number) => [...deviceErrorTaskKeys.details(), id] as const,
  histories: (opts: { start: Date; end: Date }) =>
    [
      ...deviceErrorTaskKeys.all,
      "history",
      { start: opts.start.toISOString(), end: opts.end.toISOString() },
    ] as const,
  affectedDevices: (groupId: number | undefined) =>
    [...deviceErrorTaskKeys.all, "affectedDevices", { groupId }] as const,
};

export function useDeviceErrorTaskList() {
  const token = useSelector((state: any) => state.token.key);
  const groupHierarchy = useSelector(groupHierarchiesSelector);

  return useQuery(
    deviceErrorTaskKeys.lists(),
    () => getDeviceErrorTaskList({ token }),
    {
      select: (errorTasks) =>
        errorTasks.map((task) => {
          const group = findGroupById(groupHierarchy, task.groupId);
          const groupParentChain = getParentChain(groupHierarchy, task.groupId);

          return {
            ...task,
            groupName: group?.name,
            parentGroupNames:
              groupParentChain?.map((parent) => parent.name) ?? [],
          };
        }),
    }
  );
}

export function useDeviceErrorTaskDetail(taskId: number) {
  const token = useSelector((state: any) => state.token.key);
  const groupHierarchy = useSelector(groupHierarchiesSelector);

  return useQuery(
    deviceErrorTaskKeys.detail(taskId),
    () => getDeviceErrorTaskDetail({ token, taskId }),
    {
      select: (errorTask) => ({
        ...errorTask,
        groupName: findGroupById(groupHierarchy, errorTask.groupId)?.name,
      }),
    }
  );
}

function useDeviceErrorTaskAffectedDevices<TSelect>(
  groupId: number | undefined,
  opts: {
    select?: (data: DeviceErrorTaskAffectedDevicesApi) => TSelect;
    enabled?: boolean;
  } = {}
) {
  const token = useSelector((state: any) => state.token.key);

  return useQuery(
    deviceErrorTaskKeys.affectedDevices(groupId),
    () =>
      getDeviceErrorTaskAffectedDevices({
        groupId,
        token,
      }),
    { select: opts.select, enabled: opts.enabled }
  );
}

function addTriggerStatesToAffectedDevices(
  affectedDevices: DeviceErrorTaskAffectedDevicesApi,
  deviceIdToStates: Map<number, DeviceErrorTaskStates[]>
) {
  const addTriggerStates = (
    devices: DeviceErrorTaskAffectedDevicesApi["childGroupDevices"],
    owner: "self" | "child"
  ) =>
    devices.map((dev) => ({
      ...dev,
      owner,
      triggerStates: new Map(
        possibleDeviceErrorTaskStates.map((state) => [
          state,
          (deviceIdToStates.get(dev.id) ?? []).includes(state),
        ])
      ),
    }));

  return [
    ...addTriggerStates(affectedDevices.groupDevices, "self"),
    ...addTriggerStates(affectedDevices.childGroupDevices, "child"),
  ];
}

export function useDeviceErrorTaskCreateFormData(groupId: number) {
  return useDeviceErrorTaskAffectedDevices(groupId, {
    select: (affectedDevices) => ({
      deviceTriggerStates: addTriggerStatesToAffectedDevices(
        affectedDevices,
        new Map()
      ),
    }),
  });
}

export function useDeviceErrorTaskUpdateFormData(taskId: number) {
  const errorTask = useDeviceErrorTaskDetail(taskId);

  return useDeviceErrorTaskAffectedDevices(errorTask.data?.groupId, {
    enabled: errorTask.data?.id !== undefined,
    select: (affectedDevices) => {
      if (!errorTask.data) {
        return;
      }

      const devIdToTriggerState = errorTask.data.sharedForAllDevices
        ? new Map<number, DeviceErrorTaskStates[]>()
        : new Map(
            errorTask.data.deviceTriggerStates.map((devStates) => [
              devStates.deviceId,
              devStates.triggerStates,
            ])
          );

      return {
        ...errorTask.data,
        deviceTriggerStates: addTriggerStatesToAffectedDevices(
          affectedDevices,
          devIdToTriggerState
        ),
      };
    },
  });
}

export function useDeviceErrorTaskExecutionHistory(params: {
  start: Date;
  end: Date;
}) {
  const token = useSelector((state: any) => state.token.key);
  const groupHierarchy = useSelector(groupHierarchiesSelector);

  return useQuery(
    deviceErrorTaskKeys.histories(params),
    () =>
      getDeviceErrorTaskExecutionHistory({
        ...params,
        token,
      }),
    {
      select: (history) =>
        history.map((entry) =>
          !entry.errorTask
            ? entry
            : {
                ...entry,
                errorTask: {
                  ...entry.errorTask,
                  groupName: findGroupById(
                    groupHierarchy,
                    entry.errorTask.groupId
                  )?.name,
                },
              }
        ),
    }
  );
}

export function useCreateDeviceErrorTask() {
  const token = useSelector((state: any) => state.token.key);
  const queryClient = useQueryClient();

  return useMutation(
    (task: DeviceErrorTaskCreateApi) => postDeviceErrorTask({ token, task }),
    {
      onSuccess: () => {
        toast.success("Succesfully created error task");
        return queryClient.invalidateQueries(["deviceErrorTask"]);
      },
    }
  );
}

export function useUpdateDeviceErrorTask() {
  const token = useSelector((state: any) => state.token.key);
  const queryClient = useQueryClient();

  return useMutation(
    (data: { taskId: number; task: DeviceErrorTaskUpdateApi }) =>
      patchDeviceErrorTask({ token, task: data.task, taskId: data.taskId }),
    {
      onSuccess: () => {
        toast.success("Succesfully updated error task");
        return queryClient.invalidateQueries(["deviceErrorTask"]);
      },
    }
  );
}

export function useDeleteDeviceErrorTask() {
  const token = useSelector((state: any) => state.token.key);
  const queryClient = useQueryClient();

  return useMutation(
    (taskId: number) => deleteDeviceErrorTask({ token, taskId }),
    {
      onSuccess: () => {
        toast.success("Succesfully deleted error task");
        return queryClient.invalidateQueries(["deviceErrorTask"]);
      },
    }
  );
}
