import { useEffect, useMemo, useState } from "react";
import _ from "lodash/fp";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { PrimaryButtonLarge } from "../../../components/Common/Button";
import { Table } from "../../../components/Common/Table";
import { Spinner } from "../../../components/Spinner";
import {
  deviceLicensesSelector,
  groupLicensesSelector,
  isLicenseLoadingSelector,
} from "../../../selectors/admin";
import * as TYPES from "../../../constants/actionTypes";
import { parseISO } from "date-fns";
import { Selector } from "../../../components/Common/Selector";
import { DeviceType, GroupType } from "../../../interfaces/types";
import { DropdownCombobox } from "../../../components/Common/DropdownCombobox";
import { groupHierarchiesSelector } from "../../../selectors/groups";
import { getParentChain } from "../../../utils/group";
import * as actions from "../../../constants/actionTypes";
import { TreeNode } from "../../../reducers/groups";

const isoStringToDateString = (isoString: string) =>
  parseISO(isoString).toLocaleDateString();

type DeviceTableRow = ReturnType<typeof deviceLicensesSelector>[0];
const constantDeviceLicensesTableProps = {
  columns: [
    { Header: "Group", accessor: "groupName", width: 2 },
    {
      Header: "Group type",
      accessor: ({ groupType }: DeviceTableRow) =>
        groupType === GroupType.OPERATOR ? "Operator" : "Owner",
      width: 1,
      Cell: (props: any) => (
        <div style={{ textAlign: "center" }}>{props.cell.value}</div>
      ),
      disableFilters: true,
    },
    {
      Header: "Parent groups",
      accessor: ({ groupParentChain }: DeviceTableRow) =>
        groupParentChain
          ? _.reverse(groupParentChain)
              .map((parentGroup) => parentGroup.name)
              .join(" / ")
          : null,
      width: 4,
    },
    { Header: "Serial number", accessor: "deviceSerialNumber", width: 2 },
    { Header: "Site", accessor: "deviceSite", width: 3 },
    {
      Header: "Insights",
      accessor: ({ insights }: any) =>
        insights ? isoStringToDateString(insights) : null,
      id: "insights",
      disableFilters: true,
      width: 2,
    },
    {
      Header: "Integrated",
      accessor: ({ integrated }: any) =>
        integrated ? isoStringToDateString(integrated) : null,
      id: "integrated",
      disableFilters: true,
      width: 2,
    },
  ],
  initialSortBy: [{ id: "insights" }],
  enableFilters: true,
  infiniteScroll: true,
};
function DeviceLicensesTable({ licenses }: any) {
  const data = useMemo(() => licenses, [licenses]);

  return <Table data={data} {...constantDeviceLicensesTableProps} />;
}

type GroupTableRow = ReturnType<typeof groupLicensesSelector>[0];
const constantGroupLicensesTableProps = {
  columns: [
    // Width specifies flex-basis, i.e., the relative amount of
    // space the column should occupy
    { Header: "Group", accessor: "groupName", width: 3 },
    {
      Header: "Group type",
      accessor: ({ groupType }: GroupTableRow) =>
        groupType === GroupType.OPERATOR ? "Operator" : "Owner",
      width: 1.5,
      Cell: (props: any) => (
        <div style={{ textAlign: "center" }}>{props.cell.value}</div>
      ),
      disableFilters: true,
    },
    {
      Header: "Parent groups",
      accessor: ({ groupParentChain }: GroupTableRow) =>
        groupParentChain
          ? _.reverse(groupParentChain)
              .map((parentGroup) => parentGroup.name)
              .join(" / ")
          : null,
      width: 6,
    },
    {
      Header: "Intro",
      accessor: ({ intro }: GroupTableRow) =>
        intro ? isoStringToDateString(intro) : null,
      id: "intro",
      width: 2,
      disableFilters: true,
    },
    {
      Header: "Insights",
      accessor: ({ insights }: GroupTableRow) =>
        insights ? isoStringToDateString(insights) : null,
      id: "insights",
      width: 2,
      disableFilters: true,
    },
    {
      Header: "Integrated",
      accessor: ({ integrated }: GroupTableRow) =>
        integrated ? isoStringToDateString(integrated) : null,
      id: "integrated",
      width: 2,
      disableFilters: true,
    },
  ],
  initialSortBy: [{ id: "intro" }],
  infiniteScroll: true,
  enableFilters: true,
};
function GroupLicensesTable({ licenses }: any) {
  const data = useMemo(() => licenses, [licenses]);

  return <Table data={data} {...constantGroupLicensesTableProps} />;
}

const deviceToString = (device: DeviceType | null): string => {
  if (!device) {
    return "";
  }

  const siteStr = device.site ? ` / ${device.site}` : "";
  return `${device.serialNumber}${siteStr}`;
};

const insightsGroupInfo = (
  deviceLicenses: any,
  groupLicenses: any,
  hierarchies: TreeNode[],
  deviceSerial: string | undefined,
  groupId: number | undefined
): string => {
  if (deviceSerial) {
    const deviceLicense = deviceLicenses.find(
      (license: { deviceSerialNumber: string }) =>
        license.deviceSerialNumber === deviceSerial
    );
    if (new Date(deviceLicense?.insights) > new Date()) {
      return `${deviceSerial}: Device insights license found
      that expires on ${isoStringToDateString(deviceLicense?.insights)}`;
    }
  }

  if (!groupId) {
    return "";
  }

  const groupLicense = groupLicenses.find(
    (license: any) => license?.groupId === groupId
  );

  if (
    new Date(groupLicense?.insights) > new Date() &&
    groupLicense?.groupName !== "Europress"
  ) {
    return `${deviceSerial}: Parent group '${groupLicense?.groupName}'
      has insights license that expires on
      ${isoStringToDateString(groupLicense?.insights)}`;
  }

  const parentChain = getParentChain(hierarchies, groupId);

  for (let index = parentChain!.length - 1; index >= 0; index--) {
    const groupId = parentChain![index].groupId;
    const groupLicense = groupLicenses.find(
      (license: any) => license?.groupId === groupId
    );
    if (
      new Date(groupLicense?.insights) > new Date() &&
      groupLicense?.groupName !== "Europress"
    ) {
      return `${deviceSerial}: Closest insights license
        is at '${groupLicense?.groupName}' group that expires
        on ${isoStringToDateString(groupLicense?.insights)}`;
    }
  }

  return `${deviceSerial}: Insights license not found`;
};

function DeviceSelect({
  devices,
  onSelect,
}: {
  devices: DeviceType[];
  onSelect: (device: DeviceType | undefined | null) => void;
}) {
  const [searchDevices, setSearchDevices] = useState(devices);

  useEffect(() => {
    setSearchDevices(devices);
  }, [devices]);

  return (
    <DropdownCombobox
      // Show at most 50 elements to prevent slowness for thousands of devices
      items={searchDevices.slice(0, 50)}
      itemToString={deviceToString}
      onSelectedItemChange={({ selectedItem }) => onSelect(selectedItem)}
      labelString="Search for insights license"
      placeholder="Enter serial number or alias"
      width="33%"
      onInputValueChange={({ inputValue }) => {
        if (inputValue === undefined) {
          setSearchDevices(devices);
        } else {
          setSearchDevices(
            devices.filter((device) =>
              deviceToString(device)
                .toLowerCase()
                .includes(inputValue?.toLowerCase())
            )
          );
        }
      }}
    />
  );
}

const SearchContainer = styled.div`
  display: flex;
  padding: 2rem;
  padding-left: 0rem;
`;

const Page = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 1rem;
  background-color: var(--ice-blue);
`;
const LicensesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 4px;
  padding: 1rem;
  background-color: var(--background);

  > a {
    margin-bottom: 2rem;
  }

  > .selector {
    align-self: flex-end;
    margin-bottom: 1rem;
  }

  > table {
    width: 100%;
  }
`;
export function Licenses() {
  const dispatch = useDispatch();
  const token = useSelector((state: any) => state.token.key);
  const isLoading = useSelector(isLicenseLoadingSelector);
  const [tableType, setTableType] = useState<"group" | "devices">("group");

  const [insightsInfoString, setInsightsInfoString] = useState<
    undefined | string
  >();
  const devices: DeviceType[] = useSelector((state: any) =>
    _.sortBy("serialNumber", state.devices.elements)
  );
  const hierarchies = useSelector(groupHierarchiesSelector);
  const groupLicenses = useSelector(groupLicensesSelector);
  const deviceLicenses = useSelector(deviceLicensesSelector);

  useEffect(() => {
    if (devices.length === 0) {
      dispatch({
        type: actions.FETCH_DEVICES,
        payload: { token, initialFetch: false },
      });
    }
    // eslint-disable-next-line
  }, [dispatch, token]);

  useEffect(() => {
    dispatch({
      type: TYPES.GET_ALL_LICENSES,
      payload: { token },
    });
  }, [dispatch, token]);

  return (
    <Page>
      <LicensesContainer>
        <Link to={`/admin/licenses/groups/`}>
          <PrimaryButtonLarge>Edit licenses</PrimaryButtonLarge>
        </Link>
        <Selector<typeof tableType>
          values={[
            { id: "group", title: "Group licenses" },
            { id: "devices", title: "Device licenses" },
          ]}
          selected={tableType}
          onSelect={setTableType}
        />
        {isLoading ? (
          <Spinner />
        ) : tableType === "group" ? (
          <GroupLicensesTable licenses={groupLicenses} />
        ) : (
          <DeviceLicensesTable licenses={deviceLicenses} />
        )}
        <SearchContainer>
          <DeviceSelect
            devices={devices}
            onSelect={(device) =>
              setInsightsInfoString(
                insightsGroupInfo(
                  deviceLicenses,
                  groupLicenses,
                  hierarchies,
                  device?.serialNumber,
                  device?.ownerGroupId
                )
              )
            }
          />
        </SearchContainer>
        {insightsInfoString ? <p>{insightsInfoString}</p> : <p></p>}
      </LicensesContainer>
    </Page>
  );
}
