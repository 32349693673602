import { useTranslation } from "react-i18next";
import { Modal } from "./Common/Modal";

interface AboutModalProps {
  aboutOpen: boolean;
  handleCloseAbout: () => void;
}

export function AboutModal(props: AboutModalProps) {
  const { t } = useTranslation();
  const version = process.env.REACT_APP_VERSION;

  const content: JSX.Element = (
    <>
      <h3>
        {t("header.userMenu.aboutModal.version", "Version")} {version}
      </h3>
      <div>
        {t(
          "header.userMenu.aboutModal.copyright",
          "Copyright © 2019 Europress"
        )}
      </div>
    </>
  );

  return (
    <Modal
      isOpen={props.aboutOpen}
      handleClose={props.handleCloseAbout}
      content={content}
      title={t("header.userMenu.aboutModal.header", "Europress SMART")}
    />
  );
}
