import React from "react";
import { FormRectangle } from "../../../components/Common/FormRectangle";
import { FormTitle } from "../../../components/Common/FormTitle";
import { FormWrapper } from "../../../components/Common/FormWrapper";
import { GroupSelect } from "../GroupSelect";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import { Link, Switch, Route, RouteComponentProps } from "react-router-dom";
import * as Yup from "yup";
import { FormSubmitButton } from "../../../components/Common/FormSubmitButton";
import { FlexBoxWrapper } from "../../../components/Common";
import { EditGroupLicensesForm } from "./EditGroupLicensesForm";
import styled from "styled-components";
import { groupHierarchiesSelector } from "../../../selectors/groups";

interface SelectGroupFormValues {
  groupId: number | null;
}
const SelectGroupFormSchema = Yup.object().shape({
  groupId: Yup.mixed().required("Group needs to be selected"),
});
function SelectGroupForm(props: RouteComponentProps) {
  const groupHierarchy = useSelector(groupHierarchiesSelector);

  return (
    <Formik<SelectGroupFormValues>
      initialValues={{
        groupId: null,
      }}
      onSubmit={({ groupId }) =>
        props.history.push(`/admin/licenses/groups/${groupId}`)
      }
      validationSchema={SelectGroupFormSchema}
    >
      {(props) => (
        <FormWrapper>
          <GroupSelect
            id="groupId"
            value={props.values.groupId}
            onChange={props.setFieldValue}
            error={
              props.touched.groupId && props.errors.groupId
                ? props.errors.groupId
                : undefined
            }
            options={groupHierarchy}
          />
          <FlexBoxWrapper>
            <Link to="/admin/licenses">
              <FormSubmitButton>Back</FormSubmitButton>
            </Link>
            &nbsp;
            <FormSubmitButton type="submit">Next</FormSubmitButton>
          </FlexBoxWrapper>
        </FormWrapper>
      )}
    </Formik>
  );
}

const Wrapper = styled.div`
  min-height: 100%;
  padding-top: 2rem;
  background-color: var(--seafoam-blue-with-opacity);
`;
export function GroupLicensesForm() {
  return (
    <Wrapper>
      <FormRectangle style={{ width: "40rem" }}>
        <FormTitle title="Edit licenses" />
        <Switch>
          <Route
            exact
            path="/admin/licenses/groups"
            component={SelectGroupForm}
          />
          <Route
            path="/admin/licenses/groups/:groupId"
            component={EditGroupLicensesForm}
          />
        </Switch>
      </FormRectangle>
    </Wrapper>
  );
}
