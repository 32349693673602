import styled from "styled-components";
import { useCombobox, UseComboboxStateChange } from "downshift";
import { HTMLInput } from "./FormInput";

const Label = styled.label`
  font-family: Poppins;
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--col-1-c-2020);
`;

const Input = styled(HTMLInput)<{ itemsVisible: boolean }>`
  width: 100%;
  border-radius: ${(props) => (props.itemsVisible ? "1rem 1rem 0 0" : "1rem")};
  color: var(--col-1-c-2020);
  font-size: 1rem;
  padding: 0.5rem 0.7rem;

  &:focus {
    box-shadow: none;
  }
`;

const Item = styled.li<{ isHighlighted: boolean }>`
  color: var(--col-1-c-2020);
  font-size: 0.875rem;
  padding: 0.3rem 0.7rem;
  background-color: ${(props) =>
    props.isHighlighted ? "var(--seafoam-blue-with-opacity)" : "inherit"};
`;

const SpacerInner = styled.div`
  background-color: var(--light-blue-grey);
  height: 1px;
  margin: 0 0.5rem 0.2rem;
`;

const Spacer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  background-color: white;
`;

const Menu = styled.ul`
  max-height: 10rem;
  width: 100%;
  overflow-y: scroll;
  background-color: white;
  padding: 0;
  margin: 0;
  list-style: none;
`;

const MenuWrapper = styled.div<{ itemsVisible: boolean }>`
  display: ${(props) => (props.itemsVisible ? "initial" : "none")};
  position: absolute;
  width: 100%;
  background-color: white;
  border-radius: 0 0 1rem 1rem;
  z-index: 1000;
  overflow: hidden;
`;

const Wrapper = styled.div<{ width?: string }>`
  position: relative;
  width: ${(props) => props.width ?? "initial"};
`;

export function DropdownCombobox<Item>({
  items,
  itemToString,
  onInputValueChange,
  onSelectedItemChange,
  labelString,
  placeholder,
  width,
}: {
  items: Item[];
  itemToString: (item: Item | null) => string;
  onInputValueChange: (inputChange: UseComboboxStateChange<Item>) => void;
  onSelectedItemChange: (inputChange: UseComboboxStateChange<Item>) => void;
  labelString: string;
  placeholder: string;
  width?: string;
}) {
  const {
    isOpen,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox<Item>({
    items,
    itemToString,
    onInputValueChange,
    onSelectedItemChange,
  });

  const itemsVisible = isOpen && 0 < items.length;

  return (
    <Wrapper width={width}>
      <Label {...getLabelProps()}>{labelString}</Label>
      <div {...getComboboxProps()}>
        <Input
          itemsVisible={itemsVisible}
          {...getInputProps({ placeholder })}
        />
      </div>
      <MenuWrapper itemsVisible={itemsVisible}>
        <Spacer>
          <SpacerInner />
        </Spacer>
        <Menu {...getMenuProps()}>
          {isOpen &&
            items.map((item, index) => (
              <Item
                isHighlighted={highlightedIndex === index}
                key={`${item}${index}`}
                {...getItemProps({ item, index })}
              >
                {itemToString(item)}
              </Item>
            ))}
        </Menu>
      </MenuWrapper>
    </Wrapper>
  );
}
