import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Spinner } from "../Spinner";
import styled from "styled-components";
import { DeviceType } from "../../interfaces/types";
import { useTranslation } from "react-i18next";
import { getDataAnalyticsToken } from "../../api/data-analytics";

const Container = styled.iframe`
  border-width: 0;
  height: 100%;
  width: 100%;
`;

const Content = styled.div`
  height: 100%;
  width: 100%;
`;
const ContainerAccessBox = styled.div`
  margin-top: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Box = styled.div`
  border: 1px solid;
  padding: 10px;
  box-shadow: 5px 10px #888888;
`;

interface DataAnalyticsProps {
  device: DeviceType;
}

export function DataAnalytics(props: DataAnalyticsProps) {
  const [analyticsToken, setAnalyticsToken] = useState<string | null>(null);
  const [analyticsOrigin, setAnalyticsOrigin] = useState<string | null>(null);
  const [fetchState, setFetchState] = useState<
    "initial" | "fetching" | "done" | "notAllowed" | "error"
  >("initial");

  const token = useSelector((state: any) => state.token.key);
  const language = useSelector((state: any) => state.users.language) || "en";
  const dataAnalyticsAllowed = useSelector(
    (state: any) => state.users.dataAnalyticsAllowed
  );

  const { t } = useTranslation();

  useEffect(() => {
    const fetchNotStarted = fetchState === "initial";
    const canFetchAnalyticsToken = token && dataAnalyticsAllowed;

    if (fetchNotStarted && canFetchAnalyticsToken) {
      const fetchData = async () => {
        try {
          setFetchState("fetching");
          const res = await getDataAnalyticsToken(
            token,
            props.device.serialNumber
          );

          setFetchState("done");
          setAnalyticsOrigin(res.origin);
          setAnalyticsToken(res.access_token);
        } catch (error) {
          setFetchState(
            error.response?.status === 403 ? "notAllowed" : "error"
          );
        }
      };

      fetchData();
    }
  }, [token, dataAnalyticsAllowed, fetchState, analyticsToken, props.device]);

  if (dataAnalyticsAllowed) {
    const getAnalyticsUrl = () => {
      if (props.device.guid) {
        return `${analyticsOrigin}/europress/dashboard/device/${props.device.guid}/?access_token=${analyticsToken}&lang=${language}`;
      }
      return undefined;
    };

    if (!props.device) {
      return null;
    }

    if (fetchState === "initial" || fetchState === "fetching") {
      return <Spinner />;
    }

    if (fetchState === "error" || fetchState === "notAllowed") {
      return (
        <Content>
          <ContainerAccessBox>
            <Box>
              {fetchState === "notAllowed"
                ? t(
                    "analytics.license_needed",
                    "Accessing Data Analytics needs active insights license. Please contact Europress."
                  )
                : t(
                    "analytics.couldNotConnect",
                    "Could not connect to data analytics. Please try again later."
                  )}
            </Box>
          </ContainerAccessBox>
        </Content>
      );
    }

    const url = getAnalyticsUrl();
    if (url) {
      return <Container src={url} />;
    }
    return null;
  }

  return (
    <Content>
      <ContainerAccessBox>
        <Box>
          {t(
            "analytics.license_needed",
            "Accessing Data Analytics needs active insights license. Please contact Europress."
          )}
        </Box>
      </ContainerAccessBox>
    </Content>
  );
}
