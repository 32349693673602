import { call, put } from "redux-saga/effects";
import * as groupApi from "../api/groups";
import * as TYPES from "../constants/actionTypes";
import { replace } from "connected-react-router";
import { NotifySuccess, NotifyError } from "../components/Common/Notify";

/**
 * Fetch group hierarchy action. IF fetch fails puts action FETCH_GROUP_HIERARCHY_FAILURE. For success passes data
 * through FETCH_GROUP_HIERARCHY_SUCCESS action.
 * @export
 * @param {*} action
 */
export function* fetchGroupHierarchy(action: any) {
  try {
    const result = yield call(groupApi.getGroupHierarchy, action);
    yield put({
      type: TYPES.FETCH_GROUP_HIERARCHY_SUCCESS,
      payload: result.data,
    });
  } catch (err) {
    yield put({ type: TYPES.FETCH_GROUP_HIERARCHY_FAILURE });
  }
}

export function* removeGroupForAdmin(action: TYPES.DeleteGroupAction) {
  try {
    const result = yield call(groupApi.deleteGroupForAdmin, action);
    yield put({
      type: TYPES.DELETE_GROUP_FOR_ADMIN_SUCCESS,
      payload: result.data,
    });
    // Trick to refresh groups view after delete
    yield put(replace("/reload"));
    yield put(replace("/admin/groups"));
    NotifySuccess(TYPES.DELETE_GROUP_FOR_ADMIN_SUCCESS);
  } catch (err) {
    yield put({
      type: TYPES.DELETE_GROUP_FOR_ADMIN_FAILURE,
    });
    NotifyError(
      TYPES.DELETE_GROUP_FOR_ADMIN_FAILURE,
      err?.response?.data?.error
    );
  }
}

export function* fetchAllLicenses(action: any) {
  try {
    const response = yield call(groupApi.getAllLicenses, action);
    yield put({
      type: TYPES.GET_ALL_LICENSES_SUCCESS,
      payload: {
        licenses: response.data,
      },
    });
  } catch (error) {
    yield put({ type: TYPES.GET_ALL_LICENSES_FAILURE });
    NotifyError(TYPES.GET_ALL_LICENSES_FAILURE);
  }
}

export function* fetchGroupLicenses(action: any) {
  try {
    const response = yield call(groupApi.getGroupLicenses, action);
    yield put({
      type: TYPES.GET_GROUP_LICENSES_SUCCESS,
      payload: {
        licenses: response.data,
        groupId: action.payload.groupId,
      },
    });
  } catch (error) {
    yield put({ type: TYPES.GET_GROUP_LICENSES_FAILURE });
    NotifyError(TYPES.GET_GROUP_LICENSES_FAILURE);
  }
}

export function* updateGroupLicenses(action: any) {
  try {
    yield call(groupApi.putGroupLicenses, action);
    yield put({
      type: TYPES.UPDATE_GROUP_LICENSES_SUCCESS,
      payload: {
        licenses: action.payload.licenses,
        groupId: action.payload.groupId,
      },
    });
    NotifySuccess(TYPES.UPDATE_GROUP_LICENSES_SUCCESS);
  } catch (error) {
    yield put({ type: TYPES.UPDATE_GROUP_LICENSES_FAILURE });
    NotifyError(
      TYPES.UPDATE_GROUP_LICENSES_FAILURE,
      error?.response?.data?.error
    );
  }
}

export function* addScaleTagToGroup(action: any) {
  try {
    const result = yield call(groupApi.postScaleTagToGroup, action);
    yield put({
      type: TYPES.ADMIN_ADD_UNASSIGNED_SCALE_TAG_SUCCESS,
      payload: result.data,
    });
  } catch (err) {
    yield put({ type: TYPES.ADMIN_ADD_UNASSIGNED_SCALE_TAG_FAILURE });
    NotifyError(TYPES.ADMIN_ADD_UNASSIGNED_SCALE_TAG_FAILURE);
  }
}
export function* removeScaleTagFromGroup(action: any) {
  try {
    const result = yield call(groupApi.postRemoveTagFromGroup, action);
    yield put({
      type: TYPES.ADMIN_REMOVE_SCALE_TAG_SUCCESS,
      payload: result.data,
    });
  } catch (err) {
    yield put({ type: TYPES.ADMIN_REMOVE_SCALE_TAG_FAILURE });
  }
}
export function* revokeScaleTag(action: any) {
  try {
    const result = yield call(groupApi.patchRevokeTag, action);
    yield put({
      type: TYPES.REVOKE_SCALE_TAG_SUCCESS,
      payload: result.data,
    });
  } catch (err) {
    yield put({ type: TYPES.REVOKE_SCALE_TAG_FAILURE });
  }
}
export function* unrevokeScaleTag(action: any) {
  try {
    const result = yield call(groupApi.patchUnrevokeTag, action);
    yield put({
      type: TYPES.UNREVOKE_SCALE_TAG_SUCCESS,
      payload: result.data,
    });
  } catch (err) {
    yield put({ type: TYPES.UNREVOKE_SCALE_TAG_FAILURE });
  }
}
export function* assignScaleTag(action: any) {
  try {
    const result = yield call(groupApi.patchAssignTag, action);
    yield put({
      type: TYPES.ASSIGN_SCALE_TAG_SUCCESS,
      payload: result.data,
    });
  } catch (err) {
    yield put({ type: TYPES.ASSIGN_SCALE_TAG_FAILURE });
  }
}
export function* unassignScaleTag(action: any) {
  try {
    const result = yield call(groupApi.patchUnassignTag, action);
    yield put({
      type: TYPES.UNASSIGN_SCALE_TAG_SUCCESS,
      payload: result.data,
    });
  } catch (err) {
    yield put({ type: TYPES.UNASSIGN_SCALE_TAG_FAILURE });
  }
}
export function* fetchGroupScaleTags(action: any) {
  try {
    const result = yield call(groupApi.getGroupScaleTags, action);
    yield put({
      type: TYPES.FETCH_GROUP_SCALE_TAGS_SUCCESS,
      payload: result.data,
    });
  } catch (err) {
    yield put({ type: TYPES.FETCH_GROUP_SCALE_TAGS_FAILURE });
  }
}

export function* fetchAdminGroupScaleTags(action: any) {
  try {
    const result = yield call(groupApi.getAdminGroupScaleTags, action);
    yield put({
      type: TYPES.FETCH_ADMIN_GROUP_SCALE_TAGS_SUCCESS,
      payload: result.data,
    });
  } catch (err) {
    yield put({ type: TYPES.FETCH_ADMIN_GROUP_SCALE_TAGS_FAILURE });
  }
}

export function* fetchUserGroupsForGroup(action: any) {
  try {
    const result = yield call(groupApi.getUserGroupsForGroup, action);
    yield put({
      type: TYPES.FETCH_USER_GROUPS_FOR_GROUP_SUCCESS,
      payload: result.data,
    });
  } catch (err) {
    yield put({ type: TYPES.FETCH_USER_GROUPS_FOR_GROUP_FAILURE });
  }
}

export function* createScaleUserGroup(action: any) {
  try {
    const result = yield call(groupApi.postCreateScaleUserGroup, action);
    yield put({
      type: TYPES.CREATE_SCALE_USER_GROUP_SUCCESS,
      payload: result.data,
    });
  } catch (err) {
    yield put({ type: TYPES.CREATE_SCALE_USER_GROUP_FAILURE });
    NotifyError(
      TYPES.CREATE_SCALE_USER_GROUP_FAILURE,
      err?.response?.data?.error
    );
  }
}

export function* deleteScaleUserGroup(action: any) {
  try {
    yield call(groupApi.deleteRemoveScaleUserGroup, action);
    yield put({
      type: TYPES.DELETE_SCALE_USER_GROUP_SUCCESS,
      payload: action.payload.id,
    });
  } catch (err) {
    yield put({ type: TYPES.DELETE_SCALE_USER_GROUP_FAILURE });
    NotifyError(TYPES.DELETE_SCALE_USER_GROUP_FAILURE);
  }
}

export function* updateScaleUserGroup(action: any) {
  try {
    const result = yield call(groupApi.putScaleUserGroup, action);
    yield put({
      type: TYPES.UPDATE_SCALE_USER_GROUP_SUCCESS,
      payload: result.data,
    });
  } catch (err) {
    yield put({ type: TYPES.UPDATE_SCALE_USER_GROUP_FAILURE });
    NotifyError(
      TYPES.UPDATE_SCALE_USER_GROUP_FAILURE,
      err?.response?.data?.error
    );
  }
}

export function* fetchScaleReports(action: any) {
  try {
    const result = yield call(groupApi.getScaleReports, action);
    yield put({
      type: TYPES.FETCH_SCALE_REPORTS_SUCCESS,
      payload: result.data,
    });
  } catch (err) {
    yield put({ type: TYPES.FETCH_SCALE_REPORTS_FAILURE });
    NotifyError(TYPES.FETCH_SCALE_REPORTS_FAILURE);
  }
}

export function* fetchScaleResults(action: any) {
  try {
    const result = yield call(groupApi.getScaleResults, action);
    yield put({
      type: TYPES.FETCH_SCALE_RESULTS_SUCCESS,
      payload: result.data,
    });
  } catch (err) {
    yield put({ type: TYPES.FETCH_SCALE_RESULTS_FAILURE });
    NotifyError(TYPES.FETCH_SCALE_RESULTS_FAILURE);
  }
}

export function* fetchScaleReportMonths(action: any) {
  try {
    const result = yield call(groupApi.getScaleReportMonths, action);
    yield put({
      type: TYPES.FETCH_SCALE_REPORT_MONTHS_SUCCESS,
      payload: result.data,
    });
  } catch (err) {
    yield put({ type: TYPES.FETCH_SCALE_REPORT_MONTHS_FAILURE });
    NotifyError(TYPES.FETCH_SCALE_REPORT_MONTHS_FAILURE);
  }
}

export function* importScaleTags(action: any) {
  try {
    const result = yield call(groupApi.postImportScaleTags, action);
    yield put({
      type: TYPES.ADMIN_IMPORT_SCALE_TAGS_SUCCESS,
      payload: result.data,
    });
    NotifySuccess(TYPES.ADMIN_IMPORT_SCALE_TAGS_SUCCESS);
  } catch (err) {
    yield put({ type: TYPES.ADMIN_IMPORT_SCALE_TAGS_FAILURE });
    NotifyError(TYPES.ADMIN_IMPORT_SCALE_TAGS_FAILURE);
  }
}

export function* fetchScaleReportSettings(action: any) {
  try {
    const result = yield call(groupApi.getScaleReportSettings, action);
    yield put({
      type: TYPES.FETCH_SCALE_REPORT_SETTINGS_SUCCESS,
      payload: result.data,
    });
  } catch (err) {
    yield put({ type: TYPES.FETCH_SCALE_REPORT_SETTINGS_FAILURE });
    NotifyError(TYPES.FETCH_SCALE_REPORT_SETTINGS_FAILURE);
  }
}

export function* updateScaleReportSettings(action: any) {
  try {
    const result = yield call(groupApi.patchUpdateScaleReportSettings, action);
    yield put({
      type: TYPES.UPDATE_SCALE_REPORT_SETTINGS_SUCCESS,
      payload: result.data,
    });
    NotifySuccess(TYPES.UPDATE_SCALE_REPORT_SETTINGS_SUCCESS);
  } catch (err) {
    yield put({ type: TYPES.UPDATE_SCALE_REPORT_SETTINGS_FAILURE });
    NotifyError(TYPES.UPDATE_SCALE_REPORT_SETTINGS_FAILURE);
  }
}

export function* fetchScaleWeightLimitNotificationSettings(action: any) {
  try {
    const result = yield call(
      groupApi.getScaleTotalWeightLimitNotificationSettings,
      action
    );
    yield put({
      type: TYPES.FETCH_SCALE_TOTAL_WEIGHT_LIMIT_NOTIFICATION_SETTINGS_SUCCESS,
      payload: result.data,
    });
  } catch (err) {
    yield put({
      type: TYPES.FETCH_SCALE_TOTAL_WEIGHT_LIMIT_NOTIFICATION_SETTINGS_FAILURE,
    });
    NotifyError(
      TYPES.FETCH_SCALE_TOTAL_WEIGHT_LIMIT_NOTIFICATION_SETTINGS_FAILURE
    );
  }
}

export function* updateScaleTotalWeightLimitNotificationSettings(action: any) {
  try {
    const result = yield call(
      groupApi.patchScaleTotalWeightLimitNotificationSettings,
      action
    );
    yield put({
      type: TYPES.UPDATE_SCALE_TOTAL_WEIGHT_LIMIT_NOTIFICATION_SETTINGS_SUCCESS,
      payload: result.data,
    });
    NotifySuccess(
      TYPES.UPDATE_SCALE_TOTAL_WEIGHT_LIMIT_NOTIFICATION_SETTINGS_SUCCESS
    );
  } catch (err) {
    yield put({
      type: TYPES.UPDATE_SCALE_TOTAL_WEIGHT_LIMIT_NOTIFICATION_SETTINGS_FAILURE,
    });
    NotifyError(
      TYPES.UPDATE_SCALE_TOTAL_WEIGHT_LIMIT_NOTIFICATION_SETTINGS_FAILURE
    );
  }
}

export function* resendScaleReport(action: any) {
  try {
    const result = yield call(groupApi.postResendScaleReport, action);
    yield put({
      type: TYPES.RESEND_SCALE_REPORT_SUCCESS,
      payload: result.data,
    });
    NotifySuccess(TYPES.RESEND_SCALE_REPORT_SUCCESS);
  } catch (err) {
    yield put({ type: TYPES.RESEND_SCALE_REPORT_FAILURE });
    NotifyError(TYPES.RESEND_SCALE_REPORT_FAILURE);
  }
}
