import { useState } from "react";
import ReactModal from "react-modal";
import styled from "styled-components";
import { Icon } from "../Common/Icon";
import icon_close from "../../assets/icon-close.svg";

interface ModalProps {
  isOpen: boolean;
  handleClose: () => void;
  content: JSX.Element;
  title: JSX.Element | string;
  navigation?: JSX.Element;
  contentPadding?: number;
  contentScroll?: boolean;
  fullScreenEnabled?: boolean;
  width?: string;
  height?: string;
}

interface ModalStyleProps {
  fullScreen: boolean;
  width?: string;
  height?: string;
}
const getModalStyles = ({ fullScreen, width, height }: ModalStyleProps) => {
  return {
    overlay: {
      zIndex: 100,
      position: "fixed" as const,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.75)",
    },
    content: {
      position: "absolute" as const,
      overflowY: "hidden" as const,
      overflowX: "hidden" as const,
      top: !fullScreen ? "50%" : "auto",
      left: !fullScreen ? "50%" : "auto",
      border: "none",
      backgroundColor: "#ffff",
      transform: !fullScreen ? "translate(-50%, -50%)" : "none",
      outline: "none",
      height: !fullScreen ? height || "500px" : "100%",
      width: !fullScreen ? width || "815px" : "100%",
    },
  };
};

interface ModalGridProps {
  navigation?: JSX.Element;
}

const ModalGrid = styled.div<ModalGridProps>`
  display: flex;
  flex-direction: column;
  height: 100%;
  font-family: Poppins;
  font-weight: 500;

  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
`;

interface HeaderProps {
  fullScreenEnabled?: boolean;
}

const Header = styled.header<HeaderProps>`
  display: flex;
  align-items: center;
  background-color: var(--seafoam-blue);

  font-size: 18px;
  line-height: 1.11;
  color: var(--1-c-2020);
  min-height: 48px;
  padding-right: 16px;
  padding-left: 16px;

  > :nth-last-child(${(props) => (props.fullScreenEnabled ? 2 : 1)}) {
    margin-left: auto;
  }
`;

const Navigation = styled.div`
  background-color: white;
  min-height: 45px;

  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1.43;
  color: var(--col-214269);

  padding-right: 16px;
  padding-left: 16px;
`;

interface ContentProps {
  contentPadding?: number;
  contentScroll?: boolean;
}

const Content = styled.div<ContentProps>`
  flex: 1;
  font-size: 14px;
  line-height: 1.43;
  font-weight: 400;
  padding-left: ${(props) =>
    props.contentPadding !== undefined ? props.contentPadding : 16}px;
  padding-right: ${(props) =>
    props.contentPadding !== undefined ? props.contentPadding : 16}px;
  background-color: var(--ice-blue);
  overflow: ${(props) => (props.contentScroll ? "auto" : "hidden")};
`;

const FullScreenButton = styled.button`
  font-size: 16px;
  font-weight: 700;
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
  color: var(--ice-blue);
  padding-right: 0.8rem;
`;

export function Modal(props: ModalProps) {
  const [fullScreen, setFullScreen] = useState(false);

  const onToggleFullScreen = () => {
    setFullScreen((prevFullScreen) => !prevFullScreen);
  };

  return (
    <ReactModal
      isOpen={props.isOpen}
      style={getModalStyles({
        fullScreen,
        width: props.width,
        height: props.height,
      })}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      onRequestClose={props.handleClose}
      className="null"
    >
      <ModalGrid navigation={props.navigation}>
        <Header fullScreenEnabled={props.fullScreenEnabled}>
          {props.title}
          {props.fullScreenEnabled && (
            <FullScreenButton onClick={onToggleFullScreen}>⤢</FullScreenButton>
          )}

          <Icon
            src={icon_close}
            onClick={props.handleClose}
            cursor={"pointer"}
          />
        </Header>
        {props.navigation && <Navigation>{props.navigation}</Navigation>}
        <Content
          contentPadding={props.contentPadding}
          contentScroll={props.contentScroll || true}
        >
          {props.content}
        </Content>
      </ModalGrid>
    </ReactModal>
  );
}
