import { takeLatest, all, call } from "redux-saga/effects";

import * as TYPES from "../constants/actionTypes";
import { fetchToken } from "../actions/token-actions";
import {
  fetchGroupHierarchy,
  removeGroupForAdmin,
  fetchAllLicenses,
  fetchGroupLicenses,
  updateGroupLicenses,
  revokeScaleTag,
  unrevokeScaleTag,
  assignScaleTag,
  unassignScaleTag,
  addScaleTagToGroup,
  fetchGroupScaleTags,
  removeScaleTagFromGroup,
  fetchUserGroupsForGroup,
  createScaleUserGroup,
  fetchAdminGroupScaleTags,
  deleteScaleUserGroup,
  updateScaleUserGroup,
  fetchScaleReports,
  fetchScaleResults,
  fetchScaleReportMonths,
  fetchScaleReportSettings,
  updateScaleReportSettings,
  updateScaleTotalWeightLimitNotificationSettings,
  importScaleTags,
  resendScaleReport,
  fetchScaleWeightLimitNotificationSettings,
} from "../actions/groups-actions";
import * as deviceActions from "../actions/devices-actions";
import { logout } from "../actions/application-actions";
import {
  fetchUserForRegistration,
  registerUser,
  fetchUsersForAdmin,
  fetchUserForAdmin,
  sendRegisterLinkToUser,
  createUserForAdmin,
  updateUserForAdmin,
  deleteUserForAdmin,
  disableUserForAdmin,
  fetchUser,
  postDeviceColumnsForUser,
  postSkipColumnsForUser,
  resetPassword,
  forgotPassword,
  verifyResetPasswordToken,
  changeLanguage,
  loginToServiceTool,
  verifyMFAActionForUser,
  enableMFAForUser,
  disableMFAForUser,
  enableMFAForAdmin,
  disableMFAForAdmin,
  markSurveyAnswered,
} from "../actions/users-actions";
import {
  fetchScaleEvents,
  fetchScales,
  updateScale,
  fetchFirmwares,
  postScaleFirmwareFile,
  watchForScaleTagUpdates,
} from "../actions/scale-actions";
import {
  fetchDashboard,
  postDashboardWorkLogUpdate,
} from "../actions/dashboard-actions";
import { fetchAnomalies, updateAnomalies } from "../actions/anomaly-actions";

function* sagas() {
  yield all([
    takeLatest(TYPES.FETCH_TOKEN, fetchToken),
    takeLatest(TYPES.LOGOUT, logout),
    takeLatest(TYPES.FETCH_GROUP_HIERARCHY, fetchGroupHierarchy),
    takeLatest(TYPES.FETCH_USER, fetchUser),
    takeLatest(TYPES.FETCH_USER_FOR_REGISTRATION, fetchUserForRegistration),
    takeLatest(TYPES.REGISTER_USER, registerUser),
    takeLatest(TYPES.FETCH_USERS_FOR_ADMIN, fetchUsersForAdmin),
    takeLatest(TYPES.FETCH_USER_FOR_ADMIN, fetchUserForAdmin),
    takeLatest(TYPES.SEND_REGISTER_LINK_TO_USER, sendRegisterLinkToUser),
    takeLatest(TYPES.CREATE_USER_FOR_ADMIN, createUserForAdmin),
    takeLatest(TYPES.UPDATE_USER_FOR_ADMIN, updateUserForAdmin),
    takeLatest(TYPES.DELETE_USER_FOR_ADMIN, deleteUserForAdmin),
    takeLatest(TYPES.DISABLE_USER_FOR_ADMIN, disableUserForAdmin),
    takeLatest(TYPES.ENABLE_USER_MFA_FOR_ADMIN, enableMFAForAdmin),
    takeLatest(TYPES.DISABLE_USER_MFA_FOR_ADMIN, disableMFAForAdmin),
    takeLatest(TYPES.FETCH_DEVICES_ROUTES, deviceActions.fetchDevicesRoutes),
    takeLatest(TYPES.PATCH_DEVICE_DETAIL, deviceActions.patchDeviceDetails),
    takeLatest(TYPES.RESET_BALE_COUNTER, deviceActions.resetBaleCounter),
    takeLatest(TYPES.CHANGE_BALES_READY, deviceActions.updateBalesReady),
    takeLatest(TYPES.GET_ALL_HG_DEVICES, deviceActions.fetchAllHGDevices),
    takeLatest(TYPES.PATCH_HG_DEVICE, deviceActions.patchHGDevice),
    takeLatest(
      TYPES.GET_ALL_RETROFIT_LIGHT_DEVICES,
      deviceActions.fetchAllRetrofitLightDevices
    ),
    takeLatest(
      TYPES.PATCH_RETROFIT_LIGHT_DEVICE,
      deviceActions.patchRetrofitLightDevice
    ),
    takeLatest(TYPES.DEVICES_COLUMNS_SELECTED, postDeviceColumnsForUser),
    takeLatest(TYPES.SKIP_COLUMNS_SELECTED, postSkipColumnsForUser),
    takeLatest(TYPES.DO_SERVICE_TOOL_LOGIN, loginToServiceTool),
    takeLatest(TYPES.RESET_PASSWORD, resetPassword),
    takeLatest(TYPES.FORGOT_PASSWORD, forgotPassword),
    takeLatest(TYPES.VERIFY_RESET_PASSWORD_TOKEN, verifyResetPasswordToken),
    takeLatest(TYPES.CHANGE_LANGUAGE, changeLanguage),
    takeLatest(TYPES.ENABLE_MFA_FOR_USER, enableMFAForUser),
    takeLatest(TYPES.DISABLE_MFA_FOR_USER, disableMFAForUser),
    takeLatest(TYPES.VERIFY_MFA_ACTION, verifyMFAActionForUser),
    takeLatest(TYPES.GET_DEVICE_HISTORY, deviceActions.fetchDeviceHistory),
    takeLatest(TYPES.DELETE_GROUP_FOR_ADMIN, removeGroupForAdmin),
    takeLatest(TYPES.GET_GROUP_LICENSES, fetchGroupLicenses),
    takeLatest(TYPES.GET_ALL_LICENSES, fetchAllLicenses),
    takeLatest(TYPES.UPDATE_GROUP_LICENSES, updateGroupLicenses),
    takeLatest(TYPES.ADMIN_ADD_UNASSIGNED_SCALE_TAG, addScaleTagToGroup),
    takeLatest(TYPES.FETCH_GROUP_SCALE_TAGS, fetchGroupScaleTags),
    call(watchForScaleTagUpdates),
    takeLatest(TYPES.REVOKE_SCALE_TAG, revokeScaleTag),
    takeLatest(TYPES.ASSIGN_SCALE_TAG, assignScaleTag),
    takeLatest(TYPES.UNASSIGN_SCALE_TAG, unassignScaleTag),
    takeLatest(TYPES.UNREVOKE_SCALE_TAG, unrevokeScaleTag),
    takeLatest(TYPES.FETCH_ADMIN_GROUP_SCALE_TAGS, fetchAdminGroupScaleTags),
    takeLatest(TYPES.ADMIN_REMOVE_SCALE_TAG, removeScaleTagFromGroup),
    takeLatest(TYPES.FETCH_USER_GROUPS_FOR_GROUP, fetchUserGroupsForGroup),
    takeLatest(TYPES.FETCH_SCALE_EVENTS, fetchScaleEvents),
    takeLatest(
      TYPES.UPDATE_OWNER_GROUP_FOR_DEVICES,
      deviceActions.updateOwnerGroupForDevices
    ),
    takeLatest(TYPES.GET_ALL_DEVICE_TASKS, deviceActions.fetchAllDeviceTasks),
    takeLatest(TYPES.CREATE_DEVICE_TASKS, deviceActions.createDeviceTasks),
    takeLatest(TYPES.UPDATE_DEVICE_TASK, deviceActions.updateDeviceTask),
    takeLatest(TYPES.DELETE_DEVICE_TASK, deviceActions.deleteDeviceTask),
    takeLatest(TYPES.GET_PRESSURE_DATA, deviceActions.fetchPressureData),
    takeLatest(
      TYPES.GET_DEVICE_FILL_LEVELS,
      deviceActions.fetchDeviceFillLevels
    ),
    takeLatest(TYPES.CREATE_SCALE_USER_GROUP, createScaleUserGroup),
    takeLatest(TYPES.DELETE_SCALE_USER_GROUP, deleteScaleUserGroup),
    takeLatest(TYPES.UPDATE_SCALE_USER_GROUP, updateScaleUserGroup),
    takeLatest(
      TYPES.UPDATE_OPERATOR_GROUP_FOR_DEVICES,
      deviceActions.updateOperatorGroupForDevices
    ),
    takeLatest(TYPES.FETCH_SCALE_REPORTS, fetchScaleReports),
    takeLatest(TYPES.FETCH_SCALE_RESULTS, fetchScaleResults),
    takeLatest(TYPES.FETCH_SCALE_REPORT_MONTHS, fetchScaleReportMonths),
    takeLatest(TYPES.ADMIN_IMPORT_SCALE_TAGS, importScaleTags),
    takeLatest(TYPES.FETCH_SCALE_REPORT_SETTINGS, fetchScaleReportSettings),
    takeLatest(
      TYPES.FETCH_SCALE_TOTAL_WEIGHT_LIMIT_NOTIFICATION_SETTINGS,
      fetchScaleWeightLimitNotificationSettings
    ),
    takeLatest(TYPES.UPDATE_SCALE_REPORT_SETTINGS, updateScaleReportSettings),
    takeLatest(
      TYPES.UPDATE_SCALE_TOTAL_WEIGHT_LIMIT_NOTIFICATION_SETTINGS,
      updateScaleTotalWeightLimitNotificationSettings
    ),
    takeLatest(TYPES.RESEND_SCALE_REPORT, resendScaleReport),
    call(deviceActions.watchFetchDevicesRequests),
    takeLatest(TYPES.FETCH_SCALES, fetchScales),
    takeLatest(TYPES.FETCH_DASHBOARD, fetchDashboard),
    takeLatest(TYPES.ADMIN_UPDATE_SCALE, updateScale),
    takeLatest(TYPES.FETCH_SCALE_FIRMWARES, fetchFirmwares),
    takeLatest(TYPES.POST_SCALE_UPLOAD_FILE, postScaleFirmwareFile),
    takeLatest(
      TYPES.POST_DASHBOARD_WORK_LOG_UPDATE,
      postDashboardWorkLogUpdate
    ),
    takeLatest(TYPES.FETCH_ANOMALIES, fetchAnomalies),
    takeLatest(TYPES.UPDATE_ANOMALIES, updateAnomalies),
    takeLatest(TYPES.USER_SURVEY_ANSWERED, markSurveyAnswered),
  ]);
}

export default sagas;
