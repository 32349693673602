import styled from "styled-components";

interface ButtonProps {
  margin?: string;
}
const BaseButton = styled.button<ButtonProps>`
  margin: ${(props) => props.margin || 0};
  padding: 0.4em 0.4em;
  font-size: 0.875rem;

  font-weight: 500;
  font-family: "Roboto";
  transition: background-color 0.1s linear;

  outline: none;
  border-radius: 4px;
  border-style: none;
  cursor: pointer;

  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const RoundDenimButton = styled(BaseButton)`
  background-color: var(--denim);
  color: var(--ice-blue);
  border-radius: 1em;
  padding: 0.4em 0.8em;

  :hover {
    background-color: var(--denim-dark);
  }
`;

export const SecondaryButtonSmall = styled(BaseButton)`
  padding: 0.2em 0.4em;
  background-color: var(--col-11-a-3-c-7);
  color: #ffffff;

  :hover {
    background-color: var(--col-11-a-3-c-7-dark);
  }
`;

export const DarkButton = styled(BaseButton)`
  padding: 0.4em 0.8em;
  background-color: var(--col-214269);
  color: #ffffff;

  :hover {
    background-color: var(--col-214269-dark);
  }
`;

export const PrimaryButtonLarge = styled(BaseButton)`
  padding: 0.4em 2em;
  background-color: var(--twilight-blue);
  color: #ffffff;
  text-transform: uppercase;

  :hover {
    background-color: var(--twilight-blue-dark);
  }
`;

export const ConfirmButtonLarge = styled(BaseButton)`
  padding: 0.4em 2em;
  background-color: var(--seafoam-blue);
  color: #ffffff;
  text-transform: uppercase;

  :hover {
    background-color: var(--twilight-blue-dark);
  }
`;

export const CancelButtonLarge = styled(BaseButton)`
  padding: 0.4em 2em;
  background-color: #cc6666;
  color: #ffffff;
  text-transform: uppercase;

  :hover {
    background-color: var(--twilight-blue-dark);
  }
`;
