import { useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { FormRectangle } from "../../../../components/Common/FormRectangle";
import { FormTitle } from "../../../../components/Common/FormTitle";

import {
  defaultDeviceErrorTaskEmailTexts,
  defaultDeviceErrorTaskErrorThresholds,
} from "@shared/constants";
import {
  useCreateDeviceErrorTask,
  useDeviceErrorTaskCreateFormData,
  useDeviceErrorTaskList,
} from "../../../../queries/deviceErrorTask";
import { DeviceErrorTaskCreateApi } from "@shared/apiTypes";
import { Spinner } from "../../../../components/Spinner";
import { SelectGroupForm } from "./SelectGroupForm";
import {
  ErrorTaskForm,
  ErrorTaskFormValues,
  ErrorTaskFormProps,
} from "./ErrorTaskForm";

function formValuesToApi(
  groupId: number,
  values: ErrorTaskFormValues
): DeviceErrorTaskCreateApi {
  const commonValues = {
    groupId,
    name: values.name,
    includeChildGroupDevices: values.includeChildGroupDevices,
    emailRecipients: values.emailRecipients
      .split("\n")
      .filter(Boolean)
      .map((email) => email.trim()),
    emailTexts: values.emailTexts,
    errorThresholdSeconds: values.errorThresholdSeconds,
  };

  return values.sharedForAllDevices
    ? {
        sharedForAllDevices: values.sharedForAllDevices,
        sharedTriggerStates: values.sharedTriggerStates,
        ...commonValues,
      }
    : {
        sharedForAllDevices: values.sharedForAllDevices,
        deviceTriggerStates: values.deviceTriggerStates
          .filter(
            (devStates) =>
              values.includeChildGroupDevices || devStates.owner === "self"
          )
          .map((devStates) => ({
            deviceId: devStates.id,
            triggerStates: [...devStates.triggerStates.entries()]
              .filter(([, enabled]) => enabled)
              .map(([state]) => state),
          })),
        ...commonValues,
      };
}

type FormContainerProps = Pick<
  ErrorTaskFormProps,
  "onSubmit" | "onBackClick"
> & { group: { name: string | undefined; id: number } };
export function FormContainer(props: FormContainerProps) {
  const formData = useDeviceErrorTaskCreateFormData(props.group.id);

  if (formData.isLoading) {
    return <Spinner />;
  } else if (formData.data) {
    return (
      <ErrorTaskForm
        groupName={props.group.name ?? "Group name not available"}
        submitText="Create"
        initialValues={{
          name: "",
          sharedForAllDevices: true,
          includeChildGroupDevices: true,
          sharedTriggerStates: [],
          deviceTriggerStates: formData.data.deviceTriggerStates,
          emailRecipients: "",
          emailTexts: defaultDeviceErrorTaskEmailTexts,
          errorThresholdSeconds: defaultDeviceErrorTaskErrorThresholds,
        }}
        {...props}
      />
    );
  }

  return <div>Unable to load form</div>;
}

export function ErrorTaskCreate(props: RouteComponentProps) {
  const [group, setGroup] = useState<{ id: number; name: string } | null>(null);
  const errorTasks = useDeviceErrorTaskList();
  const createTask = useCreateDeviceErrorTask();

  const groupsWithTask = new Set(
    errorTasks.data?.map((task) => task.groupId) ?? []
  );

  return (
    <div>
      <FormRectangle style={{ width: "60rem", marginTop: "2rem" }}>
        <FormTitle title="Create error task" />
        {group === null ? (
          <SelectGroupForm
            onSelect={setGroup}
            disabledGroupIds={groupsWithTask}
          />
        ) : (
          <FormContainer
            group={group}
            onBackClick={() => setGroup(null)}
            onSubmit={(values, stopSubmitting) =>
              createTask.mutate(formValuesToApi(group.id, values), {
                onSettled: stopSubmitting,
                onSuccess: () =>
                  props.history.replace("/admin/devices/tasks/device-error"),
              })
            }
          />
        )}
      </FormRectangle>
    </div>
  );
}
